export const salesInfoDictionary = {
  type: 'Operação',
  date: 'Data',
  hour: 'Hora',
  description: 'Descrição',
  installments: 'Parcelas',
  brand: 'Bandeira',
  cardNumber: 'Cartão',
  ref: 'REF',
  saleCode: 'Código da venda',
  authorizer: 'Autorizador',
} as const;
