// Para calcular a opacidade de um hex é só utilizar o site abaixo
// e adicionar no final do hex
// https://www.joshuamiron.com/percent-to-hex-converter
// Ou
// veja todos listados aq https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4

export const brandColors = {
  carbon: '#242424',
  marble: '#FAFAFA',
} as const;

export const brandVariants = {
  carbon: {
    10: `${brandColors.carbon}1A`,
    20: `${brandColors.carbon}33`,
    35: `${brandColors.carbon}59`,
    60: `${brandColors.carbon}99`,
    70: `${brandColors.carbon}B3`,
    90: `${brandColors.carbon}E6`,
    100: `${brandColors.carbon}FF`,
  },
  marble: {
    10: `${brandColors.marble}1A`,
    20: `${brandColors.marble}33`,
    35: `${brandColors.marble}59`,
    60: `${brandColors.marble}99`,
    90: `${brandColors.marble}E6`,
    100: `${brandColors.marble}FF`,
  },
} as const;

export const backgroundColors = {
  light: {
    surface: {
      default: '#F8F8FA',
      secondary: '#FFFFFF',
      tertiary: '#F7F7F7',
      marbleDark: '#F5F5F5',
    },
    negative: {
      primary: '#C93532',
      secondary: '#FFEBE9',
    },
    positive: {
      primary: '#00796C',
      medium: '#00A393',
      secondary: '#E9F6F3',
    },
    info: {
      primary: '#0080A8',
      secondary: '#EDF5F9',
    },
    warning: {
      primary: '#CC7B00',
      secondary: '#FEF6E1',
    },
    brand: {
      default: brandColors.carbon,
      primary: '#FFCE2E',
      secondary: '#FFE552',
    },
    neutral: {
      black: '#000000',
      darkest: '#3E3E3E',
      dark: '#606060',
      mediumDark: '#B9B9B9',
      mediumLight: '#DCDCDC',
      light: '#EDEDED',
      lightest: '#F5F5F5',
      white: '#ffffff',
      overlay: '#1212128F',
    },
  },
  dark: {
    surface: {
      default: '#121212',
      secondary: '#242424',
      tertiary: '#303030',
      marbleDark: '#F5F5F5',
    },
    negative: {
      primary: '#C93532',
      secondary: '#5D0001',
    },
    positive: {
      primary: '#00A090',
      secondary: '#20352F',
    },
    info: {
      primary: '#006C8E',
      secondary: '#003B4F',
    },
    warning: {
      primary: '#CC7B00',
      secondary: '#FEF6E1',
    },
    brand: {
      default: '#FBFBFB',
      primary: '#FFCE2E',
      secondary: '#FFE552',
    },
    neutral: {
      black: '#000000',
      darkest: '#3E3E3E',
      dark: '#606060',
      mediumDark: '#B9B9B9',
      mediumLight: '#DCDCDC',
      light: '#EDEDED',
      lightest: '#F5F5F5',
      white: '#ffffff',
      overlay: '#1212128F',
    },
    overlay: '#1212128F',
  },
} as const;

export const textColors = {
  light: {
    title: `${brandColors.carbon}`,
    subtitle: `${brandColors.carbon}E6`,
    paragraph: `${brandColors.carbon}B3`,
    disabled: `${brandColors.carbon}A6`,
    dark: '#606060',
    inverse: '#FFFFFF',
    negative: '#D70015',
    positive: '#005B52',
    link: '#0275C9',
    linkHighLight: '#F4BF00',
    white: '#FFFFFF',
  },
  dark: {
    title: `${brandColors.marble}`,
    subtitle: `${brandColors.marble}E6`,
    paragraph: `${brandColors.marble}B3`,
    disabled: `${brandColors.marble}A6`,
    inverse: brandColors.carbon,
    negative: '#FF6961',
    positive: '#30DB5B',
    link: '#0275C9',
    linkHighLight: '#409CFF',
  },
} as const;

export const compositionColors = {
  light: {
    icon: {
      primary: '#666666',
      secondary: `#3A3A3A`,
      tertiary: '#FFFFFF',
      disabled: '#929292',
      negative: '#D70015',
      positive: '#005B52',
    },
    fill: {
      primary: '#E8E8E8',
      secondary: `#D6D6D6`,
    },
    stroke: {
      8: `${brandColors.carbon}14`,
      16: `${brandColors.carbon}29`,
      32: `${brandColors.carbon}52`,
    },
    inverseStroke: {
      8: `${brandColors.marble}14`,
      16: `${brandColors.marble}29`,
      32: `${brandColors.marble}52`,
    },
    status: {
      negative: '#D70015',
      active: `#00A167`,
      attention: '#FF8946',
      intermediate: '#5755BE',
    },
  },
  dark: {
    icon: {
      primary: `${brandColors.marble}BF`,
      secondary: brandColors.marble,
      tertiary: '#3A3A3A',
      disabled: `${brandColors.marble}99`,
      negative: '#FF6961',
      positive: '#30DB5B',
    },
    fill: {
      primary: '#494949',
      secondary: `#3F3F3F`,
    },
    stroke: {
      8: `${brandColors.marble}14`,
      16: `${brandColors.marble}29`,
      32: `${brandColors.marble}52`,
    },
    inverseStroke: {
      8: `${brandColors.carbon}14`,
      16: `${brandColors.carbon}29`,
      32: `${brandColors.carbon}52`,
    },
    status: {
      negative: '#FF6961',
      active: `#30DB5B`,
      attention: '#FFB340',
      intermediate: '#7D7AFF',
    },
  },
} as const;

export const supportColors = {
  light: {
    pink: '#D30F45',
    green: `#00A167`,
    orange: '#FF8946',
    indigo: '#5755BE',
    purple: '#DA8FFF',
    grey: `${brandColors.carbon}E6`,
    yellow: '#FFCE2E',
    OutlineInput: '#DD1003',
  },
  dark: {
    pink: '#FF6482',
    green: `#30DB5B`,
    orange: '#FFB340',
    indigo: '#7D7AFF',
    purple: '#DA8FFF',
    grey: `${brandColors.marble}E6`,
    yellow: '#FFCE2E',
    OutlineInput: '#DD1003',
  },
} as const;
