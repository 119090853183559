import type { FunctionComponent } from 'react';
import React from 'react';

import type { Nullable } from '@portal-types/helpers';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';

import Typography from 'components/UI/Typography';
import { longDateAndTime } from 'helpers/date';

const useStyles = makeStyles((theme) => ({
  sectionGap: {
    display: 'grid',
    gap: theme.spacing(3),
  },
  boxAlert: {
    marginTop: theme.spacing(4),
    maxWidth: 328,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 228,
      margin: 0,
    },
  },
}));

type Props = {
  title: string;
  subtitle?: Nullable<string>;
  updatedAt?: string;
  updatedAtLoading?: boolean;
  sideTitleAlert?: React.ReactNode;
};

const PageSection: FunctionComponent<Props> = ({
  children,
  title,
  subtitle,
  updatedAt,
  updatedAtLoading,
  sideTitleAlert,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.sectionGap}>
      <Box display="flex" justifyContent="space-between" alignContent="center" flexWrap="wrap">
        <Box>
          <Typography variant="subtitleWebBold">{title}</Typography>
          {updatedAtLoading && <Skeleton width={320} height={24} />}
          {updatedAt && !updatedAtLoading && (
            <Typography variant="body">{`Última atualização ${longDateAndTime(updatedAt)}`}</Typography>
          )}
          {subtitle && (
            <Box mt={1}>
              <Typography variant="caption">{subtitle}</Typography>
            </Box>
          )}
        </Box>
        <Box className={classes.boxAlert}>{sideTitleAlert}</Box>
      </Box>

      <Box>{children}</Box>
    </Box>
  );
};

export default PageSection;
