export const installmentsOptions = [
  { value: 1, label: 'À vista' },
  { value: 2, label: 'Até 2 vezes' },
  { value: 3, label: 'Até 3 vezes' },
  { value: 4, label: 'Até 4 vezes' },
  { value: 5, label: 'Até 5 vezes' },
  { value: 6, label: 'Até 6 vezes' },
  { value: 7, label: 'Até 7 vezes' },
  { value: 8, label: 'Até 8 vezes' },
  { value: 9, label: 'Até 9 vezes' },
  { value: 10, label: 'Até 10 vezes' },
  { value: 11, label: 'Até 11 vezes' },
  { value: 12, label: 'Até 12 vezes' },
];
