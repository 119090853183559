import React, { FunctionComponent, useState } from 'react';

import { Grid, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';

import PageTitle from 'components/UI/PageTitle';

import MyAnticipations from './MyAnticipations';
import Simulation from './Simulation';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(6, 0),

    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(4, 0),
    },

    '& > :first-child': {
      marginBottom: theme.spacing(4),
    },
  },
  pageTabs: {
    borderBottom: `1px solid ${theme.palette.carbon[10]}`,
    padding: 0,
    marginBottom: theme.spacing(5),
  },
  tabPanel: {
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
  },
}));

const Anticipation: FunctionComponent = () => {
  const classes = useStyles();

  const [pageTab, setPageTab] = useState('simulator');

  const changeTab = (e: React.ChangeEvent<{}>, value: string) => {
    setPageTab(value);
  };

  return (
    <Grid container justify="center" alignItems="center" className={classes.container}>
      <Grid item xs={12}>
        <PageTitle title="Antecipação" />
      </Grid>

      <TabContext value={pageTab}>
        <Grid item xs={12}>
          <TabList onChange={changeTab} variant="fullWidth" indicatorColor="primary" className={classes.pageTabs}>
            <Tab label="Simulador de antecipações" value="simulator" disableRipple />
            <Tab label="Histórico de antecipações" value="my-anticipations" disableRipple />
          </TabList>
        </Grid>

        <Grid item xs={12}>
          <TabPanel value="simulator" className={classes.tabPanel}>
            <Simulation setPageTab={setPageTab} />
          </TabPanel>
        </Grid>

        <Grid item xs={12}>
          <TabPanel value="my-anticipations" className={classes.tabPanel}>
            <MyAnticipations setPageTab={setPageTab} />
          </TabPanel>
        </Grid>
      </TabContext>
    </Grid>
  );
};

export default Anticipation;
