import { formatUserDocument } from 'helpers/formatters/formatters';
import { FeatureFlags } from 'models/featureToggle';

import { client } from './client';

type GetFeatureFlags = { data?: FeatureFlags; errorMessage?: string };

export default async function featureFlagService(token: string, documentNumber: string): Promise<GetFeatureFlags> {
  try {
    const { parsedBody: body } = await client.get<GetFeatureFlags['data']>(`portal/v1/feature-flags`, {
      token,
      headers: {
        'document-number': formatUserDocument(documentNumber),
      },
      useWhitelabel: true,
    });

    if (body) {
      return { data: body };
    }

    throw new Error('Ocorreu um erro ao buscar as features');
  } catch (e) {
    return {
      errorMessage: 'Ocorreu um erro ao buscar as features',
    };
  }
}
