import React from 'react';
import type { FunctionComponent, ReactElement } from 'react';

import { Box, useMediaQuery } from '@material-ui/core';
import type { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';

import Typography from 'components/UI/Typography';
import { longDateAndTime } from 'helpers/date';

const useStyles = makeStyles((theme) => ({
  gridGap: {
    display: 'grid',
    gap: theme.spacing(1, 0),
  },
}));

type Props = {
  title: string;
  subtitle?: string;
  isLoading?: boolean;
  updatedAt?: string;
};

const PageTitle: FunctionComponent<Props> = ({ title, subtitle, isLoading, updatedAt }): ReactElement => {
  const classes = useStyles();
  const xsScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

  return (
    <Box className={classes.gridGap}>
      <Box>
        <Typography variant="subtitleWebBold">{title}</Typography>
      </Box>
      {subtitle && (
        <Box>
          <Typography variant="body">{subtitle}</Typography>
        </Box>
      )}

      {isLoading ? (
        <Box>
          <Skeleton width={xsScreen ? 220 : 320} height={24} data-testid="skeleton-page-title" />
        </Box>
      ) : (
        updatedAt && (
          <Box>
            <Typography variant="body">{`Última atualização ${longDateAndTime(updatedAt)}`}</Typography>
          </Box>
        )
      )}
    </Box>
  );
};

export default PageTitle;
