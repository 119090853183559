import type { FunctionComponent } from 'react';
import React from 'react';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Typography from 'components/UI/Typography';
import { parse } from 'date-fns';
import { longLocalizedDate } from 'helpers/date';
import type { SaleItem } from 'models/sales';

import SalesGroupSale from './Sale';

const useStyles = makeStyles((theme) => ({
  root: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(4),
    },
  },
  groupHeader: {
    backgroundColor: theme.palette.backgroundColor.surface?.marbleDark,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

type Props = {
  date: string;
  total: number;
  list: SaleItem[];
};

const SalesGroup: FunctionComponent<Props> = ({ date, list }) => {
  const classes = useStyles();

  const parsedDate = parse(date, 'yyyy-MM-dd', new Date());
  return (
    <Box className={classes.root}>
      <Box className={classes.groupHeader}>
        <Typography variant="bodyBold">{longLocalizedDate(parsedDate)}</Typography>
      </Box>
      {list.map((sale) => (
        <SalesGroupSale sale={sale} key={sale.id} />
      ))}
    </Box>
  );
};

export default SalesGroup;
