/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import type { FunctionComponent } from 'react';

import { Snackbar as MUISnackbar } from '@material-ui/core';
import type { SnackbarProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Alert } from '@material-ui/lab';
import type { AlertProps } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  snackbar: {
    width: '100%',
    maxWidth: 600,
    padding: theme.spacing(0, 6, 3),
    [theme.breakpoints.down('xs')]: {
      left: 0,
    },
  },
  errorIcon: {
    color: theme.palette.common.white,
  },
}));

type Props = {
  snackbarProps?: SnackbarProps;
  alertProps?: AlertProps;
  message: string;
  open: boolean;
  onClose?: () => void;
};

const Snackbar: FunctionComponent<Props> = ({ snackbarProps, alertProps, message, open, onClose }) => {
  const classes = useStyles();
  const [snackOpen, setSnackOpen] = useState(open);

  const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackOpen(false);
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    setSnackOpen(open);
  }, [open]);

  return (
    <MUISnackbar
      className={classes.snackbar}
      open={snackOpen}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      {...snackbarProps}
    >
      <Alert variant="filled" iconMapping={{ success: <CheckCircleIcon /> }} onClose={handleClose} {...alertProps}>
        {message}
      </Alert>
    </MUISnackbar>
  );
};

export default Snackbar;
