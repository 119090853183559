export const salesInfoDictionary = {
  operation: 'Operação',
  formattedInstallments: 'Parcela',
  date: 'Data',
  hour: 'Hora',
  cardBrand: 'Bandeira',
  cardNumber: 'Cartão',
  nsu: 'NSU',
  orderId: 'Código da venda',
  financialInstitutionName: 'Instituição Financeira',
  financialInstitution: 'CNPJ Instituição Financeira',
} as const;
