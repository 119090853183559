import type { FunctionComponent } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { Box, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { fade, makeStyles } from '@material-ui/core/styles';

import type { RouteItem } from 'models/routes';
import { WhitelabelSelectors } from 'store/whitelabel';

const useStyles = makeStyles((theme) => ({
  listItem: {
    position: 'relative',
    padding: 0,
  },
  link: {
    color: fade(theme.palette.secondary.contrastText, 0.9),
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    width: 232,
    textDecoration: 'none',
    '&:focus, &:hover, &[data-active="true"]': {
      '& > div': {
        backgroundColor: fade(theme.palette.secondary.contrastText, 0.12),
      },
    },
    '&[data-active="true"]': {
      '& .MuiTypography-root': {
        fontWeight: 'bold',
      },
      '& svg': {
        color: theme.palette.primary.main,
        fill: theme.palette.primary.main,
      },
      '&::before': {
        position: 'absolute',
        left: 0,
        backgroundColor: theme.palette.primary.main,
        width: 4,
        height: 40,
        content: '""',
        borderRadius: `0 ${theme.shape.borderRadius * 2}px ${theme.shape.borderRadius * 2}px 0`,
      },
    },
  },
  itemWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 40,
    borderRadius: theme.shape.borderRadius * 2,
    paddingLeft: theme.spacing(1),
  },
  listItemIcon: {
    minWidth: 0,
    marginRight: theme.spacing(1),
    color: fade(theme.palette.secondary.contrastText, 0.9),
  },
}));

type Props = {
  item: RouteItem;
  active: boolean;
};

const SideMenuNavigationGroupItem: FunctionComponent<Props> = ({ item, active }) => {
  const classes = useStyles();

  const whitelabelTitle = useSelector(WhitelabelSelectors.whitelabelTitle);

  const { path, title, icon: Icon, hasWhitelabelTitle } = item;
  const completeTitle = hasWhitelabelTitle ? `${title} ${whitelabelTitle}` : title;

  return (
    <ListItem className={classes.listItem}>
      <RouterLink to={path} className={classes.link} data-active={active}>
        <Box className={classes.itemWrapper}>
          <ListItemIcon className={classes.listItemIcon}>
            <Icon fontSize="small" />
          </ListItemIcon>

          <ListItemText primary={completeTitle} primaryTypographyProps={{ variant: 'body2' }} />
        </Box>
      </RouterLink>
    </ListItem>
  );
};

export default SideMenuNavigationGroupItem;
