import React, { ReactElement } from 'react';

import { Grid } from '@material-ui/core';

import Button from 'components/UI/Button';
import Typography from 'components/UI/Typography';
import { ContentPage } from 'models/faq';

type ResponseDrawerProps = {
  title: string;
  answer: ReactElement | null;
  setContentPage: (contentPage: ContentPage) => void;
};

const AnswerPage = ({ answer, title, setContentPage }: ResponseDrawerProps): JSX.Element => {
  return (
    <Grid container item xs={12}>
      <Typography variant="subtitleWebBold" gutterBottom paragraph>
        {title}
      </Typography>
      <Grid item xs={12}>
        {answer && answer}
      </Grid>
      <Grid container item xs={12} justify="center">
        <Button
          size="large"
          type="button"
          variant="contained"
          color="primary"
          title="Voltar para pagina de dúvidas frequetes"
          aria-label="Voltar para pagina de dúvidas frequetes"
          onClick={() => setContentPage('main')}
        >
          Voltar
        </Button>
      </Grid>
    </Grid>
  );
};

export default AnswerPage;
