import { formatUserDocument } from 'helpers/formatters/formatters';
import { PaymentLinkGroup } from 'models/paymentLink';
import type { ResponsePaymentLinkPost, CreatePaymentLink } from 'models/paymentLink';
import { client } from 'services/client';

type GetAllPaymentLinksParsed = { data?: PaymentLinkGroup; errorMessage?: string };

export const getPaymentLinks = async (page: number): Promise<GetAllPaymentLinksParsed> => {
  try {
    const localStorageAuthJson = localStorage.getItem('persist:auth') as string;
    const localStorageAuth = JSON.parse(localStorageAuthJson);
    const localStorageAuthData = JSON.parse(localStorageAuth.data);

    const localStorageAccountJson = localStorage.getItem('persist:account') as string;
    const localStorageAccount = JSON.parse(localStorageAccountJson);
    const localStorageAccountData = JSON.parse(localStorageAccount.data);

    const response = await client.get<PaymentLinkGroup>(`portal/v1/payment-links?page=${page}`, {
      token: localStorageAuthData.accessToken,
      useWhitelabel: true,
      headers: {
        'document-number': formatUserDocument(localStorageAccountData.documentNumber),
      },
    });
    if (response.parsedBody) {
      return {
        data: response.parsedBody,
      };
    }

    throw new Error('Ocorreu um erro ao buscar os Links de pagamento');
  } catch (error) {
    return { errorMessage: error.message };
  }
};

export const postPaymentLink = async ({
  amount,
  description,
  credit,
  debit,
}: CreatePaymentLink): Promise<ResponsePaymentLinkPost> => {
  try {
    const localStorageAuthJson = localStorage.getItem('persist:auth') as string;
    const localStorageAuth = JSON.parse(localStorageAuthJson);
    const localStorageAuthData = JSON.parse(localStorageAuth.data);

    const localStorageAccountJson = localStorage.getItem('persist:account') as string;
    const localStorageAccount = JSON.parse(localStorageAccountJson);
    const localStorageAccountData = JSON.parse(localStorageAccount.data);

    const response = await client.post<ResponsePaymentLinkPost['data']>(`portal/v1/payment-links`, {
      token: localStorageAuthData.accessToken,
      useWhitelabel: true,
      headers: {
        'document-number': formatUserDocument(localStorageAccountData.documentNumber),
        'content-type': 'application/json',
      },
      data: {
        amount,
        description,
        debit,
        credit,
      },
    });

    if (response.parsedBody) {
      return {
        data: response.parsedBody,
      };
    }

    throw new Error('Ocorreu ao criar um novo Link de pagamento');
  } catch (error) {
    return { errorMessage: error.message };
  }
};
