import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';

import { Box, Card, IconButton, Modal, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import Button from 'components/UI/Button';
import Typography from 'components/UI/Typography';
import { formatDoubleValue, formatNumberOfDays } from 'helpers/formatters/formatters';
import { TaxesValues } from 'models/taxes';

import CommercialConditionsCardSkeleton from './Skeleton';

const useStyles = makeStyles((theme) => ({
  cardCell: {
    gridColumnEnd: 'span 4',

    [theme.breakpoints.down('md')]: {
      gridColumnEnd: 'span 6',
    },
    [theme.breakpoints.down('xs')]: {
      gridColumnEnd: 'span 12',
    },
  },
  machineCard: {
    maxWidth: '328px',
  },
  table: {
    margin: theme.spacing(2, 0),
    '& thead': {
      backgroundColor: theme.palette.backgroundColor.neutral?.lightest,
    },
    '& th, td': {
      padding: theme.spacing(2, 1),
    },
  },
  tableModal: {
    margin: theme.spacing(2, 0, 0),
    '& thead': {
      backgroundColor: theme.palette.backgroundColor.neutral?.lightest,
    },
    '& th, td': {
      padding: theme.spacing(1, 1),
    },
  },
  modalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    position: 'relative',
    padding: theme.spacing(3),
    border: `1px solid ${theme.palette.carbon[10]}`,
    background: theme.palette.common.white,
    borderRadius: 8,
    width: 310,
    overflowY: 'auto',
    outline: 'none',
    height: 'auto',
    maxHeight: '90vh',
  },
}));

type Props = {
  icon: ReactElement;
  isLoading: boolean;
  title: string;
  subtitle?: string;
  cardData?: Array<{
    operation: string;
    receiving: number;
    tax: number;
  }>;
};

const TableHeadCard = ({ texts }: { texts: string[] }) => {
  return (
    <TableHead>
      <TableRow>
        {texts.map((text) => (
          <TableCell key={text}>
            <Typography variant="caption3">{text}</Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

type TableBodyRows = {
  rows: Array<{
    title: string;
    tax: number;
    receiving: number;
    isWeekDay: boolean;
  }>;
};

const TableBodyCustom: FunctionComponent<TableBodyRows> = ({ rows }) => {
  return (
    <TableBody>
      {rows.map(({ title, tax, receiving, isWeekDay }) => (
        <TableRow key={`${title}${tax}${receiving}`}>
          <TableCell component="th" scope="row">
            <Typography variant="caption">{title}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="caption">{`${formatDoubleValue(tax)}%`}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="caption">{formatNumberOfDays(receiving, isWeekDay)}</Typography>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

const ComercialConditionsCard: FunctionComponent<Props> = ({ icon: Icon, title, subtitle, cardData, isLoading }) => {
  const classes = useStyles();
  const [debit, setDebit] = useState<TaxesValues>();
  const [credit, setCredit] = useState<TaxesValues>();
  const [installments, setInstallments] = useState<TaxesValues[]>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const headTexts = ['Operação', 'Taxa', 'Receba em'];

  useEffect(() => {
    if (cardData) {
      setDebit(cardData?.filter((data) => data.operation.includes('DÉBITO'))[0]);
      setCredit(cardData?.filter((data) => data.operation.includes('CRÉDITO'))[0]);
      setInstallments(cardData?.filter((data) => data.operation.match('(PARC.|PARCELADO) SEM')));
    }
  }, [cardData]);

  if (isLoading || !debit || !credit || !installments) {
    return (
      <div className={classes.cardCell}>
        <CommercialConditionsCardSkeleton type="link" />
      </div>
    );
  }

  if (!cardData) {
    return null;
  }

  return (
    <div className={classes.cardCell}>
      <Card className={classes.machineCard}>
        <Box display="flex">
          {Icon}
          <Box ml={1}>
            <Typography variant="bodyBold">{title}</Typography>
            <Typography variant="caption">{subtitle}</Typography>
          </Box>
        </Box>

        <Table className={classes.table} aria-label="simple table">
          <TableHeadCard texts={headTexts} />
          <TableBodyCustom
            rows={[
              {
                title: 'Débito',
                tax: debit?.tax,
                receiving: debit?.receiving,
                isWeekDay: true,
              },
              {
                title: 'Crédito à vista',
                tax: credit?.tax,
                receiving: credit?.receiving,
                isWeekDay: false,
              },
              {
                title: 'Crédito até 12x',
                tax: installments?.find((inst) => inst.operation.includes('12'))?.tax!,
                receiving: installments?.find((inst) => inst.operation.includes('12'))?.receiving!,
                isWeekDay: false,
              },
            ]}
          />
        </Table>

        <Button
          onClick={() => setIsModalOpen(true)}
          variant="text"
          value="Ver mais condições de taxas"
          aria-label={`Ver mais condições de taxas para ${title}`}
          fullWidth
        >
          Ver mais condições
        </Button>
      </Card>
      <Modal
        className={classes.modalContainer}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="Veja as condições de taxas."
        aria-describedby="Veja as condições de taxas de parcelamento."
      >
        <Card className={classes.modalContent}>
          <Box position="absolute" top={0} right={0} mt={1} mr={1}>
            <IconButton onClick={() => setIsModalOpen(false)} aria-label="Fechar janela.">
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          <Box display="flex">
            {Icon}
            <Box ml={1}>
              <Typography variant="bodyBold">{title}</Typography>
              <Typography variant="caption">{subtitle}</Typography>
            </Box>
          </Box>

          <Table className={classes.tableModal} aria-label="simple table">
            <TableHeadCard texts={headTexts} />
            <TableBodyCustom
              rows={installments.map((installment) => ({
                title: `Crédito ${installment.operation.match(/\d+/g)}x`,
                tax: installment.tax,
                receiving: installment.receiving,
                isWeekDay: false,
              }))}
            />
          </Table>
        </Card>
      </Modal>
    </div>
  );
};

export default ComercialConditionsCard;
