import React, { useState } from 'react';
import type { FunctionComponent } from 'react';

import { Box, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';

import Button from 'components/UI/Button';
import ServerError from 'components/UI/ServerError';
import Tooltip from 'components/UI/Tooltip';
import Typography from 'components/UI/Typography';
import { formatAmount } from 'helpers/formatters/formatters';
import type { SimulationResponseData } from 'models/anticipation';
import { postAnticipation } from 'services/anticipation';

import AcquirerLabel from '../AcquirerLabel';
import AnticipationTypographyHighlight from '../TypographyHighlight';

const useStyles = makeStyles((theme) => ({
  carbon70: {
    color: theme.palette.carbon[70],
    textAlign: 'center',
  },
  neutralDark: {
    color: theme.palette.textColor.dark,
  },
  acquirerCards: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'start',
      '& > div': {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        '&:not(:last-child)': {
          marginBottom: theme.spacing(2),
        },
      },
    },
  },
  sectionButton: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column-reverse',
    flexWrap: 'wrap',
    '& > button:not(:last-child)': {
      marginTop: theme.spacing(2),
    },

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      flexWrap: 'nowrap',
      '& > button:not(:last-child)': {
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(0),
      },
    },
  },
}));

type Props = {
  simulation: SimulationResponseData;
  onEditSimulation: () => void;
  onConfirmAnticipation: () => void;
};

const AnticipationConfirmation: FunctionComponent<Props> = ({
  simulation,
  onEditSimulation,
  onConfirmAnticipation,
}) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [simulationError, setSimulationError] = useState(false);

  const parseSimulationToAnticipate = (data: SimulationResponseData) => {
    return data.acquirers.map(({ simulationId, grossAmount, taxAmount }) => ({
      simulationId,
      grossAmount,
      taxAmount,
    }));
  };

  const confirmAnticipation = () => {
    setLoading(true);

    const anticipates = parseSimulationToAnticipate(simulation);
    postAnticipation(anticipates)
      .then(({ data }) => {
        if (data && data.length) {
          onConfirmAnticipation();
        } else {
          throw new Error();
        }
      })
      .catch((_) => {
        setLoading(false);
        setSimulationError(true);
      })
      .finally(() => setLoading(false));
  };

  const { acquirers, grossAmount, netAmount, taxAmount } = simulation;

  return (
    <>
      {simulationError ? (
        <ServerError
          onTryAgain={() => {
            setSimulationError(false);
            confirmAnticipation();
          }}
          title="Ocorreu um erro"
          message="Não foi possível finalizar a sua antecipação. Tente novamente em instantes."
        />
      ) : (
        <Card>
          <Typography variant="bodyBold" component="h2" gutterBottom>
            Resumo da antecipação
          </Typography>
          <Typography variant="caption" className={classes.carbon70}>
            Verifique e confirme os dados abaixo para solicitar a sua antecipação
          </Typography>

          <Box my={4}>
            {acquirers.map((acquirer) => (
              <Card className={classes.acquirerCards} key={acquirer.acquirerDocumentNumber}>
                <Box minWidth={80}>
                  <AcquirerLabel documentNumber={acquirer.acquirerDocumentNumber} name={acquirer.acquirerName} />
                </Box>

                <Box>
                  <Typography variant="caption" className={classes.neutralDark}>
                    Recebíveis
                  </Typography>
                  <Typography variant="caption" component="p">{`R$ ${formatAmount(acquirer.grossAmount)}`}</Typography>
                </Box>
                <Box>
                  <Typography variant="caption" className={classes.neutralDark}>
                    Taxa
                  </Typography>
                  <Typography variant="caption" component="p">{`R$ ${formatAmount(acquirer.taxAmount)}`}</Typography>
                </Box>
                <Box>
                  <Typography variant="caption" className={classes.neutralDark}>
                    Total a Receber
                  </Typography>
                  <Typography variant="caption" component="p">{`R$ ${formatAmount(acquirer.netAmount)}`}</Typography>
                </Box>
              </Card>
            ))}
          </Box>

          <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
            <Typography variant="caption" component="p" className={classes.carbon70}>
              Total de recebíveis
            </Typography>

            <Typography variant="body2">{`R$ ${formatAmount(grossAmount)}`}</Typography>
          </Box>

          <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
            <Box display="flex">
              <Typography variant="caption" component="p" className={classes.carbon70}>
                Total de Taxas
              </Typography>

              <Box ml={1}>
                <Tooltip
                  title="As taxas cobradas são ponderadas de acordo com os tipos de operação e parcelas disponíveis para antecipar"
                  placement="top"
                  enterTouchDelay={1}
                  leaveTouchDelay={4000}
                  arrow
                >
                  <HelpOutlineRoundedIcon fontSize="small" className={classes.carbon70} />
                </Tooltip>
              </Box>
            </Box>

            <Typography variant="body2">{`R$ ${formatAmount(taxAmount)}`}</Typography>
          </Box>

          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="caption" component="p" className={classes.carbon70}>
              Total a receber
            </Typography>

            <AnticipationTypographyHighlight value={formatAmount(netAmount)} />
          </Box>

          <Box className={classes.sectionButton}>
            <Button
              fullWidth
              title="Editar Valor"
              aria-label="Editar valor da antecipação de recebíveis."
              variant="outlined"
              size="large"
              onClick={onEditSimulation}
              disabled={loading}
            >
              editar valor
            </Button>
            <Button
              fullWidth
              title="Confirmar Pedido"
              aria-label="Confirmar a antecipação de recebíveis."
              variant="contained"
              size="large"
              color="primary"
              onClick={confirmAnticipation}
              loading={loading}
            >
              confirmar
            </Button>
          </Box>
        </Card>
      )}
    </>
  );
};

export default AnticipationConfirmation;
