import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';

import { Box, CircularProgress, Grid, makeStyles } from '@material-ui/core';
import { InfoRounded } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';

import DetailsDrawer from 'components/DetailsDrawer';
import Button from 'components/UI/Button';
import EmptyList from 'components/UI/EmptyList';
import Typography from 'components/UI/Typography';
import { returnMonth } from 'helpers/date';
import { NotificationDates, NotificationType } from 'models/notification';
import { getNotifications } from 'services/notification';
import { AccountSelectors } from 'store/accounts';
import { CredentialsSelectors } from 'store/auth/credentials';

import Notification from './Notification';

const useStyles = makeStyles((theme) => ({
  wrapperTitle: {
    margin: theme.spacing(2, 0, 1),
    maxHeight: 34,
  },
  date: {
    color: theme.palette.carbon[70],
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    borderRadius: theme.shape.borderRadius,
  },
  buttonText: {
    color: theme.palette.textColor.link,
  },
  cardHeader: {
    padding: 0,
  },
  cardActions: {
    padding: 0,
  },
  monthTitle: {
    padding: theme.spacing(2, 0),
  },
  iconError: {
    fontSize: 40,
  },
  contentError: {
    height: 400,
    '& > div': {
      justifyContent: 'space-between',
      alignItems: 'center',
      '& > *': {
        marginBottom: theme.spacing(3),
      },
    },
  },
  cardLoading: {
    borderRadius: 8,
    height: 176,
    '& > span': {
      transform: 'scale(1, 0.9)',
    },
  },
}));

type NotificationListProps = {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  setNotificationUnreadCount: (p: number) => void;
};

const NotificationList = ({
  open,
  onClose,
  onOpen,
  setNotificationUnreadCount,
}: NotificationListProps): JSX.Element => {
  const classes = useStyles();

  const [notificationLoading, setNotificationLoading] = useState(true);
  const [notificationError, setNotificationError] = useState(false);
  const [notificationsList, setNotificationsList] = useState<NotificationDates | null>(null);
  const [notificationsCount, setNotificationsCount] = useState<number>(0);
  const [notificationCountUnread, setNotificationCountUnread] = useState<number>(0);

  const [hasNextPage, setHasNextPage] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);

  const accountDocumentNumber = useSelector(AccountSelectors.accountDocumentNumber);
  const accessToken = useSelector(CredentialsSelectors.credentialsAccessToken);

  const notificationsService = useCallback(
    (currPage: number) => {
      setNotificationError(false);
      setNotificationLoading(true);

      const notificationsUnread = (dates: NotificationDates) => {
        const monthsNotification = Object.keys(dates);
        monthsNotification.map((month) => {
          setNotificationCountUnread((prevState) => prevState + dates[month].countUnread);

          return setNotificationUnreadCount(notificationCountUnread);
        });
      };

      const newCurrentPage = currPage + 1;

      if (accountDocumentNumber && accessToken) {
        getNotifications({ currentPage: newCurrentPage, documentNumber: accountDocumentNumber, accessToken })
          .then((response) => {
            const { data } = response;
            if (data) {
              const { count, dates } = data;
              setNotificationsList(dates);
              setNotificationsCount(count);
              setCurrentPage(newCurrentPage);
              setHasNextPage(data.hasNextPage);
              notificationsUnread(dates);
            }
          })
          .catch(() => {
            setNotificationError(true);
          })
          .finally(() => setNotificationLoading(false));
      }
    },
    [accountDocumentNumber, accessToken, setNotificationUnreadCount, notificationCountUnread],
  );

  const nextPage = () => {
    notificationsService(currentPage);
  };

  const DrawerDetail = (): JSX.Element => {
    if (notificationError) {
      return (
        <Grid container direction="column" className={classes.contentError} justify="center">
          <Grid container direction="column">
            <InfoRounded color="error" className={classes.iconError} />
            <Typography variant="body" align="center">
              Algo deu errado e não tivemos uma resposta do servidor.
            </Typography>
            <Button variant="outlined" onClick={nextPage}>
              Tentar novamente
            </Button>
          </Grid>
        </Grid>
      );
    }

    if (notificationLoading) {
      return (
        <Grid item xs={12} container justify="flex-start">
          <Grid item xs={12} container justify="flex-start">
            <Skeleton animation="wave" variant="text" width="100%" height={36} />
            <Box width="100%" className={classes.cardLoading}>
              <Skeleton animation="wave" variant="text" height={176} />
            </Box>
            <Box width="100%" className={classes.cardLoading}>
              <Skeleton animation="wave" variant="text" height={176} />
            </Box>
            <Box width="100%" className={classes.cardLoading}>
              <Skeleton animation="wave" variant="text" height={176} />
            </Box>
          </Grid>
        </Grid>
      );
    }

    if (notificationsList && Object.keys(notificationsList).length) {
      return (
        <InfiniteScroll
          dataLength={notificationsCount}
          hasMore={hasNextPage}
          loader={<CircularProgress color="primary" />}
          next={nextPage}
        >
          {Object.keys(notificationsList).map((month) => {
            const {
              [month]: { notifications },
            } = notificationsList;
            return (
              <Grid key={month} item xs={12} container justify="flex-start">
                <Grid item xs={12} container justify="flex-start">
                  <Grid
                    item
                    xs={12}
                    container
                    justify="space-between"
                    alignItems="baseline"
                    className={classes.monthTitle}
                  >
                    <Typography variant="bodyBold">{returnMonth(month)}</Typography>
                    <Typography variant="caption2" component="p">
                      {`${notificationsList[month]?.countUnread} mensagens não lidas`}
                    </Typography>
                  </Grid>
                  {notifications.map((notification: NotificationType) => (
                    <Notification
                      key={notification.id}
                      id={notification.id}
                      createdAt={notification.createdAt}
                      subject={notification.subject}
                      description={notification.description}
                      imageUrl={notification.imageUrl}
                      isRead={notification.isRead}
                      cta={notification.cta}
                    />
                  ))}
                </Grid>
              </Grid>
            );
          })}
        </InfiniteScroll>
      );
    }

    return (
      <Grid item xs={12} direction="column" container justify="center" alignItems="center" style={{ height: '100%' }}>
        <EmptyList withoutBorder subtitle="Seus avisos e ofertas aparecerão automaticamente aqui." />
      </Grid>
    );
  };

  useEffect(() => {
    if (open) {
      notificationsService(0);
    }
  }, [open, notificationsService]);

  return (
    <DetailsDrawer open={open} onClose={onClose} onOpen={onOpen}>
      <Grid item xs={12} container justify="flex-start" className={classes.wrapperTitle}>
        {notificationLoading ? (
          <Skeleton variant="text" width="100%" height={36} />
        ) : (
          <Typography variant="subtitleMobile2">Notificação</Typography>
        )}
      </Grid>
      <DrawerDetail />
    </DetailsDrawer>
  );
};

export default NotificationList;
