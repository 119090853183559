import React, { useState, useEffect } from 'react';
import type { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { Badge, Box, Divider, IconButton, useMediaQuery } from '@material-ui/core';
import type { Theme } from '@material-ui/core/styles';
import { fade, makeStyles } from '@material-ui/core/styles';
import DehazeRoundedIcon from '@material-ui/icons/DehazeRounded';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';

import SideMenuUserArea from 'components/Header/UserArea';
import NotificationList from 'components/Notification';
import SideMenuMobileDrawer from 'components/SideMenu/Mobile/Drawer';
import Feature from 'components/UI/Feature';
import { Features } from 'models/featureToggle';
import { AccountSelectors } from 'store/accounts';
import { WhitelabelSelectors } from 'store/whitelabel';

const useStyles = makeStyles((theme) => {
  const colorSecondaryContrastText90 = fade(theme.palette.secondary.contrastText, 0.9);

  return {
    header: {
      height: 64,
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& > span': {
        color: colorSecondaryContrastText90,
      },
    },
    iconButton: {
      padding: 0,
      color: fade(theme.palette.secondary.contrastText, 0.9),
      marginRight: theme.spacing(2),
    },
    divider: {
      height: 32,
      width: 1,
      backgroundColor: fade(theme.palette.secondary.contrastText, 0.08),
      margin: theme.spacing(0, 2, 0, 0),
    },
  };
});

const Header: FunctionComponent = () => {
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const classes = useStyles();
  const location = useLocation();

  const whitelabelTitle = useSelector(WhitelabelSelectors.whitelabelTitle);
  const whitelabelAssets = useSelector(WhitelabelSelectors.whitelabelAssets)!;
  const accountUserName = useSelector(AccountSelectors.accountName);
  const accountDocumentNumber = useSelector(AccountSelectors.accountDocumentNumber);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const [notificationDrawerOpen, setNotificationDrawerOpen] = useState(false);
  const [notificationUnreadCount, setNotificationUnreadCount] = useState(0);

  useEffect(() => {
    setDrawerOpen(false);
  }, [location]);

  const handleOpenDrawer = () => {
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const handleCloseNotificationDrawer = () => {
    setNotificationDrawerOpen(false);
  };
  const handleOpenNotificationDrawer = () => {
    setNotificationDrawerOpen(true);
  };

  return (
    <>
      <SideMenuMobileDrawer open={drawerOpen} onClose={handleCloseDrawer} onOpen={handleOpenDrawer} />
      <Feature name={Features.NOTIFICATIONS}>
        <NotificationList
          open={notificationDrawerOpen}
          onClose={handleCloseNotificationDrawer}
          onOpen={handleOpenNotificationDrawer}
          setNotificationUnreadCount={setNotificationUnreadCount}
        />
      </Feature>
      <Box component="header" className={classes.header}>
        <Box display="flex">
          {matches && (
            <IconButton
              onClick={handleOpenDrawer}
              size="small"
              aria-label="botão para abrir o menu lateral"
              className={classes.iconButton}
            >
              <DehazeRoundedIcon />
            </IconButton>
          )}
          <img src={whitelabelAssets['logo-negative']} alt={whitelabelTitle} />
        </Box>
        <Box display="flex" alignItems="center">
          <IconButton
            size="small"
            aria-label="abrir central de notificações"
            className={classes.iconButton}
            onClick={handleOpenNotificationDrawer}
          >
            <Badge badgeContent={notificationUnreadCount} variant="dot" overlap="circle" color="primary">
              <NotificationsNoneIcon />
            </Badge>
          </IconButton>
          <IconButton
            size="small"
            aria-label="botão para abrir a página de ajuda"
            className={classes.iconButton}
            component={Link}
            to="/help"
          >
            <HelpOutlineOutlinedIcon />
          </IconButton>
          <Divider className={classes.divider} />
          <SideMenuUserArea userName={accountUserName || ''} documentNumber={accountDocumentNumber || ''} />
        </Box>
      </Box>
    </>
  );
};

export default Header;
