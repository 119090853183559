import React from 'react';
import type { FunctionComponent } from 'react';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Typography from 'components/UI/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5, 1),
    backgroundColor: theme.palette.success.light,
    borderRadius: theme.shape.borderRadius,
  },
  greenText: {
    color: theme.palette.success.dark,
  },
}));

type Props = {
  value: string;
};

const AnticipationTypographyHighlight: FunctionComponent<Props> = ({ value }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Typography variant="bodyBold" className={classes.greenText}>
        {`R$ ${value}`}
      </Typography>
    </Box>
  );
};

export default AnticipationTypographyHighlight;
