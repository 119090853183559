import React, { ChangeEvent, Dispatch, useState } from 'react';

import { Box, Checkbox, CheckboxProps, FormControlLabel, IconButton, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIconRounded from '@material-ui/icons/Close';

import { ReactComponent as IrIcon } from 'assets/images/icon-ir.svg';
import Button from 'components/UI/Button';
import Typography from 'components/UI/Typography';
import { dirfReportService } from 'services/dirf';

import { YEARS } from './constants';

const useStyles = makeStyles((theme) => ({
  modalContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(3),
    border: `1px solid ${theme.palette.carbon[10]}`,
    boxSizing: 'border-box',
    background: theme.palette.common.white,
    borderRadius: 8,
    width: '100%',
    marginRight: 16,
    marginLeft: 16,
    outline: 'none',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      width: 550,
      height: 'auto',
    },
  },
  closeButton: {
    position: 'absolute',
    top: '24px',
    right: '24px',
  },
  modalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(3),
    },
  },
  actionButton: {
    padding: theme.spacing(1, 2),
    justifyContent: 'center',
    alignItems: 'center',
    width: 212,
    height: 48,
  },
  carbon: {
    color: theme.palette.carbon[100],
  },
  formGroup: {
    display: 'flex',
    margin: theme.spacing(3, 0),
    '& > label > span:first-child': {
      padding: theme.spacing(0.5),
    },
  },
  modalTitle: {
    maxWidth: 300,
    margin: theme.spacing(3, 0, 1),
  },
  subtitleModal: {
    width: 380,
    [theme.breakpoints.down('sm')]: {
      width: 270,
    },
  },
}));

type DirfModalProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<boolean>;
};

const DirfModal = ({ isOpen, setIsOpen }: DirfModalProps): JSX.Element => {
  const classes = useStyles();

  const [dirfListReportToDownload, setDirfListReportToDownload] = useState<Array<string | never>>([]);
  const [dirfReportError, setDirfReportError] = useState(false);
  const [dirfReportLoading, setDirfReportLoading] = useState(false);

  const getDirfReport = () => {
    setDirfReportLoading(true);
    dirfListReportToDownload?.map((year: string) =>
      dirfReportService(year)
        .then((response) => {
          if (response.data) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.id = `download_dirf_report_button_${year}`;
            link.setAttribute('download', `DIRF ${year}.pdf`);
            link.click();
            link.remove();
          } else {
            throw new Error(response.errorMessage);
          }
        })
        .catch(() => setDirfReportError(true))
        .finally(() => setDirfReportLoading(false)),
    );
  };

  const dirfDownloadReport = (e: ChangeEvent<{} & CheckboxProps>) => {
    const { target } = e;
    const year = target?.value as string;

    setDirfListReportToDownload((prevState) => {
      if (target?.checked) {
        return [...prevState, year];
      }
      const idx = dirfListReportToDownload.indexOf(year);
      const removedYear = dirfListReportToDownload[idx];
      const index = dirfListReportToDownload.indexOf(removedYear);

      prevState.splice(index, 1);

      return prevState;
    });
  };

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)} className={classes.modalContainer} disableEnforceFocus>
      <Box className={classes.modalContent}>
        <IconButton onClick={() => setIsOpen(false)} component="button" className={classes.closeButton}>
          <CloseIconRounded className={classes.carbon} />
        </IconButton>
        <IrIcon />
        <Box display="flex" flexDirection="column" textAlign="center" alignItems="center">
          <Typography className={classes.modalTitle} variant="bodyBold">
            DIRF (Declaração do Imposto de Renda Retido na Fonte)
          </Typography>
          <Typography className={classes.subtitleModal} variant="caption">
            Selecione o ano de calendário da DIRF que deseja baixar
          </Typography>
        </Box>
        <Box className={classes.formGroup}>
          {YEARS.map((year) => (
            <FormControlLabel
              onChange={(e) => dirfDownloadReport(e)}
              control={<Checkbox color="primary" />}
              value={year}
              label={year}
            />
          ))}
        </Box>
        <Box className={classes.buttonsWrapper}>
          <Button
            variant="contained"
            title="Baixar Dirf"
            color="primary"
            onClick={getDirfReport}
            className={classes.actionButton}
            disabled={dirfReportLoading || dirfListReportToDownload.length === 0}
          >
            Baixar
          </Button>
        </Box>
        {dirfReportError && (
          <Typography variant="caption" align="center">
            Houve um erro ao buscar o relatório. Tente novamente mais tarde.
          </Typography>
        )}
      </Box>
    </Modal>
  );
};

export default DirfModal;
