import { formatUserDocument } from 'helpers/formatters/formatters';
import { ListSkuResponse, TrackingParams, TrackingServiceResponse } from 'models/tracking';
import { client } from 'services/client';

export const getTracking = async ({ status, page }: TrackingParams): Promise<TrackingServiceResponse> => {
  const localStorageAuthJson = localStorage.getItem('persist:auth') as string;
  const localStorageAuth = JSON.parse(localStorageAuthJson);
  const localStorageAuthData = JSON.parse(localStorageAuth.data);

  const localStorageAccountJson = localStorage.getItem('persist:account') as string;
  const localStorageAccount = JSON.parse(localStorageAccountJson);
  const localStorageAccountData = JSON.parse(localStorageAccount.data);

  const statusQuery = (): string => {
    if (status === 'all') return '';
    if (status?.length === 0) return '';
    return `&status=${status?.join('&status=')}`;
  };

  try {
    const { parsedBody } = await client.get<TrackingServiceResponse['data']>(
      `portal/v1/tracking?page=${page}${statusQuery()}`,
      {
        token: localStorageAuthData.accessToken,
        useWhitelabel: true,
        headers: {
          'content-type': 'application/json',
          'document-number': formatUserDocument(localStorageAccountData.documentNumber),
        },
      },
    );
    if (parsedBody) {
      return { data: parsedBody };
    }

    throw new Error('Erro ao buscar tracking');
  } catch (error) {
    return { errorMessage: 'Erro ao buscar tracking' };
  }
};

export const listSku = async () => {
  const localStorageAuthJson = localStorage.getItem('persist:auth') as string;
  const localStorageAuth = JSON.parse(localStorageAuthJson);
  const localStorageAuthData = JSON.parse(localStorageAuth.data);

  const localStorageAccountJson = localStorage.getItem('persist:account') as string;
  const localStorageAccount = JSON.parse(localStorageAccountJson);
  const localStorageAccountData = JSON.parse(localStorageAccount.data);

  try {
    const { parsedBody } = await client.get<ListSkuResponse['data']>('portal/v1/services-orders/sku', {
      token: localStorageAuthData.accessToken,
      useWhitelabel: true,
      headers: {
        'content-type': 'application/json',
        'document-number': formatUserDocument(localStorageAccountData.documentNumber),
      },
    });

    if (parsedBody) {
      return {
        data: parsedBody,
      };
    }

    throw new Error("Erro ao buscar lista de SKU's");
  } catch (error) {
    return { errorMessage: "Erro ao buscar lista de SKU's" };
  }
};
