import React from 'react';
import type { FunctionComponent } from 'react';

import { Box, BoxProps, Card, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Skeleton } from '@material-ui/lab';

import Button from 'components/UI/Button';
import Typography from 'components/UI/Typography';
import { longDateAndTime } from 'helpers/date';

const useStyles = makeStyles((theme) => ({
  buttonUpdateCard: {
    color: theme.palette.textColor.link,
  },
  errorCard: {
    [theme.breakpoints.down('sm')]: {
      height: 356,
      justifyContent: 'center',
    },
  },
  infoIcon: {
    marginRight: theme.spacing(1),
  },
  cardCell: {
    gridColumnEnd: 'span 3',

    [theme.breakpoints.down('sm')]: {
      gridColumnEnd: 'span 12',
      marginBottom: theme.spacing(2),
    },
  },
  card: {
    padding: theme.spacing(2),
    display: 'flex',
    width: '100%',
    minHeight: theme.spacing(12),
    backgroundColor: theme.palette.backgroundColor.surface?.marbleDark,
  },
  sectionTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 110px)',
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  },
  sectionTitleLoading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    gap: theme.spacing(1),
    width: 116,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  },
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'baseline',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'nowrap',
    },
  },
  sectionHeaderLoading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'baseline',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'nowrap',
    },
  },
}));

type Props = {
  title: string;
  updatedAt?: string;
  updatedAtLoading?: boolean;
  hasError?: boolean;
  onUpdateCard?: () => void;
  linkElement?: React.ReactNode;
};

const PageSection: FunctionComponent<Props & BoxProps> = ({
  children,
  title,
  updatedAt,
  updatedAtLoading,
  hasError,
  onUpdateCard,
  linkElement,
}) => {
  const classes = useStyles();

  if (updatedAtLoading) {
    return (
      <Box>
        <Box className={classes.sectionHeaderLoading}>
          <Box className={classes.sectionTitleLoading}>
            <Skeleton width="100%" height={24} />
          </Box>
        </Box>

        <Box>{children}</Box>
      </Box>
    );
  }

  if (hasError) {
    return (
      <Box>
        <Box display="flex" justifyContent="space-between" alignContent="center" flexWrap="wrap">
          <Card className={classes.card}>
            <Grid container direction="column" alignItems="center" className={classes.errorCard}>
              <Grid container direction="row" alignItems="center" justify="center">
                <InfoOutlinedIcon className={classes.infoIcon} fontSize="small" />
                <Typography variant="bodyBold">Visualização indisponível</Typography>
              </Grid>
              <Button variant="text" className={classes.buttonUpdateCard} onClick={onUpdateCard}>
                <Typography variant="bodyBold">Atualizar</Typography>
              </Button>
            </Grid>
          </Card>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Box className={classes.sectionHeader}>
        <Box className={classes.sectionTitle}>
          {updatedAtLoading ? (
            <Skeleton width={155} height={24} />
          ) : (
            <Typography variant="bodyBold">{title}</Typography>
          )}
          {updatedAt && <Typography variant="caption2">{`Atualizado em ${longDateAndTime(updatedAt)}`}</Typography>}
        </Box>
        {linkElement && linkElement}
      </Box>

      <Box>{children}</Box>
    </Box>
  );
};

export default PageSection;
