import { combineReducers } from '@reduxjs/toolkit';

import { createMigrate, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import accountReducer from './accounts';
import credentialsReducer from './auth/credentials';
import dialogsReducer from './dialogs';
import downloadAreaReducer from './download';
import featureFlagsReducers from './featureFlags/reducer';
import { accountMigrations } from './migrators';
import whitelabelReducer from './whitelabel';

const accountPersistConfig = {
  key: 'account',
  storage,
  whitelist: ['persisted', 'data'],
  version: 0,
  migrate: createMigrate(accountMigrations as any, { debug: false }),
};

const rootReducer = combineReducers({
  account: persistReducer(accountPersistConfig, accountReducer),
  auth: combineReducers({
    credentials: persistReducer({ key: 'auth', storage, whitelist: ['data'], version: 0 }, credentialsReducer),
  }),
  whitelabel: whitelabelReducer,
  featureFlags: featureFlagsReducers,
  downloadArea: downloadAreaReducer,
  dialogs: dialogsReducer,
});

export default rootReducer;
