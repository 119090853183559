import type { FunctionComponent } from 'react';
import React from 'react';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as WebErrorIcon } from 'assets/images/web-generic-error.svg';
import Button from 'components/UI/Button';
import Typography from 'components/UI/Typography';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  carbon100: {
    color: theme.palette.carbon[100],
  },
  neutralColorsDark: {
    color: theme.palette.textColor.dark,
  },
  icon: {
    width: '100%',
    marginBottom: theme.spacing(3),
  },
}));

type Props = {
  onTryAgain: () => void;
  loading?: boolean;
};

const AuthFormsServerUnavailable: FunctionComponent<Props> = ({ onTryAgain, loading }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <WebErrorIcon className={classes.icon} />
      <Box>
        <Typography variant="subtitleMobile2" component="p" className={classes.carbon100} align="center" paragraph>
          Ocorreu um erro
        </Typography>

        <Typography
          variant="body2"
          component="p"
          gutterBottom
          paragraph
          className={classes.neutralColorsDark}
          align="center"
        >
          Não foi possível carregar as informações, tente novamente em instantes.
        </Typography>

        <Button
          fullWidth
          title="Tentar novamente"
          aria-label="botão para recarregar a simulação de antecipação"
          variant="contained"
          color="primary"
          size="large"
          onClick={onTryAgain}
        >
          Tentar Novamente
        </Button>
      </Box>
    </Box>
  );
};

export default AuthFormsServerUnavailable;
