import React from 'react';

import { Box, Grid, makeStyles, Hidden, Link } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import PageSection from 'components/PageSection';
import SelectDropdown from 'components/SelectDropdown';
import TrackingSkeleton from 'components/Tracking/Skeleton';
import TrackingCard from 'components/Tracking/TrackingCard';
import Button from 'components/UI/Button';
import EmptyList from 'components/UI/EmptyList';
import Typography from 'components/UI/Typography';
import InfoPopupZendeskClosed from 'components/ZendeskClosed/InfoPopupZendeskClosed';
import { Order, PAGES } from 'models/machine';
import { TrackingData, TrackingResponseData } from 'models/tracking';
import Chargers from 'pages/Machine/components/Chargers';
import PaperRollsRequest from 'pages/Machine/components/PaperRollsRequest';
import Success from 'pages/Machine/components/Success';
import { getTracking, listSku } from 'services/tracking';

// import OrderForm from './components/OrderForm';
import { filterStatus, OrderType } from './constants';

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.background.default,
    margin: theme.spacing(6, 0),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(4, 0),
    },
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(4),
    },
  },
  pageSection: {
    backgroundColor: theme.palette.backgroundColor.surface?.secondary,
    border: `1px solid ${theme.palette.carbon[10]}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    width: '100%',
  },
  titlePageSection: {
    '& > div': {
      width: 'auto',
    },
  },
  select: {
    color: 'red',
  },
  cardHeader: {
    width: '100%',
    minHeight: '72px',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    backgroundColor: theme.palette.backgroundColor.surface?.marbleDark,
    border: `1px solid ${theme.palette.carbon[10]}`,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between',
    },
  },
  headerWrapper: {
    width: 'calc(100% - 36px)',
    display: 'flex',
    justifyContent: 'space-between',
  },
  orderHeader: {
    maxWidth: 410,
  },
  cardHeaderText: {
    fontSize: '0.875rem',
  },
  cardOtherInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '33%',
    '& > p': {
      marginRight: theme.spacing(3),
    },
  },
  iconLink: {
    color: theme.palette.textColor.link,
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    fontWeight: theme.typography.fontWeightBold,
    '& > svg': {
      marginRight: theme.spacing(0.5),
    },
  },
  link: {
    color: theme.palette.textColor.link,
    textDecoration: 'none',
  },
  paginateButton: {
    marginTop: theme.spacing(2),
  },
}));

type ContentComponentType = {
  [PAGES.TRACKING]: () => JSX.Element;
  [PAGES.COIL_FORM]: () => JSX.Element;
  [PAGES.CHARGER_FORM]: () => JSX.Element;
  [PAGES.SUCCESS]: () => JSX.Element;
};

const Machine = () => {
  const firstPage = 1;
  const manualLink = 'https://www.c6bank.com.br/c6pay/manuais/';
  const classes = useStyles();

  const [trackingIsLoading, setTrackingIsLoading] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [trackingData, setTrackingData] = React.useState<TrackingResponseData | null>(null);
  const [trackingError, setTrackingError] = React.useState<boolean>();
  const [trackingUpdatedAt, setTrackingUpdatedAt] = React.useState<string | undefined>(undefined);

  const [page, setPage] = React.useState<PAGES>(PAGES.TRACKING);
  const [orderType, setOrderType] = React.useState<OrderType | undefined>();
  const [anchorElStatus, setAnchorElStatus] = React.useState<null | HTMLElement>(null);
  const [openFilter, setOpenFilter] = React.useState(false);
  const [filter, setFilter] = React.useState({
    status: [],
  });
  const [currentPage, setCurrentPage] = React.useState(firstPage);
  const [totalPages, setTotalPages] = React.useState(0);
  const [code, setCode] = React.useState<number>(0);
  const [order, setOrder] = React.useState<Order>({
    coilQuantity: 0,
    charger: '',
    reason: '',
  });

  const [chargerList, setChargerList] = React.useState<string[]>([]);
  const [chargerListLoading, setChargerListLoading] = React.useState(true);
  // const [chargerListError, setChargerListError] = React.useState(false);

  const getTrackingList = React.useCallback(
    (currPage: number, callback: (data: TrackingResponseData) => void) => {
      setTrackingIsLoading(true);
      setTrackingError(false);
      const status = filter.status.length === filterStatus.length ? 'all' : filter.status;
      getTracking({ page: currPage, status })
        .then((response) => {
          const { data: dataResponse, errorMessage } = response;
          if (dataResponse) {
            setTrackingUpdatedAt(new Date().toISOString());
            callback({ ...dataResponse });
            setCurrentPage(dataResponse.page || firstPage);
            setTotalPages(dataResponse.totalPage || 0);
          } else {
            throw new Error(errorMessage);
          }
        })
        .catch(() => setTrackingError(true))
        .finally(() => setTrackingIsLoading(false));
    },
    [filter],
  );

  const handleBack = () => {
    setPage(PAGES.TRACKING);
  };

  const mergePaginatedPayload = (oldRecord: TrackingResponseData, newRecord: TrackingResponseData) => {
    const local = [...oldRecord.data, ...newRecord.data];

    const items = local.reduce((acc: TrackingData[], curr: TrackingData) => {
      acc.push(curr);
      return acc;
    }, []);

    return {
      page: newRecord.page,
      totalPage: newRecord.totalPage,
      data: items,
    };
  };

  const nextPage = () => {
    getTrackingList(currentPage + 1, (data: TrackingResponseData) => {
      const mergedData = mergePaginatedPayload(trackingData!, data);
      setTrackingData((mergedData as unknown) as TrackingResponseData);
    });
  };

  const TrackingBehavior = (): JSX.Element => {
    if (trackingIsLoading) {
      return <TrackingSkeleton />;
    }
    if (trackingData && trackingData?.data.length) {
      return (
        <>
          <Grid container item md={12} className={classes.cardHeader}>
            <div className={classes.headerWrapper}>
              <Grid item md={6} xs={7} className={classes.orderHeader}>
                <Typography className={classes.cardHeaderText} variant="bodyBold">
                  Pedidos
                </Typography>
              </Grid>
              <Hidden smDown>
                <Grid item md={2}>
                  <Typography className={classes.cardHeaderText} variant="bodyBold">
                    Data do pedido
                  </Typography>
                </Grid>
                <Grid item md={2}>
                  <Typography className={classes.cardHeaderText} variant="bodyBold">
                    Previsão de entrega
                  </Typography>
                </Grid>
              </Hidden>
              <Grid item md={2} xs={5}>
                <Typography className={classes.cardHeaderText} variant="bodyBold">
                  Status
                </Typography>
              </Grid>
            </div>
          </Grid>

          {trackingData?.data?.map((item) => {
            return (
              <TrackingCard
                key={item.orderNumber}
                status={item.status}
                date={item.openingDate}
                deliveryForecast={item.deadLine}
                equipment={item.equipment}
                orderNumber={item.orderNumber}
                equipmentModel={item.equipmentModel}
                quantity={item.quantity}
                equipmentSerialNumber={item.equipmentSerialNumber}
              />
            );
          })}
          {trackingData?.data && (
            <Grid item xs={12} className={classes.paginateButton}>
              {totalPages > currentPage && (
                <Box display="flex" justifyContent="center">
                  <Button
                    type="submit"
                    title="Mostrar mais"
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={trackingIsLoading}
                    onClick={nextPage}
                  >
                    MOSTRAR MAIS
                  </Button>
                </Box>
              )}
            </Grid>
          )}
        </>
      );
    }

    return (
      <EmptyList subtitle="Todos os seus pedidos irão aparecer aqui assim que você realizar alguma solicitação." />
    );
  };

  const changeFilter = (filterName: string) => {
    return (field: string[]) =>
      setFilter({
        ...filter,
        [filterName]: field,
      });
  };

  const handleClickFilter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElStatus(event.currentTarget);
    setOpenFilter((previousOpen) => !previousOpen);
  };

  React.useEffect(() => {
    if (page === PAGES.TRACKING) {
      getTrackingList(firstPage, (data) => setTrackingData(data));
    }
  }, [getTrackingList, page]);

  React.useEffect(() => {
    listSku()
      .then(({ data }) => {
        if (data) {
          setChargerList(data.charger);
        }
      })
      .catch((error) => {
        // setChargerListError(true);
      })
      .finally(() => {
        setChargerListLoading(false);
      });
  }, []);

  const canBeOpenFilter = openFilter && Boolean(anchorElStatus);

  const ContentComponent: ContentComponentType = {
    [PAGES.TRACKING]: (): JSX.Element => (
      <>
        {/* <OrderForm setPage={setPage} hasCharger={!chargerList?.length} hasError={chargerListError} /> */}
        <Box className={classes.pageSection}>
          <PageSection
            title="Maquininhas"
            className={classes.titlePageSection}
            updatedAt={trackingUpdatedAt}
            hasError={trackingError}
            onUpdateCard={() => {
              getTrackingList(currentPage, (data) => setTrackingData(data));
            }}
            linkElement={
              <div className={classes.iconLink}>
                <HelpOutlineIcon fontSize="small" />
                <Link
                  href={manualLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.link}
                  aria-label="Acessar manual das maquininhas."
                >
                  Manual das maquininhas
                </Link>
              </div>
            }
          >
            <Box marginBottom={2}>
              <SelectDropdown
                setAnchorEl={setAnchorElStatus}
                anchorEl={anchorElStatus}
                onChange={changeFilter('status')}
                idPopper="status"
                options={filterStatus}
                canBeOpen={canBeOpenFilter}
                buttonLabel="Status"
                onButtonClick={handleClickFilter}
                openPopper={openFilter}
                setOpenPopper={setOpenFilter}
              />
            </Box>

            <Box>{TrackingBehavior()}</Box>
          </PageSection>
        </Box>
      </>
    ),
    [PAGES.COIL_FORM]: (): JSX.Element => (
      <PaperRollsRequest
        setPage={setPage}
        setLoading={setIsLoading}
        loading={isLoading}
        setCode={setCode}
        handleBack={handleBack}
        setOrder={setOrder}
        order={order}
        setOrderType={setOrderType}
      />
    ),
    [PAGES.CHARGER_FORM]: (): JSX.Element => (
      <Chargers
        setPage={setPage}
        setLoading={setIsLoading}
        loading={isLoading}
        setCode={setCode}
        handleBack={handleBack}
        setOrder={setOrder}
        order={order}
        setOrderType={setOrderType}
        chargerList={chargerList}
        chargerListLoading={chargerListLoading}
      />
    ),
    [PAGES.SUCCESS]: (): JSX.Element => (
      <Success code={code} order={order} handleBack={handleBack} orderType={orderType} />
    ),
  };

  return (
    <Grid container className={classes.container}>
      <InfoPopupZendeskClosed />
      <Typography variant="subtitleWebBold">Meus pedidos</Typography>
      {ContentComponent[page]()}
    </Grid>
  );
};

export default Machine;
