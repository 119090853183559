import React, { FunctionComponent } from 'react';

import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(6, 0),

    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(4, 0),
    },

    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(4),
    },
  },
}));

const PageContainer: FunctionComponent = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      {children}
    </Grid>
  );
};

export default PageContainer;
