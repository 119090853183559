import type { ThunkApi } from '@portal-types/store';

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { authRequests } from 'services/auth';
import { HTTPError } from 'services/client';
import { AccountActions } from 'store/accounts';

import type { LoginAction, RefreshTokenAction } from './types';

export const logout = createAction('credentials/logout');
export const clearError = createAction('credentials/clear-error');

export const refresh = createAsyncThunk<RefreshTokenAction['output'], RefreshTokenAction['input']>(
  'credentials/refresh',
  async ({ refreshToken }, thunkApi) => {
    try {
      return authRequests.refreshCredentials(refreshToken);
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const login = createAsyncThunk<LoginAction['output'], LoginAction['input'], ThunkApi>(
  'credentials/login',
  async ({ email, password, reCaptchaResponse }, thunkApi) => {
    try {
      const loginResponse = await authRequests.login(email, password, reCaptchaResponse);

      thunkApi.dispatch(
        AccountActions.saveAccount({
          data: {
            email,
            name: undefined,
            documentNumber: undefined,
            userDocuments: loginResponse.accountData.userDocuments,
            userPortal: 'full',
          },
        }),
      );
      return loginResponse!.authData;
    } catch (error) {
      const messageErrorAcess = 'Ocorreu um erro. Não foi possível carregar as informações.';
      const messageRefresh = 'Tente novamente.';

      if (error instanceof HTTPError && error.statusNumber >= 500) {
        return thunkApi.rejectWithValue({
          message: messageErrorAcess,
          messageLink: messageRefresh,
          networkError: true,
        });
      }

      if (error.statusNumber === 401) {
        return thunkApi.rejectWithValue({ message: 'E-mail ou senha inválidos' });
      }

      if (error.statusNumber === 403) {
        return thunkApi.rejectWithValue({
          message: messageErrorAcess,
          messageLink: messageRefresh,
        });
      }

      return thunkApi.rejectWithValue({ message: messageErrorAcess, messageLink: messageRefresh });
    }
  },
);
