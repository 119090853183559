import type { FunctionComponent } from 'react';
import React, { useState, useEffect } from 'react';

import { Step, StepConnector, StepLabel, Stepper, withStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

import clsx from 'clsx';

import { activeStepError } from '../constants';
import useStyles from './TrackingStepperDesktop.styles';

const TrackingStepperConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 6,
  },
  active: {
    '& $line': {
      background: theme.palette.backgroundColor.positive?.primary,
    },
  },
  completed: {
    '& $line': {
      background: theme.palette.backgroundColor.positive?.primary,
    },
  },
  line: {
    height: 2,
    border: 0,
    backgroundColor: theme.palette.carbon[35],
  },
}))(StepConnector);

type Props = {
  activeStep: number;
};

const TrackingStepperDesktop: FunctionComponent<Props> = ({ activeStep }) => {
  const [hasError, setHasError] = useState(false);
  const classes = useStyles();

  const steps = [
    {
      label: 'Em separação',
      date: '',
      rootCss: classes.stepRootFirst,
      labelCss: classes.stepLabelFirst,
    },
    {
      label: 'Com a transportadora',
      date: '',
      rootCss: classes.stepRootMiddle,
      labelCss: classes.stepLabelMiddle,
    },
    {
      label: 'Em rota de entrega',
      date: '',
      rootCss: classes.stepRootLast,
      labelCss: classes.stepLabelMiddle,
    },
    {
      label: hasError ? 'Falha na entrega' : 'Entregue',
      date: '',
      rootCss: hasError ? classes.stepRootLastWithError : classes.stepRootLast,
      labelCss: classes.stepLabelLast,
    },
  ];

  const renderIcon = (active: boolean, completed: boolean) => {
    if (!hasError && active) {
      return <MoreHorizIcon className={classes.dotActiveIcon} />;
    }

    if (completed) {
      return <CheckIcon className={classes.dotCompletedIcon} />;
    }

    if (hasError) {
      return <PriorityHighIcon className={classes.dotFailureIcon} />;
    }

    return '';
  };

  const renderDot = (active: boolean, completed: boolean) => {
    if (active && !hasError) {
      return (
        <div aria-label="dot" className={clsx(classes.dotRoot, { [classes.dotActive]: active })}>
          {renderIcon(active, completed)}
        </div>
      );
    }
    if (completed) {
      return (
        <div aria-label="dot" className={clsx(classes.dotRoot, { [classes.dotCompleted]: completed })}>
          {renderIcon(active, completed)}
        </div>
      );
    }

    if (hasError) {
      return (
        <div aria-label="dot" className={classes.dotError}>
          {renderIcon(active, completed)}
        </div>
      );
    }

    return (
      <div aria-label="dot" className={clsx(classes.dotRoot, { [classes.dotCompleted]: completed })}>
        {renderIcon(active, completed)}
      </div>
    );
  };

  useEffect(() => {
    if (activeStep === activeStepError) {
      setHasError(true);
    }
  }, [activeStep]);

  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={<TrackingStepperConnector />}
      classes={{ root: classes.stepperRoot }}
    >
      {steps.map((step) => (
        <Step key={step.label} classes={{ root: step.rootCss }}>
          <StepLabel
            classes={{
              label: step.labelCss,
            }}
            StepIconComponent={({ active, completed }) => renderDot(active, completed)}
          >
            {step.label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default TrackingStepperDesktop;
