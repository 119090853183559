import React from 'react';
import type { FunctionComponent } from 'react';

import { Box, Card, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import Typography from 'components/UI/Typography';
import { AnticipationListData } from 'models/anticipation';

import AnticipationListItem from '../AnticipationListItem';
import AnticipationListHeaderDesktop from './Desktop';
import AnticipationListHeaderMobile from './Mobile';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    padding: theme.spacing(4),
  },
}));

type AnticipationListProps = {
  list: AnticipationListData;
  changePageTab: () => void;
};

const AnticipationList: FunctionComponent<AnticipationListProps> = ({ list, changePageTab }) => {
  const classes = useStyles();
  const smScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Card className={classes.card}>
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <Box mb={4}>
          <Typography variant="bodyBold" component="h2" align="center" gutterBottom>
            Minhas antecipações
          </Typography>
        </Box>
        {smScreen ? <AnticipationListHeaderMobile /> : <AnticipationListHeaderDesktop />}
        {list.map((item) => {
          return (
            <AnticipationListItem
              key={item.id}
              id={item.id}
              acquirers={item.acquirers}
              netAmount={item.netAmount}
              grossAmount={item.grossAmount}
              taxAmount={item.taxAmount}
              status={item.status}
              startAnticipatedDate={item.startAnticipatedDate}
              endAnticipatedDate={item.endAnticipatedDate}
              createdAt={item.createdAt}
              changePageTab={changePageTab}
            />
          );
        })}
      </Box>
    </Card>
  );
};

export default AnticipationList;
