import { createReducer } from '@reduxjs/toolkit';

import { getFeatureFlags } from './actions';

export default createReducer({ data: {}, loading: false, error: false }, (builder) =>
  builder
    .addCase(getFeatureFlags.pending, (state) => {
      state.data = {};
      state.loading = true;
      state.error = false;
    })
    .addCase(getFeatureFlags.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = false;
    })
    .addCase(getFeatureFlags.rejected, (state, action) => {
      state.data = {};
      state.loading = false;
      state.error = true;
    }),
);
