import type { ClientStatus } from '@portal-types/pages/clients';

import { compositionColors } from 'themes/colors';

export const clientStatuses: Record<ClientStatus, { color: string; label: string }> = {
  active: {
    color: compositionColors.light.status.active,
    label: 'Ativo',
  },
  installation: {
    color: compositionColors.light.status.intermediate,
    label: 'Em instalação',
  },
  cancelation: {
    color: compositionColors.light.status.attention,
    label: 'Em cancelamento',
  },
  canceled: {
    color: compositionColors.light.status.negative,
    label: 'Cancelado',
  },
};
