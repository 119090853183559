import type { RootState } from '@portal-types/store';

import { Loading } from 'helpers/enums';

export const isAuthenticated = (state: RootState) => Boolean(state.auth.credentials.data);
export const isCredentialsLoading = (state: RootState) => state.auth.credentials.loading === Loading.PENDING;
export const isCredentialUnavailableService = (state: RootState) => Boolean(state.auth.credentials.unavailableService);

export const credentialsAccessToken = (state: RootState) => state.auth.credentials.data?.accessToken;
export const credentialsData = (state: RootState) => state.auth.credentials.data;
export const credentialsError = (state: RootState) => state.auth.credentials.error ?? { message: '', messageLink: '' };
