import { useEffect } from 'react';
import type { EffectCallback } from 'react';

/**
 * useEffect que roda apenas no primeiro render do componente
 * @param callback
 */
function useDidMount(callback: EffectCallback) {
  useEffect(() => {
    return callback();
    // eslint-disable-next-line
  }, []);
}

export default useDidMount;
