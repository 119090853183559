import React, { FunctionComponent, useState, useEffect } from 'react';

import { Box } from '@material-ui/core';

import AnticipationList from 'components/Anticipation/AnticipationList';
import EmptyList from 'components/UI/EmptyList';
import ServerError from 'components/UI/ServerError';
import { AnticipationListData } from 'models/anticipation';
import { getAnticipationList } from 'services/anticipation';

type Props = {
  setPageTab: (value: string) => void;
};

const MyAnticipations: FunctionComponent<Props> = ({ setPageTab }) => {
  const [initialLoading, setInitialLoading] = useState(true);
  const [initialRequestError, setInitialRequestError] = useState(false);
  const [anticipationList, setAnticipationList] = useState<AnticipationListData>([]);

  const initialRequest = () => {
    setInitialLoading(true);
    getAnticipationList()
      .then(({ data }) => {
        if (data && Array.isArray(data)) {
          return setAnticipationList(data);
        }

        throw new Error();
      })
      .catch((_) => setInitialRequestError(true))
      .finally(() => setInitialLoading(false));
  };

  useEffect(() => {
    initialRequest();
  }, []);

  const conditionalComponents = () => {
    if (initialLoading) {
      return <></>;
    }

    if (!anticipationList.length) {
      return (
        <Box mt={4} width={464}>
          <EmptyList subtitle="Você ainda não realizou nenhuma antecipação de recebíveis." />
        </Box>
      );
    }

    if (initialRequestError) {
      return (
        <Box mt={4}>
          <ServerError
            onTryAgain={() => {
              setInitialRequestError(false);
              initialRequest();
            }}
            title="Ocorreu um erro"
            message="Não foi possível carregar as informações. Tente novamente em instantes."
          />
        </Box>
      );
    }

    return <AnticipationList list={anticipationList} changePageTab={() => setPageTab('simulator')} />;
  };

  return conditionalComponents();
};

export default MyAnticipations;
