import React, { FunctionComponent } from 'react';

import { Box, Card, Modal, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';

import { ReactComponent as AlertImage } from 'assets/images/alert.svg';
import Button from 'components/UI/Button';
import Typography from 'components/UI/Typography';

const useStyles = makeStyles((theme) => ({
  pendingActions: {
    display: 'grid',
    gridGap: theme.spacing(1),
    margin: theme.spacing(3, 0, 0),
  },
  modalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: `1px solid ${theme.palette.carbon[10]}`,
    minWidth: 328,
    width: 504,
    margin: theme.spacing(0, 2),
    '& > svg': {
      marginBottom: theme.spacing(4),
    },
  },
  sectionModalButton: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column-reverse',
    flexWrap: 'wrap',
    width: '100%',
    '& > button:not(:last-child)': {
      marginTop: theme.spacing(2),
    },

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      flexWrap: 'nowrap',
      '& > button:not(:last-child)': {
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(0),
      },
    },
  },
}));

type Props = {
  action: 'confirm' | 'revert';
  actionLabel: string;
  isModalOpen: boolean;

  revertSuccess: boolean;
  setRevertSuccess: (flag: boolean) => void;

  confirmSuccess: boolean;
  setConfirmSuccess: (flag: boolean) => void;

  error: boolean;
  setError: (flag: boolean) => void;

  setIsModalOpen: (open: boolean) => void;
  patchTransactionPendingAction: (action: 'confirm' | 'revert', transactionCode: string) => void;
  setCurrentAction: (action: 'confirm' | 'revert') => void;
  transactionCode: string;
};

const SalesGroupSalePending: FunctionComponent<Props> = ({
  action,
  actionLabel,
  isModalOpen,
  setIsModalOpen,
  patchTransactionPendingAction,
  setCurrentAction,
  transactionCode,
  revertSuccess,
  setRevertSuccess,
  confirmSuccess,
  setConfirmSuccess,
  error,
  setError,
}) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.pendingActions}>
        <Button
          fullWidth
          title="Confirmar venda"
          aria-label="Confirmar Venda que está pendente."
          variant="contained"
          size="large"
          color="primary"
          onClick={() => {
            setCurrentAction('confirm');
            setIsModalOpen(true);
          }}
        >
          Confirmar venda
        </Button>
        <Button
          fullWidth
          title="Cancelar venda"
          aria-label="Cancelar venda que está pendente."
          variant="outlined"
          size="large"
          onClick={() => {
            setCurrentAction('revert');
            setIsModalOpen(true);
          }}
        >
          Cancelar venda
        </Button>

        <Alert severity="info">Saiba mais sobre transações pendentes em nossa Central de ajuda.</Alert>
      </Box>

      <Snackbar open={revertSuccess} onClose={() => setRevertSuccess(false)}>
        <Alert variant="filled" severity="success">
          Venda cancelada com sucesso. Em instantes o status será atualizado.
        </Alert>
      </Snackbar>

      <Snackbar open={confirmSuccess} onClose={() => setConfirmSuccess(false)}>
        <Alert variant="filled" severity="success">
          Venda confirmada com sucesso. Em instantes o status será atualizado.
        </Alert>
      </Snackbar>

      <Snackbar open={error} onClose={() => setError(false)}>
        <Alert variant="filled" severity="error">
          Houve um erro. Tente novamente em instantes.
        </Alert>
      </Snackbar>

      <Modal
        className={classes.modalContainer}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="Veja as condições de taxas."
        aria-describedby="Veja as condições de taxas de parcelamento."
      >
        <Card className={classes.modalContent}>
          <AlertImage />
          <Typography align="center" component="h1" variant="subtitle2" paragraph>
            Atenção
          </Typography>
          <Typography align="center" component="p" variant="caption">
            Ao realizar essa ação ela não poderá ser desfeita.
          </Typography>
          <Typography align="center" component="p" variant="caption">
            Tem certeza que deseja continuar?
          </Typography>
          <Box className={classes.sectionModalButton}>
            <Button
              fullWidth
              title="Voltar"
              aria-label="Cancelar a ação e voltar para detalhes."
              variant="outlined"
              size="large"
              onClick={() => setIsModalOpen(false)}
            >
              Voltar
            </Button>
            <Button
              fullWidth
              title={`${actionLabel} venda`}
              aria-label={`${actionLabel} Venda que está pendente.`}
              variant="contained"
              size="large"
              color="primary"
              onClick={() => patchTransactionPendingAction(action, transactionCode)}
            >
              {`${actionLabel} venda`}
            </Button>
          </Box>
        </Card>
      </Modal>
    </>
  );
};

export default SalesGroupSalePending;
