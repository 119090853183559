import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import MainLayout from 'layouts/Main';
import type { Routes } from 'models/routes';

import { parseGroupRouteByRoutes, NotFound } from './groupedRoutes';
import type { GroupedRoutes } from './groupedRoutes';

const PrivateRoutesChildren = (type: 'tef' | 'full') => {
  const groupedRoutesByPortal = parseGroupRouteByRoutes(type);

  return groupedRoutesByPortal.reduce((acc: Routes, curr: GroupedRoutes) => {
    acc.push(...curr.routes);
    return acc;
  }, []);
};

const usePrivateRoutes = () => {
  const commonRoutes = [{ path: '*', element: <NotFound title="Página desconhecida" /> }];

  const portalRoutes = {
    tef: useRoutes([
      {
        path: '/',
        element: <MainLayout />,
        children: [
          ...PrivateRoutesChildren('tef'),
          {
            path: '/',
            element: <Navigate to="/sales" replace />,
          },
        ],
      },
      { path: '/auth/*', element: <Navigate to="/sales" replace /> },
      ...commonRoutes,
    ]),

    full: useRoutes([
      {
        path: '/',
        element: <MainLayout />,
        children: [
          ...PrivateRoutesChildren('full'),
          {
            path: '/',
            element: <Navigate to="/resume" replace />,
          },
        ],
      },
      { path: '/auth/*', element: <Navigate to="/resume" replace /> },
      ...commonRoutes,
    ]),
  };

  return portalRoutes;
};

export default usePrivateRoutes;
