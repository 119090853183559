import React, { FunctionComponent } from 'react';

import { Box } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';

import Tooltip from 'components/UI/Tooltip';
import Typography from 'components/UI/Typography';

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.backgroundColor.neutral?.lightest,
  },
  resultValuesCard: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 0),
    borderBottom: `1px solid ${theme.palette.carbon[10]}`,
  },
  carbon70: {
    color: theme.palette.carbon[70],
  },
}));

type Props = {
  labels: ObjectLabelsOrValues;
  values: ObjectLabelsOrValues;
};

type ObjectLabelsOrValues = {
  paymentMethod: string;
  receiveAmount: string;
  receiveDate: string;
  tax: string;
};

type DictionaryResultKeys = keyof ObjectLabelsOrValues;

const ResultCard: FunctionComponent<Props> = ({ labels, values }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Box display="flex" justifyContent="space-between" alignContent="center">
        <Typography variant="subtitle2" component="h3" color="textPrimary" paragraph>
          Resultado da simulação
        </Typography>

        <Tooltip
          title="Os valores e as taxas simuladas podem conter variações e serão aplicados somente nas vendas confirmadas."
          placement="top"
          enterTouchDelay={1}
          leaveTouchDelay={4000}
          arrow
        >
          <HelpOutlineRoundedIcon fontSize="small" className={classes.carbon70} />
        </Tooltip>
      </Box>

      {Object.keys(labels).map((key) => {
        if (values[key as DictionaryResultKeys]) {
          return (
            <Box className={`${classes.resultValuesCard} ${classes.carbon70}`} key={key}>
              <Typography variant="caption" color="inherit">
                {labels[key as DictionaryResultKeys]}
              </Typography>
              <Typography variant="caption" color="inherit">
                {values[key as DictionaryResultKeys]}
              </Typography>
            </Box>
          );
        }

        return null;
      })}
    </Card>
  );
};

export default ResultCard;
