import React, { Component, ErrorInfo, ReactNode } from 'react';

import ServerError from './UI/ServerError';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    ineum('reportError', error, {
      componentStack: errorInfo.componentStack,
    });
  }

  render() {
    const { state, props } = this;

    if (state.hasError) {
      return (
        <ServerError
          onTryAgain={() => {
            window.location.reload();
          }}
          title="Ocorreu um erro"
          message="Não foi possível carregar as informações."
        />
      );
    }

    return props.children;
  }
}

export default ErrorBoundary;
