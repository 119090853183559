import React, { FunctionComponent, ReactElement, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { Box, Modal, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIconRounded from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';

import Button from 'components/UI/Button';
import PageTitle from 'components/UI/PageTitle';
import Typography from 'components/UI/Typography';
import type { ReceivablesFilter as ReceivablesFilterType } from 'models/receivables';
import { downloadReportService } from 'services/report';
import { store } from 'store';
import { DownloadAreaActions, DownloadAreaSelectors } from 'store/download';

import ReceivablesFilter from '../Filter';

const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  modalContent: {
    padding: theme.spacing(3),
    border: `1px solid ${theme.palette.carbon[10]}`,
    boxSizing: 'border-box',
    background: theme.palette.common.white,
    borderRadius: 8,
    width: '100%',
    outline: 'none',
    overflowY: 'scroll',
    height: '100vh',
    [theme.breakpoints.up('sm')]: {
      overflowY: 'hidden',
      width: 550,
      height: 'auto',
    },
    display: 'grid',
    gap: theme.spacing(3),
  },
  modalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalWrapper: {
    backgroundColor: theme.palette.common.white,
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonsWrapper: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(3),
    },
  },
  actionButton: {
    padding: theme.spacing(1, 2),
    justifyContent: 'center',
    alignItems: 'center',
    width: 112,
  },
  reportButton: {
    color: theme.palette.textColor.link,
    fontSize: '1rem',
    textTransform: 'none',
    borderRadius: '8px',
  },
  carbon: {
    color: theme.palette.carbon[100],
  },
}));

type Props = {
  filter: ReceivablesFilterType;
};

const ReceivablesHeader: FunctionComponent<Props> = ({ filter }): ReactElement => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const [currentFilter, setCurrentFilter] = useState(filter);
  const isMounted = useRef(false);
  const reportList = useSelector(DownloadAreaSelectors.reportList);

  const [reportError, setReportError] = useState<boolean>(false);

  const handleOpenModal = (): void => {
    setIsOpen(true);
  };

  const downloadReport = async () => {
    downloadReportService(currentFilter);
    setIsOpen(false);
  };

  useEffect(() => {
    if (isMounted.current) {
      setCurrentFilter(filter);
    } else {
      isMounted.current = true;
    }
  }, [filter]);

  useEffect(() => {
    if (reportList.length === 0) store.dispatch(DownloadAreaActions.downloading(false));
  }, [reportList]);

  return (
    <Box className={classes.headerWrapper}>
      <PageTitle title="Recebíveis" />
      <Button
        variant="text"
        size="large"
        color="secondary"
        type="button"
        aria-label="Baixar relatório de recebíveis."
        startIcon={<GetAppIcon />}
        onClick={handleOpenModal}
        className={classes.reportButton}
      >
        Baixar relatório de recebíveis
      </Button>
      <Modal open={isOpen} onClose={() => setIsOpen(false)} className={classes.modalContainer} disableEnforceFocus>
        <Box className={classes.modalContent}>
          <Box className={classes.modalHeader}>
            <Typography variant="bodyBold">Relatório de recebíveis</Typography>
            <IconButton onClick={() => setIsOpen(false)} component="button">
              <CloseIconRounded className={classes.carbon} />
            </IconButton>
          </Box>
          <ReceivablesFilter setFilter={setCurrentFilter} filter={currentFilter} isColumn />
          <Box className={classes.buttonsWrapper}>
            <Button
              variant="contained"
              title="aplicar filtros"
              className={classes.actionButton}
              color="primary"
              onClick={() => {
                setReportError(false);
                downloadReport();
              }}
            >
              Baixar
            </Button>
            <Button
              onClick={() => {
                setIsOpen(false);
                setReportError(false);
              }}
              variant="outlined"
              className={classes.actionButton}
              color="default"
              type="reset"
            >
              Cancelar
            </Button>
          </Box>
          {reportError && (
            <Typography variant="caption" align="center">
              Houve um erro ao buscar o relatório. Tente novamente mais tarde.
            </Typography>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default ReceivablesHeader;
