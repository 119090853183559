import React from 'react';
import type { FunctionComponent } from 'react';

import type { SvgIcon } from '@material-ui/core';
import { Box, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    backgroundColor: theme.palette.backgroundColor.surface?.marbleDark,
  },
}));

type Props = {
  icon?: typeof SvgIcon;
};

const MySummariesCardSkeleton: FunctionComponent<Props> = ({ icon }) => {
  const classes = useStyles();
  return (
    <Box>
      <Card className={classes.card} data-testid="skeleton-my-summaries-card">
        {icon && (
          <Box mr={2}>
            <Skeleton variant="rect" width={48} height={48} animation="wave" />
          </Box>
        )}
        <Box width="100%" height={48} display="flex" flexDirection="column" justifyContent="space-between">
          <Skeleton width="100%" height={20} animation="wave" />
          <Skeleton width="100%" height={20} animation="wave" />
        </Box>
      </Card>
    </Box>
  );
};

export default MySummariesCardSkeleton;
