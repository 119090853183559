import type { FunctionComponent } from 'react';
import React, { useState } from 'react';

import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DescriptionIcon from '@material-ui/icons/Description';

import AccountCard from 'components/Account/Card';
import AccountSkeleton from 'components/Account/Skeleton';
import Button from 'components/UI/Button';
import PageTitle from 'components/UI/PageTitle';
import ServerError from 'components/UI/ServerError';
import useDidMount from 'hooks/useDidMount';
import {
  AccountDataResponse,
  AccountGroupTitle,
  BusinessLegalType,
  ContactPhoneType,
  Contacts,
  ParsedBusinessData,
  ParsedBusinessLegalType,
  ParsedContact,
} from 'models/account';
import { getAccountData } from 'services/account';

import { addressDictionary, bankDictionary, businessDataDictionary, contactDictionary } from './constants';
import { AccountDataGroup, Dictionaries, ParsedAccountData } from './types';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(6, 0),

    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(4, 0),
    },

    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(4),
    },
  },
  cardsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: theme.spacing(2),

    [theme.breakpoints.up('sm')]: {
      gridAutoRows: '1fr',
    },
  },
  header: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
  buttonText: {
    textTransform: 'none',
  },
  buttonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      '& .MuiButton-root:not(:first-child)': {
        marginTop: theme.spacing(1),
      },
    },
  },
}));

const Account: FunctionComponent = () => {
  const classes = useStyles();
  const [accountData, setAccountData] = useState<ParsedAccountData>([]);
  const [isLoading, setIsLoading] = useState(true);

  type CreateAccountGroupList = (
    data: AccountDataGroup,
    dictionary: Dictionaries,
  ) => { subtitle: string; value: string }[];

  const createAccountGroupList: CreateAccountGroupList = (data, dictionary) => {
    return Object.entries(dictionary).map(([key, value]) => ({
      subtitle: value,
      value: data[key as keyof typeof data],
    }));
  };

  type ParseBusinessData = (data: AccountDataResponse) => ParsedBusinessData;
  const parseBusinessData: ParseBusinessData = ({ documentNumber, corporateName, brandName, akreditasiId, type }) => ({
    akreditasiId,
    type: type === BusinessLegalType.PERSONAL ? ParsedBusinessLegalType.PERSONAL : ParsedBusinessLegalType.BUSINESS,
    cpf: type === BusinessLegalType.PERSONAL ? documentNumber : null,
    cnpj: type === BusinessLegalType.BUSINESS ? documentNumber : null,
    corporateName,
    brandName,
  });

  type ParseContacts = (contact: Contacts) => ParsedContact;
  const parseContact: ParseContacts = ({ name, email, phones }) => ({
    name,
    email,
    cellPhone: phones.find((phone) => phone.type === ContactPhoneType.CELLPHONE)?.number,
    commercialPhone: phones.find((phone) => phone.type === ContactPhoneType.COMMERCIAL)?.number,
  });

  const getAccountInformation = async () => {
    setIsLoading(true);
    const response = await getAccountData();
    setIsLoading(false);
    if (response.data) {
      const { addresses, contacts, bankAccounts } = response.data;
      setAccountData([
        {
          title: AccountGroupTitle.BUSINESS_DATA,
          list: createAccountGroupList(parseBusinessData(response.data), businessDataDictionary),
        },
        {
          title: AccountGroupTitle.ADDRESS,
          list: addresses.length ? createAccountGroupList(addresses[0], addressDictionary) : undefined,
        },
        {
          title: AccountGroupTitle.CONTACT,
          list: contacts ? createAccountGroupList(parseContact(contacts), contactDictionary) : undefined,
        },
        {
          title: AccountGroupTitle.BANK,
          list: bankAccounts.length ? createAccountGroupList(bankAccounts[0], bankDictionary) : undefined,
        },
      ]);
    }
  };

  useDidMount(() => {
    getAccountInformation();
    return () => {
      setAccountData([]);
    };
  });

  const Container = () => (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Box className={classes.header}>
          <PageTitle title="Minha conta" />

          <Box className={classes.buttonsWrapper}>
            <Button
              variant="text"
              href="https://openfiles.paygo.com.br/Termo_de_Uso_e_Politica_de_Privacidade_v3.2.pdf"
              startIcon={<DescriptionIcon />}
              className={classes.buttonText}
              component="a"
              componentProps={{
                target: '_blank',
                'aria-label': 'Termos e condições de uso do Portal do Cliente.',
              }}
            >
              Termos e Condições
            </Button>

            <Button
              href="https://openfiles.paygo.com.br/Contrato_de_Subadquirencia.pdf"
              variant="text"
              startIcon={<DescriptionIcon />}
              className={classes.buttonText}
              component="a"
              componentProps={{
                target: '_blank',
                'aria-label': 'Contrato de subadquirência do Portal do Cliente.',
              }}
            >
              Contrato de Subadquirência
            </Button>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <div className={classes.cardsContainer}>
          {isLoading ? (
            <AccountSkeleton />
          ) : (
            accountData.map((data) => <AccountCard list={data.list} title={data.title} key={data.title} />)
          )}
        </div>
      </Grid>
    </Grid>
  );

  return isLoading || accountData?.length ? <Container /> : <ServerError onTryAgain={() => getAccountInformation()} />;
};

export default Account;
