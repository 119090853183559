import type { FunctionComponent } from 'react';
import React, { useState } from 'react';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import RepeatRoundedIcon from '@material-ui/icons/RepeatRounded';

import { ReactComponent as LinkIcon } from 'assets/images/link.svg';
import { ReactComponent as MachineIcon } from 'assets/images/machine.svg';
import { ReactComponent as PixIcon } from 'assets/images/pix.svg';
import BrandIcon from 'components/UI/BrandIcon';
import Typography from 'components/UI/Typography';
import { hoursAndMinutes } from 'helpers/date';
import { formatAmount, formatCardNumbers } from 'helpers/formatters/formatters';
import { PaymentTypes, SaleItem, SaleStatus } from 'models/sales';

import SalesGroupSaleDetails from './Details';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.carbon[10]}`,
    cursor: 'pointer',
    padding: theme.spacing(2, 0),
    '&:hover': {
      backgroundColor: theme.palette.backgroundColor.surface?.marbleDark,
    },
  },
  column: {
    display: 'flex',
    alignItems: 'center',
  },
  valueContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    marginRight: theme.spacing(2),
    height: 40,
  },
  installments: {
    color: theme.palette.carbon[60],
  },
  statusContainer: {
    width: 92,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('xs')]: {
      width: 24,
      '& span': {
        display: 'none',
      },
    },
  },
  statusSummaryContainer: {
    width: 92,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      width: 24,
      '& span': {
        display: 'none',
      },
    },
  },
  statusPendingColor: {
    color: theme.palette.carbon[70],
  },
  timeContainer: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  detail: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardNumberContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  cardNumber: {
    marginRight: theme.spacing(1),
  },
  linkIcon: { width: '18px', height: '18px', fill: theme.palette.carbon[100] },
  carbon: {
    fill: theme.palette.carbon[100],
  },
  success: {
    fill: theme.palette.success.main,
  },
  warning: {
    fill: theme.palette.primary.main,
  },
}));

type Props = {
  sale: SaleItem;
};

const SalesGroupSale: FunctionComponent<Props> = ({ sale }) => {
  const classes = useStyles();
  const [detailsOpen, setDetailsOpen] = useState(false);

  const openDetail = () => {
    setDetailsOpen(true);
  };

  type SalesTypeMap = { [key in PaymentTypes]: { icon: () => JSX.Element; label: string } };
  const salesTypeMap: SalesTypeMap = {
    [PaymentTypes.PIX]: {
      icon: () => <PixIcon />,
      label: 'Pix',
    },
    [PaymentTypes.E_WALLET]: {
      icon: () => <LinkIcon className={classes.linkIcon} />,
      label: 'Carteira Digital',
    },
    [PaymentTypes.DEBIT]: {
      icon: () => <MachineIcon className={classes.carbon} />,
      label: 'Débito',
    },
    [PaymentTypes.CREDIT]: {
      icon: () => <MachineIcon className={classes.carbon} />,
      label: 'Crédito',
    },
    [PaymentTypes.VOUCHER]: {
      icon: () => <MachineIcon className={classes.carbon} />,
      label: 'Voucher',
    },
  };

  type SalesStatusMap = {
    [key in SaleStatus]: { icon: () => JSX.Element; label: string };
  };
  const salesStatusMap: SalesStatusMap = {
    [SaleStatus.APPROVED]: {
      icon: () => <CheckIcon fontSize="small" />,
      label: 'Aprovado',
    },
    [SaleStatus.PENDING]: {
      icon: () => <QueryBuilderIcon fontSize="small" />,
      label: 'Pendente',
    },
    [SaleStatus.CANCELLED]: {
      icon: () => <CancelIcon fontSize="small" />,
      label: 'Cancelado',
    },
    [SaleStatus.REFUSED]: {
      icon: () => <ErrorIcon fontSize="small" />,
      label: 'Recusado',
    },
    [SaleStatus.REVERSED]: {
      icon: () => <RepeatRoundedIcon fontSize="small" />,
      label: 'Devolvida',
    },
  };

  const saleStatusLabel = salesStatusMap[sale.status]?.label || '';
  const saleTypeLabel = salesTypeMap[sale.type]?.label || '';

  const getDetailContent = () => {
    const renderCardInfo = [PaymentTypes.CREDIT, PaymentTypes.DEBIT, PaymentTypes.VOUCHER].includes(sale.type);
    const renderDescription = [PaymentTypes.PIX, PaymentTypes.E_WALLET].includes(sale.type);

    if (renderCardInfo && sale.cardNumber) {
      return (
        <Box className={classes.cardNumberContainer}>
          <Typography variant="caption" className={classes.cardNumber}>
            {formatCardNumbers(sale.cardNumber)}
          </Typography>
          {sale.brand && <BrandIcon brand={sale.brand} />}
        </Box>
      );
    }

    if (renderDescription && sale.description) {
      return <Typography variant="caption">{sale.description}</Typography>;
    }

    return null;
  };

  return (
    <>
      <SalesGroupSaleDetails
        open={detailsOpen}
        onClose={() => setDetailsOpen(false)}
        onOpen={() => setDetailsOpen(true)}
        details={{ ...sale, type: saleTypeLabel, status: saleStatusLabel }}
        StatusIcon={salesStatusMap[sale.status]?.icon}
        TypeIcon={salesTypeMap[sale.type]?.icon}
      />
      <Box className={classes.root} onClick={openDetail}>
        <Box className={classes.column}>
          <Box className={classes.timeContainer}>
            {sale.method === 'e-wallet' ? salesTypeMap[sale.method]?.icon() : salesTypeMap[sale.type]?.icon()}
            <Typography variant="caption" className={classes.statusPendingColor}>
              {hoursAndMinutes(sale.date)}
            </Typography>
          </Box>
          <Box className={classes.detail}>
            <Typography variant="caption" className={classes.statusPendingColor}>
              {saleTypeLabel}
            </Typography>
            {getDetailContent()}
          </Box>
        </Box>
        <Box className={classes.column}>
          <Box className={classes.valueContainer}>
            <Typography variant="body">{`R$ ${formatAmount(sale.value)}`}</Typography>
            {sale.installments && sale.type === 'credit' && (
              <Typography className={classes.installments} variant="caption">
                {sale.installments === 1 ? 'à vista' : `parcelado em ${sale.installments}x`}
              </Typography>
            )}
          </Box>
          <Box className={classes.statusContainer}>
            {salesStatusMap[sale.status]?.icon()}
            <Typography variant="caption">{saleStatusLabel}</Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SalesGroupSale;
