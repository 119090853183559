import type { FunctionComponent } from 'react';
import React from 'react';

import { Box, Card, Grid, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(3),
    margin: 'auto',
    color: theme.palette.carbon[70],
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4),
      width: 656,
    },
  },
}));

const TaxSimulatorSkeleton: FunctionComponent = () => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Card className={classes.card}>
        <Skeleton animation="wave" width="50%" />
        <Skeleton animation="wave" width="80%" />

        <Box display="flex" justifyContent="space-between" mt={4}>
          <Skeleton variant="rect" width={280} height={288} animation="wave" />

          <Skeleton variant="rect" width={280} height={288} animation="wave" />
        </Box>
      </Card>
    </Grid>
  );
};

export default TaxSimulatorSkeleton;
