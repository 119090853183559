import React, { ReactElement } from 'react';

import { Box, Grid, makeStyles } from '@material-ui/core';

import EssencialMP35P from 'assets/images/essencial-mp35p.png';
import EssencialS920 from 'assets/images/essencial-s920.png';
import SmartN910 from 'assets/images/smart-n910.png';
import SuperMiniD195 from 'assets/images/supermini-d195.png';
import SuperMiniMP35 from 'assets/images/supermini-mp35.png';
import PageSection from 'components/PageSection';
import { ContentPage } from 'models/faq';

import AnswerPage from './components/AnswerPage';
import FaqAccordion from './components/FaqAccordion';
import FaqHeader from './components/FaqHeader';
import ManualItem from './components/ManualItem';
import { faqList } from './constants';

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.background.default,
    margin: theme.spacing(6, 0),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(4, 0),
    },
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(4),
    },
  },
  pageSection: {
    backgroundColor: theme.palette.backgroundColor.surface?.secondary,
    border: `1px solid ${theme.palette.carbon[10]}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    width: '100%',
  },
  manualList: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
}));

const Faq = (): JSX.Element => {
  const [contentPage, setContentPage] = React.useState<ContentPage>('main');
  const [answer, setAnswer] = React.useState<ReactElement | null>(null);
  const [titleFaq, setTitleFaq] = React.useState<string>('');

  const classes = useStyles();
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <FaqHeader />
      </Grid>

      {contentPage === 'main' ? (
        <>
          <Grid item xs={12} className={classes.pageSection}>
            <PageSection title="Manual de instruções">
              <Box component="ul" className={classes.manualList}>
                <ManualItem
                  machineImageLink={SuperMiniD195}
                  manualLink="https://cdn.c6bank.com.br/c6-site-docs/c6pay/supermini-manual-d195.pdf"
                  machineName="SuperMini"
                  machineModel="Maquininha D195"
                />
                <ManualItem
                  machineImageLink={SuperMiniMP35}
                  manualLink="https://cdn.c6bank.com.br/c6-site-docs/c6pay/supermini-manual-mp35.pdf"
                  machineName="SuperMini"
                  machineModel="Maquininha MP35"
                />
                <ManualItem
                  machineImageLink={EssencialS920}
                  manualLink="https://cdn.c6bank.com.br/c6-site-docs/c6pay/essencial-manual-s920.pdf"
                  machineName="Essencial"
                  machineModel="Maquininha S920"
                />
                <ManualItem
                  machineImageLink={EssencialMP35P}
                  manualLink="https://cdn.c6bank.com.br/c6-site-docs/c6pay/essencial-manual-mp35p.pdf"
                  machineName="Essencial"
                  machineModel="Maquininha MP35P"
                />
                <ManualItem
                  machineImageLink={SmartN910}
                  manualLink="https://cdn.c6bank.com.br/c6-site-docs/c6pay/smart-manual-n910.pdf"
                  machineName="Smart"
                  machineModel="Maquininha N910"
                />
              </Box>
            </PageSection>
          </Grid>
          <Grid item xs={12} className={classes.pageSection}>
            <PageSection title="Quer saber Mais?">
              <FaqAccordion
                faqList={faqList}
                setContentPage={setContentPage}
                setAnswer={setAnswer}
                setTitleFaq={setTitleFaq}
              />
            </PageSection>
          </Grid>
        </>
      ) : (
        <AnswerPage title={titleFaq} answer={answer} setContentPage={setContentPage} />
      )}
    </Grid>
  );
};

export default Faq;
