import React from 'react';
import type { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import { Box, SwipeableDrawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import Typography from 'components/UI/Typography';

const useStyles = makeStyles((theme) => ({
  drawer: {
    '& > .MuiPaper-root': {
      padding: theme.spacing(3),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        borderRadius: `${theme.shape.borderRadius * 4}px 0 0 0`,
        width: 360,
      },
    },
  },
  carbon70: {
    color: theme.palette.carbon['70'],
  },
}));

type Props = {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
};

const ReceivablesGroupItemDetails: FunctionComponent<Props> = ({ open, onClose, onOpen }) => {
  const classes = useStyles();

  return (
    <SwipeableDrawer
      className={classes.drawer}
      anchor="right"
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      aria-label="drawer lateral"
      disableSwipeToOpen
    >
      <Box mb={3}>
        <CloseIcon className={classes.carbon70} cursor="pointer" onClick={onClose} aria-label="Fechar janela" />
      </Box>

      <Typography variant="header" component="h1" gutterBottom paragraph>
        Dúvidas frequentes.
      </Typography>

      <Typography variant="bodyBold" component="h2">
        Qual o valor mínimo para antecipação?
      </Typography>
      <Typography variant="caption" className={classes.carbon70} component="p" paragraph>
        O valor mínimo é de R$ 100,00 para cada adquirente.
      </Typography>

      <Typography variant="bodyBold" component="h2">
        Como as taxas são calculadas?
      </Typography>
      <Typography variant="caption" className={classes.carbon70} component="p" paragraph>
        As taxas são calculadas de acordo com os tipos de operação e parcelas disponíveis para antecipar.
      </Typography>

      <Typography variant="bodyBold" component="h2">
        Quando posso antecipar meus recebíveis?
      </Typography>
      <Typography variant="caption" className={classes.carbon70} component="p" paragraph>
        A antecipação está disponível de segunda a sexta-feira, das 8h30 às 13h30, exceto feriado. Este período é
        determinado pelo horário de funcionamento da regulamentação em vigor.
      </Typography>

      <Typography variant="bodyBold" component="h2">
        Em quanto tempo poderei fazer uma nova antecipação?
      </Typography>
      <Typography variant="caption" className={classes.carbon70} component="p" paragraph>
        Quando o pedido anterior for concluído, você poderá fazer uma nova antecipação. Assim, garantimos a segurança da
        operação.
      </Typography>

      <Typography variant="bodyBold" component="h2">
        Quando recebo os valores da antecipação?
      </Typography>
      <Typography variant="caption" className={classes.carbon70} component="p" paragraph>
        Se o pedido for aprovado, você recebe os valores antecipados até às 18h.
      </Typography>
      <Link to="/faq">
        <Typography variant="bodylink" display="block" paragraph gutterBottom align="left">
          Conheça mais sobre Antecipação em nossa Central de Ajuda.
        </Typography>
      </Link>
    </SwipeableDrawer>
  );
};

export default ReceivablesGroupItemDetails;
