import React from 'react';
import type { FunctionComponent } from 'react';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

import Typography from 'components/UI/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
    borderRadius: 50,
    '& > span': {
      marginLeft: theme.spacing(1),
    },
  },
  success: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.dark,
  },
  error: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.dark,
  },
  pending: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark,
  },
}));

type AntitipationStatusProps = {
  text?: string;
  icon: typeof SvgIcon;
  variant: 'success' | 'error' | 'pending';
};

const AnticipationStatus: FunctionComponent<AntitipationStatusProps> = ({ text, icon: Icon, variant }) => {
  const classes = useStyles();

  return (
    <Box className={`${classes.root} ${classes[variant]}`}>
      <Icon fontSize="small" />
      {text && (
        <Typography component="span" variant="overline" color="inherit">
          {text}
        </Typography>
      )}
    </Box>
  );
};
export default AnticipationStatus;
