import React from 'react';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as SearchErrorIcon } from 'assets/images/search-error.svg';
import Typography from 'components/UI/Typography';

const useStyles = makeStyles((theme) => ({
  container: (props: { withoutBorder?: boolean }) => ({
    border: props.withoutBorder ? 'none' : `1px solid ${theme.palette.carbon[10]}`,
    backgroundColor: theme.palette.common.white,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(3, 5),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(3, 1),
    },
  }),
  icon: {
    width: '100%',
    marginBottom: theme.spacing(3),
  },
  subtitle: {
    color: theme.palette.carbon[90],
  },
}));

type Props = {
  subtitle: string;
  withoutBorder?: boolean;
};

const EmptyList = ({ subtitle, withoutBorder }: Props): JSX.Element => {
  const styleProps = {
    withoutBorder,
  };
  const classes = useStyles(styleProps);

  return (
    <Box className={classes.container}>
      <SearchErrorIcon className={classes.icon} />
      <Typography align="center" variant="subtitleMobile2" gutterBottom>
        Nenhum resultado para exibir.
      </Typography>
      <Typography align="center" variant="body" gutterBottom className={classes.subtitle}>
        {subtitle}
      </Typography>
    </Box>
  );
};

export default EmptyList;
