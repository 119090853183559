import React, { FunctionComponent, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as LinkIcon } from 'assets/images/link.svg';
import { ReactComponent as MachineIcon } from 'assets/images/payment-machine.svg';
import CommercialConditionsCard from 'components/CommercialConditions/Card';
import BrandIcon from 'components/UI/BrandIcon';
import PageTitle from 'components/UI/PageTitle';
import ServerError from 'components/UI/ServerError';
import { Brands } from 'models/brands';
import { GetTaxes, TaxesKeys } from 'models/taxes';
import { getTaxes } from 'services/taxes';
import { AccountSelectors } from 'store/accounts';

import PixConditionsCard from './PixConditionsCard';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(6, 0),

    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(4, 0),
    },

    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(4),
    },
  },
  cardsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: theme.spacing(2),
  },
  marginIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${theme.palette.carbon[10]}`,
    borderRadius: theme.shape.borderRadius,
    minWidth: 40,
    height: 24,
  },
}));

const CommercialConditions: FunctionComponent = () => {
  const classes = useStyles();

  const [taxes, setTaxes] = useState<GetTaxes | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const accountUserPortal = useSelector(AccountSelectors.accountUserPortal);

  const initRequestGetTaxes = useCallback(() => {
    getTaxes()
      .then(({ data }) => {
        if (data) {
          setTaxes(data);
        } else {
          throw Error();
        }
      })
      .catch(() => setHasError(true))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    setIsLoading(true);

    initRequestGetTaxes();

    return () => {
      setHasError(false);
      setIsLoading(false);
    };
  }, [initRequestGetTaxes]);

  const removeTaxes = (oldTaxes: GetTaxes, keysToRemove: TaxesKeys[]) => {
    const newTaxes: Partial<GetTaxes> = { ...oldTaxes };
    keysToRemove.forEach((key) => {
      delete newTaxes[key];
    });

    return { ...newTaxes };
  };

  const titleFromTaxes: { [keyof in TaxesKeys]: string } = {
    PADRÃO: `Vendas via maquininha`,
    'LINK PGTO': 'Vendas via Link de pagamento',
    MASTER: 'Mastercard',
    VISA: 'Visa',
    ELO: 'Elo',
    HIPERCARD: 'Hipercard',
    CABAL: 'Cabal',
    AMEX: 'Amex',
    AFRN: 'AFRN',
    BANESCARD: 'Banescard',
    SOROCRED: 'Sorocred',
    BANESECARD: 'Banesecard',
    JCB: 'JCB',
    CREDSYSTEM: 'Credsystem',
  };

  const Container = () => (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <PageTitle title="Taxas" />
      </Grid>
      <Grid item xs={12}>
        <PixConditionsCard userPortal={accountUserPortal} />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.cardsContainer}>
          {Object.keys(removeTaxes(taxes!, ['PADRÃO', 'LINK PGTO']))
            .sort()
            .map((taxKey) => (
              <CommercialConditionsCard
                isLoading={isLoading}
                subtitle={`Taxas para ${titleFromTaxes[taxKey as TaxesKeys]}`}
                title={titleFromTaxes['PADRÃO']}
                icon={
                  <Box className={classes.marginIcon}>
                    <BrandIcon brand={taxKey.toLowerCase() as Brands} />
                  </Box>
                }
                cardData={taxes?.[taxKey as TaxesKeys]}
              />
            ))}
          <CommercialConditionsCard
            isLoading={isLoading}
            title={titleFromTaxes['PADRÃO']}
            subtitle="Demais taxas"
            icon={<MachineIcon />}
            cardData={taxes?.['PADRÃO']}
          />

          <CommercialConditionsCard
            isLoading={isLoading}
            title={titleFromTaxes['LINK PGTO']}
            subtitle="Taxas + R$ 0,35 por venda confirmada."
            icon={<LinkIcon />}
            cardData={taxes?.['LINK PGTO']}
          />
        </div>
      </Grid>
    </Grid>
  );

  if (taxes && !hasError && !isLoading) {
    return <Container />;
  }

  if (hasError) {
    return <ServerError onTryAgain={() => initRequestGetTaxes()} />;
  }

  return null;
};

export default CommercialConditions;
