import type { FunctionComponent } from 'react';
import React from 'react';

import { Box, Grid, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Typography from 'components/UI/Typography';
import { fullDateFormat, returnWeekday } from 'helpers/date';
import { formatAmount } from 'helpers/formatters/formatters';
import type { UrType } from 'models/receivables';

import ReceivablesUr from './Item/ReceivablesUr';

const useStyles = makeStyles((theme) => ({
  root: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(4),
    },
  },
  groupHeader: {
    backgroundColor: theme.palette.backgroundColor.surface?.tertiary,
    padding: theme.spacing(2, 6, 2, 2),
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    border: `1px solid ${theme.palette.carbon[10]}`,
  },
  headerUr: {
    padding: theme.spacing(1, 2),
  },
  carbon70Color: {
    color: theme.palette.carbon[70],
  },
}));

type Props = {
  date: string;
  total: number;
  totalReceivables: number;
  list: UrType[];
};

const ReceivablesGroup: FunctionComponent<Props> = ({ date, total, list, totalReceivables }) => {
  const classes = useStyles();
  const today = new Date();
  const isToday = fullDateFormat(date) === fullDateFormat(today);

  return (
    <Box className={classes.root}>
      <Box className={classes.groupHeader}>
        <div>
          <Typography variant="bodyBold">{isToday ? 'Hoje' : returnWeekday(date)}</Typography>
          <Typography variant="caption">{fullDateFormat(date || undefined)}</Typography>
        </div>
        <div>
          <Typography variant="bodyBold">
            <Typography variant="caption" className={classes.carbon70Color}>
              R$
            </Typography>
            {` ${formatAmount(total || 0)}`}
          </Typography>
          <Typography variant="caption" paragraph className={classes.carbon70Color}>
            {`${totalReceivables} vendas`}
          </Typography>
        </div>
      </Box>
      <div>
        <Grid container className={classes.headerUr}>
          <Grid item sm={7} md={8} xs={7}>
            <Typography variant="bodyBold">Bandeiras</Typography>
          </Grid>
          <Grid item sm={5} md={2} xs={5}>
            <Typography variant="bodyBold">Saldo</Typography>
          </Grid>
          <Hidden smDown>
            <Grid item sm={6} md={2} xs={6}>
              <Typography variant="bodyBold">Status</Typography>
            </Grid>
          </Hidden>
        </Grid>
        {list?.map((ur) => (
          <ReceivablesUr receivable={ur} key={`${ur.amount}-${ur.date.toString()}`} />
        ))}
      </div>
    </Box>
  );
};

export default ReceivablesGroup;
