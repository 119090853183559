import { makeStyles } from '@material-ui/core/styles';

import { clientStatuses } from 'helpers/constants';

const useStyles = makeStyles((theme) => {
  const labelStyle = {
    fontSize: '0.75rem',
    color: theme.palette.carbon[90],
    '&.MuiStepLabel-completed, &.MuiStepLabel-active, &.MuiStepLabel-alternativeLabel': {
      color: theme.palette.carbon[90],
      fontWeight: 600,
      marginTop: theme.spacing(5),
    },
  };

  return {
    dotRoot: {
      position: 'relative',
      top: '-4px',
      backgroundColor: theme.palette.backgroundColor.surface?.secondary,
      zIndex: 1,
      width: 20,
      height: 20,
      border: `2px solid ${theme.palette.carbon[35]}`,
      borderRadius: '50%',
    },
    dotActive: {
      border: `2px solid ${theme.palette.backgroundColor.positive?.primary}`,
    },
    dotActiveIcon: {
      color: theme.palette.backgroundColor.positive?.primary,
      width: 16,
      height: 16,
    },
    dotCompleted: {
      border: `2px solid ${theme.palette.backgroundColor.positive?.primary}`,
      background: theme.palette.backgroundColor.positive?.primary,
    },
    dotCompletedIcon: {
      color: theme.palette.textColor.inverse,
      width: 16,
      height: 14,
    },
    dotError: {
      position: 'relative',
      width: 20,
      height: 20,
      borderRadius: '50%',
      zIndex: 1,
      top: '-4px',
      border: `2px solid ${clientStatuses.canceled.color}`,
      background: clientStatuses.canceled.color,
    },
    dotFailureIcon: {
      color: theme.palette.textColor.inverse,
      width: 16,
      height: 14,
    },
    stepperRoot: {
      padding: 0,
    },
    stepRootFirst: {
      padding: 0,
      '& > div > span': {
        background: theme.palette.carbon[35],
      },
    },
    stepRootMiddle: {
      padding: 0,
      '& .MuiStepConnector-root': {
        left: '-50%',
        right: '50%',
      },
      '& > div > span': {
        background: theme.palette.carbon[35],
      },
    },
    stepRootLast: {
      padding: 0,
      '& .MuiStepConnector-root': {
        left: '-50%',
        right: '50%',
      },
      '& > div > span': {
        background: theme.palette.carbon[35],
      },
    },
    stepRootLastWithError: {
      padding: 0,
      '& .MuiStepConnector-root': {
        left: '-50%',
        right: '50%',
      },
      '& > div > span': {
        background: `${theme.palette.carbon[35]} !important`,
      },
    },
    stepLabelFirst: {
      ...labelStyle,
      '&.MuiStepLabel-alternativeLabel': {
        position: 'relative',
        right: '-45%',
        textAlign: 'start',
      },
    },
    stepLabelMiddle: {
      ...labelStyle,
    },
    stepLabelLast: {
      ...labelStyle,
      '&.MuiStepLabel-alternativeLabel': {
        position: 'relative',
        left: '-45%',
        textAlign: 'end',
      },
    },
    labelDate: {
      fontSize: '0.75rem',
      color: theme.palette.carbon[70],
      whiteSpace: 'pre-line',
    },
  };
});

export default useStyles;
