import React, { useState, useEffect } from 'react';
import type { FormEvent } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { Box, Link, FormControlLabel, Checkbox, useMediaQuery, CircularProgress } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import type { Theme } from '@material-ui/core/styles';

import { ReactComponent as AgreementsIcon } from 'assets/images/agreements.svg';
import Button from 'components/UI/Button';
import ServerError from 'components/UI/ServerError';
import Typography from 'components/UI/Typography';
import { getAgreementsPending, signAgreements } from 'services/agreements';
import { useAppDispatch } from 'store';
import { CredentialsActions } from 'store/auth/credentials';
import { WhitelabelSelectors } from 'store/whitelabel';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    border: `1px solid ${theme.palette.carbon[10]}`,
    borderTop: 'none',
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: theme.palette.backgroundColor.surface?.secondary,
    maxWidth: 464,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  icon: {
    width: '100%',
  },
  content: {
    margin: theme.spacing(2, 3),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  spacing: {
    margin: theme.spacing(1, 0),
  },
  label: {
    color: theme.palette.carbon[90],
    margin: theme.spacing(2, 0),
    display: 'flex',
    alignItems: 'flex-start',
    '& > span': {
      paddingTop: 0,
    },
  },
  button: {
    margin: theme.spacing(1, 0),
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const Agreements = () => {
  const classes = useStyles();
  const [checkTerms, setCheckTerms] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [agreementsPending, setAgreementsPending] = useState<string[]>([]);

  const whitelabelTitle = useSelector(WhitelabelSelectors.whitelabelTitle);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const matchesXsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

  const initialRequest = () => {
    setLoading(true);
    getAgreementsPending()
      .then(({ data }) => {
        if (data && !data.agreementsPending.length) {
          navigate('/');
        } else if (data && data.agreementsPending.length) {
          setAgreementsPending(data.agreementsPending);
        } else {
          throw new Error();
        }
      })
      .catch((err) => setError(true))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    initialRequest();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    signAgreements(agreementsPending)
      .then(({ data }) => {
        if (data?.ok) {
          navigate('/');
        } else {
          throw new Error('Contrato não foi assinado');
        }
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  };

  if (loading) {
    return (
      <Backdrop className={classes.backdrop} open>
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }

  if (error) {
    return (
      <ServerError
        onTryAgain={() => {
          setError(false);
          initialRequest();
        }}
      />
    );
  }

  return (
    <Backdrop className={classes.backdrop} open>
      <Box className={classes.wrapper}>
        <AgreementsIcon className={classes.icon} />
        <form className={classes.content} onSubmit={handleSubmit}>
          <Typography variant="header" align="center" className={classes.spacing}>
            Seu primeiro acesso
          </Typography>
          <Typography variant="body" align="center" className={classes.spacing}>
            Para continuar, é necessário que você aceite os termos e condições abaixo.
          </Typography>
          <Link
            className={classes.spacing}
            target="_blank"
            href="https://openfiles.paygo.com.br/Contrato_de_Subadquirencia.pdf"
            rel="noopener noreferrer"
          >
            <Typography variant="bodylink" className={classes.uppercase}>
              Contrato de Subadquirência
            </Typography>
          </Link>
          <Link
            className={classes.spacing}
            target="_blank"
            href="https://openfiles.paygo.com.br/Termo_de_Uso_e_Politica_de_Privacidade_v3.2.pdf"
            rel="noopener noreferrer"
          >
            <Typography variant="bodylink" className={classes.uppercase}>
              Termos e Condições
            </Typography>
          </Link>
          <FormControlLabel
            control={<Checkbox checked={checkTerms} onChange={() => setCheckTerms((prev) => !prev)} color="primary" />}
            label={`Eu li e concordo com os termos e condições do Portal ${whitelabelTitle}.`}
            className={classes.label}
          />

          <Button
            className={classes.button}
            disabled={!checkTerms}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            size={matchesXsDown ? 'small' : 'large'}
          >
            Entrar na minha conta
          </Button>

          <Button
            className={classes.button}
            type="button"
            fullWidth
            variant="outlined"
            size={matchesXsDown ? 'small' : 'large'}
            onClick={() => dispatch(CredentialsActions.logout())}
          >
            Cancelar
          </Button>
        </form>
      </Box>
    </Backdrop>
  );
};

export default Agreements;
