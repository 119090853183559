import type { FunctionComponent, ReactElement } from 'react';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Box, Modal, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIconRounded from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';

import SalesFilter from 'components/Sales/Filter';
import Button from 'components/UI/Button';
import PageTitle from 'components/UI/PageTitle';
import Typography from 'components/UI/Typography';
import type { Filter } from 'models/sales';
import { getSalesReportService } from 'services/sales';
import { AccountSelectors } from 'store/accounts';
import { WhitelabelSelectors } from 'store/whitelabel';

const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  modalContent: {
    padding: theme.spacing(3),
    border: `1px solid ${theme.palette.carbon[10]}`,
    boxSizing: 'border-box',
    background: theme.palette.common.white,
    borderRadius: 8,
    width: '100%',
    outline: 'none',
    overflowY: 'scroll',
    height: '100vh',
    [theme.breakpoints.up('sm')]: {
      overflowY: 'hidden',
      width: 550,
      height: 'auto',
    },
    display: 'grid',
    gap: theme.spacing(3),
  },
  modalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonsWrapper: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(3),
    },
  },
  actionButton: {
    padding: theme.spacing(1, 2),
    justifyContent: 'center',
    alignItems: 'center',
    width: 112,
  },
  reportButton: {
    color: theme.palette.info.dark,
    fontSize: '1rem',
    textTransform: 'none',
    borderRadius: '8px',
  },
  carbon: {
    color: theme.palette.carbon[100],
  },
}));

type Props = {
  titleProps: {
    isLoading?: boolean;
    title: string;
    updatedAt?: string;
  };
  page: number;
};

const SalesHeader: FunctionComponent<Props> = ({ page, titleProps }): ReactElement => {
  const classes = useStyles();
  const today = new Date();
  const emptyFilter = {
    endDate: today,
    startDate: today,
    method: 'all',
    status: 'all',
    date: 'today',
  };
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState<Filter>(emptyFilter);
  const [reportError, setReportError] = useState(false);
  const [reportLoading, setReportLoading] = useState(false);
  const accountDocumentNumber = useSelector(AccountSelectors.accountDocumentNumber);

  const whitelabelTitle = useSelector(WhitelabelSelectors.whitelabelTitle) as string;

  const fileNameWhitelabel: { [key: string]: string } = {
    'C6 Pay': 'Vendas para Relatório de Vendas C6Pay',
    PayGo: 'Relatório de Vendas PayGo',
    SolPay: 'Relatório de Vendas SolPay',
  };

  useEffect(() => {
    setFilter(emptyFilter);
    setReportError(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const downloadReport = () => {
    setReportLoading(true);
    const { endDate, startDate, method: paymentType, status } = filter;
    getSalesReportService({
      documentNumber: accountDocumentNumber as string,
      startDate,
      endDate,
      status,
      paymentType,
      page,
    })
      .then((response) => {
        if (response.data) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.id = 'download_button_report';
          link.setAttribute('download', `${fileNameWhitelabel[whitelabelTitle]}.xlsx`);
          link.click();
          link.remove();
        } else {
          throw new Error(response.errorMessage);
        }
      })
      .catch(() => setReportError(true))
      .finally(() => setReportLoading(false));
  };

  return (
    <Box className={classes.headerWrapper}>
      <PageTitle {...titleProps} />
      <Button
        variant="text"
        size="large"
        aria-label="Baixar relatório de vendas"
        color="secondary"
        type="button"
        startIcon={<GetAppIcon />}
        onClick={() => setIsOpen(true)}
        className={classes.reportButton}
      >
        Baixar relatório de vendas
      </Button>
      <Modal open={isOpen} onClose={() => setIsOpen(false)} className={classes.modalContainer}>
        <Box className={classes.modalContent}>
          <Box className={classes.modalHeader}>
            <Typography variant="bodyBold">Relatório de vendas</Typography>
            <IconButton onClick={() => setIsOpen(false)} component="button">
              <CloseIconRounded className={classes.carbon} />
            </IconButton>
          </Box>
          <SalesFilter filter={filter} setFilter={setFilter} isColumn />
          <Box className={classes.buttonsWrapper}>
            <Button
              variant="contained"
              title="aplicar filtros"
              className={classes.actionButton}
              color="primary"
              onClick={() => downloadReport()}
              loading={reportLoading}
            >
              Baixar
            </Button>
            <Button
              onClick={() => setIsOpen(false)}
              variant="outlined"
              className={classes.actionButton}
              color="default"
              type="reset"
            >
              Cancelar
            </Button>
          </Box>
          {reportError && (
            <Typography variant="caption" align="center">
              Houve um erro ao buscar o relatório. Tente novamente mais tarde.
            </Typography>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default SalesHeader;
