import type { FunctionComponent } from 'react';
import React from 'react';

import { Box, Card, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  content: {
    border: ` 1px solid ${theme.palette.carbon[10]}`,
    backgroundColor: theme.palette.marble[100],
    borderRadius: theme.shape.borderRadius * 2,
    display: 'flex',
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2),

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      '& > :last-child': {
        marginTop: theme.spacing(2),
      },
    },
  },
  contentItem: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',

    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
    },
  },
  contentSkeleton: {
    marginBottom: theme.spacing(1),

    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(0),
    },
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),

    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
}));

const AnticipationSkeleton: FunctionComponent = () => {
  const classes = useStyles();

  return (
    <Card>
      <Box width="100%" component="h2">
        <Skeleton animation="wave" />
      </Box>

      <Box className={classes.content}>
        <Box className={classes.contentItem}>
          <Skeleton animation="wave" className={classes.contentSkeleton} width={100} height={16} />
          <Skeleton animation="wave" className={classes.contentSkeleton} width={100} height={24} />
        </Box>
        <Box className={classes.contentItem}>
          <Skeleton animation="wave" className={classes.contentSkeleton} width={100} height={16} />
          <Skeleton animation="wave" className={classes.contentSkeleton} width={100} height={24} />
        </Box>
      </Box>

      <Box>
        <Box>
          <Skeleton animation="wave" className={classes.contentSkeleton} width="100%" height={16} />
        </Box>
        <Box>
          <Skeleton animation="wave" className={classes.contentSkeleton} width="100%" height={16} />
        </Box>
        <Box className={classes.button}>
          <Skeleton animation="wave" className={classes.contentSkeleton} width={168} height={40} />
        </Box>
      </Box>

      <Box />
    </Card>
  );
};

export default AnticipationSkeleton;
