import { createReducer } from '@reduxjs/toolkit';

import { changeWebBankingModal } from './actions';
import { dialogsInitialState } from './constants';
import type { DialogsCentral } from './types';

export default createReducer<DialogsCentral>(dialogsInitialState, (builder) =>
  builder.addCase(changeWebBankingModal, (state, action) => {
    state.isOpen = action.payload;
  }),
);
