import type { FunctionComponent } from 'react';
import React from 'react';

import { Box, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as SuccessIcon } from 'assets/images/success.svg';
import Button from 'components/UI/Button';
import Typography from 'components/UI/Typography';
import { formatAmount } from 'helpers/formatters/formatters';
import type { AcquirersInputValues, SimulationResponseData } from 'models/anticipation';

import AcquirerLabel from '../AcquirerLabel';
import AnticipationTypographyHighlight from '../TypographyHighlight';

const useStyles = makeStyles((theme) => ({
  carbon70: {
    color: theme.palette.carbon[70],
  },
  pointer: {
    cursor: 'pointer',
  },
  button: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(4, 8, 1),
    },
  },
  acquirerCards: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'start',
      '& > :first-child': {
        marginBottom: theme.spacing(2),
      },
    },
  },
  acquirerCardsInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
      '& > div': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        '&:not(:last-child)': {
          marginRight: 0,
          marginBottom: theme.spacing(2),
        },
      },
    },
  },
}));

type Props = {
  amountWithDiscount: number;
  acquirers: SimulationResponseData['acquirers'];
  acquirersAmount: AcquirersInputValues;
  onGoBack: () => void;
};

const AnticipationSuccess: FunctionComponent<Props> = ({ amountWithDiscount, acquirers, onGoBack }) => {
  const classes = useStyles();

  return (
    <Card>
      <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
        <SuccessIcon />

        <Box mt={3}>
          <Typography variant="bodyBold" component="h2" align="center" gutterBottom>
            Pedido de antecipação solicitado!
          </Typography>
          <Typography variant="caption" className={classes.carbon70} align="center" component="p">
            Em breve enviaremos novidades sobre o seu pedido de antecipação.
          </Typography>
        </Box>
      </Box>

      <Box my={4}>
        {acquirers.map((acquirer) => (
          <Card className={classes.acquirerCards} key={acquirer.acquirerDocumentNumber}>
            <Box minWidth={80}>
              <AcquirerLabel documentNumber={acquirer.acquirerDocumentNumber} name={acquirer.acquirerName} />
            </Box>

            <Box className={classes.acquirerCardsInfo}>
              <Box mr={3}>
                <Typography variant="caption" className={classes.carbon70}>
                  Pedido
                </Typography>
                <Typography variant="body2">{`#${acquirer.simulationId}`}</Typography>
              </Box>

              <Box>
                <Typography variant="caption" className={classes.carbon70}>
                  Total a receber
                </Typography>
                <Typography variant="body2">{`R$ ${formatAmount(acquirer.netAmount)}`}</Typography>
              </Box>
            </Box>
          </Card>
        ))}
      </Box>

      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="caption" component="p" className={classes.carbon70}>
          Total a receber
        </Typography>

        <AnticipationTypographyHighlight value={formatAmount(amountWithDiscount)} />
      </Box>

      <Box className={classes.button}>
        <Button
          fullWidth
          title="Voltar ao início"
          variant="contained"
          color="primary"
          size="large"
          aria-label="botão para voltar a página de Meus resumos"
          onClick={onGoBack}
        >
          Minhas Antecipações
        </Button>
      </Box>
    </Card>
  );
};

export default AnticipationSuccess;
