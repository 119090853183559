import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';

export const MySummariesCardText = [
  {
    icon: ShoppingBasketIcon,
    suffix: 'vendas',
    text: 'acumuladas',
  },
  {
    icon: AttachMoneyIcon,
    prefix: 'R$',
    text: 'em vendas',
  },
  {
    prefix: 'R$',
    text: 'hoje',
  },
  {
    prefix: 'R$',
    text: 'amanhã',
  },
  {
    prefix: 'R$',
    text: 'próximos 7 dias',
  },
  {
    prefix: 'R$',
    text: 'próximos 30 dias',
  },
  {
    prefix: 'R$',
    text: 'últimos 7 dias',
  },
  {
    prefix: 'R$',
    text: 'últimos 30 dias',
  },
];
