import React from 'react';
import type { FunctionComponent } from 'react';

import { Box, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as WebErrorIcon } from 'assets/images/web-generic-error.svg';
import Button from 'components/UI/Button';

import Typography from '../Typography';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    maxWidth: 464,
    height: 368,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3, 11),
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'center',

    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(3, 4),
    },
  },
  carbon100: {
    color: theme.palette.carbon[100],
  },
  neutralColorsDark: {
    color: theme.palette.textColor.dark,
  },
  icon: {
    width: '100%',
    marginBottom: theme.spacing(3),
  },
}));

type Props = {
  onTryAgain: () => void;
  title?: string;
  message?: string;
};

const ServerError: FunctionComponent<Props> = ({ onTryAgain, title, message }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Card className={classes.card}>
        <WebErrorIcon className={classes.icon} />
        <Box>
          {title && (
            <Box mb={1}>
              <Typography variant="subtitleMobile2" component="p" className={classes.carbon100}>
                {title}
              </Typography>
            </Box>
          )}
          <Box mb={3}>
            <Typography variant="body2" className={classes.neutralColorsDark}>
              {message || 'Algo deu errado e não tivemos uma resposta do servidor.'}
            </Typography>
          </Box>
          <Button
            fullWidth
            title="Tentar novamente"
            aria-label="botão para recarregar a simulação de antecipação"
            variant="contained"
            color="primary"
            size="large"
            onClick={onTryAgain}
          >
            Tentar Novamente
          </Button>
        </Box>
      </Card>
    </Box>
  );
};

export default ServerError;
