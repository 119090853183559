import { Nullable } from '@portal-types/helpers';

export enum PaymentLinkStatus {
  APPROVED = 'approved',
  PENDING = 'pending',
  EXPIRED = 'expired',
  REFUSED = 'refused',
}

export type PaymentLink = {
  description: Nullable<string>;
  expirationDate: Nullable<string>;
  amount: Nullable<number>;
  status: Nullable<PaymentLinkStatus>;
  link: Nullable<string>;
  id: Nullable<string>;
};

export type PaymentLinkGroup = {
  currentPage: number;
  totalPages: number;
  items: PaymentLink[];
};

export type CreatePaymentLink = {
  amount: number;
  description: string;
  debit: boolean;
  credit: {
    installments: number;
  } | null;
};

export type ResponsePaymentLinkPost = {
  data?: {
    id: string;
    url: string;
    expirationDate: string;
    description: string;
    amount: number;
  };
  errorMessage?: string;
};
