import React from 'react';
import type { FunctionComponent } from 'react';

import { Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import { ReactComponent as PixIcon } from 'assets/images/pix.svg';
import BrandIcon from 'components/UI/BrandIcon';
import Typography from 'components/UI/Typography';
import { formatAmount, formatCardNumbersSmall } from 'helpers/formatters/formatters';
import { Brands } from 'models/brands';
import { PaymentTypes, SaleItem, SaleStatus } from 'models/sales';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.carbon[10]}`,
    padding: theme.spacing(2),
  },
  column: {
    display: 'flex',
    alignItems: 'center',
  },
  columnPrice: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  valueContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    marginRight: theme.spacing(2),
    height: 40,
  },
  statusSummaryContainer: {
    width: theme.spacing(10),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  statusPendingColor: {
    color: theme.palette.carbon[70],
  },
  brandContainer: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.palette.carbon[10]}`,
    borderRadius: '50%',
    width: theme.spacing(4),
    height: theme.spacing(4),
    background: theme.palette.backgroundColor.surface?.marbleDark,
    '& > svg': {
      maxWidth: 26,
      maxHeight: 26,
    },
  },
  detail: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardNumberContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  cardNumber: {
    marginRight: theme.spacing(1),
  },
  success: {
    fill: theme.palette.composition.status?.active,
  },
  warning: {
    fill: theme.palette.composition.status?.attention,
  },
}));

type Props = {
  sale: SaleItem;
};

const LastSales = ({ sale }: Props): JSX.Element => {
  const classes = useStyles();

  type SalesSummaryStatusMap = {
    [key in SaleStatus]: { icon: () => JSX.Element; label: string };
  };
  const salesSummaryStatusMap: SalesSummaryStatusMap = {
    [SaleStatus.APPROVED]: {
      icon: () => <FiberManualRecordIcon fontSize="small" className={classes.success} />,
      label: 'Aprovado',
    },
    [SaleStatus.PENDING]: {
      icon: () => <FiberManualRecordIcon fontSize="small" className={classes.warning} />,
      label: 'Pendente',
    },
    [SaleStatus.CANCELLED]: {
      icon: () => <FiberManualRecordIcon fontSize="small" color="error" />,
      label: 'Cancelado',
    },
    [SaleStatus.REFUSED]: {
      icon: () => <FiberManualRecordIcon fontSize="small" color="error" />,
      label: 'Recusado',
    },
    [SaleStatus.REVERSED]: {
      icon: () => <FiberManualRecordIcon fontSize="small" color="error" />,
      label: 'Devolvida',
    },
  };

  type BrandIconProps = {
    brand: Brands;
  };
  type SalesTypeMap = {
    [key in PaymentTypes]: {
      icon: FunctionComponent<BrandIconProps> | JSX.Element;
      label: string;
    };
  };

  type SalesStatusMap = {
    [key in SaleStatus]: { label: string };
  };

  const salesStatusMap: SalesStatusMap = {
    [SaleStatus.APPROVED]: {
      label: 'Aprovado',
    },
    [SaleStatus.PENDING]: {
      label: 'Pendente',
    },
    [SaleStatus.CANCELLED]: {
      label: 'Cancelado',
    },
    [SaleStatus.REFUSED]: {
      label: 'Recusado',
    },
    [SaleStatus.REVERSED]: {
      label: 'Devolvida',
    },
  };

  const salesTypeMap: SalesTypeMap = {
    [PaymentTypes.PIX]: {
      icon: <PixIcon />,
      label: 'Pix',
    },
    [PaymentTypes.E_WALLET]: {
      icon: <PixIcon />,
      label: 'Carteira Digital',
    },
    [PaymentTypes.DEBIT]: {
      icon: <BrandIcon brand={sale.brand} />,
      label: 'Débito',
    },
    [PaymentTypes.CREDIT]: {
      icon: <BrandIcon brand={sale.brand} />,
      label: 'Crédito',
    },
    [PaymentTypes.VOUCHER]: {
      icon: <BrandIcon brand={sale.brand} />,
      label: 'Voucher',
    },
  };

  const saleStatusLabel = salesStatusMap[sale.status]?.label || '';
  const saleTypeLabel = salesTypeMap[sale.type]?.label || '';

  const getDetailContent = () => {
    const renderCardInfo = [PaymentTypes.CREDIT, PaymentTypes.DEBIT, PaymentTypes.VOUCHER].includes(sale.type);
    const renderDescription = [PaymentTypes.PIX, PaymentTypes.E_WALLET].includes(sale.type);

    if (renderCardInfo && sale.cardNumber) {
      return (
        <Box className={classes.cardNumberContainer}>
          <Typography variant="caption" className={classes.cardNumber}>
            {formatCardNumbersSmall(sale.cardNumber)}
          </Typography>
        </Box>
      );
    }

    if (renderDescription && sale.description) {
      return <Typography variant="caption">{sale.description}</Typography>;
    }

    return null;
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.column}>
        <Box className={classes.brandContainer}>{salesTypeMap[sale.type]?.icon}</Box>
        <Box className={classes.detail}>
          <Typography variant="caption" className={classes.statusPendingColor}>
            {saleTypeLabel}
          </Typography>
          {getDetailContent()}
        </Box>
      </Box>
      <Box className={classes.columnPrice}>
        <Box className={classes.valueContainer}>
          <Typography variant="body">{`R$ ${formatAmount(sale.value)}`}</Typography>
        </Box>
        <Box className={classes.statusSummaryContainer}>
          {salesSummaryStatusMap[sale.status]?.icon()}
          <Typography variant="caption">{saleStatusLabel}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default LastSales;
