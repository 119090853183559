import { formatUserDocument } from 'helpers/formatters/formatters';
import { client } from 'services/client';

type ServiceOrdersService = {
  data?: {
    code: number;
  };
  errorMessage?: string;
};

type ServiceOrdersParams = {
  charger?: '720054 - Carregador N910' | '720053 - Carregador D195 USB' | '720052 - Carregador S920 Mini Base' | '';
  reasonChange?: string;
  coilQuantity?: number | null;
};

export const createServiceOrder = async ({
  coilQuantity,
  charger,
  reasonChange,
}: ServiceOrdersParams): Promise<ServiceOrdersService> => {
  const localStorageAuthJson = localStorage.getItem('persist:auth') as string;
  const localStorageAuth = JSON.parse(localStorageAuthJson);
  const localStorageAuthData = JSON.parse(localStorageAuth.data);

  const localStorageAccountJson = localStorage.getItem('persist:account') as string;
  const localStorageAccount = JSON.parse(localStorageAccountJson);
  const localStorageAccountData = JSON.parse(localStorageAccount.data);

  try {
    const response = await client.post<ServiceOrdersService['data']>('portal/v1/services-orders', {
      token: localStorageAuthData.accessToken,
      useWhitelabel: true,
      headers: {
        'content-type': 'application/json',
        'document-number': formatUserDocument(localStorageAccountData.documentNumber),
      },
      data: {
        equipment: charger ? 'charger' : 'coil',
        ...(charger
          ? {
              charger: {
                model: charger,
                reason: reasonChange,
              },
            }
          : {
              coil: {
                quantity: coilQuantity,
              },
            }),
      },
    });

    if (response.parsedBody) {
      return { data: response.parsedBody };
    }

    throw new Error('Ocorreu um erro ao criar a ordem de serviço');
  } catch (e) {
    return {
      errorMessage: 'Ocorreu um erro ao criar a ordem de serviço',
    };
  }
};
