import React from 'react';
import type { FunctionComponent } from 'react';

import { Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Typography from 'components/UI/Typography';
import helpersFormatters from 'helpers/formatters';

const useStyles = makeStyles((theme) => {
  return {
    avatar: {
      backgroundColor: (contrastColor: boolean) =>
        contrastColor ? theme.palette.secondary.contrastText : theme.palette.secondary.main,
      width: 32,
      height: 32,
      [theme.breakpoints.up('lg')]: {
        marginRight: theme.spacing(2),
      },
      '& ::before': {
        content: '',
        heigth: 16,
        border: `1px solid ${theme.palette.composition.inverseStroke?.[32]}`,
      },
    },
    avatarLetters: {
      color: (contrastColor: boolean) =>
        !contrastColor ? theme.palette.secondary.contrastText : theme.palette.secondary.main,
    },
  };
});

type Props = {
  userName: string;
  contrastColor?: boolean;
};

const SideMenuAvatar: FunctionComponent<Props> = ({ userName, contrastColor = false }) => {
  const classes = useStyles(contrastColor);

  return (
    <Avatar alt={userName} className={classes.avatar}>
      <Typography variant="subtitleWebUppercaseSmall" className={classes.avatarLetters} component="b">
        {helpersFormatters.userInitials(userName)}
      </Typography>
    </Avatar>
  );
};

export default SideMenuAvatar;
