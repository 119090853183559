import { formatUserDocument } from 'helpers/formatters/formatters';
import {
  MarkAsReadResponse,
  NotificationMonthListType,
  NotificationsParams,
  NotificationsServiceResponse,
} from 'models/notification';
import { client } from 'services/client';

export const getNotifications = async ({
  currentPage,
  documentNumber,
  accessToken,
}: NotificationsParams): Promise<NotificationsServiceResponse> => {
  try {
    const { parsedBody } = await client.get<NotificationMonthListType>(
      `portal/v1/notifications?page=${currentPage}&perPage=5`,
      {
        token: accessToken,
        useWhitelabel: true,
        headers: {
          'document-number': formatUserDocument(documentNumber),
        },
      },
    );

    if (parsedBody) {
      return {
        data: parsedBody,
      };
    }

    throw new Error('Ocorreu um erro ao buscar as notificações');
  } catch (error) {
    return { errorMessage: error.errorMessage };
  }
};

export const markAsRead = async (notificationId: number) => {
  const localStorageAuthJson = localStorage.getItem('persist:auth') as string;
  const localStorageAuth = JSON.parse(localStorageAuthJson);
  const localStorageAuthData = JSON.parse(localStorageAuth.data);

  const localStorageAccountJson = localStorage.getItem('persist:account') as string;
  const localStorageAccount = JSON.parse(localStorageAccountJson);
  const localStorageAccountData = JSON.parse(localStorageAccount.data);

  try {
    const response = await client.patch<MarkAsReadResponse>('portal/v1/notifications/mark_as_read', {
      token: localStorageAuthData.accessToken,
      useWhitelabel: true,
      headers: {
        'document-number': formatUserDocument(localStorageAccountData.documentNumber),
        'content-type': 'application/json',
      },
      data: { notificationId },
    });

    console.log(notificationId, response);
    if (response.parsedBody) {
      return { data: response.parsedBody };
    }

    throw new Error('Ocorreu um problema ao atualizar o status de lido da notificação');
  } catch (error) {
    return { errorMessage: error.message };
  }
};
