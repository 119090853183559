import React, { useEffect, useState } from 'react';
import type { FunctionComponent } from 'react';

import { Box, Divider, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as LinkCreated } from 'assets/images/link-created.svg';
import ClipboardJS from 'clipboard';
import PaymentLinkShareButtons from 'components/PaymentLink/ShareButtons';
import Button from 'components/UI/Button';
import Typography from 'components/UI/Typography';
import { fullDateFormat } from 'helpers/date';
import helpersFormatters from 'helpers/formatters';

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(4, 0, 3),
  },
  sectionLinkInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
  sectionLink: {
    display: 'grid',
    gap: theme.spacing(2),
    margin: theme.spacing(2, 0, 4),
    overflowWrap: 'anywhere',
    '& > button': {
      textTransform: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      gridAutoFlow: 'column',
    },
  },
  sectionButtons: {
    display: 'flex',
    '& > *:not(last-child)': {
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(0, 5),
    },
  },
  expirationText: {
    color: theme.palette.carbon[70],
  },
}));

type Props = {
  id: string;
  url: string;
  description: string;
  amount: number;
  expirationDate: string;
  setSuccessMessage: (arg: boolean) => void;
  closeModal: () => void;
  createAnotherPaymentLink: () => void;
  refreshPaymentLink: () => void;
};

const PaymentLinkCreate: FunctionComponent<Props> = ({
  id,
  url,
  description,
  amount,
  expirationDate,
  setSuccessMessage,
  createAnotherPaymentLink,
  closeModal,
  refreshPaymentLink,
}) => {
  const classes = useStyles();
  const [clipboardButton, setClipboardButton] = useState<ClipboardJS | null>(null);
  const [clipboardLink, setClipboardLink] = useState<ClipboardJS | null>(null);
  const expirationDateFormatted = fullDateFormat(expirationDate);

  useEffect(() => {
    setClipboardButton(new ClipboardJS(`#clipboard-action-button-${id}`));
    setClipboardLink(new ClipboardJS(`#target-clipboard-link-${id}`));
    return () => {
      clipboardButton?.destroy();
      clipboardLink?.destroy();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (clipboardButton) {
      clipboardButton.on('success', () => {
        setSuccessMessage(true);
      });
    }
  }, [clipboardButton, setSuccessMessage]);

  useEffect(() => {
    if (clipboardLink) {
      clipboardLink.on('success', () => {
        setSuccessMessage(true);
      });
    }
  }, [clipboardLink, setSuccessMessage]);

  return (
    <>
      <Box className={classes.sectionLinkInfo}>
        <LinkCreated />
        <Typography variant="bodyBold">Seu link de pagamento está pronto!</Typography>
        <Typography variant="body">{description}</Typography>
        <Typography variant="header2">{`R$ ${helpersFormatters.formatIntToAmount(amount)}`}</Typography>
        <Typography className={classes.expirationText} variant="caption">
          Link válido até
          <b>{` ${expirationDateFormatted}.`}</b>
        </Typography>
      </Box>

      <Divider className={classes.divider} />

      <Typography variant="bodyBold">Agora compartilhe o link com seu cliente</Typography>
      <Box className={classes.sectionLink}>
        <Link href={url} target="_blank" rel="noreferrer noopener" color="inherit">
          <Typography
            id={`target-clipboard-link-${id}`}
            data-clipboard-target={`#target-clipboard-link-${id}`}
            variant="textLink"
            aria-label="Abrir link para pagamento."
          >
            {url}
          </Typography>
        </Link>
        <Button
          variant="outlined"
          id={`clipboard-action-button-${id}`}
          data-clipboard-target={`#target-clipboard-link-${id}`}
          aria-label="Copiar link criado para compartilhar."
        >
          Copiar Link
        </Button>
      </Box>

      <PaymentLinkShareButtons link={url} />

      <Box className={classes.sectionButtons}>
        <Button
          type="submit"
          title="Voltar"
          fullWidth
          variant="outlined"
          size="small"
          aria-label="Ver histórico de links de pagamento criados."
          onClick={() => {
            closeModal();
            refreshPaymentLink();
          }}
        >
          Ver Lista
        </Button>
        <Button
          type="button"
          title="Criar Outro"
          fullWidth
          variant="contained"
          color="primary"
          size="small"
          aria-label="Criar novo link de pagamento."
          onClick={createAnotherPaymentLink}
        >
          Criar Outro
        </Button>
      </Box>
    </>
  );
};

export default PaymentLinkCreate;
