import type { Nullable } from '@portal-types/helpers';

import type { Brands } from 'models/brands';

import { DownloadAreaItem } from './download';

// Types relacionados ao serviço de receivables
export type ReceivablesServiceResponse = {
  data?: ReceivablesData;
  errorMessage?: string;
};

export type ReceivablesData = {
  currentPage: number;
  totalPages: number;
  receivablesList: ReceivablesGroup[];
};

export type UrType = {
  amount: number;
  date: Date;
  status: ReceivablesUrToQueryParamsStatus;
  operation: ReceivablesQueryParamsOperation;
  cardBrand: Brands;
};

export type ReceivablesUrData = {
  currentPage: number;
  totalPages: number;
  totalItems: number;
  items: {
    [date: string]: {
      totalReceivables: number;
      totalAmount: number;
      urs: UrType[];
    };
  };
};

export type ReceivablesGroup = {
  date: Nullable<string>;
  totalAmount: Nullable<number>;
  items: ReceivableItem[];
};

export type ReceivableItem = {
  saleDate: Nullable<string>;
  receivableDate: Nullable<string>;
  status: Nullable<ReceivablesStatus>;
  operation: Nullable<ReceivablesOperation>;
  cardNumber: Nullable<string>;
  cardBrand: Nullable<Brands>;
  installment: Nullable<number>;
  installments: Nullable<number>;
  orderId: Nullable<string>;
  nsu: Nullable<string>;
  discount: Nullable<number>;
  amount: Nullable<number>;
  totalAmount: Nullable<number>;
  formattedInstallments: Nullable<string>;
  financialInstitutionName: Nullable<string>;
  financialInstitution: Nullable<string>;
};

export enum ReceivablesStatus {
  AGENDADO = 'agendado',
  PAGO = 'pago',
  ADIADA = 'adiada',
  CANCELADA = 'cancelada',
  PENDENTE = 'pendente',
  LIQUIDADA = 'liquidada',
  OUTROS = 'outros',
  BLOQUEADA = 'bloqueada',
}

export enum ReceivablesUrStatus {
  agendado = 'agendado',
  pago = 'pago',
  rejeitado = 'rejeitado',
  pendente = 'pendente',
  bloqueado = 'bloqueado',
}

export enum ReceivablesOperation {
  DEBITO = 'venda debito',
  CREDITO = 'a vista',
}

export enum OperationLabel {
  DEBITO = 'Débito',
  CREDITO_PARCELADO = 'Crédito parcelado',
  CREDITO_A_VISTA = 'Crédito à vista',
}

// Types relacionados ao query params do serviço de receivables
export type ReceivableServiceParams = {
  startDate: Date;
  endDate: Date;
  currPage: number;
  operation?:
    | ReceivablesQueryParamsOperation[]
    | ReceivablesQueryParamsOperation.DEBIT
    | ReceivablesQueryParamsOperation.ALL
    | null;
  status?: ReceivablesQueryParamsStatus | null;
  cardBrand?: Brands | null;
};

export enum ReceivablesQueryParamsDate {
  TODAY = 'today',
  NEXT_DAY = 'nextDay',
  NEXT_WEEK = 'nextWeek',
  NEXT_MONTH = 'nextMonth',
  DATE_PERIOD = 'datePeriod',
}

export enum ReceivablesQueryParamsStatus {
  ALL = 'all',
  EARLY = 'early',
  BLOCKED = 'blocked',
  CANCELED = 'canceled',
  PAYED = 'payed',
  PENDING = 'pending',
  SCHEDULED = 'scheduled',
  SETTLED = 'settled',
  OTHER = 'other',
}

export enum ReceivablesUrToQueryParamsStatus {
  agendado = ReceivablesQueryParamsStatus.SCHEDULED,
  pago = ReceivablesQueryParamsStatus.PAYED,
  pendente = ReceivablesQueryParamsStatus.PENDING,
  bloqueado = ReceivablesQueryParamsStatus.BLOCKED,
  outros = ReceivablesQueryParamsStatus.OTHER,
  cancelada = ReceivablesQueryParamsStatus.CANCELED,
}

export enum ReceivablesQueryParamsOperation {
  ALL = 'all',
  CREDIT = 'credit',
  DEBIT = 'debit',
  INSTALLMENT_WITHOUT_INTEREST = 'installmentWithoutInterest',
  INSTALLMENT_WITH_INTEREST = 'installmentWithInterest',
}

export enum ReceivablesQueryParamsBrands {
  ALL = 'all',
  HIPERCARD = 'hipercard',
  MASTER = 'master',
  AMEX = 'amex',
  ELO = 'elo',
  VISA = 'visa',
  DINERS = 'diners club',
  TICKET = 'ticket log',
  CETELEM = 'cetelem',
}

export enum ReceivablesQueryUrParamsOperation {
  all = 'all',
  credit = 'crédito',
  debit = 'débito',
  installmentWithoutInterest = 'parcelado sem juros',
  installmentWithInterest = 'parcelado com juros',
}

export type ReceivablesFilter = {
  status: ReceivablesQueryParamsStatus;
  operation: ReceivablesQueryParamsOperation;
  brand: ReceivablesQueryParamsBrands;
  checkedDate: ReceivablesQueryParamsDate;
  startDate: Date;
  endDate: Date;
};

// Types relacionado ao serviço de receivables summary
export type ReceivablesSummaryServiceResponse = {
  data?: ReceivableSummaryData;
  errorMessage?: string;
};

export type ReceivableSummaryData = {
  today: number;
  nextDay: number;
  nextWeek: number;
  nextMonth: number;
};

export type ReceivablesSummaryResponse = { data?: ReceivableSummary; errorMessage?: string };

export type ReceivableSummary = {
  today: {
    startDate: string;
    endDate: string;
    total: number;
    count: number;
  };
  nextDay: {
    startDate: string;
    endDate: string;
    total: number;
    count: number;
  };
  nextWeek: {
    startDate: string;
    endDate: string;
    total: number;
    count: number;
  };
  nextMonth: {
    startDate: string;
    endDate: string;
    total: number;
    count: number;
  };
};

export type ReceivablesReportResponse = {
  response?: DownloadAreaItem;
  errorMessage?: string;
};

export type ReceivablesUrResponse = {
  data?: ReceivablesUrData;
  errorMessage?: string;
};

export type ReceivableReportServiceParams = {
  startDate: Date;
  endDate: Date;
  operation?: ReceivablesQueryParamsOperation;
  status?: ReceivablesQueryParamsStatus;
  cardBrand?: ReceivablesQueryParamsBrands;
};

export type ReceivablesUrServiceParams = {
  startDate: Date;
  endDate: Date;
  perPage?: number;
  currentPage?: number;
  operation?: ReceivablesQueryParamsOperation;
  status?: ReceivablesQueryParamsStatus;
  cardBrand?: ReceivablesQueryParamsBrands;
};

export type PendingReceivablesData = {
  currentPage: number;
  totalPages: number;
  total: number;
  transactions: ReceivableItem[];
};

export type PendingReceivablesResponse = {
  data?: PendingReceivablesData;
  errorMessage?: string;
};
