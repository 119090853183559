import { formatRequestDate } from 'helpers/date';
import { formatUserDocument } from 'helpers/formatters/formatters';
import { DownloadAreaItem } from 'models/download';
import { ReceivableReportServiceParams, ReceivablesReportResponse } from 'models/receivables';
import { client } from 'services/client';
import { store } from 'store';
import { DownloadAreaActions } from 'store/download';

export const requestReceivableReport = async ({
  startDate,
  endDate,
  operation,
  status,
  cardBrand,
}: ReceivableReportServiceParams): Promise<ReceivablesReportResponse> => {
  try {
    const operationQuery = (): string => {
      if (operation === 'all') return '';
      return `&operation=${operation}`;
    };

    const statusQuery = (): string => {
      if (status === 'all') return '';
      return `&status=${status}`;
    };

    const cardBrandQuery = (): string => {
      if (!cardBrand || cardBrand === 'all') return '';
      return `&card-brand=${cardBrand}`;
    };
    const localStorageAuthJson = localStorage.getItem('persist:auth') as string;
    const localStorageAuth = JSON.parse(localStorageAuthJson);
    const localStorageAuthData = JSON.parse(localStorageAuth.data);

    const localStorageAccountJson = localStorage.getItem('persist:account') as string;
    const localStorageAccount = JSON.parse(localStorageAccountJson);
    const localStorageAccountData = JSON.parse(localStorageAccount.data);

    const response = await client.get(
      `portal/v1/reports/receivables?start-date=${formatRequestDate(startDate)}&end-date=${formatRequestDate(
        endDate,
      )}${statusQuery()}${operationQuery()}${cardBrandQuery()}`,
      {
        token: localStorageAuthData.accessToken,
        useWhitelabel: true,
        headers: {
          'document-number': formatUserDocument(localStorageAccountData.documentNumber),
        },
      },
    );
    if (response.parsedBody) {
      return {
        response: response.parsedBody,
      };
    }
    throw new Error('Ocorreu um erro ao realizar o Download');
  } catch (error) {
    return { errorMessage: 'Ocorreu um erro ao buscar o relatório' };
  }
};

export const requestReceivableReportPolling = async (id: number) => {
  try {
    const reportsQuery = (): string => {
      return `id=${id}`;
    };

    const localStorageAuthJson = localStorage.getItem('persist:auth') as string;
    const localStorageAuth = JSON.parse(localStorageAuthJson);
    const localStorageAuthData = JSON.parse(localStorageAuth.data);

    const localStorageAccountJson = localStorage.getItem('persist:account') as string;
    const localStorageAccount = JSON.parse(localStorageAccountJson);
    const localStorageAccountData = JSON.parse(localStorageAccount.data);

    const response = await client.get(`portal/v1/reports?${reportsQuery()}`, {
      token: localStorageAuthData.accessToken,
      useWhitelabel: true,
      headers: {
        'document-number': formatUserDocument(localStorageAccountData.documentNumber),
      },
    });

    if (response.parsedBody) {
      return {
        data: response.parsedBody[0],
      };
    }
    throw new Error('Ocorreu um erro ao realizar o Polling');
  } catch (error) {
    throw new Error('Ocorreu um erro ao realizar o Polling');
  }
};

const downloadReportFile = (downloadUrl: string): void => {
  const fileNameWhitelabel: { [key: string]: string } = {
    'C6 Pay': 'Vendas para Relatório de Recebíveis C6Pay',
    PayGo: 'Relatório de Recebíveis PayGo',
    SolPay: 'Relatório de Recebíveis SolPay',
  };
  const whitelabelTitle = store.getState().whitelabel.data?.title;

  const link = document.createElement('a');
  link.href = downloadUrl;
  link.id = 'download_button_report';
  link.setAttribute('download', `${fileNameWhitelabel[whitelabelTitle || 'C6 Pay']}.xlsx`);
  link.click();
  link.remove();
};

export const downloadReportService = async (currentFilter: any) => {
  const { startDate, endDate, operation, status, brand } = currentFilter;
  const { response } = await requestReceivableReport({ startDate, endDate, operation, status, cardBrand: brand });
  if (response) {
    store.dispatch(DownloadAreaActions.reportList([...store.getState().downloadArea.data?.reportList, response]));
    store.dispatch(DownloadAreaActions.downloading(true));
    console.log(store.getState().downloadArea.data?.reportList);

    const pooling = setTimeout(() => {
      requestReceivableReportPolling(response.id).then(({ data }) => {
        if (data.status === 'completed') {
          downloadReportFile(data.downloadUrl);
          const deleteReport = store
            .getState()
            .downloadArea.data?.reportList.filter((report: DownloadAreaItem) => report.id !== data.id);
          store.dispatch(DownloadAreaActions.reportList([...deleteReport]));
          clearInterval(pooling);
        }
      });
    }, 15000); // 15 seconds
  }
};
