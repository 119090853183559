import type { FunctionComponent } from 'react';
import React from 'react';

import { Paper, useMediaQuery } from '@material-ui/core';
import type { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as PaymentLinkIcon } from 'assets/images/payment-link-cash.svg';
import Button from 'components/UI/Button';
import Typography from 'components/UI/Typography';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    padding: theme.spacing(3, 6),
    maxWidth: 464,
    margin: theme.spacing(6, 'auto'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(3),
    },
  },
  title: {
    color: theme.palette.carbon[100],
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    color: theme.palette.carbon[100],
    textAlign: 'center',
    marginBottom: theme.spacing(3),
  },
  button: {
    width: '100%',
  },
}));

type Props = {
  onCreateLink: () => void;
};

const PaymentLinkWelcome: FunctionComponent<Props> = ({ onCreateLink }) => {
  const classes = useStyles();
  const xsScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

  return (
    <Paper className={classes.container} variant="outlined">
      <PaymentLinkIcon />
      <Typography variant={xsScreen ? 'subtitleMobile' : 'header'} className={classes.title}>
        Você ainda não criou links de pagamento.
      </Typography>
      <Button variant="contained" color="primary" size="large" className={classes.button} onClick={onCreateLink}>
        Criar link de pagamento.
      </Button>
    </Paper>
  );
};

export default PaymentLinkWelcome;
