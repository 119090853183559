export enum Brands {
  AMEX = 'amex',
  BANESCARD = 'banescard',
  BANESECARD = 'banesecard',
  CABAL = 'cabal',
  CETELEM = 'cetelem',
  CREDSYSTEM = 'credsystem',
  CREDZ = 'credz',
  DINERS = 'diners',
  ELO = 'elo',
  HIPER = 'hiper',
  HIPERCARD = 'hipercard',
  JCB = 'jcb',
  MAESTRO = 'maestro',
  MAIS = 'mais',
  MASTERCARD = 'mastercard',
  MASTER = 'master',
  SICREDI = 'sicredi',
  SOROCRED = 'sorocred',
  VISA = 'visa',
  VISA_ELECTRON = 'visa-electron',

  ABRAPETITE = 'abrapetite',
  ALELO = 'alelo',
  BEN = 'ben',
  GOOD_CARD = 'goodcard',
  GREEN_CARD = 'green-card',
  NUTRICASH = 'nutricash',
  SODEXO = 'sodexo',
  TICKET = 'ticket',
  VEGAS_CARD = 'vegas',
  VEROCARD = 'verocard',
  VR = 'vr',
  CNAB = 'outros',
  // BANRICARD = 'banricard',
  // BNBCLUBE = 'bnbclube',
  // BIQ_BENEFICIOS = 'biq-beneficios',
  // CONVENIOS_CARD = 'convenios-card',
  // COOPERCRED = 'coopercred',
  // EUCARD = 'eucard',
  // FLEX_CAR_VISA_VAL = 'flex-car',
  // MAXXVAN = 'maxxvan',
  // PLANVALE = 'planvale',
  // SINDPLUS = 'sindplus',
  // UP_BRASIL = 'up-brasil',
  // VALECARD = 'valecard',
  // VALESHOP = 'valeshop',
}

export type BrandDictionary = { [key in Brands]: JSX.Element };
