import type { ThunkApi } from '@portal-types/store';

import { createAsyncThunk } from '@reduxjs/toolkit';

import { FeatureFlags } from 'models/featureToggle';
import featureFlagService from 'services/featureFlag';
import { AccountSelectors } from 'store/accounts';
import { CredentialsSelectors } from 'store/auth/credentials';

export const getFeatureFlags = createAsyncThunk<FeatureFlags, void, ThunkApi>(
  'featureFlags/save',
  async (_, thunkApi) => {
    const accountDocumentNumber = AccountSelectors.accountDocumentNumber(thunkApi.getState()) as string;
    const token = CredentialsSelectors.credentialsAccessToken(thunkApi.getState()) as string;

    try {
      const response = await featureFlagService(token, accountDocumentNumber);

      if (response.data) {
        return response.data;
      }

      throw new Error();
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);
