import type { FunctionComponent } from 'react';
import React from 'react';

import type { TooltipProps } from '@material-ui/core';
import { Tooltip as MuiTooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    arrow: {
      color: theme.palette.carbon[100],
    },
    tooltip: {
      color: theme.palette.marble[100],
      backgroundColor: theme.palette.carbon[100],
      fontSize: '0.75rem',
      padding: theme.spacing(2),
      lineHeight: '125%',
      borderRadius: 8,
    },
  }),
  { name: 'MuiTooltip' },
);

const Tooltip: FunctionComponent<TooltipProps> = (props) => {
  const classes = useStyles();

  return <MuiTooltip classes={classes} {...props} />;
};

export default Tooltip;
