import { formatRequestDate } from 'helpers/date';
import { formatUserDocument } from 'helpers/formatters/formatters';
import {
  ReceivablesData,
  ReceivablesQueryParamsOperation,
  ReceivablesQueryParamsStatus,
  ReceivableServiceParams,
  ReceivablesServiceResponse,
  ReceivablesSummaryServiceResponse,
  ReceivableSummaryData,
  ReceivablesSummaryResponse,
  PendingReceivablesData,
  PendingReceivablesResponse,
  ReceivableSummary,
  ReceivablesUrServiceParams,
  ReceivablesUrData,
  ReceivablesUrResponse,
  ReceivablesQueryParamsBrands,
} from 'models/receivables';
import { client } from 'services/client';

export const getReceivablesSummary = async (documentNumber: string): Promise<ReceivablesSummaryServiceResponse> => {
  const localStorageAuthJson = localStorage.getItem('persist:auth') as string;
  const localStorageAuth = JSON.parse(localStorageAuthJson);
  const localStorageAuthData = JSON.parse(localStorageAuth.data);

  try {
    const { parsedBody } = await client.get<ReceivableSummaryData>(`portal/v1/receivables/summary`, {
      token: localStorageAuthData.accessToken,
      useWhitelabel: true,
      headers: {
        'document-number': formatUserDocument(documentNumber),
      },
    });

    if (parsedBody) {
      return { data: parsedBody };
    }

    throw new Error('Ocorreu um erro ao buscar os Valores Parciais');
  } catch (error) {
    return { errorMessage: error.message };
  }
};

export const getReceivables = async (documentNumber: string): Promise<ReceivablesSummaryResponse> => {
  const localStorageAuthJson = localStorage.getItem('persist:auth') as string;
  const localStorageAuth = JSON.parse(localStorageAuthJson);
  const localStorageAuthData = JSON.parse(localStorageAuth.data);

  try {
    const { parsedBody } = await client.get<ReceivableSummary>(`portal/v2/receivables/summary`, {
      token: localStorageAuthData.accessToken,
      useWhitelabel: true,
      headers: {
        'document-number': formatUserDocument(documentNumber),
      },
    });

    if (parsedBody) {
      return {
        data: parsedBody,
      };
    }

    throw new Error('Ocorreu um erro ao buscar os Valores Parciais');
  } catch (error) {
    return { errorMessage: 'Ocorreu um erro ao buscar os Valores Parciais' };
  }
};

export const getReceivableService = async ({
  startDate,
  endDate,
  currPage,
  operation,
  status,
  cardBrand,
}: ReceivableServiceParams): Promise<ReceivablesServiceResponse> => {
  const localStorageAuthJson = localStorage.getItem('persist:auth') as string;
  const localStorageAuth = JSON.parse(localStorageAuthJson);
  const localStorageAuthData = JSON.parse(localStorageAuth.data);

  const localStorageAccountJson = localStorage.getItem('persist:account') as string;
  const localStorageAccount = JSON.parse(localStorageAccountJson);
  const localStorageAccountData = JSON.parse(localStorageAccount.data);

  try {
    const operationQuery = (): string => {
      if (!operation || operation === ReceivablesQueryParamsOperation.ALL) return '';
      if (operation && operation !== ReceivablesQueryParamsOperation.DEBIT) {
        return `&operation=${operation.join('&operation=')}`;
      }
      return `&operation=${operation}`;
    };
    const cardBrandQuery = (): string => {
      if (!cardBrand) return '';
      return `&card-brand=${cardBrand}`;
    };

    const statusQuery = (): string => {
      if (!status || status === ReceivablesQueryParamsStatus.ALL) return '';
      return `&status=${status}`;
    };

    const currPageQuery = (): string => String(currPage);
    const nextPageQuery = (): string => String(currPage + 1);
    const perPage = 100;

    const { parsedBody: body } = await client.get<ReceivablesData>(
      `portal/v1/receivables?start-date=${formatRequestDate(startDate)}&end-date=${formatRequestDate(
        endDate,
      )}${cardBrandQuery()}&per-page=${perPage}&current-page=${currPageQuery()}&next-page=${nextPageQuery()}${operationQuery()}${statusQuery()}`,
      {
        token: localStorageAuthData.accessToken,
        useWhitelabel: true,
        headers: {
          'document-number': formatUserDocument(localStorageAccountData.documentNumber),
        },
      },
    );

    if (body) {
      return {
        data: body,
      };
    }

    throw new Error('Ocorreu um erro ao buscar os Meus Recebíveis');
  } catch (error) {
    return { errorMessage: error.message };
  }
};

export const getReceivablesUrService = async ({
  startDate,
  endDate,
  perPage,
  currentPage,
  cardBrand,
  status,
  operation,
}: ReceivablesUrServiceParams): Promise<ReceivablesUrResponse> => {
  const operationQuery = (): string => {
    if (!operation || operation === ReceivablesQueryParamsOperation.ALL) return '';
    return `&operation=${operation}`;
  };
  const cardBrandQuery = (): string => {
    if (!cardBrand || cardBrand === ReceivablesQueryParamsBrands.ALL) return '';
    return `&cardBrand=${cardBrand}`;
  };

  const statusQuery = (): string => {
    if (!status || status === ReceivablesQueryParamsStatus.ALL) return '';
    return `&status=${status}`;
  };
  const localStorageAuthJson = localStorage.getItem('persist:auth') as string;
  const localStorageAuth = JSON.parse(localStorageAuthJson);
  const localStorageAuthData = JSON.parse(localStorageAuth.data);

  const localStorageAccountJson = localStorage.getItem('persist:account') as string;
  const localStorageAccount = JSON.parse(localStorageAccountJson);
  const localStorageAccountData = JSON.parse(localStorageAccount.data);

  try {
    const { parsedBody } = await client.get<ReceivablesUrData>(
      `portal/v2/receivables/ur?startDate=${formatRequestDate(startDate)}&endDate=${formatRequestDate(
        endDate,
      )}&perPage=${perPage}&currentPage=${currentPage}${operationQuery()}${statusQuery()}${cardBrandQuery()}`,
      {
        token: localStorageAuthData.accessToken,
        useWhitelabel: true,
        headers: {
          'document-number': formatUserDocument(localStorageAccountData.documentNumber),
        },
      },
    );

    if (parsedBody) {
      return {
        data: parsedBody,
      };
    }

    throw new Error('Ocorreu um erro ao buscar os UR');
  } catch (error) {
    return { errorMessage: 'Ocorreu um erro ao buscar os UR' };
  }
};

export const getPendingReceivables = async (page: number, perPage: number): Promise<PendingReceivablesResponse> => {
  const localStorageAuthJson = localStorage.getItem('persist:auth') as string;
  const localStorageAuth = JSON.parse(localStorageAuthJson);
  const localStorageAuthData = JSON.parse(localStorageAuth.data);

  const localStorageAccountJson = localStorage.getItem('persist:account') as string;
  const localStorageAccount = JSON.parse(localStorageAccountJson);
  const localStorageAccountData = JSON.parse(localStorageAccount.data);

  try {
    const { parsedBody } = await client.get<PendingReceivablesData>(
      `portal/v1/receivables/pending?page=${page}&perPage=${perPage}`,
      {
        token: localStorageAuthData.accessToken,
        useWhitelabel: true,
        headers: {
          'document-number': formatUserDocument(localStorageAccountData.documentNumber),
        },
      },
    );

    if (parsedBody) {
      return {
        data: parsedBody,
      };
    }

    throw new Error('Ocorreu um erro ao buscar os recebíveis pendentes');
  } catch (error) {
    return {
      errorMessage: 'Ocorreu um erro ao buscar os recebíveis pendentes',
    };
  }
};
