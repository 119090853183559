import React from 'react';

import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import BarChartOutlinedIcon from '@material-ui/icons/BarChartOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import LinkIcon from '@material-ui/icons/Link';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import UpdateOutlinedIcon from '@material-ui/icons/UpdateOutlined';

import { ReactComponent as CalculatorIcon } from 'assets/images/calculator.svg';
import { ReactComponent as TaskIcon } from 'assets/images/task.svg';
import { ReactComponent as OfferIcon } from 'assets/images/verified.svg';
import Feature from 'components/UI/Feature';
import { Features } from 'models/featureToggle';
import type { Routes } from 'models/routes';
import Account from 'pages/Account';
import Agreements from 'pages/Agreements';
import Anticipation from 'pages/Anticipation';
import CommercialConditions from 'pages/CommercialConditions';
import Conciliation from 'pages/Conciliation';
import Faq from 'pages/Faq';
import Help from 'pages/Help';
import Machine from 'pages/Machine';
import MySummaries from 'pages/MySummaries';
import Offers from 'pages/Offers';
import PaymentLink from 'pages/PaymentLink';
import Receivables from 'pages/Receivables';
import Sales from 'pages/Sales';
import TaxSimulator from 'pages/TaxSimulator';

export type GroupedRoutes = { groupName?: string; routes: Routes };

export const NotFound = ({ title }: { title: string }) => {
  return (
    <div>
      <h1>Não Encontrada</h1>
      <h2>{title}</h2>
    </div>
  );
};

export const groupedRoutes: Array<GroupedRoutes> = [
  {
    groupName: 'Serviços',
    routes: [
      {
        path: '/resume',
        title: 'Resumos',
        icon: BarChartOutlinedIcon,
        featureKey: Features.MY_SUMMARIES,
        element: (
          <Feature name={Features.MY_SUMMARIES} fallback={<NotFound title="Página /resume desconhecida" />}>
            <MySummaries />
          </Feature>
        ),
      },
      {
        path: '/sales',
        title: 'Vendas',
        icon: ShoppingCartOutlinedIcon,
        featureKey: Features.SALES,
        element: (
          <Feature name={Features.SALES} fallback={<NotFound title="Página /sales desconhecida" />}>
            <Sales />
          </Feature>
        ),
      },
      {
        path: '/receivables',
        title: 'Recebíveis',
        icon: AttachMoneyOutlinedIcon,
        featureKey: Features.RECEIVABLES,
        element: (
          <Feature name={Features.RECEIVABLES} fallback={<NotFound title="Página /receivables desconhecida" />}>
            <Receivables />
          </Feature>
        ),
      },
      {
        path: '/payment-link',
        title: 'Link de pagamento',
        icon: LinkIcon,
        featureKey: Features.PAYMENT_LINK,
        element: (
          <Feature name={Features.PAYMENT_LINK} fallback={<NotFound title="Página /payment-link desconhecida" />}>
            <PaymentLink />
          </Feature>
        ),
      },
      {
        path: '/anticipation',
        title: 'Antecipação',
        icon: UpdateOutlinedIcon,
        featureKey: Features.ANTICIPATION,
        element: (
          <Feature name={Features.ANTICIPATION} fallback={<NotFound title="Página /anticipation desconhecida" />}>
            <Anticipation />
          </Feature>
        ),
      },
    ],
  },
  {
    groupName: 'Suporte',
    routes: [
      {
        path: '/commercial-conditions',
        title: 'Taxas',
        icon: AccountBalanceOutlinedIcon,
        featureKey: Features.COMMERCIAL_CONDITIONS,
        element: (
          <Feature
            name={Features.COMMERCIAL_CONDITIONS}
            fallback={<NotFound title="Página /commercial-conditions desconhecida" />}
          >
            <CommercialConditions />
          </Feature>
        ),
      },
      {
        path: '/tax-simulator',
        title: 'Simulador de vendas',
        icon: CalculatorIcon,
        featureKey: Features.TAX_SIMULATOR,
        element: (
          <Feature name={Features.TAX_SIMULATOR} fallback={<NotFound title="Página /tax-simulator desconhecida" />}>
            <TaxSimulator />
          </Feature>
        ),
      },
      {
        path: '/offers',
        title: 'Ofertas',
        icon: OfferIcon,
        featureKey: Features.OFFERS,
        element: (
          <Feature name={Features.OFFERS} fallback={<NotFound title="Página /offers desconhecida" />}>
            <Offers />
          </Feature>
        ),
      },
      {
        path: '/conciliator',
        title: 'Conciliador',
        icon: TaskIcon,
        featureKey: Features.CONCILIATOR,
        element: (
          <Feature name={Features.CONCILIATOR} fallback={<NotFound title="Página /conciliator desconhecida" />}>
            <Conciliation />
          </Feature>
        ),
      },
      {
        path: '/machines',
        title: 'Meus pedidos',
        icon: LocalShippingOutlinedIcon,
        featureKey: Features.MACHINES,
        element: (
          <Feature name={Features.MACHINES} fallback={<NotFound title="Página /machines desconhecida" />}>
            <Machine />
          </Feature>
        ),
      },
    ],
  },
  {
    routes: [
      {
        path: '/help',
        title: 'Ajuda',
        icon: HelpOutlineOutlinedIcon,
        featureKey: Features.HELP,
        element: (
          <Feature name={Features.HELP} fallback={<NotFound title="Página /help desconhecida" />}>
            <Help />
          </Feature>
        ),
      },
      {
        path: '/agreements',
        title: 'Aceite de termos',
        icon: AccountBalanceOutlinedIcon,
        element: <Agreements />,
      },
      {
        path: '/account',
        title: 'Minha conta',
        icon: PersonRoundedIcon,
        element: (
          <Feature name={Features.ACCOUNT} fallback={<NotFound title="Página /account desconhecida" />}>
            <Account />
          </Feature>
        ),
      },
    ],
  },
  {
    routes: [
      {
        path: '/faq',
        title: 'FAQ',
        icon: HelpOutlineOutlinedIcon,
        featureKey: Features.HELP,
        element: (
          <Feature name={Features.HELP} fallback={<NotFound title="Página /faq desconhecida" />}>
            <Faq />
          </Feature>
        ),
      },
    ],
  },
];

export const parseGroupRouteByRoutes = (type: 'tef' | 'full', status?: string) => {
  const tefRoutes = ['/sales', '/help', '/agreements', '/account', '/conciliator'];
  const fullRoutes = groupedRoutes.flatMap(({ routes }) => routes.map((route) => route.path));

  const userTypeRoutes = new Set(type === 'full' ? fullRoutes : tefRoutes);

  if (status === 'Cancelado') {
    const canceledUserRemoveRoute = ['/payment-link', '/tax-simulator', '/offers', '/machines'];
    canceledUserRemoveRoute.forEach((route) => userTypeRoutes.delete(route));
  }

  return groupedRoutes.map((group) => {
    const routes = group.routes.filter((route) => userTypeRoutes.has(route.path));
    return { ...group, routes };
  });
};
