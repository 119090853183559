import { formatUserDocument } from 'helpers/formatters/formatters';
import type { GetTaxes, GetTaxesResponse } from 'models/taxes';
import { client } from 'services/client';

export const getTaxes = async (): Promise<GetTaxesResponse> => {
  try {
    const localStorageAuthJson = localStorage.getItem('persist:auth') as string;
    const localStorageAuth = JSON.parse(localStorageAuthJson);
    const localStorageAuthData = JSON.parse(localStorageAuth.data);

    const localStorageAccountJson = localStorage.getItem('persist:account') as string;
    const localStorageAccount = JSON.parse(localStorageAccountJson);
    const localStorageAccountData = JSON.parse(localStorageAccount.data);

    const { parsedBody: body } = await client.get<GetTaxes>(`portal/v2/taxes`, {
      token: localStorageAuthData.accessToken,
      useWhitelabel: true,
      headers: {
        'document-number': formatUserDocument(localStorageAccountData.documentNumber),
      },
    });

    return {
      data: body,
    };
  } catch (e) {
    return {
      errorMessage: 'Ocorreu um erro ao buscar as taxas das condições comerciais',
    };
  }
};
