import React from 'react';
import type { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import { Features, FeatureFlags } from 'models/featureToggle';
import { getFeatureFlags, isFeatureFlagsLoading } from 'store/featureFlags/selectors';

type Props = {
  name: Features;
  fallback?: JSX.Element;
};

const Feature: FunctionComponent<Props> = ({ name, children, fallback }) => {
  const getFeatures: FeatureFlags = useSelector(getFeatureFlags);
  const isFeatureFlagLoading = useSelector(isFeatureFlagsLoading);

  if (isFeatureFlagLoading) {
    return null;
  }

  return <>{getFeatures[name] ? children : fallback}</>;
};

export default Feature;
