import React, { FunctionComponent, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router';

import { Box } from '@material-ui/core';

import AnticipationConfirmation from 'components/Anticipation/Confirmation';
import AnticipationInit from 'components/Anticipation/Init';
import SimulationUnavailable from 'components/Anticipation/SimulationUnavailable';
import AnticipationSkeleton from 'components/Anticipation/Skeleton';
import AnticipationSuccess from 'components/Anticipation/Success';
import EmptyList from 'components/UI/EmptyList/EmptyList';
import ServerError from 'components/UI/ServerError';
import {
  SimulationResponseData,
  AcquirersInputValues,
  SimulationAcquirer,
  AnticipationSteps,
} from 'models/anticipation';
import { getSimulation } from 'services/anticipation';

type Props = {
  setPageTab: (value: string) => void;
};

type Components = () => { [key in AnticipationSteps]: JSX.Element };

const Simulator: FunctionComponent<Props> = ({ setPageTab }) => {
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState<AnticipationSteps>(AnticipationSteps.INIT);
  const [simulation, setSimulation] = useState<SimulationResponseData>({
    status: 'unavailable',
    grossAmount: 0,
    taxAmount: 0,
    netAmount: 0,
    acquirers: [],
  });
  const [acquirersInputValues, setAcquirersInputValues] = useState<AcquirersInputValues>({});
  const [initialLoading, setInitialLoading] = useState(true);
  const [initialRequestError, setInitialRequestError] = useState(false);

  const convertAcquirerInputsIntoParams = (inputs: AcquirersInputValues) => {
    return Object.keys(inputs).reduce((acc: string[], curr) => {
      if (inputs[curr].active) {
        const param = `${curr},${inputs[curr].value / 100}`;
        acc.push(param);
        return acc;
      }

      return acc;
    }, []);
  };

  const mapAcquirersToInputValues = useCallback(
    (acquirer: SimulationAcquirer) => {
      setAcquirersInputValues((state) => ({
        ...state,
        [acquirer.acquirerDocumentNumber]: {
          initialValue: acquirer.grossAmount * 100,
          value: acquirer.grossAmount * 100,
          active: !(acquirer.grossAmount * 100 < 10000),
          disabled: acquirer.grossAmount * 100 < 10000,
          error: false,
        },
      }));
    },
    [setAcquirersInputValues],
  );

  const initialRequest = useCallback(
    (params: string[]) => {
      setInitialLoading(true);

      getSimulation(params)
        .then(({ data }) => {
          if (data && data.status) {
            setSimulation(data);
            return data.acquirers?.map(mapAcquirersToInputValues);
          }

          throw new Error();
        })
        .catch((_) => setInitialRequestError(true))
        .finally(() => setInitialLoading(false));
    },
    [mapAcquirersToInputValues],
  );

  useEffect(() => {
    initialRequest([]);
  }, [initialRequest]);

  const changeAcquirerInputAmount = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    acquirerDocumentNumber: string,
  ) => {
    const inputedValue = e.target.value;
    const value = parseInt(inputedValue.replace(/\D/g, ''), 10);

    if (acquirersInputValues[acquirerDocumentNumber].initialValue >= value) {
      setAcquirersInputValues((state) => ({
        ...state,
        [acquirerDocumentNumber]: {
          ...state[acquirerDocumentNumber],
          value,
          error: value < 10000 || Number.isNaN(value),
        },
      }));
    }
  };

  const switchAcquirer = (e: React.ChangeEvent<HTMLInputElement>, acquirerDocumentNumber: string) => {
    const active = e.target.checked;

    setAcquirersInputValues((state) => ({
      ...state,
      [acquirerDocumentNumber]: {
        ...state[acquirerDocumentNumber],
        active,
      },
    }));
  };

  const components: Components = () => {
    const { acquirers, netAmount } = simulation;

    return {
      [AnticipationSteps.INIT]: (
        <AnticipationInit
          acquirers={acquirers}
          amountWithDiscount={netAmount}
          acquirerInputAmount={acquirersInputValues}
          changeAcquirerAmount={changeAcquirerInputAmount}
          switchAcquirer={switchAcquirer}
          onSimulate={() => {
            const params = convertAcquirerInputsIntoParams(acquirersInputValues);
            initialRequest(params);
            setCurrentStep(AnticipationSteps.CONFIRMATION);
          }}
        />
      ),
      [AnticipationSteps.CONFIRMATION]: (
        <AnticipationConfirmation
          simulation={simulation}
          onEditSimulation={() => {
            initialRequest([]);
            setCurrentStep(AnticipationSteps.INIT);
          }}
          onConfirmAnticipation={() => setCurrentStep(AnticipationSteps.SUCCESS)}
        />
      ),
      [AnticipationSteps.SUCCESS]: (
        <AnticipationSuccess
          amountWithDiscount={netAmount}
          acquirers={acquirers}
          acquirersAmount={acquirersInputValues}
          onGoBack={() => setPageTab('my-anticipations')}
        />
      ),
    };
  };

  const conditionalComponents = () => {
    if (initialLoading) {
      return <AnticipationSkeleton />;
    }

    if (initialRequestError) {
      return (
        <Box mt={4}>
          <ServerError
            onTryAgain={() => {
              setInitialRequestError(false);
              const params = convertAcquirerInputsIntoParams(acquirersInputValues);
              initialRequest(params);
            }}
            title="Ocorreu um erro"
            message="Não foi possível carregar as informações. Tente novamente em instantes."
          />
        </Box>
      );
    }

    if (simulation && simulation.status === 'unavailable') {
      return (
        <SimulationUnavailable onGoBack={() => navigate('/')} goMyAnticipation={() => setPageTab('my-anticipations')} />
      );
    }

    if (simulation && !simulation.acquirers.length) {
      return <EmptyList subtitle="Seus recebíveis aparecerão automaticamente aqui para você antecipar" />;
    }

    return components()[currentStep];
  };

  return <Box width={504}>{conditionalComponents()}</Box>;
};

export default Simulator;
