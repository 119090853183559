import React from 'react';
import type { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import { Box, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { WhitelabelSelectors } from 'store/whitelabel';

type Props = {
  onBack?: () => void;
};

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  headerBackIcon: {
    color: theme.palette.carbon[100],
    position: 'absolute',
  },
  whitelabelTitle: (props: { whiteLabelLogo: string }) => ({
    background: `url(${props.whiteLabelLogo}) center no-repeat`,
    color: 'transparent',
    width: '100%',
    height: 30,
  }),
}));

const CardHeader: FunctionComponent<Props> = ({ onBack }) => {
  const whitelabelTitle = useSelector(WhitelabelSelectors.whitelabelTitle);
  const whitelabelAssets = useSelector(WhitelabelSelectors.whitelabelAssets)!;

  const styleProps = {
    whiteLabelLogo: whitelabelAssets.logo,
  };
  const classes = useStyles(styleProps);

  return (
    <Box className={classes.header}>
      {onBack && (
        <IconButton aria-label="voltar." size="small" onClick={onBack} className={classes.headerBackIcon}>
          <ArrowBackIcon color="inherit" fontSize="inherit" />
        </IconButton>
      )}
      <Box
        component="h1"
        lineHeight={0}
        my={0}
        width="100%"
        textAlign="center"
        maxHeight={40}
        className={classes.whitelabelTitle}
      >
        {`${whitelabelTitle}.`}
      </Box>
    </Box>
  );
};

export default CardHeader;
