import type { FunctionComponent } from 'react';
import React from 'react';

import { Box, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2, 1),
    borderBottom: `1px solid ${theme.palette.carbon[10]}`,
    marginBottom: theme.spacing(3),
  },
}));

const ReceivablesSkeleton: FunctionComponent = () => {
  const classes = useStyles();

  const skeletonKeys = [1, 2, 3];

  return (
    <Box width="100%">
      {skeletonKeys.map((key) => {
        return (
          <Box className={classes.row} key={key}>
            <Box display="flex">
              <Box pr={2}>
                <Skeleton width={51} height={36} animation="wave" />
              </Box>
            </Box>
            <Box display="flex">
              <Box mr={5}>
                <Skeleton width={56} height={24} animation="wave" />
              </Box>
              <Box>
                <Skeleton width={32} height={24} animation="wave" />
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default ReceivablesSkeleton;
