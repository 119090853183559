import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import { WhitelabelSelectors } from 'store/whitelabel';
import { brandColors } from 'themes/colors';

const PageHead = () => {
  const whitelabelTitle = useSelector(WhitelabelSelectors.whitelabelTitle);
  const whitelabelAssets = useSelector(WhitelabelSelectors.whitelabelAssets)!;

  const manifest = JSON.stringify({
    short_name: `Portal ${whitelabelTitle}`,
    name: `Portal ${whitelabelTitle}`,
    description: 'Consulte suas transações e faça a gestão do seu negócio de um jeito simples e rápido.',
    lang: 'pt-BR',

    background_color: brandColors.carbon,
    theme_color: brandColors.carbon,
    display: 'standalone',
    icons: [
      {
        src: whitelabelAssets.icons['android-chrome-512x512'],
        sizes: '512x512',
      },
    ],
  });

  const blob = new Blob([manifest], { type: 'application/json' });
  const manifestURL = URL.createObjectURL(blob);

  return (
    <Helmet>
      <title>{`Portal ${whitelabelTitle}`}</title>
      <meta name="theme-color" content={brandColors.carbon} />
      <meta
        name="description"
        content="Consulte suas transações e faça a gestão do seu negócio de um jeito simples e rápido."
      />

      <link rel="manifest" href={manifestURL} />

      <link rel="icon" href={whitelabelAssets.icons['android-chrome-512x512']} />
      <link rel="mask-icon" href={whitelabelAssets.icons['apple-touch']} color={brandColors.carbon} />
      <link rel="apple-touch-icon" href={whitelabelAssets.icons['apple-touch']} />
      <link rel="icon" sizes="32x32" href={whitelabelAssets.icons['fav-icon-32x32']} />
    </Helmet>
  );
};

export default PageHead;
