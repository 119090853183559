import { formatUserDocument } from 'helpers/formatters/formatters';
import type { AccountDataResponse } from 'models/account';
import { client } from 'services/client';

type AccountResponse = { data?: AccountDataResponse; errorMessage?: string };

export const getAccountData = async (): Promise<AccountResponse> => {
  const localStorageAuthJson = localStorage.getItem('persist:auth') as string;
  const localStorageAuth = JSON.parse(localStorageAuthJson);
  const localStorageAuthData = JSON.parse(localStorageAuth.data);

  const localStorageAccountJson = localStorage.getItem('persist:account') as string;
  const localStorageAccount = JSON.parse(localStorageAccountJson);
  const localStorageAccountData = JSON.parse(localStorageAccount.data);

  try {
    const { parsedBody: body } = await client.get<AccountDataResponse>(`portal/v1/accounts/information`, {
      token: localStorageAuthData.accessToken,
      useWhitelabel: true,
      headers: {
        'document-number': formatUserDocument(localStorageAccountData.documentNumber),
      },
    });

    if (body) {
      return {
        data: body,
      };
    }

    throw new Error('Algo de errado');
  } catch (error) {
    return { errorMessage: error.message };
  }
};
