import type { FunctionComponent } from 'react';
import React from 'react';

import { Box, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as ClockIcon } from 'assets/images/clock.svg';
import Button from 'components/UI/Button';
import Typography from 'components/UI/Typography';

const useStyles = makeStyles((theme) => ({
  sectionButton: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column-reverse',
    flexWrap: 'wrap',
    '& > button:not(:last-child)': {
      marginTop: theme.spacing(2),
    },

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      flexWrap: 'nowrap',
      '& > button:not(:last-child)': {
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(0),
      },
    },
  },
  carbon70: {
    color: theme.palette.carbon[70],
  },
}));

type Props = {
  onGoBack: () => void;
  goMyAnticipation: () => void;
};

const SimulationUnavailable: FunctionComponent<Props> = ({ onGoBack, goMyAnticipation }) => {
  const classes = useStyles();

  return (
    <Card>
      <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" mt={2}>
        <ClockIcon />

        <Box my={4} mx={5}>
          <Typography variant="bodyBold" component="h2" align="center" gutterBottom>
            Sistema indisponível!
          </Typography>
          <Typography variant="caption" className={classes.carbon70} align="center" component="p">
            Novas solicitações de antecipação somente são permitidas de segunda à sexta-feira das 8h30 às 13h30, exceto
            feriados.
          </Typography>
        </Box>
      </Box>

      <Box className={classes.sectionButton} mb={1}>
        <Button
          fullWidth
          title="Voltar ao Início"
          aria-label="botão para redirecionar para os Resumos"
          variant="outlined"
          size="large"
          onClick={onGoBack}
        >
          Voltar ao Início
        </Button>
        <Button
          fullWidth
          title="Minhas Antecipações"
          aria-label="botão para redirecionar para Minhas antecipações"
          variant="contained"
          size="large"
          color="primary"
          onClick={goMyAnticipation}
        >
          Minhas Antecipações
        </Button>
      </Box>
    </Card>
  );
};

export default SimulationUnavailable;
