import type { FunctionComponent } from 'react';
import React from 'react';
import { useNavigate } from 'react-router';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { Alert } from '@material-ui/lab';

import { ReactComponent as EmailImage } from 'assets/images/email-success-send.svg';
import Button from 'components/UI/Button';
import Typography from 'components/UI/Typography';

type Props = {
  email: string;
  loading: boolean;
  resendEmail: (email: string, resend: boolean) => void;
  isResendEmail: boolean;
};

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'grid',
    gap: theme.spacing(2),
  },
  emailIcon: {
    margin: 'auto',
  },
  alert: {
    borderRadius: theme.shape.borderRadius * 2,
  },
  carbon90: {
    color: theme.palette.carbon[90],
  },
  carbon70: {
    color: theme.palette.carbon[70],
  },
  alertVerify: {
    padding: theme.spacing(2),
    '& > .MuiAlert-message': {
      padding: 0,
    },
  },
}));

const AuthFormsForgotSuccess: FunctionComponent<Props> = ({ email, loading, resendEmail, isResendEmail }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <>
      <Box display="flex" justifyContent="center">
        <EmailImage className={classes.emailIcon} alignmentBaseline="central" />
      </Box>
      <Box my={6}>
        <Typography className={classes.carbon90} variant="header" component="h2" align="center" paragraph gutterBottom>
          E-mail enviado.
        </Typography>
        <Typography align="center" variant="body2" component="p" gutterBottom paragraph className={classes.carbon70}>
          Em até 5 minutos enviaremos um e-mail de recuperação de senha para
          <strong>{` ${email}.`}</strong>
        </Typography>
        <Alert severity="info" color="info" icon={<CheckCircle color="inherit" />} className={classes.alertVerify}>
          <Typography align="center" component="span" paragraph variant="caption2" className={classes.carbon70}>
            Caso não receba, confira sua caixa de spam ou verifique se o e-mail informado é o cadastrado e tente
            novamente.
          </Typography>
        </Alert>
      </Box>

      <Box className={classes.buttons}>
        <Button
          component="a"
          onClick={() => navigate('/auth/login')}
          size="large"
          fullWidth
          variant="contained"
          color="primary"
          title="Acessar Conta"
          aria-label="Acessar conta."
          disabled={loading}
        >
          Acessar Conta
        </Button>
        {isResendEmail && !loading && (
          <Alert
            className={classes.alert}
            variant="filled"
            severity="success"
            icon={<CheckCircle />}
            aria-label="caixa com alerta de sucesso"
          >
            E-mail reenviado com sucesso!
          </Alert>
        )}
        {!isResendEmail && (
          <Button
            size="large"
            type="button"
            variant="outlined"
            fullWidth
            title="Reenviar E-mail"
            aria-label="Reenviar e-mail de recuperação de senha."
            loading={loading}
            onClick={() => resendEmail(email, true)}
          >
            Reenviar E-mail
          </Button>
        )}
      </Box>
    </>
  );
};

export default AuthFormsForgotSuccess;
