import type { FunctionComponent } from 'react';
import React, { useEffect, useState } from 'react';

import { Zoom, Fab } from '@material-ui/core';
import { fade, makeStyles } from '@material-ui/core/styles';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      boxShadow: 'none',
      position: 'fixed',
      bottom: '40px',
      right: '40px',
      height: '48px',
      width: '48px',
      '&:active': {
        boxShadow: 'none',
      },
    },
    primary: {
      color: fade(theme.palette.primary.contrastText, 0.9),
      background: `linear-gradient(105.23deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
      '&:hover': {
        background: 'unset',
        backgroundColor: theme.palette.primary.main,
      },
      '&:focus': {
        background: 'unset',
        backgroundColor: theme.palette.primary.main,
      },
      '& .MuiTouchRipple-rippleVisible': {
        opacity: 0.75,
      },
      '& .MuiTouchRipple-child': {
        backgroundColor: theme.palette.primary.light,
      },
    },
  }),
  { name: 'MuiFab' },
);

const ScrollToTopButton: FunctionComponent = () => {
  useStyles();
  const [showScroll, setShowScroll] = useState(false);

  const scrollToTop = () => {
    document.querySelector('main')?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const checkScrollTop = () => {
      const scrollTop = document.querySelector('main')?.scrollTop;
      if (scrollTop) {
        if (!showScroll && scrollTop > 100) {
          setShowScroll(true);
        } else if (showScroll && scrollTop <= 100) {
          setShowScroll(false);
        }
      }
    };
    document.querySelector('main')?.addEventListener('scroll', checkScrollTop);
    return () => {
      document.querySelector('main')?.removeEventListener('scroll', checkScrollTop);
    };
  }, [showScroll]);

  return (
    <Zoom in={showScroll}>
      <Fab color="primary" onClick={scrollToTop}>
        <ArrowUpwardRoundedIcon />
      </Fab>
    </Zoom>
  );
};

export default ScrollToTopButton;
