export enum Loading {
  IDLE = 'idle',
  PENDING = 'pending',
}

export enum RecoverStatus {
  SUCCESS = 'success',
  FAILED = 'failed',
}

export enum Brand {
  MASTERCARD = 'mastercard',
  VISA = 'visa',
  ELO = 'elo',
  MAESTRO = 'maestro',
}
