import type { FunctionComponent } from 'react';
import React from 'react';

import { Box, Card, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  cardCell: {
    height: 560,
    gridColumnEnd: 'span 6',

    [theme.breakpoints.down('xs')]: {
      gridColumnEnd: 'span 12',
    },
  },
}));

const AccountSkeleton: FunctionComponent = () => {
  const classes = useStyles();

  const cards = [1, 2, 3, 4];
  const items = [1, 2, 3, 4, 5, 6, 7, 8];

  return (
    <>
      {cards.map((card) => (
        <Card className={classes.cardCell} key={card}>
          <Box display="flex" flexDirection="column" height="100%">
            <Box width="100%" mb={4}>
              <Skeleton animation="wave" />
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
              {items.map((item) => (
                <Box key={item} display="flex" justifyContent="space-between" flexDirection="column">
                  <Skeleton width="100%" animation="wave" />
                  <Skeleton width="100%" animation="wave" />
                </Box>
              ))}
            </Box>
          </Box>
        </Card>
      ))}
    </>
  );
};

export default AccountSkeleton;
