import * as React from 'react';
import { useSelector } from 'react-redux';

import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Grid,
  LinearProgress,
} from '@material-ui/core';
import { fade, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GetAppIcon from '@material-ui/icons/GetApp';

import Typography from 'components/UI/Typography';
import { DownloadAreaItem } from 'models/download';
import { ReceivablesQueryParamsStatus } from 'models/receivables';
import { downloadReportService } from 'services/report';
import { store, useAppDispatch } from 'store';
import { DownloadAreaActions, DownloadAreaSelectors } from 'store/download';
import { backgroundColors, compositionColors } from 'themes/colors';
// import { DownloadAreaData, DownloadAreaState } from 'store/download/types';

const useStyles = makeStyles((theme) => ({
  downloadArea: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    marginBottom: theme.spacing(2),
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      bottom: 0,
    },
  },
  downloadWrapper: {
    width: 328,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 32px)',
    },
  },
  accordionRoot: {
    backgroundColor: backgroundColors.dark.surface.tertiary,
    color: fade(theme.palette.secondary.contrastText, 0.9),
  },
  progressWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(4),
  },
  progressContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    '& > .MuiLinearProgress-root': {
      width: '100%',
    },
  },
  iconButton: {
    backgroundColor: compositionColors.dark.status.negative,
    width: theme.spacing(3),
    height: theme.spacing(3),
    borderRadius: '50%',
    marginLeft: theme.spacing(2),
  },
  errorIcon: { color: compositionColors.dark.status.negative },
  expandIcon: {
    color: fade(theme.palette.secondary.contrastText, 0.9),
  },
  summaryTitle: {
    '& > .MuiAccordionSummary-content': {
      display: 'flex',
      justifyContent: 'flex-start',
      gap: theme.spacing(1),
    },
  },
  info: {
    fontSize: '0.75rem',
  },
  closeIcon: {
    padding: '5px',
    color: backgroundColors.dark.surface.tertiary,
  },
  errorLabel: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
  linkLabel: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));

const DownloadCard = (): JSX.Element => {
  const classes = useStyles();

  const dispatch = useAppDispatch();
  const reportList = useSelector(DownloadAreaSelectors.reportList) as DownloadAreaItem;

  const [expanded, setExpanded] = React.useState<number | false>(1);
  const handleChange = (panel: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const cancelReportDownload = (id: number) => {
    const deleteReport = store
      .getState()
      .downloadArea.data?.reportList.filter((report: DownloadAreaItem) => report.id !== id);
    store.dispatch(DownloadAreaActions.reportList([...deleteReport]));
    if (store.getState().downloadArea.data?.reportList.length === 0) {
      dispatch(DownloadAreaActions.downloading(false));
    }
  };

  const downloadReport = (report: DownloadAreaItem) => {
    const deleteReport = store
      .getState()
      .downloadArea.data?.reportList.filter((reportFilter: DownloadAreaItem) => reportFilter.id !== report.id);
    store.dispatch(DownloadAreaActions.reportList([...deleteReport]));
    console.log(report);
    const { startDate, endDate, status, cardBrand } = report.filter;
    const currentFilter = {
      startDate,
      endDate,
      operation: ReceivablesQueryParamsStatus.ALL,
      status: status.length > 0 ? status : ReceivablesQueryParamsStatus.ALL,
      brand: cardBrand.length > 0 ? cardBrand : ReceivablesQueryParamsStatus.ALL,
      checkedDate: 'today',
    };
    downloadReportService(currentFilter);
  };

  return (
    <Box className={classes.downloadArea}>
      <div className={classes.downloadWrapper}>
        <Accordion expanded={expanded === 1} onChange={handleChange(1)} className={classes.accordionRoot}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={classes.summaryTitle}
          >
            <GetAppIcon />
            <Typography variant="subtitle2">{`Baixando relatório (${Object.keys(reportList).length})`}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction="column">
              {Object.values(reportList)?.map((report: any) => {
                return (
                  report !== 'completed' && (
                    <Box key={report.id} className={classes.progressWrapper}>
                      <Box className={classes.progressContainer}>
                        <LinearProgress />
                        <Typography variant="body" className={classes.info}>
                          {`Recebíveis de ${report.filter.startDate} a ${report.filter.endDate}`}
                        </Typography>
                        {report.status === 'error' && (
                          <Box className={classes.errorLabel}>
                            <ErrorIcon fontSize="small" className={classes.errorIcon} aria-label="Error" />
                            <Typography variant="paragraphSmallRegular">{`Erro ao baixar arquivo. `}</Typography>
                            <Typography
                              variant="paragraphSmallBold"
                              className={classes.linkLabel}
                              onClick={() => downloadReport(report)}
                            >
                              Tentar novamente
                            </Typography>
                          </Box>
                        )}
                      </Box>
                      <IconButton
                        aria-label="Cancel"
                        className={classes.iconButton}
                        onClick={() => cancelReportDownload(report.id)}
                      >
                        <CloseIcon className={classes.closeIcon} />
                      </IconButton>
                    </Box>
                  )
                );
              })}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>
    </Box>
  );
};

export default DownloadCard;
