import type { FunctionComponent, ReactElement } from 'react';
import React, { useEffect } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { idleTimeout } from 'app-constants';
import { reCaptchaKey } from 'config';
import { differenceInMilliseconds, isFuture } from 'date-fns';
import { AccountActions, AccountSelectors } from 'store/accounts';
import { CredentialsActions, CredentialsSelectors } from 'store/auth/credentials';
import { getFeatureFlags } from 'store/featureFlags/actions';

type Props = {
  publicRoutes: ReactElement | null;
  privateRoutes: { tef: ReactElement | null; full: ReactElement | null };
};

const AuthGuardian: FunctionComponent<Props> = ({ publicRoutes, privateRoutes }) => {
  const navigate = useNavigate();
  const isAuthenticated = useSelector(CredentialsSelectors.isAuthenticated);
  const credentialsData = useSelector(CredentialsSelectors.credentialsData);
  const accountDocumentNumber = useSelector(AccountSelectors.accountDocumentNumber);
  const accountUserPortal = useSelector(AccountSelectors.accountUserPortal);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated && accountDocumentNumber) {
      dispatch(getFeatureFlags());
    }

    if (isAuthenticated && !accountDocumentNumber) {
      navigate('/auth/access-account');
    }

    return () => {
      dispatch(AccountActions.clearPersisted);
    };
  }, [dispatch, navigate, isAuthenticated, accountDocumentNumber]);

  useEffect(() => {
    let unsubTimeout: NodeJS.Timeout | null = null;

    if (credentialsData) {
      if (isFuture(new Date(credentialsData.expirationDate))) {
        unsubTimeout = setTimeout(() => {
          dispatch(CredentialsActions.refresh({ refreshToken: credentialsData?.refreshToken }));
        }, differenceInMilliseconds(new Date(credentialsData.expirationDate), new Date()));
      } else {
        dispatch(CredentialsActions.logout());
      }
    }

    return () => {
      if (unsubTimeout) {
        clearTimeout(unsubTimeout);
      }
    };
  }, [credentialsData, dispatch]);

  useIdleTimer({
    timeout: idleTimeout,
    onIdle: () => {
      dispatch(CredentialsActions.logout());
    },
  });

  return isAuthenticated && accountDocumentNumber ? (
    privateRoutes[accountUserPortal]
  ) : (
    <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>{publicRoutes}</GoogleReCaptchaProvider>
  );
};

export default AuthGuardian;
