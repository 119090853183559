import React, { ReactNode, useState } from 'react';
import type { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { Box, BoxProps, Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as OuiBounceModal } from 'assets/images/ouibounce-modal-download-offline.svg';
import Button from 'components/UI/Button';
import Typography from 'components/UI/Typography';
import { CredentialsActions } from 'store/auth/credentials';

const useStyles = makeStyles((theme) => ({
  modalWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4),
  },
  modalTitle: {
    marginTop: theme.spacing(2),
  },
  modalDescription: {
    marginTop: theme.spacing(2),
    color: theme.palette.carbon[70],
    maxWidth: 440,
    textAlign: 'center',
  },
  buttonSection: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(4),
  },
  firstButton: {
    marginRight: theme.spacing(2),
  },
}));

type Props = {
  children: ReactNode;
  title: string;
  description: string;
  downloading?: boolean;
  isLogout?: boolean;
};

const OuibounceModal: FunctionComponent<Props & BoxProps> = ({
  children,
  title,
  description,
  downloading,
  isLogout,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const wasOpened = JSON.parse(sessionStorage.getItem('OuibounceModalIsOpened') as string);

  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = (e: any) => {
    if (wasOpened) {
      return;
    }
    if (e.clientY < 0 && downloading) {
      setIsOpen(true);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    sessionStorage.setItem('OuibounceModalIsOpened', 'true');
  };

  const handleExit = () => {
    dispatch(CredentialsActions.logout());
    navigate('/auth/login');
  };

  React.useEffect(() => {
    if (isLogout && downloading) {
      setIsOpen(true);
    }
  }, [isLogout, downloading]);

  return (
    <>
      <Box onMouseLeave={(e) => handleOpen(e)}>{children}</Box>
      <Dialog open={isOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box className={classes.modalWrapper}>
          <OuiBounceModal />
          <Typography variant="header" className={classes.modalTitle}>
            {title}
          </Typography>
          <Typography variant="paragraphMediumRegularShort" className={classes.modalDescription}>
            {description}
          </Typography>
          {isLogout ? (
            <Box className={classes.buttonSection}>
              <Button
                fullWidth
                title="Voltar ao Início"
                aria-label="botão para redirecionar para os Resumos"
                variant="outlined"
                size="large"
                className={classes.firstButton}
                onClick={handleClose}
              >
                Voltar
              </Button>
              <Button
                fullWidth
                title="Voltar ao Início"
                aria-label="botão para redirecionar para os Resumos"
                variant="contained"
                color="primary"
                size="large"
                onClick={() => handleExit()}
              >
                Sair
              </Button>
            </Box>
          ) : (
            <Box className={classes.buttonSection}>
              <Button
                fullWidth
                title="Voltar ao Início"
                aria-label="botão para redirecionar para os Resumos"
                variant="contained"
                color="primary"
                size="large"
                onClick={handleClose}
              >
                Entendi
              </Button>
            </Box>
          )}
        </Box>
      </Dialog>
    </>
  );
};

export default OuibounceModal;
