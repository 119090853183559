import React from 'react';
import type { FunctionComponent } from 'react';

import { SwipeableDrawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import SideMenuNavigation from 'components/SideMenu/Navigation';

const useStyles = makeStyles((theme) => ({
  drawer: {
    '& > .MuiPaper-root': {
      padding: theme.spacing(3, 0),
      backgroundColor: theme.palette.secondary.main,
      width: 240,
    },
  },
}));

type Props = {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
};

const SideMenuMobileDrawer: FunctionComponent<Props> = ({ open, onClose, onOpen }) => {
  const classes = useStyles();

  return (
    <SwipeableDrawer
      className={classes.drawer}
      anchor="left"
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      aria-label="menu lateral"
    >
      <SideMenuNavigation />
    </SwipeableDrawer>
  );
};

export default SideMenuMobileDrawer;
