import { makeStyles } from '@material-ui/core/styles';

import { clientStatuses } from 'helpers/constants';

const useStyles = makeStyles((theme) => {
  const labelStyle = {
    fontSize: '0.75rem',
    color: theme.palette.carbon[90],
    fontWeight: 600,
  };

  const descriptionStyle = {
    ...labelStyle,
    fontWeight: 500,
  };

  return {
    root: {
      '& .MuiStepLabel-iconContainer': {
        zIndex: 2,
      },
    },
    dotRoot: {
      position: 'relative',
      top: '-4px',
      backgroundColor: theme.palette.backgroundColor.neutral?.white,
      zIndex: 1,
      width: 20,
      height: 20,
      border: `2px solid ${theme.palette.carbon[35]}`,
      borderRadius: '50%',
    },
    stepConnector: {
      '& .MuiStepConnector-vertical': {
        position: 'relative',
        height: 40,
        marginLeft: 9,
        '& > span': {
          position: 'absolute',
          zIndex: 0,
          height: 70,
          top: -15,
        },
      },
    },
    dotActive: {
      border: `2px solid ${theme.palette.backgroundColor.positive?.primary}`,
    },
    dotActiveIcon: {
      color: theme.palette.backgroundColor.positive?.primary,
      width: 16,
      height: 16,
    },
    dotCompleted: {
      border: `2px solid ${theme.palette.backgroundColor.positive?.primary}`,
      background: theme.palette.backgroundColor.positive?.primary,
      zIndex: 1,
    },
    dotCompletedIcon: {
      color: theme.palette.textColor.inverse,
      width: 16,
      height: 14,
    },
    dotError: {
      position: 'relative',
      width: 20,
      height: 20,
      borderRadius: '50%',
      zIndex: 1,
      top: '-4px',
      border: `2px solid ${clientStatuses.canceled.color}`,
      background: clientStatuses.canceled.color,
    },
    dotFailureIcon: {
      color: theme.palette.textColor.inverse,
      width: 16,
      height: 14,
    },
    firstLabel: {
      '& .MuiStepLabel-labelContainer': {
        marginLeft: theme.spacing(3),
      },
      '& .MuiStepLabel-labelContainer > span': {
        ...labelStyle,
      },
    },
    middleLabel: {
      '& .MuiStepLabel-labelContainer': {
        marginLeft: theme.spacing(3),
      },
      '& .MuiStepLabel-labelContainer > span': {
        ...labelStyle,
      },
    },
    lastLabel: {
      '& .MuiStepLabel-labelContainer': {
        marginLeft: theme.spacing(3),
      },
      '& .MuiStepLabel-labelContainer > span': {
        ...labelStyle,
      },
    },
    date: {
      ...descriptionStyle,
    },
  };
});

export default useStyles;
