import type { ReactElement } from 'react';
import React from 'react';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import SelectDropdownSingle from 'components/SelectDropdown/SelectDropdownSingle';
import { SaleStatus, PaymentTypes, Filter } from 'models/sales';

import DateFilter from './Date/SalesFilterDate';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      display: 'grid',
      gap: theme.spacing(3),
      justifyContent: 'center',
    },
  },
  selectWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
  },
  columnStyle: {
    flexDirection: 'column',
    '& .MuiToggleButtonGroup-root': {
      flexWrap: 'wrap',
    },
    '& [class*="selectWrapper"]': {
      width: '100%',
      marginTop: theme.spacing(2),
    },
  },
}));

type Props = {
  setFilter: Function;
  filter: Filter;
  isColumn?: boolean;
};

const SalesFilter = ({ setFilter, filter, isColumn }: Props): ReactElement => {
  const classes = useStyles();
  const [openPopperStatus, setOpenPopperStatus] = React.useState(false);
  const [openPopperOperation, setOpenPopperOperation] = React.useState(false);
  const [anchorElStatus, setAnchorElStatus] = React.useState<null | HTMLElement>(null);
  const [anchorElOperation, setAnchorElOperation] = React.useState<null | HTMLElement>(null);
  const methodOptions = [
    { label: 'Todas', value: 'all' },
    { label: 'Crédito', value: PaymentTypes.CREDIT },
    { label: 'Débito', value: PaymentTypes.DEBIT },
    { label: 'Pix e Carteira Digital', value: PaymentTypes.E_WALLET },
    { label: 'Voucher', value: PaymentTypes.VOUCHER },
  ];

  const statusOptions = [
    { label: 'Todos', value: 'all' },
    { label: 'Aprovado', value: SaleStatus.APPROVED },
    { label: 'Recusado', value: SaleStatus.REFUSED },
    { label: 'Cancelado', value: SaleStatus.CANCELLED },
    { label: 'Pendente', value: SaleStatus.PENDING },
    { label: 'Devolvido', value: SaleStatus.REVERSED },
  ];

  const handleClickStatus = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElStatus(event.currentTarget);
    setOpenPopperStatus((previousOpen) => !previousOpen);
    setOpenPopperOperation(false);
  };

  const handleClickOperation = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElOperation(event.currentTarget);
    setOpenPopperOperation((previousOpen) => !previousOpen);
    setOpenPopperStatus(false);
  };

  const changeFilter = (filterName: string) => {
    return (field: string) =>
      setFilter({
        ...filter,
        [filterName]: field,
      });
  };

  const canBeOpenOperation = openPopperOperation && Boolean(anchorElOperation);
  const canBeOpenStatus = openPopperStatus && Boolean(anchorElStatus);

  return (
    <Box className={`${classes.container} ${isColumn && classes.columnStyle}`}>
      <DateFilter filter={filter} setFilter={setFilter} />
      <Box className={classes.selectWrapper}>
        <SelectDropdownSingle
          setAnchorEl={setAnchorElOperation}
          anchorEl={anchorElOperation}
          onChange={changeFilter('method')}
          idPopper="operation"
          options={methodOptions}
          canBeOpen={canBeOpenOperation}
          buttonLabel="Operações"
          onButtonClick={handleClickOperation}
          openPopper={openPopperOperation}
          setOpenPopper={setOpenPopperOperation}
        />
        <SelectDropdownSingle
          setAnchorEl={setAnchorElStatus}
          anchorEl={anchorElStatus}
          onChange={changeFilter('status')}
          idPopper="status"
          options={statusOptions}
          canBeOpen={canBeOpenStatus}
          buttonLabel="Status"
          onButtonClick={handleClickStatus}
          openPopper={openPopperStatus}
          setOpenPopper={setOpenPopperStatus}
        />
      </Box>
    </Box>
  );
};

export default SalesFilter;
