import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import AuthGuardian from 'routes/AuthGuardian';

import usePrivateRoutes from './private';
import usePublicRoutes from './public';

export const AppRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    ineum('page', location.pathname);
  }, [location]);

  const privateRoutes = usePrivateRoutes();
  const publicRoutes = usePublicRoutes();

  return <AuthGuardian publicRoutes={publicRoutes} privateRoutes={privateRoutes} />;
};
