import type { FunctionComponent } from 'react';
import React from 'react';

import type { Nullable } from '@portal-types/helpers';

import { Box, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Typography from 'components/UI/Typography';
import { formatCnpj, formatCpf, formatZipCode } from 'helpers/formatters/formatters';
import { AccountGroupTitle } from 'models/account';

const useStyles = makeStyles((theme) => ({
  cardCell: {
    gridColumnEnd: 'span 6',

    [theme.breakpoints.down('xs')]: {
      gridColumnEnd: 'span 12',
    },
  },
  cardContainer: {
    [theme.breakpoints.up('sm')]: {
      height: '100%',
    },
  },
  infoText: {
    color: theme.palette.carbon[90],
  },
  itemValue: {
    wordBreak: 'break-word',
    width: '100%',
  },
}));

type Item = {
  subtitle: string;
  value: Nullable<string>;
};

type Props = {
  title: AccountGroupTitle;
  list?: Item[];
};

const AccountCard: FunctionComponent<Props> = ({ title, list }) => {
  const classes = useStyles();

  if (!list) {
    return null;
  }
  const formatters = {
    CNPJ: formatCnpj,
    CPF: formatCpf,
    CEP: formatZipCode,
  };

  return (
    <div className={classes.cardCell}>
      <Card className={classes.cardContainer}>
        <Box display="flex" flexDirection="column" height="100%">
          <Box display="flex" mb={2}>
            <Typography variant="subtitleWebUppercaseSmall">{title}</Typography>
          </Box>

          <Box display="flex" flexDirection="column">
            {list.map(
              (item) =>
                item.value && (
                  <Box mt={2} key={item.subtitle}>
                    <Typography variant="caption" className={classes.infoText}>
                      {item.subtitle}
                    </Typography>

                    <Typography variant="body" className={classes.itemValue}>
                      {item.subtitle in formatters
                        ? formatters[item.subtitle as keyof typeof formatters](item.value)
                        : item.value}
                    </Typography>
                  </Box>
                ),
            )}
          </Box>
        </Box>
      </Card>
    </div>
  );
};

export default AccountCard;
