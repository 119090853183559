import TagManager from 'react-gtm-module';

import { createReducer } from '@reduxjs/toolkit';

import {
  clearPersisted,
  saveAccount,
  changeDocumentNumber,
  changeUserPortal,
  changeUserName,
  changeUserStatus,
} from './actions';
import { accountInitialState } from './constants';
import type { AccountState } from './types';

export default createReducer<AccountState>(accountInitialState, (builder) =>
  builder
    .addCase(saveAccount, (state, action) => {
      state.data = action.payload.data;
      if (action.payload.shouldPersist) {
        state.persisted = action.payload.data;
      }
    })
    .addCase(changeDocumentNumber, (state, action) => {
      state.data = { ...state.data, documentNumber: action.payload } as AccountState['data'];

      TagManager.dataLayer({
        dataLayer: {
          event: 'profile',
          userId: action.payload,
        },
      });
    })
    .addCase(changeUserStatus, (state, action) => {
      state.data = { ...state.data, status: action.payload } as AccountState['data'];
    })
    .addCase(changeUserPortal, (state, action) => {
      state.data = { ...state.data, userPortal: action.payload } as AccountState['data'];
    })
    .addCase(changeUserName, (state, action) => {
      state.data = { ...state.data, name: action.payload } as AccountState['data'];
    })
    .addCase(clearPersisted, () => accountInitialState),
);
