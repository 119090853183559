import React, { useCallback, useEffect, useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { Card, Grid, makeStyles, Switch, Snackbar } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import { Alert, Skeleton } from '@material-ui/lab';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import Button from 'components/UI/Button';
import EmptyList from 'components/UI/EmptyList';
import Feature from 'components/UI/Feature';
import PageContainer from 'components/UI/PageContainer';
import PageTitle from 'components/UI/PageTitle';
import ServerError from 'components/UI/ServerError';
import Typography from 'components/UI/Typography';
import { removeFormatUserDocumentNumber } from 'helpers/formatters/formatters';
import { Features } from 'models/featureToggle';
import { ConciliationType } from 'models/reconciliation';
import { getConciliators, updateConciliatorAuthorize } from 'services/reconciliation';

import ModalEdi from './ModalEdi';

const useStyles = makeStyles((theme) => ({
  emptyContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      height: 400,
    },
  },
  card: {
    maxWidth: 544,
  },
  conciliator: {
    border: `1px solid ${theme.palette.carbon[10]}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2, 0),
    alignItems: 'center',
  },
  showRulesDecoration: {
    textAlign: 'start',
    textDecorationLine: 'underline',
    color: theme.palette.textColor.link,
    padding: 0,
    '&:hover': {
      textDecorationLine: 'underline',
      background: 'transparent',
    },
  },
  saveButton: {
    margin: theme.spacing(2, 4, 0),
  },
  alert: {
    marginTop: theme.spacing(4),
  },
}));
type ConciliatorFormattedType = {
  conciliatorName: string;
  conciliatorDocumentNumber: string;
  status: boolean;
};
const Conciliation = () => {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);

  const [snackbarSuccessOpen, setSnackbarSuccessOpen] = useState(false);
  const [snackbarErrorOpen, setSnackbarErrorOpen] = useState(false);

  const [conciliatorIsLoading, setConciliatorIsLoading] = useState(true);
  const [conciliatorError, setConciliatorError] = useState(false);
  const [conciliators, setConciliators] = useState<ConciliatorFormattedType[]>([]);

  const handleChangeConciliatorStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConciliators((prevState) =>
      prevState.map((conciliator: ConciliatorFormattedType) => {
        if (event.target.name === conciliator.conciliatorDocumentNumber) {
          return { ...conciliator, status: event.target.checked };
        }
        return conciliator;
      }),
    );
  };

  const handleUpdateConciliator = () => {
    setConciliatorIsLoading(true);
    const formattedConciliator = conciliators.map(
      ({ conciliatorDocumentNumber, status }: ConciliatorFormattedType) => ({
        conciliatorDocumentNumber: removeFormatUserDocumentNumber(conciliatorDocumentNumber),
        authorize: status,
      }),
    );

    updateConciliatorAuthorize(formattedConciliator)
      .then(({ parsedBody }) => {
        if (parsedBody) {
          setSnackbarSuccessOpen(true);
        } else {
          setSnackbarErrorOpen(true);
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setConciliatorIsLoading(false));
  };

  const removeRejectedConciliators = (array: ConciliationType[]): ConciliationType[] => {
    return array.filter((conciliator) => conciliator.status !== 'rejected');
  };

  const initRequestGetConciliators = useCallback(() => {
    getConciliators()
      .then((response) => {
        if (response?.parsedBody) {
          setConciliatorIsLoading(false);
          const notRejectedConciliators = removeRejectedConciliators(
            response?.parsedBody as ConciliationType[],
          ) as ConciliationType[];
          const conciliatorList = notRejectedConciliators?.map((data) => ({
            ...data,
            status: data.status === 'authorized',
          })) as ConciliatorFormattedType[];
          return setConciliators(conciliatorList);
        }
        throw new Error(response.errorMessage);
      })
      .catch(() => setConciliatorError(true));
  }, []);

  const retryGetConciliator = () => {
    initRequestGetConciliators();
  };

  useEffect(() => {
    initRequestGetConciliators();
  }, [initRequestGetConciliators]);

  if (conciliatorError) {
    return (
      <ServerError
        onTryAgain={() => {
          setConciliatorError(false);
          retryGetConciliator();
        }}
        title="Ocorreu um erro"
        message="Algo deu errado e não tivemos uma resposta do servidor."
      />
    );
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Snackbar autoHideDuration={6000} open={snackbarSuccessOpen} onClose={() => setSnackbarSuccessOpen(false)}>
        <Alert variant="filled" severity="success">
          Autorização de acesso da conciliadora atualizado
        </Alert>
      </Snackbar>
      <Snackbar autoHideDuration={3000} open={snackbarErrorOpen} onClose={() => setSnackbarErrorOpen(false)}>
        <Alert variant="filled" severity="error">
          Não foi possível alterar o estado de acesso atual. Tente novamente
        </Alert>
      </Snackbar>
      <PageContainer>
        <Grid item xs={12} container justify="space-between">
          <PageTitle
            title="Conciliação"
            subtitle="Realize a gestão dos seus conciliadores de forma simples e rápida."
            isLoading={false}
          />
          <Feature name={Features.EDI_REPORT}>
            <Button
              variant="text"
              size="large"
              color="secondary"
              type="button"
              startIcon={<GetAppIcon />}
              onClick={() => setModalOpen(true)}
            >
              Baixar extrato EDI
            </Button>
          </Feature>
        </Grid>
        <ModalEdi open={modalOpen} setOpen={setModalOpen} />
        <Grid item xs={12} container justify="center">
          {!conciliators.length ? (
            <div className={classes.emptyContainer}>
              <EmptyList subtitle="Você não tem nenhuma conciliadora para exibir." />
            </div>
          ) : (
            <Card className={classes.card}>
              <Typography variant="subtitle2" component="h2">
                Autorização de conciliadora
              </Typography>
              <Typography variant="caption">
                Abaixo você pode permitir ou negar o acesso da sua conciliadora às informações relacionadas a todas as
                suas transações de meio de pagamento.
              </Typography>
              {conciliatorIsLoading ? (
                <div className={classes.conciliator}>
                  <Skeleton width="100%" animation="wave" />
                </div>
              ) : (
                conciliators?.map(({ conciliatorName, conciliatorDocumentNumber, status }) => {
                  return (
                    <div key={conciliatorDocumentNumber} className={classes.conciliator}>
                      <div>
                        <Typography variant="bodyBold">{conciliatorName}</Typography>
                        <Typography variant="caption">{conciliatorDocumentNumber}</Typography>
                      </div>
                      <Switch
                        checked={status}
                        onChange={handleChangeConciliatorStatus}
                        color="primary"
                        name={conciliatorDocumentNumber}
                        inputProps={{ 'aria-label': `habilitar ${conciliatorName}` }}
                      />
                    </div>
                  );
                })
              )}
              <Alert severity="info" color="info" icon={<InfoRoundedIcon color="inherit" />} className={classes.alert}>
                Atenção: ao permitir o acesso para a conciliadora selecionada, esta poderá requisitar e obter acesso a
                todas as informações relacionadas a todas as transações de meio de pagamento.
                <Button
                  href="https://openfiles.paygo.com.br/Termo_de_Autorizacao_de_Trafego_EDI.pdf"
                  variant="text"
                  size="small"
                  className={classes.showRulesDecoration}
                  aria-label="Abrir janela de dúvidas frequentes."
                  componentProps={{
                    target: '_blank',
                  }}
                >
                  Consulte o Termo de Autorização de Tráfego de Dados EDI.
                </Button>
              </Alert>
              <div className={classes.saveButton}>
                <Button
                  type="button"
                  title="Salvar Alterações"
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="large"
                  aria-label="Salvar alterações da autorização de conciliadores."
                  disabled={conciliatorIsLoading}
                  onClick={handleUpdateConciliator}
                >
                  Salvar Alterações
                </Button>
              </div>
            </Card>
          )}
        </Grid>
      </PageContainer>
    </MuiPickersUtilsProvider>
  );
};

export default Conciliation;
