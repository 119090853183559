import type { FunctionComponent } from 'react';
import React from 'react';

import { Box, capitalize } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import { Alert, AlertTitle } from '@material-ui/lab';

import DetailsDrawer from 'components/DetailsDrawer';
import Typography from 'components/UI/Typography';
import { fullDateFormat, hoursAndMinutes } from 'helpers/date';
import { formatAmount } from 'helpers/formatters/formatters';
import { AnticipationData, AnticipationItemStatusDictionaryValues } from 'models/anticipation';

import AnticipationStatus from './AnticipationStatus/AnticipationStatus';

const useStyles = makeStyles((theme) => ({
  antipationInfoItem: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),
    borderBottom: `1px solid ${theme.palette.carbon[10]}`,
  },
  carbon70: {
    color: theme.palette.carbon[70],
  },
}));

type Props = {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  details: Omit<AnticipationData, 'status'> & { status: AnticipationItemStatusDictionaryValues };
  changePageTab: () => void;
};

const AnticipationDetails: FunctionComponent<Props> = ({ open, onClose, onOpen, details, changePageTab }) => {
  const classes = useStyles();
  const {
    id,
    acquirers,
    grossAmount,
    netAmount,
    taxAmount,
    status,
    startAnticipatedDate,
    endAnticipatedDate,
    createdAt,
  } = details;

  const anctipationInfoDictionary = {
    acquirer: 'Adquirente',
    date: 'Data do pedido',
    hour: 'Hora',
    anticipatePeriod: 'Período antecipado',
    receivableAmount: 'Recebíveis',
    tax: 'Taxa',
    order: 'Pedido',
  };

  const anticipationInfo = {
    acquirer: acquirers.map((acquirer) => capitalize(acquirer.name)).join(' | '),
    date: createdAt && fullDateFormat(createdAt),
    hour: createdAt && hoursAndMinutes(createdAt, true),
    anticipatePeriod: `${fullDateFormat(startAnticipatedDate)} a ${fullDateFormat(endAnticipatedDate)}`,
    receivableAmount: formatAmount(grossAmount),
    tax: formatAmount(taxAmount),
    order: `#${id}`,
  };

  const renderAnticipationInfoContent = (
    dictionary: typeof anctipationInfoDictionary,
    infos: typeof anticipationInfo,
  ) => {
    return Object.entries(dictionary).map(([keyName, v]) => {
      const saleInfoValue = infos[keyName as keyof typeof infos];
      return (
        saleInfoValue && (
          <Box key={keyName} className={classes.antipationInfoItem}>
            <Typography variant="caption">{v}</Typography>
            <Typography variant="caption" className={classes.carbon70}>
              {saleInfoValue}
            </Typography>
          </Box>
        )
      );
    });
  };

  return (
    <DetailsDrawer open={open} onClose={onClose} onOpen={onOpen}>
      <Box mb={4}>
        <AnticipationStatus text={status.text} icon={status.icon} variant={status.variant} />
      </Box>

      <Typography variant="caption" className={classes.carbon70}>
        Total a receber
      </Typography>
      <Typography variant="subtitleWebBold">{`R$ ${formatAmount(netAmount || 0)}`}</Typography>

      <Box display="flex" flexDirection="column" width="100%" my={6}>
        {renderAnticipationInfoContent(anctipationInfoDictionary, anticipationInfo)}
      </Box>

      {status.text === 'Recusado' && (
        <Alert severity="info" color="info" icon={<InfoRoundedIcon color="inherit" />}>
          <AlertTitle>Importante</AlertTitle>
          <Typography variant="caption" component="p" paragraph>
            O valor solicitado não está disponível. Isso pode acontecer porque foram feitas antecipações anteriores e o
            valor dos recebíveis foi atualizado.
          </Typography>
          <Typography variant="caption" component="span">
            {'Você ainda poderá conferir se existem recebíveis à antecipar e escolher um novo valor na aba de '}
          </Typography>
          <Typography variant="bodylink2" component="strong" onClick={changePageTab}>
            Simulador.
          </Typography>
        </Alert>
      )}
    </DetailsDrawer>
  );
};

export default AnticipationDetails;
