import React, { ChangeEvent, FunctionComponent } from 'react';

import { Box, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Typography from 'components/UI/Typography';
import { formatIntToAmount } from 'helpers/formatters/formatters';
import { OperationTypeKeys, PaymentMethodKeys } from 'models/taxSimulator';

const useStyles = makeStyles((theme) => ({
  textFieldAmount: {
    '& .MuiInput-input': {
      textAlign: 'end',
    },
  },
}));

type Props = {
  amount: number;
  changeAmountValue: (e: ChangeEvent<HTMLInputElement>) => void;
  errorAmount: string;
  setErrorAmount: React.Dispatch<React.SetStateAction<string>>;

  paymentMethod: PaymentMethodKeys | '';
  paymentMethodOptions: { [key in PaymentMethodKeys]?: string };
  setPaymentMethod: React.Dispatch<React.SetStateAction<'' | PaymentMethodKeys>>;

  operationType: OperationTypeKeys | '';
  operationTypeOptions: {
    credit: string;
    debit: string;
  };
  setOperationType: React.Dispatch<React.SetStateAction<'' | OperationTypeKeys>>;

  installment: string;
  installmentOptions: {
    value: string;
    label: string;
  }[];
  setInstallment: React.Dispatch<React.SetStateAction<string>>;

  getValueFromSelectAndSetValue: <T>(
    event: React.ChangeEvent<{
      value: unknown;
    }>,
    setValueFunction: (value: React.SetStateAction<'' | T>) => void,
  ) => void;
  validEmptyField: (value: string | number, setError: React.Dispatch<React.SetStateAction<string>>) => void;
};

const Form: FunctionComponent<Props> = ({
  amount,
  changeAmountValue,
  errorAmount,
  setErrorAmount,

  paymentMethod,
  paymentMethodOptions,
  setPaymentMethod,

  operationType,
  operationTypeOptions,
  setOperationType,

  installment,
  installmentOptions,
  setInstallment,

  getValueFromSelectAndSetValue,
  validEmptyField,
}) => {
  const classes = useStyles();

  return (
    <>
      <TextField
        fullWidth
        className={classes.textFieldAmount}
        error={!!errorAmount}
        type="text"
        label="Valor da venda"
        variant="standard"
        value={formatIntToAmount(amount)}
        onChange={changeAmountValue}
        onBlur={() => validEmptyField(amount, setErrorAmount)}
        helperText={errorAmount}
        required
        InputProps={{
          margin: 'none',
          startAdornment: (
            <InputAdornment position="start">
              <Typography variant="caption">R$</Typography>
            </InputAdornment>
          ),
        }}
      />
      <FormControl fullWidth>
        <InputLabel id="label-select-installments">Meio de Pagamento</InputLabel>
        <Select
          labelId="label-select-installments"
          value={paymentMethod}
          onChange={(e) => getValueFromSelectAndSetValue<PaymentMethodKeys>(e, setPaymentMethod)}
          IconComponent={ExpandMoreIcon}
          variant="standard"
        >
          {Object.entries(paymentMethodOptions!).map(([value, label], key) => (
            <MenuItem value={value} key={`${key + 1}`} selected={value === paymentMethod}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <InputLabel id="label-select-installments">Tipo de operação</InputLabel>
        <Select
          labelId="label-select-installments"
          value={operationType}
          onChange={(e) => getValueFromSelectAndSetValue<OperationTypeKeys>(e, setOperationType)}
          IconComponent={ExpandMoreIcon}
          variant="standard"
        >
          {Object.entries(operationTypeOptions).map(([value, label], key) => (
            <MenuItem value={value} key={`${key + 1}`} selected={value === operationType}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box hidden={operationType !== 'credit'}>
        <FormControl fullWidth>
          <InputLabel id="label-select-installments">Numero de parcelas</InputLabel>
          <Select
            labelId="label-select-installments"
            value={installment}
            onChange={(e) => getValueFromSelectAndSetValue<string>(e, setInstallment)}
            IconComponent={ExpandMoreIcon}
            variant="standard"
          >
            {installmentOptions.map(({ value, label }, key) => (
              <MenuItem value={value} key={`${key + 1}`} selected={value === installment}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

export default Form;
