import React from 'react';

import { Box, Grid, makeStyles } from '@material-ui/core';

import { ReactComponent as SuccessIcon } from 'assets/images/success.svg';
import Button from 'components/UI/Button';
import Typography from 'components/UI/Typography';
import type { Order } from 'models/machine';

import { OrderType } from '../constants';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    border: `1px solid ${theme.palette.carbon[10]}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(4, 2),
    background: theme.palette.backgroundColor.surface?.secondary,
  },
  header: {
    '& > h1': {
      marginTop: theme.spacing(4),
    },
  },
  cardWrapper: {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    width: 504,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  card: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.backgroundColor.surface?.marbleDark,
    border: `1px solid ${theme.palette.carbon[10]}`,
    borderRadius: theme.spacing(1),
    width: '100%',
  },
  buttonWrapper: {
    marginTop: theme.spacing(4),
  },
}));

type SuccessPropType = {
  orderType?: OrderType;
  code: number;
  order: Order;
  handleBack: () => void;
};

const coil: { [key in number]: string } = {
  1: '1 pacote (6 unidades de bobinas)',
  2: '2 pacotes (12 unidades de bobinas)',
  3: '3 pacotes (18 unidades de bobinas)',
};

const Success = ({ code, order, handleBack, orderType }: SuccessPropType) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2} justify="center">
      <Box className={classes.cardWrapper}>
        <Grid container justify="center" className={classes.container}>
          <Grid item xs={12} container alignItems="center" direction="column" className={classes.header}>
            <SuccessIcon />
            <Typography variant="subtitle2" component="h1" paragraph gutterBottom>
              Pedido realizado com sucesso!
            </Typography>
            <Typography variant="caption" component="p" paragraph>
              Em breve enviaremos novidades sobre o seu pedido. Confira os detalhes:
            </Typography>
          </Grid>
          <Box mt={4} className={classes.card}>
            <Typography variant="bodyBold" paragraph gutterBottom>
              Detalhes do pedido
            </Typography>
            <Typography variant="caption3" component="p">
              Número do pedido
            </Typography>
            <Typography variant="caption" component="p" paragraph gutterBottom>
              {code}
            </Typography>
            {orderType === OrderType.COIL ? (
              <>
                <Typography variant="caption3" component="p">
                  Quantidade de bobinas?
                </Typography>
                <Typography variant="caption" component="p" paragraph gutterBottom>
                  {coil[order?.coilQuantity]}
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="caption3" component="p">
                  Qual o modelo da maquininha?
                </Typography>
                <Typography variant="caption" component="p" paragraph gutterBottom>
                  {order?.charger}
                </Typography>
                <Typography variant="caption3" component="p">
                  Qual o defeito com o seu carregador?
                </Typography>
                <Typography variant="caption" component="p" paragraph gutterBottom>
                  {order?.reason}
                </Typography>
              </>
            )}
          </Box>
          <Grid container justify="center" className={classes.buttonWrapper}>
            <Button fullWidth variant="contained" color="primary" size="large" onClick={handleBack}>
              Voltar para o início
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default Success;
