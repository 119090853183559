import { createReducer } from '@reduxjs/toolkit';

import { Loading } from 'helpers/enums';
import type { WhitelabelConfig } from 'models/whitelabelConfig';

import { getWhitelabel, clearWhitelabel } from './actions';

type WhitelabelState = {
  loading: Loading;
  data?: WhitelabelConfig;
  error?: string;
};

const initialWhitelabel = {
  loading: Loading.IDLE,
  data: undefined,
  error: undefined,
};

export default createReducer<WhitelabelState>(initialWhitelabel, (builder) =>
  builder
    .addCase(getWhitelabel.pending, (state) => {
      state.loading = Loading.PENDING;
      state.data = undefined;
      state.error = undefined;
    })
    .addCase(getWhitelabel.fulfilled, (state, action) => {
      state.loading = Loading.IDLE;
      state.data = action.payload;
      state.error = undefined;
    })
    .addCase(getWhitelabel.rejected, (state, action) => {
      state.loading = Loading.IDLE;
      state.data = undefined;
      state.error = action.payload?.message;
    })
    .addCase(clearWhitelabel, (_) => initialWhitelabel),
);
