import type { FunctionComponent, ReactElement } from 'react';
import React, { useState } from 'react';

import { Dialog, Box, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import Typography from 'components/UI/Typography';

const useStyles = makeStyles((theme) => ({
  modalWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
  },
  closeIcon: {
    width: '15px',
    height: '15px',
    '& :first-child': {
      width: '18px',
      height: '18px',
    },
  },
  modalContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  modalDescription: {
    marginTop: theme.spacing(2),
    color: theme.palette.carbon[70],
    '& > ul': {
      listStylePosition: 'outside',
      margin: '1px solid red',
    },
  },
  buttonSection: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(4),
  },
  firstButton: {
    marginRight: theme.spacing(2),
  },
}));

const InfoPopupZendeskClosed: FunctionComponent = (): ReactElement => {
  const classes = useStyles();
  const [isOpen, setOpen] = useState(true);

  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        maxWidth="xs"
        fullWidth
      >
        <Box className={classes.modalWrapper}>
          <Box className={classes.modalContainer}>
            <Typography variant="paragraphLargeBoldLong">Novas solicitações</Typography>
            <IconButton aria-label="close" className={classes.closeIcon} onClick={handleCloseDialog}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Typography variant="paragraphSmallRegular" className={classes.modalDescription}>
            <p>
              {` O processo de solicitação de novos produtos mudou. Agora, você pode fazer a solicitação através da nossa `}
              Central de Atendimento C6 Pay
              {` pelos telefones `}
              <strong>3003-6116</strong>
              {` (capitais e regiões metropolitanas) ou `}
              <strong>0800 660 6116</strong>
              {` (demais regiões).`}
            </p>
            <p>
              {`Para solicitar `}
              <strong>bobinas</strong>
              {`, você precisa: `}
            </p>
            <ul>
              <li>Ter uma maquininha ativa compatível com a bobina;</li>
              <li>Não ter feito nenhuma solicitação de bobina nos últimos 15 dias;</li>
              <li>Estar utilizando o equipamento.</li>
            </ul>

            <p>
              {`Para solicitar `}
              <strong>carregadores</strong>
              {` , você precisa: `}
            </p>
            <ul>
              <li>Verificar se o carregador está funcionando em outra tomada;</li>
              <li>Ter uma maquininha ativa e em uso;</li>
              <li>Não ter feito nenhuma solicitação de carregador nos últimos 3 meses.</li>
            </ul>
            <p>
              <strong>Importante: o prazo para recebimento é de até 10 dias úteis.</strong>
            </p>
          </Typography>
        </Box>
      </Dialog>
    </>
  );
};

export default InfoPopupZendeskClosed;
