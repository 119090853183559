import type { FunctionComponent } from 'react';
import React from 'react';

import { Box, Hidden, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  groupHeader: {
    backgroundColor: theme.palette.backgroundColor.surface?.marbleDark,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(3),
    width: '100%',
    height: 56,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2, 1),
    borderBottom: `1px solid ${theme.palette.carbon[10]}`,
    marginBottom: theme.spacing(3),
  },
}));

const ReceivablesGroupSkeleton: FunctionComponent = () => {
  const classes = useStyles();

  const skeletonKeys = [1, 2, 3, 4, 5, 6];

  return (
    <Box width="100%">
      <Box className={classes.groupHeader} />
      {skeletonKeys.map((key) => {
        return (
          <Box className={classes.row} key={key}>
            <Box display="flex">
              <Box pr={2}>
                <Skeleton width={51} height={36} animation="wave" />
              </Box>
              <Hidden smDown>
                <Box display="flex" flexDirection="column" justifyContent="space-between">
                  <Skeleton width={121} height={16} animation="wave" />
                  <Skeleton width={121} height={16} animation="wave" />
                </Box>
              </Hidden>
            </Box>
            <Box display="flex">
              <Box mr={5}>
                <Skeleton width={56} height={24} animation="wave" />
              </Box>
              <Box>
                <Skeleton width={32} height={24} animation="wave" />
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default ReceivablesGroupSkeleton;
