import type { ReactElement, MouseEvent } from 'react';
import React, { useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { Typography, Modal, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import { ToggleButtonGroup, ToggleButton, Alert } from '@material-ui/lab';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import Button from 'components/UI/Button';
import { endOfDay, isValid, startOfDay, eachDayOfInterval, add } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { datePickerFormat } from 'helpers/date';
import type { Filter } from 'models/sales';

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    padding: theme.spacing(3),
    border: `1px solid ${theme.palette.carbon[10]}`,
    background: theme.palette.common.white,
    borderRadius: 8,
    width: '90%',
    maxWidth: 550,
    overflowY: 'auto',
    outline: 'none',
    height: 'auto',
    maxHeight: '90vh',
  },
  modalHeader: {
    paddingBottom: theme.spacing(3),
  },
  modalBody: {
    display: 'grid',
    gap: theme.spacing(1),
    justifyContent: 'center',
    gridTemplateColumns: '1fr 1fr',
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'center',
    padding: `${theme.spacing(4)}px 0 ${theme.spacing(2)}px`,
    '& > *:first-child': {
      margin: theme.spacing(0, 1, 0, 0),
    },
    [theme.breakpoints.down('xs')]: {
      '& > *:first-child': {
        margin: theme.spacing(0, 0, 1),
      },
      flexDirection: 'column',
    },
  },
  modalErrorMessage: {
    color: theme.palette.error.main,
    paddingTop: theme.spacing(1),
  },
  actionButton: {
    padding: theme.spacing(1, 2),
    justifyContent: 'center',
    alignItems: 'center',
  },
  toggleButtonContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, 240px)',
      justifyContent: 'center',
    },
  },
  datePicker: {
    [theme.breakpoints.down('sm')]: {
      '& + .MuiInputAdornment-root': {
        width: theme.spacing(5),
      },
    },
  },
  toogleButton: {
    borderRadius: 120,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  alertWrapper: {
    margin: theme.spacing(2, 0),
  },
  calendarIcon: {
    width: 14,
    marginRight: theme.spacing(0.5),
  },
}));

type Props = {
  setFilter: Function;
  filter: Filter;
};

const DateFilter = ({ setFilter, filter }: Props): ReactElement => {
  const today = new Date();
  const classes = useStyles();
  const [customDate, setCustomDate] = useState(false);
  const [checkedDate, setCheckedDate] = useState(filter.date);
  const [startDatePeriod, setStartDatePeriod] = useState<Date>(filter.startDate);
  const [endDatePeriod, setEndDatePeriod] = useState<Date>(filter.endDate);
  const [datePeriodApplyButtonDisabled, setDatePeriodApplyButtonDisabled] = useState(false);
  const [isValidDate, setIsValidDate] = useState(true);

  const dateButtons = [
    { label: 'Hoje', value: 'today' },
    { label: '7 dias', value: 'last7Days' },
    { label: '30 dias', value: 'last30Days' },
    {
      label:
        checkedDate === 'datePeriod' ? (
          `${datePickerFormat(startDatePeriod)} - ${datePickerFormat(endDatePeriod)}`
        ) : (
          <Box display="flex" alignItems="center">
            <CalendarTodayIcon className={classes.calendarIcon} />
            Período
          </Box>
        ),
      value: 'datePeriod',
    },
  ];

  const errorMessages = {
    minDate: 'A data final não pode ser menor que a data de inicio',
    maxDate: 'Você não pode selecionar vendas no futuro',
    format: 'Formato de data inválido',
  };

  type DateRange = () => { [key: string]: () => { endDate: Date; startDate: Date } };

  const dateRange: DateRange = () => {
    const endDate = today;
    const startDate = new Date(endDate);
    return {
      today: () => {
        return { startDate, endDate };
      },
      last7Days: () => {
        endDate.setDate(endDate.getDate() - 1);
        startDate.setDate(endDate.getDate() - 6);
        return { startDate, endDate };
      },
      last30Days: () => {
        endDate.setDate(endDate.getDate() - 8);
        startDate.setDate(endDate.getDate() - 58);
        return { startDate, endDate };
      },
    };
  };

  const applyDateFilter = (event: MouseEvent<HTMLElement>, clickedDate: string) => {
    if (clickedDate === null && checkedDate !== 'datePeriod') {
      return;
    }
    if (clickedDate === 'datePeriod' || (checkedDate === 'datePeriod' && clickedDate === null)) {
      setCustomDate(true);
      return;
    }

    setCheckedDate(clickedDate);
    const { endDate, startDate } = dateRange()[clickedDate]();

    setFilter({
      endDate,
      startDate,
      method: filter.method,
      status: filter.status,
      date: clickedDate,
    });
  };

  const dayInterval = (): Array<Date> | null => {
    if (!startDatePeriod) {
      return null;
    }
    return eachDayOfInterval({ start: startDatePeriod, end: today });
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <ToggleButtonGroup
        value={checkedDate}
        exclusive
        onChange={applyDateFilter}
        className={classes.toggleButtonContainer}
      >
        {dateButtons.map(({ label, value }) => (
          <ToggleButton className={classes.toogleButton} key={value} value={value}>
            <Typography variant="body2">{label}</Typography>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <Modal
        className={classes.modalContainer}
        open={customDate}
        aria-labelledby="Selecione as datas"
        aria-describedby="Selecione um período de datas para suas vendas."
      >
        <section className={classes.modalContent}>
          <header className={classes.modalHeader}>
            <Typography variant="h6">Selecione um período</Typography>
          </header>
          <div className={classes.modalBody}>
            <KeyboardDatePicker
              value={startDatePeriod}
              label="Data de início"
              variant="inline"
              format="dd/MM/yyyy"
              maxDate={today}
              maxDateMessage={errorMessages.maxDate}
              minDate={new Date('2021-10-01T00:00')}
              minDateMessage="Você não pode consultar períodos inferiores à 01/10/2021"
              autoOk
              inputProps={{ className: classes.datePicker }}
              onChange={(date) => {
                setStartDatePeriod(date as Date);
                if ((date as Date) > endDatePeriod) {
                  setDatePeriodApplyButtonDisabled(true);
                  setIsValidDate(false);
                } else if (!isValid(date)) {
                  setIsValidDate(false);
                } else {
                  setIsValidDate(true);
                  setDatePeriodApplyButtonDisabled(false);
                }
              }}
              KeyboardButtonProps={{
                'aria-label': 'Selecionar data de início das vendas',
              }}
              invalidDateMessage={errorMessages.format}
            />
            <KeyboardDatePicker
              value={endDatePeriod}
              label="Data de fim"
              variant="inline"
              format="dd/MM/yyyy"
              maxDate={dayInterval.length > 31 ? add(startDatePeriod, { days: 31 }) : today}
              maxDateMessage={
                endDatePeriod > today ? errorMessages.maxDate : 'O período deve ter um intervalo máximo de 31 dias.'
              }
              minDate={startDatePeriod}
              minDateMessage={errorMessages.minDate}
              autoOk
              inputProps={{ className: classes.datePicker }}
              onChange={(date) => {
                setEndDatePeriod(date as Date);
                if (startOfDay(startDatePeriod) > (date as Date)) {
                  setDatePeriodApplyButtonDisabled(true);
                  setIsValidDate(false);
                } else if (!isValid(date) || (date as Date) > endOfDay(today)) {
                  setIsValidDate(false);
                } else {
                  setIsValidDate(true);
                  setDatePeriodApplyButtonDisabled(false);
                }
              }}
              KeyboardButtonProps={{
                'aria-label': 'Selecionar data de fim das vendas',
              }}
              invalidDateMessage={errorMessages.format}
            />
          </div>
          <div className={classes.alertWrapper}>
            <Alert severity="info" color="info" icon={<InfoRoundedIcon color="inherit" />}>
              Para consultar períodos anteriores à 01/10/2021, entre em contato através da nossa Central de ajuda.
            </Alert>
          </div>
          <footer className={classes.modalFooter}>
            <Button
              onClick={() => {
                setStartDatePeriod(filter.startDate);
                setEndDatePeriod(filter.endDate);
                setDatePeriodApplyButtonDisabled(false);
                setCustomDate(false);
              }}
              variant="outlined"
              className={classes.actionButton}
              fullWidth
              color="default"
              type="reset"
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              title="aplicar filtros"
              className={classes.actionButton}
              fullWidth
              color="primary"
              disabled={datePeriodApplyButtonDisabled || !isValidDate || dayInterval.length > 31}
              onClick={() => {
                setFilter({
                  endDate: endDatePeriod,
                  startDate: startDatePeriod,
                  method: filter.method,
                  status: filter.status,
                  date: 'datePeriod',
                });
                setCheckedDate('datePeriod');
                setCustomDate(false);
              }}
            >
              Aplicar filtros
            </Button>
          </footer>
        </section>
      </Modal>
    </MuiPickersUtilsProvider>
  );
};

export default DateFilter;
