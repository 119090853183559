import React from 'react';

import { makeStyles, Box } from '@material-ui/core';

import Typography from 'components/UI/Typography';

type ReadMoreProps = {
  text: string;
  isReadMore: boolean;
};

const useStyles = makeStyles((theme) => ({
  textContentReadMore: {
    display: 'box',
    lineClamp: 3,
    boxOrient: 'vertical',
    overflow: 'hidden',
    marginBottom: theme.spacing(3),
    color: theme.palette.carbon[70],
  },
  textContentReadLess: {
    display: 'box',
    marginBottom: theme.spacing(3),
    color: theme.palette.carbon[70],
  },
}));

const ReadMore = ({ text, isReadMore }: ReadMoreProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Box mt={2}>
      <Typography variant="body2" className={isReadMore ? classes.textContentReadMore : classes.textContentReadLess}>
        {text}
      </Typography>
    </Box>
  );
};

export default ReadMore;
