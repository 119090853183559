import type { FunctionComponent } from 'react';
import React from 'react';

import { Nullable } from '@portal-types/helpers';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import { Alert } from '@material-ui/lab';

import Typography from 'components/UI/Typography';
import { formatAmount } from 'helpers/formatters/formatters';
import type { ReceivableItem } from 'models/receivables';

import ReceivablesGroupItem from './Item';

const useStyles = makeStyles((theme) => ({
  root: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(4),
    },
  },
  groupHeader: {
    backgroundColor: theme.palette.backgroundColor.surface?.marbleDark,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
  },
  alertBox: {
    color: theme.palette.error.dark,
    borderRadius: 8,
  },
}));

type Props = {
  total: Nullable<number>;
  list: ReceivableItem[];
};

const PendingReceivablesGroup: FunctionComponent<Props> = ({ total, list }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.groupHeader}>
        <Typography variant="bodyBold">Recebíveis pendentes</Typography>
        <Typography variant="bodyBold">{`R$ ${formatAmount(total || 0)}`}</Typography>
      </Box>
      <Alert className={classes.alertBox} icon={<InfoIcon fontSize="inherit" />} severity="error">
        Ação necessária para validar a confirmação da venda ou serviço prestado.
      </Alert>
      {list.map((receivable) => (
        <ReceivablesGroupItem receivable={receivable} key={receivable.orderId} />
      ))}
    </Box>
  );
};

export default PendingReceivablesGroup;
