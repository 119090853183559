import React, { FunctionComponent, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';

import { Backdrop, CircularProgress, CssBaseline } from '@material-ui/core';
import { Theme, ThemeProvider } from '@material-ui/core/styles';

import { useAppDispatch } from 'store';
import { WhitelabelActions, WhitelabelSelectors } from 'store/whitelabel';
import { themeDefault } from 'themes';

const App: FunctionComponent = ({ children }) => {
  const dispatch = useAppDispatch();
  const [theme, setTheme] = useState<Theme>();

  const whitelabelPalette = useSelector(WhitelabelSelectors.whitelabelPalette) || {};
  const whitelabelGtmId = useSelector(WhitelabelSelectors.whitelabelGtmId) || '';

  useEffect(() => {
    dispatch(WhitelabelActions.getWhitelabel());
  }, [dispatch]);

  useEffect(() => {
    if ('primary' in whitelabelPalette) {
      const newTheme = themeDefault(whitelabelPalette);
      setTheme(newTheme);
    }

    if (whitelabelGtmId) {
      TagManager.initialize({ gtmId: whitelabelGtmId });
    }
  }, [whitelabelPalette, whitelabelGtmId]);

  if (!('primary' in whitelabelPalette) && !whitelabelGtmId) {
    return (
      <Backdrop style={{ color: theme ? theme.palette.backgroundColor.surface?.secondary : '#fff' }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (theme) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    );
  }

  return null;
};

export default App;
