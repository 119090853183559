import React, { useMemo, useState, useEffect, useCallback } from 'react';
import useMeasure from 'react-use-measure';

import { Box, Card, Grid, makeStyles, useTheme } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Skeleton } from '@material-ui/lab';
import { AxisBottom, AxisTop } from '@visx/axis';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';
import { Bar } from '@visx/shape';

import { ReactComponent as SearchError } from 'assets/images/search-error.svg';
import Button from 'components/UI/Button';
import Typography from 'components/UI/Typography';
import { subMonths, startOfMonth } from 'date-fns';
import { formatRequestDate } from 'helpers/date';
import { SalesGraph } from 'models/sales';
import { salesGraph } from 'services/sales';

type SalesGraphDataType = {
  month: string;
  count: number;
};

type MonthsObjectType = {
  [index: number]: string;
};

const getYValue = (d: SalesGraphDataType) => d.count;
const getYValueToString = (d: SalesGraphDataType) => d.count.toString();

const getXValue = (d: SalesGraphDataType) => d.month;

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    height: 'calc(100% - 32px)',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    minWidth: 'calc(100% - 32px)',
    background: theme.palette.backgroundColor.surface?.marbleDark,
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.carbon[10]}`,
  },
  container: {
    position: 'relative',
    width: 300,
    height: 210,
  },
  containerError: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  containerEmptyState: {
    position: 'relative',
    width: 400,
    height: 210,
    padding: theme.spacing(2),
  },
  containerSkeleton: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
    height: '210px',
    '& > span': {
      width: `calc(100% - ${theme.spacing(4)}px)`,
      height: '100%',
    },
  },
  sectionGap: {
    display: 'grid',
    gap: theme.spacing(2),
    height: '100%',
    '& > div': {
      height: 'calc(100% - 32px)',
    },
  },
  buttonUpdateCard: {
    color: theme.palette.textColor.link,
  },
  errorCard: {
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      width: '100%',
      justifyContent: 'center',
    },
  },
  infoIcon: {
    marginRight: theme.spacing(1),
  },
  card: {
    padding: theme.spacing(2),
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    border: 'none',
    backgroundColor: theme.palette.backgroundColor.surface?.marbleDark,
  },
  emptyState: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    '&: > div:first-child': {
      marginRight: theme.spacing(2),
    },
  },
  searchError: {
    width: 152,
    height: 152,
  },
}));

const months: MonthsObjectType = {
  1: 'jan',
  2: 'fev',
  3: 'mar',
  4: 'abr',
  5: 'mai',
  6: 'jun',
  7: 'jul',
  8: 'ago',
  9: 'set',
  10: 'out',
  11: 'nov',
  12: 'dez',
};

const Chart = () => {
  const theme = useTheme();
  const classes = useStyles();
  const [ref, bounds] = useMeasure();

  const [graphData, setGraphData] = useState<SalesGraphDataType[]>([]);
  const [graphDataLoading, setGraphDataLoading] = useState(true);
  const [graphDataError, setGraphDataError] = useState(false);

  const margin = {
    top: theme.spacing(4),
    right: theme.spacing(2),
    bottom: theme.spacing(4),
    left: theme.spacing(2),
  };

  const width = bounds.width || 100;
  const height = bounds.height || 100;

  const innerWidth = width - margin.left;
  const innerHeight = height - margin.top;

  const getSalesGraphData = useCallback(() => {
    const startDate = new Date();
    const endDate = startOfMonth(subMonths(startDate, 2));

    setGraphDataLoading(true);

    salesGraph(formatRequestDate(endDate), formatRequestDate(startDate))
      .then((response) => {
        if ('data' in response) {
          const data = (response.data as unknown) as Array<SalesGraph>;

          const salesGraphData: Array<SalesGraphDataType> = data?.map(({ month, count }: SalesGraph) => ({
            month: months[month],
            count,
          }));
          setGraphData(salesGraphData);
          setGraphDataLoading(false);
        } else {
          setGraphDataLoading(false);
          setGraphDataError(true);
        }
      })
      .catch(() => setGraphDataError(true));
  }, []);

  useEffect(() => {
    getSalesGraphData();
  }, [getSalesGraphData]);

  const xScaleBand = useMemo(
    () =>
      scaleBand<string>({
        range: [0, innerWidth],
        domain: graphData?.map(getXValue),
        padding: 0.8,
      }),
    [innerWidth, graphData],
  );

  const yScaleLinear = useMemo(
    () =>
      scaleLinear<number>({
        range: [innerHeight / 2, margin.top],
        round: true,
        domain: [Math.min(...graphData?.map(getYValue)) - 1, Math.max(...graphData?.map(getYValue)) + 1],
      }),
    [innerHeight, graphData, margin.top],
  );
  const yScaleBand = useMemo(
    () =>
      scaleBand<string>({
        range: [0, innerWidth],
        domain: graphData?.map(getYValueToString),
        padding: 0.8,
      }),
    [innerWidth, graphData],
  );

  if (graphDataLoading) {
    return (
      <div className={classes.wrapper}>
        <div className={classes.containerSkeleton}>
          <Skeleton height="100%" animation="wave" />
        </div>
      </div>
    );
  }

  if (graphDataError) {
    return (
      <Box className={classes.wrapper}>
        <Box className={classes.container}>
          <Card className={classes.card}>
            <Grid container direction="column" alignItems="center" className={classes.errorCard}>
              <Grid container direction="row" alignItems="center" justify="center">
                <InfoOutlinedIcon className={classes.infoIcon} fontSize="small" />
                <Typography variant="bodyBold">Visualização indisponível</Typography>
              </Grid>
              <Button variant="text" className={classes.buttonUpdateCard} onClick={getSalesGraphData}>
                <Typography variant="bodyBold">Atualizar</Typography>
              </Button>
            </Grid>
          </Card>
        </Box>
      </Box>
    );
  }

  return (
    <div className={classes.wrapper}>
      {graphData.length === 0 ? (
        <div className={classes.containerEmptyState} ref={ref}>
          <Box className={classes.emptyState}>
            <Box>
              <SearchError className={classes.searchError} />
            </Box>
            <Box>
              <Typography variant="subtitle2">Nenhum resultado para exibir</Typography>
              <Typography variant="body2">Seus comparativos de vendas aparecerão automaticamente aqui.</Typography>
            </Box>
          </Box>
        </div>
      ) : (
        <div className={classes.container} ref={ref}>
          <svg width={width} height={height}>
            <Group top={margin.top} width={width}>
              <AxisTop
                scale={yScaleBand}
                hideAxisLine
                hideTicks
                labelOffset={24}
                tickLabelProps={() => ({
                  fontSize: theme.typography.fontSize,
                  textAnchor: 'middle',
                })}
              />
            </Group>
            <Group width={width}>
              {graphData.map((d) => {
                const xValue = getXValue(d);
                const barWidth = xScaleBand.bandwidth();
                const barHeight = getYValue(d) === 0 ? 0 : innerHeight - (yScaleLinear(getYValue(d)) ?? 0);
                const barX = xScaleBand(xValue);
                const barY = innerHeight - barHeight;

                return (
                  <Bar
                    key={`bar-${xValue}`}
                    rx={theme.spacing(0.5)}
                    x={barX}
                    y={barY}
                    width={barWidth}
                    height={barHeight}
                    strokeWidth={2}
                    fill={theme.palette.primary.main}
                  />
                );
              })}
            </Group>
            <Group width={width}>
              <AxisBottom
                top={innerHeight}
                scale={xScaleBand}
                hideAxisLine
                hideTicks
                tickLabelProps={() => ({
                  fontSize: theme.typography.fontSize,
                  textAnchor: 'middle',
                })}
              />
            </Group>
          </svg>
        </div>
      )}
    </div>
  );
};

export default Chart;
