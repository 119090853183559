import React from 'react';
import type { FunctionComponent } from 'react';

import type { SvgIcon } from '@material-ui/core';
import { Avatar, Box, Card, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import type { Theme } from '@material-ui/core/styles';

import Typography from 'components/UI/Typography';

const useStyles = makeStyles((theme: Theme) => ({
  cardCell: {
    gridColumnEnd: 'span 3',

    [theme.breakpoints.down('sm')]: {
      gridColumnEnd: 'span 12',
    },
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  avatar: {
    backgroundColor: theme.palette.backgroundColor.surface?.marbleDark,
  },
  title: {
    color: theme.palette.carbon[60],
  },
  link: {
    textDecoration: 'underline',
  },
}));

type Props = {
  icon: typeof SvgIcon;
  title: string;
  content: string;
  phone?: string;
  link?: string;
};

const HelpCard: FunctionComponent<Props> = ({ icon: Icon, title, content, phone, link }) => {
  const classes = useStyles();

  return (
    <Box className={classes.cardCell}>
      <Card className={classes.card}>
        <Box mr={2}>
          <Avatar className={classes.avatar}>
            <Icon color="primary" />
          </Avatar>
        </Box>
        <Box>
          <Typography variant="caption" className={classes.title}>
            {title}
          </Typography>
          {phone && (
            <Link href={`tel:${phone}`}>
              <Typography variant="bodyBold" className={classes.link}>
                {content}
              </Typography>
            </Link>
          )}
          {link && (
            <Link href={link} target="_blank" rel="noreferrer noopener">
              <Typography variant="bodyBold" className={classes.link}>
                {content}
              </Typography>
            </Link>
          )}
          {!phone && !link && <Typography variant="bodyBold">{content}</Typography>}
        </Box>
      </Card>
    </Box>
  );
};

export default HelpCard;
