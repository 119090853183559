import React, { FormEvent, FunctionComponent, useEffect, useState } from 'react';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Button from 'components/UI/Button';
import SelectOpen from 'components/UI/SelectOpen';
import Typography from 'components/UI/Typography';
import { useAppDispatch } from 'store';
import { AccountUserDocument } from 'store/accounts/types';
import { DialogsActions } from 'store/dialogs';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttons: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > button:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row-reverse',
      '& > button:not(:last-child)': {
        marginBottom: 0,
        marginLeft: theme.spacing(2),
      },
    },
  },
  input: {
    margin: theme.spacing(6, 0),
  },
  carbon90: {
    color: theme.palette.carbon[90],
  },
  carbon70: {
    color: theme.palette.carbon[70],
  },
}));

type Props = {
  submit: (documentNumber: string) => void;
  error: boolean;
  loading: boolean;
  title: string;
  userDocuments: Array<Omit<AccountUserDocument, 'portalType' | 'pismoId'>>;
  onCancel?: () => void;
};

const AccountAccess: FunctionComponent<Props> = ({ submit, userDocuments, loading, title, onCancel }) => {
  const [option, setOption] = useState<{ [key: string]: string } | null>(null);
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const optionsFormatted = userDocuments.map((user) => ({
    primary: user.documentNumber,
    secondary: user.corporateName || 'Nome indisponível',
  }));

  useEffect(() => {
    if (userDocuments.length === 1) {
      submit(userDocuments[0].documentNumber);
    }
  }, [userDocuments, submit]);

  const onSubmitLogin = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (option) {
      dispatch(DialogsActions.changeWebBankingModal(false));
      submit(option.primary);
    }
  };

  return (
    <form onSubmit={onSubmitLogin} noValidate className={classes.form}>
      <Typography className={classes.carbon90} variant="header" component="h2" align="center" paragraph gutterBottom>
        {title}
      </Typography>

      <Typography align="center" variant="body2" className={classes.carbon70}>
        Encontramos mais de uma conta vinculada ao seu e-mail. Selecione a conta que você deseja acessar:
      </Typography>

      <Box position="relative" width={344} my={6}>
        <SelectOpen options={optionsFormatted} setOption={setOption} />
      </Box>

      <Box className={classes.buttons}>
        <Button
          type="submit"
          size="large"
          fullWidth
          variant="contained"
          color="primary"
          title="Acessar Conta"
          aria-label={
            loading || !option
              ? 'Desabilitado, para avançar você precisa selecionar uma conta para acesso.'
              : ' Acessar conta'
          }
          disabled={loading || !option}
        >
          Entrar
        </Button>
        {onCancel && (
          <Button
            size="large"
            onClick={onCancel}
            fullWidth
            variant="outlined"
            color="primary"
            title="Cancelar"
            aria-label="Fechar janela."
          >
            Cancelar
          </Button>
        )}
      </Box>
    </form>
  );
};

export default AccountAccess;
