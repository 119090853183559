import { apiAddress } from 'config';

export class HTTPError<T> extends Error {
  response: HttpResponse<T>;

  statusNumber: number;

  constructor(message: string, response: HttpResponse<T>) {
    super(message);
    this.response = response;
    this.statusNumber = response.status;
  }
}

type FetchConfig = RequestInit & {
  data?: {
    [key: string]: any;
  };
  token?: string;
  useWhitelabel?: boolean;
};

export type HttpResponse<T> = Response & {
  parsedBody?: T;
  parsedBlob?: BlobPart;
  error?: { message?: string };
};

export async function client<T = any>(
  endpoint: string,
  { data, token, useWhitelabel, headers: customHeaders, ...customConfig }: FetchConfig = {},
): Promise<HttpResponse<T>> {
  const newHeaders: Record<string, string> = {};

  if (token) {
    newHeaders.Authorization = `Bearer ${token}`;
  }

  const { host } = window.location;
  const [, whitelabel = 'c6pay'] = host.split('.');

  if (useWhitelabel && whitelabel) {
    const whitelabelFormatted = whitelabel === 'solpayonline' ? 'solpay' : whitelabel;
    newHeaders.whitelabel = whitelabelFormatted;
  }

  const config: RequestInit = {
    method: data ? 'POST' : 'GET',
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      ...newHeaders,
      ...customHeaders,
    },
    ...customConfig,
  };

  const response: HttpResponse<T> = await fetch(`${apiAddress}/${endpoint}`, config);
  const isOpenXMLFormats = response.headers
    .get('content-type')
    ?.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

  const isPDF = response.headers.get('content-type')?.includes('application/pdf');

  try {
    if (response.status === 403) {
      response.error = await response.json();
    } else if (isOpenXMLFormats || isPDF) {
      response.parsedBlob = await response.blob();
    } else {
      response.parsedBody = await response.json();
    }
    // eslint-disable-next-line no-empty
  } catch (err) {}

  if (!response.ok) {
    if (response.error?.message === 'Terms of use not accepted by user') {
      window.location.replace('/agreements');
      throw new HTTPError<T>('Terms of use not accepted by user', response);
    }
    throw new HTTPError<T>('Erro na requisição', response);
  }

  return response;
}

client.get = function get<T = any>(endpoint: string, config: FetchConfig) {
  return client<T>(endpoint, { ...config, method: 'GET' });
};

client.post = function post<T = any>(endpoint: string, config: FetchConfig) {
  return client<T>(endpoint, { ...config, method: 'POST' });
};

client.patch = function patch<T = any>(endpoint: string, config: FetchConfig) {
  return client<T>(endpoint, { ...config, method: 'PATCH' });
};
