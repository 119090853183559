import type { FunctionComponent, ReactElement } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { Box, Divider, fade, makeStyles } from '@material-ui/core';

import { parseGroupRouteByRoutes } from 'routes/private/groupedRoutes';
import { AccountSelectors } from 'store/accounts';

import SideMenuNavigationGroup from './Group/SideMenuNavigationGroup';

const useStyles = makeStyles((theme) => ({
  nav: {
    '& div:last-child > hr': {
      display: 'none',
    },
  },
  divider: {
    backgroundColor: fade(theme.palette.secondary.contrastText, 0.08),
  },
}));

const SideMenuNavigation: FunctionComponent = () => {
  const location = useLocation();
  const classes = useStyles();

  const accountUserPortal = useSelector(AccountSelectors.accountUserPortal);
  const accountUserStatus = useSelector(AccountSelectors.accountUserStatus);

  return (
    <Box component="nav" className={classes.nav}>
      {parseGroupRouteByRoutes(accountUserPortal, accountUserStatus).reduce((acc: Array<ReactElement>, curr) => {
        if ('groupName' in curr) {
          acc.push(
            <Box key={curr.groupName}>
              <SideMenuNavigationGroup groupName={curr.groupName} routes={curr.routes} location={location.pathname} />
              <Divider className={classes.divider} />
            </Box>,
          );
          return acc;
        }

        return acc;
      }, [])}
    </Box>
  );
};

export default SideMenuNavigation;
