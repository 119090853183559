import { formatUserDocument } from 'helpers/formatters/formatters';
import { client } from 'services/client';

type AgreementsPending = { data?: { agreementsPending: string[] }; errorMessage?: string };

export const getAgreementsPending = async (): Promise<AgreementsPending> => {
  const localStorageAuthJson = localStorage.getItem('persist:auth') as string;
  const localStorageAuth = JSON.parse(localStorageAuthJson);
  const localStorageAuthData = JSON.parse(localStorageAuth.data);

  const localStorageAccountJson = localStorage.getItem('persist:account') as string;
  const localStorageAccount = JSON.parse(localStorageAccountJson);
  const localStorageAccountData = JSON.parse(localStorageAccount.data);

  try {
    const response = await client.get<AgreementsPending['data']>('portal/v1/agreements', {
      token: localStorageAuthData.accessToken,
      useWhitelabel: true,
      headers: {
        'document-number': formatUserDocument(localStorageAccountData.documentNumber),
      },
    });

    if (response.parsedBody) {
      return { data: response.parsedBody };
    }

    throw new Error('Ocorreu um erro ao buscar os termos e aceites');
  } catch (e) {
    return {
      errorMessage: e.message,
    };
  }
};

type SignAgreements = { data?: { ok: boolean }; errorMessage?: string };

export const signAgreements = async (agreementsPending: string[]): Promise<SignAgreements> => {
  const localStorageAuthJson = localStorage.getItem('persist:auth') as string;
  const localStorageAuth = JSON.parse(localStorageAuthJson);
  const localStorageAuthData = JSON.parse(localStorageAuth.data);

  try {
    const response = await client.post<SignAgreements['data']>('portal/v1/agreements/sign', {
      token: localStorageAuthData.accessToken,
      headers: {
        'content-type': 'application/json',
      },
      data: {
        agreements: agreementsPending,
      },
    });

    if (response.parsedBody) {
      return { data: response.parsedBody };
    }

    throw new Error('Ocorreu um erro ao assinar os termos e aceites');
  } catch (e) {
    return {
      errorMessage: e.message,
    };
  }
};
