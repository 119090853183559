import React, { FunctionComponent } from 'react';

import Alert from '@material-ui/lab/Alert';

type Props = {
  systemAlertText: string;
};

const PageAlertNotification: FunctionComponent<Props> = ({ systemAlertText }) => {
  return <Alert severity="warning">{systemAlertText}</Alert>;
};

export default PageAlertNotification;
