import { PersistState } from 'redux-persist';

import { AccountUserDocument } from './accounts/types';

export const accountMigrations = {
  0: (state: {
    data: {
      email: string;
      nome: string;
      documentNumber: string;
      userDocuments: Array<AccountUserDocument>;
    };
    _persist: PersistState;
  }) => {
    return {
      persisted: {
        email: state.data.email,
        name: state.data.nome,
        documentNumber: state.data.documentNumber,
        userDocuments: state.data.userDocuments,
      },
    };
  },
};
