import React, { FunctionComponent } from 'react';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Typography from 'components/UI/Typography';

const useStyles = makeStyles((theme) => ({
  header: {
    width: '100%',
    backgroundColor: theme.palette.backgroundColor.surface?.marbleDark,
    padding: theme.spacing(3, 2),
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const AnticipationListHeaderMobile: FunctionComponent = () => {
  const classes = useStyles();

  return (
    <Box className={classes.header}>
      <Box display="flex" width="100%">
        <Box width="40%">
          <Typography variant="subtitleWebUppercaseSmall" component="p">
            Data
          </Typography>
        </Box>
        <Box width="40%">
          <Typography variant="subtitleWebUppercaseSmall" component="p">
            Total a receber
          </Typography>
        </Box>
        <Box width="20%">
          <Typography variant="subtitleWebUppercaseSmall" component="p">
            Status
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default AnticipationListHeaderMobile;
