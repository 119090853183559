import React, { FunctionComponent, useEffect, useState } from 'react';

import { Box, Step, StepConnector, StepLabel, Stepper } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import { withStyles } from '@material-ui/styles';

import clsx from 'clsx';
import Typography from 'components/UI/Typography';
import { backgroundColors, brandVariants } from 'themes/colors';

import { activeStepError } from '../constants';
import useStyles from './TrackingStepperMobile.styles';

const TrackingStepperConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 6,
  },
  active: (props) => {
    return {
      '& span': {
        background: props.background,
      },
    };
  },
  completed: {
    '& $line': {
      background: theme.palette.backgroundColor.positive?.primary,
    },
  },

  disabled: {
    '& $line': {
      background: theme.palette.carbon[35],
    },
  },
  line: {
    width: 2,
    border: 0,
    backgroundColor: theme.palette.backgroundColor.neutral?.light,
  },
}))(StepConnector);

type TrackingProps = {
  status: string;
  date?: string;
};

type Props = {
  activeStep: number;
};

const TrackingStepperMobile: FunctionComponent<Props> = ({ activeStep }) => {
  const classes = useStyles();
  const [hasError, setHasError] = useState(false);
  const [lineActiveColor, setLineActiveColor] = useState<string>(backgroundColors.dark.positive.primary);

  const steps = [
    {
      label: 'Em separação',
      date: '',
      rootCss: classes.stepConnector,
      labelCss: classes.firstLabel,
    },
    {
      label: 'Com a transportadora',
      date: '',
      rootCss: classes.stepConnector,
      labelCss: classes.middleLabel,
    },
    {
      label: 'Em rota de entrega',
      date: '',
      rootCss: classes.stepConnector,
      labelCss: classes.middleLabel,
    },
    {
      label: hasError ? 'Falha na entrega' : 'Entregue',
      date: '',
      rootCss: classes.stepConnector,
      labelCss: classes.lastLabel,
    },
  ];

  const renderIcon = (active: boolean, completed: boolean) => {
    if (!hasError && active) {
      return <MoreHorizIcon className={classes.dotActiveIcon} />;
    }

    if (completed) {
      return <CheckIcon className={classes.dotCompletedIcon} />;
    }

    if (hasError && activeStep >= 3) {
      return <PriorityHighIcon className={classes.dotFailureIcon} />;
    }

    return '';
  };

  const renderDot = (active: boolean, completed: boolean) => {
    if (active && !hasError) {
      return (
        <div aria-label="dot" className={clsx(classes.dotRoot, { [classes.dotActive]: active })}>
          {renderIcon(active, completed)}
        </div>
      );
    }
    if (completed) {
      return (
        <div aria-label="dot" className={clsx(classes.dotRoot, { [classes.dotCompleted]: completed })}>
          {renderIcon(active, completed)}
        </div>
      );
    }

    if (hasError && activeStep >= 3) {
      return (
        <div aria-label="dot" className={classes.dotError}>
          {renderIcon(active, completed)}
        </div>
      );
    }

    return (
      <div aria-label="dot" className={clsx(classes.dotRoot, { [classes.dotCompleted]: completed })}>
        {renderIcon(active, completed)}
      </div>
    );
  };

  useEffect(() => {
    if (hasError) {
      setLineActiveColor(brandVariants.carbon[35]);
    }
  }, [hasError]);

  useEffect(() => {
    if (activeStep === activeStepError) {
      setHasError(true);
    }
  }, [activeStep]);

  return (
    <Box>
      <Stepper
        connector={<TrackingStepperConnector background={lineActiveColor} />}
        orientation="vertical"
        activeStep={activeStep}
        classes={{ root: classes.stepConnector }}
      >
        {steps.map((step) => (
          <Step key={step.label} classes={{ root: step.rootCss }}>
            <StepLabel
              classes={{ root: step.labelCss }}
              StepIconComponent={({ active, completed }) => renderDot(active, completed)}
            >
              {step.label}
              <Typography component="p" className={classes.date} variant="body2">
                {step.date}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default TrackingStepperMobile;
