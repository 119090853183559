import type { Nullable } from '@portal-types/helpers';

import type { Brands } from 'models/brands';

export enum SaleStatus {
  APPROVED = 'authorized',
  REFUSED = 'unauthorized',
  CANCELLED = 'cancelled',
  PENDING = 'pending',
  REVERSED = 'reversed',
}

export enum PaymentTypes {
  DEBIT = 'debit',
  CREDIT = 'credit',
  PIX = 'pix',
  E_WALLET = 'e-wallet',
  VOUCHER = 'voucher',
}

export type SaleGroupedRecord = {
  date: string;
  total: number;
  items: SaleItem[];
};

export type SaleGroup = {
  currentPage: number;
  totalPages: number;
  groupedRecords: SaleGroupedRecord[];
};

export type SaleItem = {
  id: string;
  date: string;
  type: PaymentTypes;
  description: Nullable<string>;
  cardNumber: Nullable<string>;
  installments: Nullable<number>;
  method: string;
  value: number;
  status: SaleStatus;
  brand: Brands;
  brandName: Brands;
  acquirerMessage: Nullable<string>;
  brandLabel: string;
  ref: Nullable<string>;
  saleCode: string;
  authorizer: string;
};

export type DateRangeSales = {
  startDate: number;
  endDate: number;
};

export type Filter = {
  endDate: Date;
  startDate: Date;
  method: string;
  status: string;
  date: string;
};

export type SalesSummaryResponse = {
  salesQuantity: number;
  salesAmount: number;
};

export type SalesSummaryV2Response = {
  data?: SalesSummary;
  errorMessage?: string;
};

export type SalesSummary = {
  today: {
    startDate: string;
    endDate: string;
    total: number;
    count: number;
  };
  lastDay: {
    startDate: string;
    endDate: string;
    total: number;
    count: number;
  };
  lastWeek: {
    startDate: string;
    endDate: string;
    total: number;
    count: number;
  };
  lastMonth: {
    startDate: string;
    endDate: string;
    total: number;
    count: number;
  };
};

export type SalesParams = {
  documentNumber: string;
  startDate: Date;
  endDate: Date;
  status?: string;
  paymentType?: string;
  page: number;
  'page-size'?: number;
};

export type SalesGraph = {
  month: number;
  count: number;
};

export type SalesGraphResponse = {
  data?: SalesGraph;
  errorMessage?: string;
};
