import React, { FunctionComponent, useState } from 'react';

import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Snackbar from 'components/UI/Snackbar';
import type { CreatePaymentLink, ResponsePaymentLinkPost } from 'models/paymentLink';
import { postPaymentLink } from 'services/paymentLink';

import PaymentLinkCreateEnd from './End';
import PaymentLinkCreateStart from './Start';

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    padding: theme.spacing(3),
    border: `1px solid ${theme.palette.carbon[10]}`,
    background: theme.palette.common.white,
    borderRadius: 8,
    width: '90%',
    maxWidth: 550,
    overflowY: 'auto',
    outline: 'none',
    height: 'auto',
    maxHeight: '90vh',
  },
}));

type Props = {
  isModalOpen: boolean;
  closeModal: () => void;
  refreshPaymentLink: () => void;
};

const PaymentLinkCreate: FunctionComponent<Props> = ({ isModalOpen, closeModal, refreshPaymentLink }) => {
  const classes = useStyles();
  const [paymentLink, setPaymentLink] = useState<ResponsePaymentLinkPost['data']>();
  const [successMessage, setSuccessMessage] = useState(false);

  const createLink = (data: CreatePaymentLink) => {
    postPaymentLink(data).then((res) => {
      setPaymentLink(res.data);
    });
  };

  return (
    <>
      <Modal
        className={classes.modalContainer}
        open={isModalOpen}
        aria-labelledby="Crie um link de pagamento."
        aria-describedby="Crie links de pagamento e compartilhe com seus clientes."
      >
        <section className={classes.modalContent}>
          {paymentLink ? (
            <PaymentLinkCreateEnd
              id={paymentLink.id}
              url={paymentLink.url}
              expirationDate={paymentLink.expirationDate}
              description={paymentLink.description}
              amount={paymentLink.amount}
              setSuccessMessage={setSuccessMessage}
              createAnotherPaymentLink={() => setPaymentLink(undefined)}
              closeModal={() => {
                closeModal();
                setPaymentLink(undefined);
              }}
              refreshPaymentLink={refreshPaymentLink}
            />
          ) : (
            <PaymentLinkCreateStart createLink={createLink} closeModal={closeModal} />
          )}
        </section>
      </Modal>
      <Snackbar
        message="O link para pagamento foi copiado com sucesso."
        open={successMessage}
        onClose={() => setSuccessMessage(false)}
      />
    </>
  );
};

export default PaymentLinkCreate;
