import React from 'react';

import { Box, Button, Checkbox, Popper, ClickAwayListener, makeStyles, FormControlLabel } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import Typography from 'components/UI/Typography';

const useStyles = makeStyles((theme) => ({
  buttonFilter: {
    height: 32,
    borderRadius: 120,
    padding: '12px',
    border: `solid 1px ${theme.palette.carbon[60]}`,
    textTransform: 'none',
  },
  buttonFilterChecked: {
    height: 32,
    borderRadius: 120,
    padding: '12px',
    border: `solid 1px ${theme.palette.carbon[60]}`,
    textTransform: 'inherit',
    backgroundColor: theme.palette.carbon[100],
    color: theme.palette.marble[100],
    '&:hover': {
      backgroundColor: theme.palette.carbon[100],
      color: theme.palette.marble[100],
    },
  },
  boxFilter: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.carbon[10]}`,
    minWidth: 184,
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: theme.spacing(1),
  },
  filter: {
    padding: 0,
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: theme.palette.primary.main,
    },
  },
  checkbox: {
    padding: theme.spacing(0, 1, 0),
  },
  popper: {
    zIndex: theme.zIndex.modal,
  },
  checkedLabels: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.carbon[100],
    borderRadius: 1000,
    padding: '0px 4px',
    marginRight: theme.spacing(1),
  },
}));

type Props = {
  idPopper: string;
  options: { value: string; label: string }[];
  onChange: (status: string[]) => void;
  canBeOpen?: boolean;
  buttonLabel: string;
  onButtonClick?: (event: React.MouseEvent<HTMLElement>) => void;
  openPopper: boolean;
  setOpenPopper: (open: boolean) => void;
  anchorEl: HTMLElement | null;
  setAnchorEl: (element: HTMLElement | null) => void;
};

const SelectDropdown: React.FunctionComponent<Props> = ({
  idPopper,
  options,
  onChange,
  buttonLabel,
  onButtonClick = () => {},
  openPopper,
  setOpenPopper,
  anchorEl,
  setAnchorEl,
}): React.ReactElement => {
  const classes = useStyles();
  const [checked, setChecked] = React.useState<Set<string>>(new Set());

  const handleClickStatus = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    onButtonClick(event);
  };

  const handleStatusChange = (event: React.ChangeEvent<{ value: string; checked: boolean }>) => {
    const newState = new Set(checked);
    if (event.target.checked) {
      newState.add(event.target.value);
    } else {
      newState.delete(event.target.value);
    }

    setChecked(newState);
  };

  const handleFilterButton = () => {
    if (checked.size > 0) {
      onChange(Array.from(checked));
    }
    setOpenPopper(false);
  };

  const handleClickAway = () => {
    setOpenPopper(false);
  };

  const disableFilterButton = () => checked === null;

  const getButtonLabel = () => {
    if (checked.size > 1) {
      return (
        <>
          <span className={classes.checkedLabels}>{checked.size}</span>
          {buttonLabel}
        </>
      );
    }

    if (checked.size === 1) {
      const labelCheck = options.find(({ value }) => checked.has(value));
      return labelCheck?.label;
    }

    return buttonLabel;
  };

  const handleAllOption = (event: React.ChangeEvent<{ value: string; checked: boolean }>) => {
    const newState = new Set(checked);
    if (event.target.checked) {
      options.forEach((option) => newState.add(option.value));
    } else {
      newState.clear();
    }

    setChecked(newState);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box>
        <Button
          onClick={handleClickStatus}
          className={checked.size ? classes.buttonFilterChecked : classes.buttonFilter}
          endIcon={!openPopper ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
        >
          <Typography variant="body2">{getButtonLabel() as string}</Typography>
        </Button>
        {openPopper ? (
          <Popper
            id={idPopper}
            open={openPopper}
            anchorEl={anchorEl}
            placement="bottom-start"
            className={classes.popper}
          >
            <Box className={classes.boxFilter}>
              <FormControlLabel
                className={classes.filter}
                control={
                  <Checkbox
                    className={classes.checkbox}
                    onChange={handleAllOption}
                    checked={checked.size === options.length}
                  />
                }
                label={<Typography variant="body2">Todos</Typography>}
              />
              {options.map(({ label, value }) => (
                <FormControlLabel
                  className={classes.filter}
                  control={
                    <Checkbox
                      className={classes.checkbox}
                      onChange={handleStatusChange}
                      name={label}
                      value={value}
                      checked={checked?.has(value)}
                    />
                  }
                  label={<Typography variant="body2">{label}</Typography>}
                />
              ))}
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={handleFilterButton}
                disabled={disableFilterButton()}
              >
                Filtrar
              </Button>
            </Box>
          </Popper>
        ) : null}
      </Box>
    </ClickAwayListener>
  );
};

export default SelectDropdown;
