import React, { FunctionComponent } from 'react';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import { Alert, AlertTitle } from '@material-ui/lab';

import Typography from 'components/UI/Typography';
import { backgroundColors } from 'themes/colors';

const useStyles = makeStyles((theme) => ({
  pendingActions: {
    display: 'grid',
    gridGap: theme.spacing(1),
    margin: theme.spacing(3, 0, 0),
  },
  modalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  infoColor: {
    color: backgroundColors.dark.info.primary,
  },
  list: {
    listStyle: 'none',
    '& li:before': {
      content: '"•"',
      fontSize: '1rem',
      paddingRight: theme.spacing(1),
    },
  },
  link: {
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
}));

const SalesGroupSalePending: FunctionComponent = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.pendingActions}>
        <Alert icon={<InfoIcon fontSize="inherit" />} severity="info">
          <AlertTitle className={classes.infoColor}>
            <Typography variant="textButtonBold" component="h1">
              Importante
            </Typography>
          </AlertTitle>
          <Typography variant="textButtonSmall" component="p" className={classes.infoColor}>
            <p>
              {`Por questões de segurança, precisamos de alguns documentos para liberação do recebível pendente. Enviamos
              no seu e-mail de cadastro os documentos necessários para essa transação, não esqueça de conferir também a
              sua caixa de spam.`}
            </p>
            <p>
              {`Caso não tenha recebido, você pode enviar um e-mail para `}
              <strong>riscos.c6pay@c6bank</strong>
              {`. com com a documentação necessária para análise e liberação do
            recebível:`}
            </p>

            <ul className={classes.list}>
              <li>Nota fiscal do produto ou serviço prestado;</li>
              <li>Comprovante de entrega do produto ou serviço prestado;</li>
              <li>Descrição em detalhes do produto ou serviço prestado;</li>
              <li>Demais documentos que comprovem a venda ou serviço prestado.</li>
            </ul>

            <p>
              {`Após o envio da documentação, você receberá um retorno em até `}
              <strong>3 dias úteis</strong>
              {`. `}
            </p>
            <p>
              {`Em caso de dúvidas, entre em contato com a nossa Central de Atendimento pelos telefones `}
              <strong>3003-6116</strong>
              {` (capitais e regiões metropolitanas) ou `}
              <strong>0800 660 6116</strong>
              {` (demais regiões).`}
            </p>
          </Typography>
        </Alert>
      </Box>
    </>
  );
};

export default SalesGroupSalePending;
