import React from 'react';
import { Link } from 'react-router-dom';

import { Grid, Box, makeStyles, FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';
import InfoRounded from '@material-ui/icons/InfoRounded';
import { Alert } from '@material-ui/lab';

import PageSection from 'components/PageSection';
import Button from 'components/UI/Button';
import Typography from 'components/UI/Typography';
import { ChargerType, Order, PAGES } from 'models/machine';
import { createServiceOrder } from 'services/serviceOrders';

import { OrderType, ChangeReasonOptions } from '../constants';

const useStyles = makeStyles((theme) => ({
  formControlLabel: {
    background: theme.palette.backgroundColor.surface?.marbleDark,
    border: `1px solid ${theme.palette.carbon[10]}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  checkAddressLink: {
    color: theme.palette.carbon[100],
  },
  card: {
    border: `1px solid ${theme.palette.carbon[10]}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(4),
    background: theme.palette.backgroundColor.surface?.secondary,
  },
  formControlWrapper: {
    margin: theme.spacing(3, 0),
    width: '100%',
  },
  formControl: {
    margin: theme.spacing(1, 0),
    width: '100%',
  },
  buttonWrapper: {
    marginTop: theme.spacing(4),
    '& > button:first-child': {
      marginBottom: theme.spacing(1),
    },
  },
}));

type ChargesProps = {
  setPage: React.Dispatch<React.SetStateAction<PAGES>>;
  setCode: React.Dispatch<React.SetStateAction<number>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setOrder: React.Dispatch<React.SetStateAction<Order>>;
  setOrderType: React.Dispatch<React.SetStateAction<OrderType | undefined>>;
  order: Order;
  loading: boolean;
  handleBack: () => void;
  chargerList: string[];
  chargerListLoading: boolean;
};

const Chargers = ({
  setPage,
  setCode,
  setLoading,
  loading,
  setOrder,
  order,
  setOrderType,
  handleBack,
  chargerList,
  chargerListLoading,
}: ChargesProps): JSX.Element => {
  const classes = useStyles();

  const [charger, setCharger] = React.useState<ChargerType>('');

  const [reasonChange, setReasonChange] = React.useState<string>('');

  const [, setSuccess] = React.useState<boolean>(false);

  const handleChangeCharger = (event: React.ChangeEvent<{ value: unknown }>): void => {
    setCharger(event.target.value as ChargerType);
    setOrder({ ...order, charger: event.target.value as ChargerType });
  };

  const handleChangeReasonChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    setReasonChange(event.target.value as string);
    setOrder({ ...order, reason: event.target.value as string });
  };

  const handleSubmit = (): void => {
    setLoading(true);
    try {
      createServiceOrder({ charger, reasonChange })
        .then(({ data }) => {
          if (data?.code) {
            setOrderType(OrderType.CHARGER);
            setPage(PAGES.SUCCESS);
            setCode(data.code);
          }
        })
        .catch(() => {
          setSuccess(false);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setSuccess(false);
    }
  };

  return (
    <Grid container spacing={2} justify="center">
      <Box width={504} className={classes.card}>
        <PageSection title="Pedido de carregadores">
          <Typography variant="body">
            Preencha as informações do formulário para solicitar a substituição do carregador para a sua maquininha.
          </Typography>
          <div className={classes.formControlWrapper}>
            <Typography variant="bodyBold">Qual o modelo da maquininha?</Typography>
            <FormControl component="fieldset" variant="outlined" className={classes.formControl}>
              <InputLabel id="selectModel">Modelo da maquininha?</InputLabel>
              <Select
                labelId="selectModel"
                label="Modelo da maquininha?"
                value={charger}
                onChange={handleChangeCharger}
              >
                {chargerList?.map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography variant="bodyBold">Qual o defeito com o seu carregador?</Typography>
            <FormControl component="fieldset" variant="outlined" className={classes.formControl}>
              <InputLabel id="selectReason">Motivo da troca?</InputLabel>
              <Select
                labelId="selectReason"
                label="Motivo da troca?"
                value={reasonChange}
                onChange={handleChangeReasonChange}
              >
                {ChangeReasonOptions.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <Alert severity="info" icon={<InfoRounded color="inherit" />}>
            <Typography variant="body2" gutterBottom>
              {`Seu pedido será entregue no endereço do estabelecimento cadastrado em nosso sistema. `}
              <Link to="/account" className={classes.checkAddressLink}>
                <Typography variant="bodylink">Confira seu endereço de entrega</Typography>
              </Link>
            </Typography>
            <Typography variant="body2">
              Prazo de entrega: 5 dias para regiões Sul, Sudeste e Centro Oeste e 7 dias para regiões Norte e Nordeste.
            </Typography>
          </Alert>
          <Grid container justify="center" className={classes.buttonWrapper}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              disabled={!charger || !reasonChange || loading || chargerListLoading || !chargerList.length}
              onClick={handleSubmit}
            >
              Solicitar carregador
            </Button>
            <Button fullWidth variant="outlined" size="large" onClick={handleBack}>
              Voltar
            </Button>
          </Grid>
        </PageSection>
      </Box>
    </Grid>
  );
};

export default Chargers;
