import React from 'react';
import { Link } from 'react-router-dom';

import HomePrint from 'assets/images/home-print.png';
import Typography from 'components/UI/Typography';
import { FaqType } from 'models/faq';

export const faqList: FaqType = [
  {
    doubt: 'Conheça mais sobre os relatórios do Portal C6 Pay',
    doubtList: [
      {
        title: 'Performance dos relatórios de Vendas e Recebíveis',
        answer: (
          <Typography variant="body" paragraph>
            Caro cliente, estamos trabalhando para melhorar a performance dos relatórios, logo pode acontecer do seu seu
            relatório não ser gerado pela grande quantidade de registros vide erro: “Houve um erro ao buscar o
            relatório. Tente novamente mais tarde.” Nestes casos, recomendamos que filtrem períodos menores, caso ainda
            assim não seja possível, procure nossa Central de Atendimento.
          </Typography>
        ),
      },
    ],
  },
  {
    doubt: 'Conheça mais sobre C6 Pay Link',
    doubtList: [
      {
        title: 'O que é o Link de Pagamento C6?',
        answer: (
          <>
            <Typography variant="body" paragraph>
              Mais uma opção de pagamento para facilitar e otimizar as vendas do seu cliente.
            </Typography>
            <Typography variant="body" paragraph>
              O Link de Pagamento C6 é uma ferramenta de venda que permite o envio de uma cobrança online por e-mail,
              WhatsApp ou qualquer rede social, sem a necessidade de ter um site.
            </Typography>
            <ul>
              <li>
                <Typography variant="body">Sem taxa de adesão;</Typography>
              </li>
              <li>
                <Typography variant="body">
                  Solução segura e simples de usar diretamente no Portal C6 Pay e App C6 Bank;
                </Typography>
              </li>
              <li>
                <Typography variant="body">
                  Não é necessário ter um site próprio e nem maquininha de cartão presente no momento da venda;
                </Typography>
              </li>
              <li>
                <Typography variant="body">Indicado para qualquer tipo de negócio.</Typography>
              </li>
            </ul>
          </>
        ),
      },
      {
        title: 'Quais são as taxas do Link de Pagamento C6?',
        answer: (
          <Typography variant="body" paragraph>
            Você paga R$ 0,50 por venda confirmada no Link de Pagamento C6, além das taxas por cada tipo de compra.
            Consulte suas condições comerciais e taxas no menu “Taxa” no Portal C6 Pay.
          </Typography>
        ),
      },
      {
        title: 'Para quem é indicado o Link de Pagamento C6?',
        answer: (
          <Typography variant="body">
            Para todos os clientes que desejam vender online sem precisar de um site ou maquininha de cartão presente no
            momento da venda.
          </Typography>
        ),
      },
      {
        title: 'Como contratar o Link de Pagamento C6?',
        answer: (
          <Typography variant="body" paragraph>
            O Link de Pagamento C6 já vem habilitado para todos os clientes que adquirem uma maquininha C6 Pay. Consulte
            as condições exclusivas do Link de Pagamento no menu “Taxas” do Portal C6 Pay.
          </Typography>
        ),
      },
      {
        title: 'Como o C6 Pay Link pode ser enviado?',
        answer: (
          <Typography variant="body" paragraph>
            Pode ser enviado através de Redes Sociais, WhatsApp, E-mail e SMS.
          </Typography>
        ),
      },
      {
        title: 'O C6 Pay Link aceita quais formas de pagamento?',
        answer: (
          <Typography variant="body" paragraph>
            O estabelecimento pode optar por débito, crédito à vista e crédito parcelado em até 12X sem juros.
          </Typography>
        ),
      },
    ],
  },
  {
    doubt: 'Conheça mais sobre os recebíveis',
    doubtList: [
      {
        title: 'Tipos de Operações',
        answer: (
          <>
            <Typography variant="body" gutterBottom paragraph>
              Encontrou uma transação e não entendeu o que ela significa? Confira abaixo os Tipos de Operações
              existentes em nosso dia a dia e clique para conhecer mais.
            </Typography>
            <Typography variant="subtitleMobile2" gutterBottom>
              Crédito à Vista ou Parcelado
            </Typography>
            <Typography variant="body" gutterBottom paragraph>
              Quando o cliente paga uma compra com o cartão de crédito, ele fará o pagamento em uma data futura de
              acordo com a data de vencimento do cartão utilizado. O pagamento poderá ser feito à vista (em 1 parcela)
              ou em até 12 parcelas, sem cada uma delas, cobrada por mês do titular do cartão.
            </Typography>
            <Typography variant="subtitleMobile2" gutterBottom>
              Débito
            </Typography>
            <Typography variant="body" gutterBottom paragraph>
              Quando o cliente paga uma compra com o cartão de débito, o valor é descontado do saldo da conta bancária
              dele, na hora.
            </Typography>
            <Typography variant="subtitleMobile2" gutterBottom>
              Cancelamento de Compra
            </Typography>
            <Typography variant="body" gutterBottom paragraph>
              Ocorre quando o cliente desiste de uma compra realizada e solicita que essa venda seja cancelada. O valor
              da compra é devolvido ao titular do cartão.
            </Typography>
            <Typography variant="subtitleMobile2" gutterBottom>
              Taxa de Adesão
            </Typography>
            <Typography variant="body" gutterBottom paragraph>
              A taxa de adesão é o valor cobrado em uma contratação de um serviço e/ou produto. Ela poderá ser cobrada
              no ato da contratação ou de forma parcelada, durante os meses negociados.
            </Typography>
            <Typography variant="subtitleMobile2" gutterBottom>
              Chargeback ou Contestação de Compra
            </Typography>
            <Typography variant="body" gutterBottom paragraph>
              Chargeback ou Contestação de Compra é quando o titular do cartão não reconhece uma compra realizada ou não
              recebeu o produto ou serviço que comprou.
            </Typography>
            <Typography variant="body" gutterBottom paragraph>
              Também é possível ter ocorrido algum erro de processamento da compra.
            </Typography>
            <Typography variant="body" gutterBottom paragraph>
              Nesse caso o titular pode solicitar a verificação da compra junto ao banco emissor do cartão e abre um
              &ldquo;processo de disputa&ldquo;, onde o estabelecimento deverá provar que a compra foi de fato
              realizada. Caso isso não aconteça, o valor da compra é estornado e o estabelecimento deverá enviar o valor
              do recebível pago anteriormente pela operadora da maquininha ao titular do cartão.
            </Typography>
            <Typography variant="subtitleMobile2" gutterBottom>
              Remuneração de Saque
            </Typography>
            <Typography variant="body" gutterBottom paragraph>
              Remuneração de saque é um benefício pago pela C6 Pay por cada saque realizado em seu estabelecimento
              através da maquininha pelos clientes com conta na C6 Bank.
            </Typography>
            <Typography variant="subtitleMobile2" gutterBottom>
              Aluguel de POS
            </Typography>
            <Typography variant="body" gutterBottom paragraph>
              Aluguel de POS (Ponto de venda) ocorre quando o estabelecimento aluga uma maquininha sendo cobrado
              mensalmente por essa locação, bem como pelos demais serviços que contratar.
            </Typography>
            <Typography variant="body" gutterBottom paragraph>
              O estabelecimento tem um prazo pré-estabelecido para devolução do equipamento ou renovação do contrato.
            </Typography>
            <Typography variant="subtitleMobile2" gutterBottom>
              Repasse da sua agenda de Recebíveis
            </Typography>
            <Typography variant="bodyBold" gutterBottom paragraph>
              Quem usa os recebíveis das vendas feitas pela maquininha C6 Pay, pode acompanhar os repasses de valores
              que acontecem em 4 situações:
            </Typography>
            <Typography variant="bodyBold">Antecipação de recebíveis:</Typography>
            <Typography variant="body" gutterBottom paragraph>
              O cliente pode antecipar a sua agenda de recebíveis com outras instituições financeiras, recebendo os
              valores das vendas antes da data de liquidação das bandeiras mediante o pagamento de taxas sobre o valor
              antecipado, sendo os valores originais debitados de sua agenda na data original de liquidação.
            </Typography>
            <Typography variant="bodyBold">Concessão de crédito:</Typography>
            <Typography variant="body" gutterBottom paragraph>
              É similar a uma operação de empréstimo, na qual é concedido um crédito ao cliente cujas garantias incluem
              os recebíveis de cartão dados à instituição financeira por meio de cessão fiduciária, penhor ou outro
              instrumento de garantia, sendo valores originais debitados de sua agenda na data original de liquidação.
            </Typography>
            <Typography variant="bodyBold">Operações de ônus sobre recebíveis:</Typography>
            <Typography variant="body" gutterBottom paragraph>
              É qualquer outro tipo de operação de ônus sobre o recebível que não se enquadra juridicamente na cessão
              fiduciária. Ex: Algumas credenciadoras / subcredenciadoras, utilizam este tipo de operação para o que elas
              chamam de “colchão” ou “reserva financeira”, ou seja, é um valor que está bloqueado da unidade de
              recebível por algum motivo e que não será pago ao Estabelecimento Comercial. Desta forma, por meio da
              operação do tipo ônus – outros, a credenciadora/sub (ex: a C6Pay / Paygo / SolPay ou outras maquininhas)
              faz com que este valor não fique livre para as instituições financeiras, pois não será pago ao
              Estabelecimento Comercial, sendo os valores originais debitados de sua agenda na data original de
              liquidação.
            </Typography>
            <Typography variant="bodyBold">Bloqueio judicial:</Typography>
            <Typography variant="body" gutterBottom paragraph>
              É quando o cliente possui uma dívida e essa cobrança é feita a partir de um processo judicial que
              &ldquo;congela&ldquo; seus recebíveis, sendo os valores originais debitados de sua agenda na data original
              de liquidação.
            </Typography>
            <Typography variant="bodyBold">Unidade de Recebível Negativa (UR):</Typography>
            <Typography variant="body" gutterBottom paragraph>
              Podem aparecer valores negativos na linha que consolidam suas transações por bandeira, isso acontece por
              conta de intermitências em nossos serviços, mas fique tranquilo, se encontrar qualquer irregularidade no
              Portal C6 Pay, procure nossa Central de Atendimento.
            </Typography>

            <Typography variant="subtitleMobile2" gutterBottom>
              Correção de Taxa
            </Typography>
            <Typography variant="body" gutterBottom paragraph>
              Correção de taxa é um ajuste em seus recebíveis quando há alguma negociação entre o estabelecimento e a
              operadora da maquininha.
            </Typography>
            <Typography variant="subtitleMobile2" gutterBottom>
              Estorno
            </Typography>
            <Typography variant="body" gutterBottom paragraph>
              Estorno acontece quando o valor da venda é devolvido ao cliente pagador, ao cancelar uma compra ou
              cobrança indevida.
            </Typography>
            <Typography variant="subtitleMobile2" gutterBottom>
              Ajuste à crédito
            </Typography>
            <Typography variant="body" gutterBottom paragraph>
              O Ajuste a crédito é uma correção positiva de algum valor ao estabelecimento. Pode acontecer caso algum
              processamento seja feito de forma inadequada ou até por uma negociação realizada.
            </Typography>
            <Typography variant="subtitleMobile2" gutterBottom>
              Ajuste à débito
            </Typography>
            <Typography variant="body" gutterBottom paragraph>
              O Ajuste a débito é uma correção negativa de algum valor ao estabelecimento. Pode acontecer caso algum
              processamento seja feito de forma inadequada ou até por uma negociação realizada.
            </Typography>
            <Typography variant="subtitleMobile2" gutterBottom>
              Cashback
            </Typography>
            <Typography variant="body" gutterBottom paragraph>
              Cashback é quando o estabelecimento está participando de alguma campanha e, mediante o atingimento de
              metas ou demais condições, recebe um valor de volta em sua agenda de recebíveis.
            </Typography>
          </>
        ),
      },
    ],
  },
  {
    doubt: 'Conheça mais sobre a DIRF',
    doubtList: [
      {
        title: 'DIRF - Declaração de Imposto de Renda Retido na Fonte',
        answer: (
          <>
            <Typography variant="subtitleMobile2" gutterBottom>
              O que é a DIRF?
            </Typography>
            <Typography variant="body" paragraph>
              É a Declaração do Imposto de Renda Retido na Fonte (DIRF). Uma obrigação tributária acessória que deve ser
              preenchida e entregue de acordo com as normas editadas pela Receita Federal.
            </Typography>
            <Typography variant="body" paragraph>
              É um documento que possui as informações das comissões que a Paygo recebe de seus clientes consolidadas
              mês a mês, sobre os seus serviços prestados (não incluindo o aluguel).
            </Typography>
            <Typography variant="body" paragraph>
              No documento, as informações vão aparecer referentes a cada mês do ano escolhido.
            </Typography>
            <Typography variant="subtitleMobile2" gutterBottom>
              Onde você encontra a DIRF?
            </Typography>
            <Typography variant="body">Pelo Portal do Cliente na Web:</Typography>
            <ul>
              <li>
                <Typography variant="bodyBold" gutterBottom paragraph>
                  Acesse:
                  <Link to="/resume"> a home</Link>
                </Typography>
              </li>
              <li>
                <Typography variant="bodyBold" gutterBottom paragraph>
                  Na tela de Resumos, no canto super direito escolha a Opção “Baixar DIRF”
                </Typography>
              </li>
              <li>
                <Typography variant="bodyBold" gutterBottom paragraph>
                  Em seguida, selecione o Ano da DIRF que deseja baixar, caso queira você poderá selecionar mais de um
                  período;
                </Typography>
              </li>
              <li>
                <Typography variant="bodyBold" gutterBottom paragraph>
                  Só clicar em &ldquo;Baixar DIRF&ldquo; e pronto! A DIRF já estará salva no seu computador!
                </Typography>
              </li>
            </ul>
            <img src={HomePrint} alt="print da home do portal de estabelecimentos" style={{ maxWidth: 612 }} />

            <Typography variant="subtitleMobile2" gutterBottom>
              Informações gerais sobre a DIRF 2022
            </Typography>
            <Typography variant="bodyBold" gutterBottom>
              Prazo para entrega à Receita Federal: 23:59h do dia 28 de fevereiro de 2022
            </Typography>

            <Typography variant="subtitleSmall" gutterBottom>
              Dúvidas Frequentes
            </Typography>
            <ol>
              <li>
                <Typography variant="bodyBold">Como é calculado a DIRF?</Typography>
              </li>
              <Typography variant="body" paragraph>
                A DIRF é calculada com base no MDR Liquido Composto pela taxa de MDR sobre a transação, subtraindo os
                custos financeiros e da subadquirência, em que a C6Pay reteve o imposto de 1,5%.
              </Typography>
              <Typography variant="body" paragraph>
                Para exemplificar:
              </Typography>
              <Typography variant="body" paragraph>
                Digamos que o nosso cliente passou em seu equipamento uma compra no valor de R$10 reais e a taxa de MDR
                dele foi de R$ 1,00. Desses R$1,00, teremos que repassar R$ 0,50 centavos para os subadquirentes que nos
                auxiliam na aprovação. Nesse exemplo, o lucro da C6Pay foi de R$0,50 centavos e iremos reter 1,5% desse
                lucro final, no caso os R$ 0,0075 centavos. Esse valor final é o que constará na DIRF.
              </Typography>
              <Typography variant="body" paragraph>
                Se o valor que for repassado para as SubAdquirentes for igual ou maior do que o MDR que a C6 Pay retém
                das vendas, nesse exemplo, se for maior ou igual ao R$ 1,00, a C6 Pay não obteve lucro e por esse
                motivo, esse valor não constaria na DIRF.
              </Typography>
              <li>
                <Typography variant="bodyBold">
                  Vou precisar baixar vários DIRFs se eu tiver mais de um cadastro no mesmo CNPJ?
                </Typography>
              </li>
              <Typography variant="body" paragraph>
                Não, pois a DIRF é atrelada ao documento do cadastro (CNPJ/CPF).
              </Typography>
              <li>
                <Typography variant="bodyBold">Qual a diferença entre DIRF e Informe de Rendimentos?</Typography>
              </li>
              <Typography variant="body" paragraph>
                A DIRF é um documento obrigatório que deve ser enviado por todas as pessoas jurídicas (e também físicas,
                em algumas situações) que realizaram pagamentos a terceiros com retenção de Imposto de Renda (IR) e
                tributos, como Contribuição Social, PIS e COFINS.
              </Typography>
              <Typography variant="body" paragraph>
                Ela serve, principalmente, para informar para a Receita o valor dos impostos e das outras contribuições
                que foram pagas pelas empresas, com o intuito evitar a sonegação de impostos.
              </Typography>
              <Typography variant="body" paragraph>
                Ou seja, se você tem uma empresa, a DIRF deve informar o quanto você pagou de IR e outros tributos sobre
                o salário dos funcionários e também sobre o pagamento de prestadores de serviços durante um determinado
                período. Além disso, também entram na DIRF:
              </Typography>
              <ul>
                <li>Os rendimentos pagos a pessoas físicas residentes no país;</li>
                <li>
                  O imposto sobre a renda e contribuições retidos na fonte, dos rendimentos pagos ou creditados para
                  seus beneficiários;
                </li>
                <li>O pagamento ou envio de dinheiro a outras pessoas no exterior;</li>
                <li>O pagamento a planos de saúde coletivo empresarial;</li>
                <li>OO pagamento de fretes;</li>
                <li>O pagamento de aluguéis;</li>
                <li>Juros e comissões.</li>
              </ul>
              <Typography variant="body" paragraph>
                Já o Informe de Rendimentos é um documento enviado durante a Declaração de Imposto de Renda de Pessoa
                Física. Como o próprio nome diz, o IRPF deve ser declarado por pessoas físicas e não pelas empresas!
              </Typography>
              <li>
                <Typography variant="bodyBold">E se a minha DIRF vier zerada ou em branco?</Typography>
              </li>
              <Typography variant="body" paragraph>
                Caro cliente, sua DIRF poderá vir sem valor ou em branco, isso ocorre quando não há movimentação
                financeira, e consequentemente, não há emissão de nota fiscal por parte da PayGo. Caso tenha dúvidas,
                entre em contato através da nossa Central de Atendimento.
              </Typography>
            </ol>
          </>
        ),
      },
    ],
  },
];
