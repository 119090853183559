import React, { useState, useEffect } from 'react';
import type { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { useSpringCarousel } from 'react-spring-carousel-js';

import { Box, Card } from '@material-ui/core';
import { fade, makeStyles } from '@material-ui/core/styles';
import {
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  ArrowForwardRounded,
  FiberManualRecord,
} from '@material-ui/icons';

import IphoneBanner from 'assets/images/iphoneBanner.png';
import MacbookBanner from 'assets/images/macbookBanner.png';
import Typography from 'components/UI/Typography';

const useStyles = makeStyles((theme) => ({
  carouselFragmentWrapper: {
    height: 212,
    '& > div': {
      height: '100%',
    },
  },
  card: {
    background: `linear-gradient(275.03deg, ${theme.palette.carbon[100]} 0.98%, ${theme.palette.common.black} 71.09%)`,
    padding: theme.spacing(4),
    width: '100%',
    height: '100%',
    color: theme.palette.common.white,
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
  },
  pageSection: {
    backgroundColor: theme.palette.backgroundColor.surface?.secondary,
    border: `1px solid ${theme.palette.carbon[10]}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
  },
  primary: {
    color: fade(theme.palette.primary.contrastText, 0.9),
    background: `linear-gradient(105.23deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
    '&:hover': {
      background: 'unset',
      backgroundColor: theme.palette.primary.main,
    },
    '&:focus': {
      background: 'unset',
      backgroundColor: theme.palette.primary.main,
    },
    '& .MuiTouchRipple-rippleVisible': {
      opacity: 0.75,
    },
    '& .MuiTouchRipple-child': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  titleCard: {
    marginBottom: theme.spacing(2),
  },
  wrapperThumbs: {
    display: 'flex',
    alignContent: 'center',
    height: 32,
    '& > div': {
      justifyContent: 'space-between',
      alignContent: 'center',
      overflow: 'hidden',
      overflowX: 'hidden !important',
      width: 'auto',
    },
    '& > div > div': {
      width: theme.spacing(2),
      display: 'flex',
      justifyContent: 'center',
    },
  },
  activeItem: {
    background: 'transparent',
    border: 'none',
    height: 16,
    padding: 0,
    color: theme.palette.carbon[35],
    '& > svg': {
      height: 16,
      width: 16,
    },
  },
  item: {
    background: 'transparent',
    border: 'none',
    height: 16,
    padding: 0,
    cursor: 'pointer',
    color: theme.palette.carbon[10],
    '& > svg': {
      height: 16,
      width: 16,
    },
  },
  subtitleBanner: {
    color: theme.palette.marble[60],
  },
  arrowButtonBanner: {
    width: 16,
    height: 16,
    cursor: 'pointer',
  },
  iconButtonWrapper: {
    display: 'flex',
    alignItems: 'end',
    position: 'absolute',
    width: 'calc(100% - 32px)',
    height: 'calc(100% - 32px)',
    flexWrap: 'nowrap',
    flexDirection: 'column',
    left: 16,
    bottom: 16,
    justifyContent: 'flex-end',
  },
  iconButton: {
    borderRadius: 24,
    width: 24,
    height: 24,
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.primary.main,
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
  cardLink: {
    cursor: 'pointer',
    textDecoration: 'none',
  },
}));

// const zendeskUrl = 'https://ajuda.paygo.com.br/hc/pt-br/requests/new?ticket_form_id=1500002516701';

// const BannerZenDesk: FunctionComponent = () => {
//   const classes = useStyles();

//   return (
//     <Card className={classes.card}>
//       <Box display="flex" flexDirection="column" justifyContent="space-between" width="100%">
//         <Box display="flex" justifyContent="center" flexDirection="column">
//           <Typography variant="subtitleMobile" style={{ marginBottom: 8 }} align="left">
//             Precisando de bobina ou trocar o carregador da maquininha?
//           </Typography>

//           <Typography variant="paragraph" component="span" align="left" className={classes.subtitleBanner}>
//             Agora você pode solicitar os acessórios da maquininha direto por aqui.
//           </Typography>
//           <Box className={classes.iconButtonWrapper}>
//             <Box
//               aria-label="Solicitar bobina ou troca de carregador para maquininha de cartão."
//               className={classes.iconButton}
//             >
//               <ArrowForwardRounded color="secondary" />
//             </Box>
//           </Box>
//         </Box>
//       </Box>
//     </Card>
//   );
// };

const BannerRateSimulator: FunctionComponent = () => {
  const classes = useStyles();

  return (
    <Card aria-label="Ir para simulador de taxas" className={classes.card}>
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box display="flex" justifyContent="center" flexDirection="column" maxWidth="185px">
          <Typography variant="subtitleMobile" paragraph align="left">
            Simulador de taxas C6 Pay
          </Typography>
          <Typography variant="paragraph" component="p" align="left" className={classes.subtitleBanner}>
            Descubra a melhor taxa para sua venda.
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <img src={MacbookBanner} alt="Imagem macbook." />
        </Box>
        <Box className={classes.iconButtonWrapper}>
          <Box className={classes.iconButton}>
            <ArrowForwardRounded color="secondary" />
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

const BannerC6PayLink: FunctionComponent = () => {
  const classes = useStyles();
  return (
    <Card aria-label="Ir para página de link de pagamentos." className={classes.card}>
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box display="flex" justifyContent="center" flexDirection="column" maxWidth="185px">
          <Typography variant="subtitleMobile" paragraph align="left">
            C6 Pay link
          </Typography>
          <Typography variant="paragraph" component="p" align="left" className={classes.subtitleBanner}>
            Venda online com segurança e sem taxa de adesão.
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <img src={IphoneBanner} alt="Imagem iphone." />
        </Box>
        <Box className={classes.iconButtonWrapper}>
          <Box className={classes.iconButton}>
            <ArrowForwardRounded color="secondary" />
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

const BannerCarousel: FunctionComponent = () => {
  const classes = useStyles();
  const [itemIsActive, setItemIsActive] = useState('CarouselItem-1');

  const {
    carouselFragment,
    thumbsFragment,
    slideToPrevItem,
    slideToNextItem,
    slideToItem,
    getCurrentActiveItem,
    useListenToCustomEvent,
  } = useSpringCarousel({
    initialActiveItem: 0,
    withLoop: true,
    withThumbs: true,
    items: [
      {
        id: 'CarouselItem-1',
        renderItem: (
          <Link aria-label="Ir para página de link de pagamentos." to="/payment-link" className={classes.cardLink}>
            <BannerC6PayLink />
          </Link>
        ),
        renderThumb: (
          <button
            type="button"
            className={itemIsActive === 'CarouselItem-1' ? classes.activeItem : classes.item}
            onClick={() => slideToItem('CarouselItem-1')}
          >
            <FiberManualRecord />
          </button>
        ),
      },
      {
        id: 'CarouselItem-2',
        renderItem: (
          <Link aria-label="Ir para simulador de taxas" to="/tax-simulator" className={classes.cardLink}>
            <BannerRateSimulator />
          </Link>
        ),
        renderThumb: (
          <button
            type="button"
            className={itemIsActive === 'CarouselItem-2' ? classes.activeItem : classes.item}
            onClick={() => slideToItem('CarouselItem-2')}
          >
            <FiberManualRecord />
          </button>
        ),
      },
      // {
      //   id: 'CarouselItem-3',
      //   renderItem: (
      //     <a
      //       aria-label="Solicitar bobina ou troca de carregador para maquininha de cartão."
      //       target="_blank"
      //       href={zendeskUrl}
      //       className={classes.cardLink}
      //       rel="noopener noreferrer"
      //     >
      //       <BannerZenDesk />
      //     </a>
      //   ),
      //   renderThumb: (
      //     <button
      //       type="button"
      //       className={itemIsActive === 'CarouselItem-3' ? classes.activeItem : classes.item}
      //       onClick={() => slideToItem('CarouselItem-3')}
      //     >
      //       <FiberManualRecord />
      //     </button>
      //   ),
      // },
    ],
  });

  useListenToCustomEvent((event) => {
    if (event.eventName === 'onSlideStartChange') {
      const activeSlide = getCurrentActiveItem();
      setItemIsActive(activeSlide.id);
    }
  });

  useEffect(() => {
    const timer = setInterval(() => slideToNextItem(), 4000);
    return () => clearInterval(timer);
  }, [slideToNextItem]);

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="bodyBold" className={classes.titleCard}>
          Destaques
        </Typography>
        <Box display="flex">
          <ArrowBackIosOutlined type="prev" onClick={slideToPrevItem} className={classes.arrowButtonBanner} />
          <div className={classes.wrapperThumbs}>{thumbsFragment}</div>
          <ArrowForwardIosOutlined type="next" onClick={slideToNextItem} className={classes.arrowButtonBanner} />
        </Box>
      </Box>
      <Box className={classes.carouselFragmentWrapper}>{carouselFragment}</Box>
    </>
  );
};

export default BannerCarousel;
