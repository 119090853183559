import React from 'react';
import type { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import { Box, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { CredentialsSelectors } from 'store/auth/credentials';

const useStyles = makeStyles(() => ({
  linearLoading: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
}));

const AuthLoading: FunctionComponent = () => {
  const classes = useStyles();
  const authLoading = useSelector(CredentialsSelectors.isCredentialsLoading);

  return (
    <Box className={classes.linearLoading}>
      {authLoading ? (
        <LinearProgress data-testid="loading-linear" aria-label="Barra de carregamento do autenticação" />
      ) : null}
    </Box>
  );
};

export default AuthLoading;
