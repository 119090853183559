import { whitelabelDomain } from 'config';

import { WhitelabelConfig } from '../models/whitelabelConfig';
import { client } from './client';

type WhitelabelConfigResponse = {
  data?: WhitelabelConfig;
  errorMessage?: string;
};

export const getWhitelabelConfigs = async (): Promise<WhitelabelConfigResponse> => {
  const headers = whitelabelDomain ? { domain: whitelabelDomain } : undefined;

  try {
    const { parsedBody: body } = await client.get<WhitelabelConfig>(`portal/v1/whitelabel/config`, {
      useWhitelabel: true,
      headers,
    });

    if (body) {
      return { data: body };
    }

    throw new Error('Ocorreu um erro ao buscar as configurações de whitelabel');
  } catch (e) {
    return {
      errorMessage: 'Ocorreu um erro ao buscar as configurações de whitelabel',
    };
  }
};
