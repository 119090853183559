import type { FunctionComponent } from 'react';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import AuthForm from 'components/Auth/Forms/Login';
import AuthFormsServerUnavailable from 'components/Auth/Forms/ServerUnavailable';
import { useAppDispatch } from 'store';
import { CredentialsActions, CredentialsSelectors } from 'store/auth/credentials';
import { WhitelabelSelectors } from 'store/whitelabel';

const AuthLoginPassword: FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const authError = useSelector(CredentialsSelectors.credentialsError);
  const authUnavailableService = useSelector(CredentialsSelectors.isCredentialUnavailableService);
  const isLoading = useSelector(CredentialsSelectors.isCredentialsLoading);

  const whitelabelLinks = useSelector(WhitelabelSelectors.whitelabelLinks)!;

  useEffect(() => {
    return () => {
      dispatch(CredentialsActions.clearError());
    };
  }, [dispatch]);

  const submit = (email: string, password: string, reCaptchaResponse: string) => {
    dispatch(
      CredentialsActions.login({
        email,
        password,
        reCaptchaResponse,
      }),
    );
  };

  if (authUnavailableService) {
    return <AuthFormsServerUnavailable onTryAgain={() => navigate('/', { replace: true })} />;
  }

  return (
    <AuthForm
      submitLogin={submit}
      errorMessage={authError.message as string}
      errorMessageLink={authError.messageLink as string}
      isLoading={isLoading}
      acquirerUrl={whitelabelLinks.acquire}
    />
  );
};
export default AuthLoginPassword;
