import type { FunctionComponent } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import AuthAccessAccount from 'components/Auth/Forms/AccountAccess';
import { useAppDispatch } from 'store';
import { AccountActions, AccountSelectors } from 'store/accounts';

const AccountAccess: FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const accountUserDocuments = useSelector(AccountSelectors.accountUserDocuments) || [];

  const submit = (documentNumber: string) => {
    const userDocument = accountUserDocuments.find((user) => user.documentNumber === documentNumber);

    dispatch(AccountActions.changeUserStatus(userDocument?.status!));
    dispatch(AccountActions.changeDocumentNumber(documentNumber));
    dispatch(AccountActions.changeUserPortal(userDocument?.portalType!));
    dispatch(AccountActions.changeUserName(userDocument?.corporateName!));
    navigate('/', { replace: true });
  };

  return (
    <AuthAccessAccount
      submit={submit}
      error={false}
      loading={false}
      userDocuments={accountUserDocuments?.map((ac) => ({
        documentNumber: ac.documentNumber,
        corporateName: ac.corporateName || 'Nome indisponível',
      }))}
      title="Acessar conta"
    />
  );
};
export default AccountAccess;
