import React, { FunctionComponent } from 'react';

import { Box, InputAdornment } from '@material-ui/core';
import { fade, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';

import SideMenuAvatar from 'components/SideMenu/Avatar';
import Typography from 'components/UI/Typography';
import { matchSorter } from 'match-sorter';

const useStyles = makeStyles((theme) => ({
  checkIcon: {
    position: 'absolute',
    right: 0,
  },
  contentTextField: {
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.carbon['20']}`,
    borderRadius: theme.spacing(1, 1, 0, 0),
  },
  textField: {
    '& fieldset': {
      borderRadius: theme.spacing(1),
    },
  },
  root: {
    height: 275,
  },
  paper: {
    position: 'absolute',
    left: '-17px',
    top: 12,
    width: 344,
    maxHeight: 195,
    borderRadius: theme.spacing(0, 0, 1, 1),
    boxShadow: 'none',
    border: `1px solid ${theme.palette.carbon['20']}`,
  },
  option: {
    '&[aria-selected="true"]': {
      backgroundColor: fade(theme.palette.primary.main, 0.08),
    },
    '&[data-focus="true"]': {
      backgroundColor: fade(theme.palette.primary.main, 0.04),
    },
  },
  listbox: {
    height: '100%',
  },
}));

type Options = Array<{ primary: string; secondary: string }>;

type Props = {
  options: Options;
  setOption: (option: { primary: string; secondary: string } | null) => void;
};

const filterOption = (option: Options, { inputValue }: { inputValue: string }) =>
  matchSorter(option, inputValue, { keys: ['primary', 'secondary'] });

const SelectOpen: FunctionComponent<Props> = ({ options, setOption }) => {
  const classes = useStyles();

  return (
    <Autocomplete
      id="checkboxes-tags-demo"
      options={options}
      disableCloseOnSelect
      onChange={(_, value) => setOption(value)}
      filterOptions={filterOption}
      getOptionSelected={(option, value) => option.primary === value.primary}
      open
      noOptionsText="Sem Resultado"
      renderOption={(option, { selected }) => {
        return (
          <Box display="flex" alignItems="center" position="relative" width="100%">
            <SideMenuAvatar userName={option.secondary} />
            <Box ml={1}>
              <Typography variant="body2">{option.secondary}</Typography>
              <Typography variant="body2">{option.primary}</Typography>
            </Box>

            {selected && <CheckBoxIcon fontSize="small" color="primary" className={classes.checkIcon} />}
          </Box>
        );
      }}
      classes={{
        root: classes.root,
        option: classes.option,
        paper: classes.paper,
        listbox: classes.listbox,
      }}
      renderInput={(params) => (
        <Box className={classes.contentTextField}>
          <TextField
            {...params}
            variant="outlined"
            label="Buscar conta"
            className={classes.textField}
            InputProps={{
              ...params.InputProps,
              endAdornment: <></>,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )}
    />
  );
};

export default SelectOpen;
