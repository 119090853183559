import { formatUserDocument } from 'helpers/formatters/formatters';
import { ConciliationResponse, UpdateConciliatorProps, UpdateConciliatorResponse } from 'models/reconciliation';
import { client } from 'services/client';

export const getConciliators = async () => {
  const localStorageAuthJson = localStorage.getItem('persist:auth') as string;
  const localStorageAuth = JSON.parse(localStorageAuthJson);
  const localStorageAuthData = JSON.parse(localStorageAuth.data);

  const localStorageAccountJson = localStorage.getItem('persist:account') as string;
  const localStorageAccount = JSON.parse(localStorageAccountJson);
  const localStorageAccountData = JSON.parse(localStorageAccount.data);

  try {
    const { parsedBody } = await client.get<ConciliationResponse>('portal/v1/reconciliation/conciliators', {
      token: localStorageAuthData.accessToken,
      headers: {
        'document-number': formatUserDocument(localStorageAccountData.documentNumber),
      },
    });

    if (parsedBody) {
      return { parsedBody };
    }

    throw new Error('Ocorreu um problema ao buscar os conciliadores');
  } catch (error) {
    return { errorMessage: error.message };
  }
};

export const updateConciliatorAuthorize = async (conciliator: UpdateConciliatorProps[]) => {
  try {
    const localStorageAuthJson = localStorage.getItem('persist:auth') as string;
    const localStorageAuth = JSON.parse(localStorageAuthJson);
    const localStorageAuthData = JSON.parse(localStorageAuth.data);

    const localStorageAccountJson = localStorage.getItem('persist:account') as string;
    const localStorageAccount = JSON.parse(localStorageAccountJson);
    const localStorageAccountData = JSON.parse(localStorageAccount.data);

    const { parsedBody } = await client.patch<UpdateConciliatorResponse>('portal/v1/reconciliation/conciliators', {
      token: localStorageAuthData.accessToken,
      headers: {
        'document-number': formatUserDocument(localStorageAccountData.documentNumber),
        'content-type': 'application/json',
      },
      data: conciliator,
    });

    if (parsedBody) {
      return { parsedBody };
    }

    throw new Error('Ocorreu um problema ao atualizar os conciliadores');
  } catch (error) {
    return { errorMessage: error.message };
  }
};
