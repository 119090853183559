import type { FunctionComponent } from 'react';
import React, { useState, useEffect } from 'react';

import { Nullable } from '@portal-types/helpers';

import { Box, IconButton, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import ClipboardJS from 'clipboard';
import PaymentLinkShareButtons from 'components/PaymentLink/ShareButtons';
import Button from 'components/UI/Button';
import Snackbar from 'components/UI/Snackbar';
import Typography from 'components/UI/Typography';
import { fullDateFormat } from 'helpers/date';
import { formatIntToAmount } from 'helpers/formatters/formatters';

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    boxSizing: 'border-box',
    padding: theme.spacing(3),
    border: `1px solid ${theme.palette.carbon[10]}`,
    background: theme.palette.common.white,
    borderRadius: 8,
    outline: 'none',
    width: 550,
    minHeight: 400,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: '100%',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'absolute',
    left: 24,
    right: 24,
    top: 24,
  },
  detail: {
    borderBottom: `1px solid ${theme.palette.carbon[10]}`,
    padding: theme.spacing(2, 0),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  carbon70: {
    color: theme.palette.carbon[70],
  },
  link: {
    overflowWrap: 'anywhere',
  },
  copyButton: {
    whiteSpace: 'nowrap',
    marginLeft: theme.spacing(4),
  },
}));

type Props = {
  data: {
    amount: Nullable<number>;
    description: Nullable<string>;
    expirationDate: Nullable<string>;
    link: Nullable<string>;
    id: Nullable<string>;
  };
  open: boolean;
  onClose: () => void;
};

const PaymentLinkItemShare: FunctionComponent<Props> = ({ data, open, onClose }) => {
  const classes = useStyles();
  const { amount, description, expirationDate, link, id } = data;
  const [clipboardButton, setClipboardButton] = useState<ClipboardJS | null>(null);
  const [clipboardLink, setClipboardLink] = useState<ClipboardJS | null>(null);
  const [successMessage, setSuccessMessage] = useState(false);

  useEffect(() => {
    setClipboardButton(new ClipboardJS(`#clipboard-action-button-${id}`));
    setClipboardLink(new ClipboardJS(`#target-clipboard-link-${id}`));
    return () => {
      clipboardButton?.destroy();
      clipboardLink?.destroy();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (clipboardButton) {
      clipboardButton.on('success', () => {
        setSuccessMessage(true);
      });
    }
  }, [clipboardButton]);

  useEffect(() => {
    if (clipboardLink) {
      clipboardLink.on('success', () => {
        setSuccessMessage(true);
      });
    }
  }, [clipboardLink]);

  return (
    <>
      <Modal className={classes.modalContainer} open={open} onClose={onClose}>
        <Box className={classes.modalContent}>
          <Box className={classes.header}>
            <Typography variant="bodyBold">Compartilhar link</Typography>
            <IconButton onClick={onClose} color="secondary">
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className={classes.detail}>
            <Box mb={1}>
              <Typography variant="body">{description}</Typography>
            </Box>
            <Box mb={1}>
              <Typography variant="subtitleWebBold">{`R$ ${formatIntToAmount(amount || 0)}`}</Typography>
            </Box>
            <Box mb={1}>
              <Typography variant="caption" className={classes.carbon70}>
                {`Link válido até `}

                <strong>{`${fullDateFormat(expirationDate || '')}`}</strong>
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={3} maxWidth="100%">
              <Typography
                id={`target-clipboard-link-${id}`}
                data-clipboard-target={`#target-clipboard-link-${id}`}
                variant="textLink"
                className={classes.link}
              >
                {link}
              </Typography>
              <Box className={classes.copyButton}>
                <Button
                  variant="outlined"
                  id={`clipboard-action-button-${id}`}
                  data-clipboard-target={`#target-clipboard-link-${id}`}
                >
                  Copiar Link
                </Button>
              </Box>
            </Box>
            <PaymentLinkShareButtons link={link} />
          </Box>
        </Box>
      </Modal>
      <Snackbar
        message="Link copiado para a área de transferência."
        open={successMessage}
        onClose={() => setSuccessMessage(false)}
      />
    </>
  );
};

export default PaymentLinkItemShare;
