import type { FunctionComponent } from 'react';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import DialogMigratedUsers from 'components/DialogMigratedUsers';
import PaymentLinkCreate from 'components/PaymentLink/Create';
import PaymentLinkItem from 'components/PaymentLink/Item';
import PaymentLinkSkeleton from 'components/PaymentLink/Skeleton';
import PaymentLinkWelcome from 'components/PaymentLink/Welcome';
import Button from 'components/UI/Button';
import PageSection from 'components/UI/PageSection';
import PageTitle from 'components/UI/PageTitle';
import ScrollToTopButton from 'components/UI/ScrollToTopButton/ScrollToTopButton';
import ServerError from 'components/UI/ServerError';
import { PaymentLinkGroup } from 'models/paymentLink';
import { getPaymentLinks } from 'services/paymentLink';
import { AccountSelectors } from 'store/accounts';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(6, 0),

    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(4, 0),
    },

    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(4),
    },
  },
}));

const PaymentLink: FunctionComponent = () => {
  const [page, setPage] = useState(0);
  const [updatedAt, setUpdatedAt] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const [paymentLinkData, setPaymentLinkData] = useState<PaymentLinkGroup | null>({
    currentPage: 0,
    totalPages: 0,
    items: [],
  });
  const [createPaymentLinkModal, setCreatePaymentLinkModal] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [dialogMigratedUsersIsOpen, setDialogMigratedUsersIsOpen] = useState(false);
  const accountUserStatus = useSelector(AccountSelectors.accountUserStatus);

  const getPaymentLinkData = async () => {
    setIsLoading(true);
    const response = await getPaymentLinks(page);
    if (response.data) {
      setUpdatedAt(new Date().toISOString());
      const { data } = response;
      setPaymentLinkData((prevData) => ({ ...data, items: [...prevData!.items, ...data.items] }));
    } else {
      setServerError(true);
    }
    setPage((prevPage) => prevPage + 1);
    setIsLoading(false);
  };

  const refreshGetPaymentLinkData = async () => {
    setIsLoading(true);
    const response = await getPaymentLinks(0);
    if (response.data) {
      setUpdatedAt(new Date().toISOString());
      const { data } = response;
      setPaymentLinkData({ ...data });
    } else {
      setServerError(true);
    }
    setPage(1);
    setIsLoading(false);
  };

  useEffect(() => {
    getPaymentLinkData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (accountUserStatus === 'Migrado C6Pay') {
      setDialogMigratedUsersIsOpen(true);
    }
  }, [accountUserStatus]);

  const classes = useStyles();

  if (serverError) {
    return (
      <ServerError
        onTryAgain={() => {
          setServerError(false);

          refreshGetPaymentLinkData();
        }}
      />
    );
  }

  return (
    <>
      {paymentLinkData && (
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <PageTitle title="Link de pagamento" />
          </Grid>
          <Grid item xs={12}>
            {!isLoading && !paymentLinkData.items.length ? (
              <Box display="flex" justifyContent="center" mt={5}>
                <PaymentLinkWelcome onCreateLink={() => setCreatePaymentLinkModal(true)} />
              </Box>
            ) : (
              <>
                <Box mb={5}>
                  <Button
                    title="criar link de pagamento"
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={isLoading}
                    onClick={() => setCreatePaymentLinkModal(true)}
                  >
                    Criar link de pagamento
                  </Button>
                </Box>
                <PageSection title="Histórico de links" updatedAt={updatedAt} updatedAtLoading={isLoading}>
                  {paymentLinkData.items && (
                    <Box>
                      {paymentLinkData.items.map((item) => (
                        <PaymentLinkItem item={item} isLoading={isLoading} key={item.id} />
                      ))}
                    </Box>
                  )}
                  {isLoading && <PaymentLinkSkeleton />}
                </PageSection>

                <Box display="flex" flexDirection="column" alignItems="center" mt={3}>
                  <Button
                    type="submit"
                    title="Mostrar mais"
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={isLoading || page + 1 >= paymentLinkData.totalPages}
                    onClick={() => getPaymentLinkData()}
                  >
                    MOSTRAR MAIS
                  </Button>
                </Box>
                <ScrollToTopButton />
              </>
            )}
          </Grid>
          <PaymentLinkCreate
            isModalOpen={createPaymentLinkModal}
            closeModal={() => setCreatePaymentLinkModal(false)}
            refreshPaymentLink={refreshGetPaymentLinkData}
          />
        </Grid>
      )}
      <DialogMigratedUsers isOpen={dialogMigratedUsersIsOpen} setOpen={setDialogMigratedUsersIsOpen} />
    </>
  );
};

export default PaymentLink;
