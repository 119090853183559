import type { ChangeEvent, FocusEvent, FormEvent, FunctionComponent } from 'react';
import React, { useState } from 'react';

import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Button from 'components/UI/Button';
import Typography from 'components/UI/Typography';
import helpersValidates from 'helpers/validates';

type Props = {
  email: string;
  setEmail: Function;
  submit: (email: string, resend: boolean) => void;
  error: boolean;
  loading: boolean;
};

const useStyles = makeStyles((theme) => ({
  input: {
    margin: theme.spacing(6, 0),
  },
  carbon90: {
    color: theme.palette.carbon[90],
  },
  carbon70: {
    color: theme.palette.carbon[70],
  },
}));

const AuthFormsPasswordRecover: FunctionComponent<Props> = ({ submit, error, loading, email, setEmail }) => {
  const [emailError, setEmailError] = useState(false);
  const classes = useStyles();

  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    if (helpersValidates.isValidEmail(e.target.value)) {
      setEmailError(false);
    }
  };

  const handleValidEmail = (e: FocusEvent<HTMLInputElement>) => {
    setEmailError(!helpersValidates.isValidEmail(e.target.value));
  };

  const getEmailErrorMessage = () => {
    if (!email) {
      return 'Este campo é obrigatório.';
    }

    return 'E-mail inválido.';
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!helpersValidates.isValidEmail(email)) return setEmailError(true);

    return submit(email, false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography className={classes.carbon90} variant="header" component="h2" align="center" paragraph gutterBottom>
        Esqueci minha senha
      </Typography>

      <Typography align="center" variant="body2" className={classes.carbon70}>
        Informe seu e-mail de acesso para continuar.
      </Typography>
      <TextField
        fullWidth
        className={classes.input}
        error={error || emailError}
        variant="outlined"
        type="email"
        label="E-mail"
        value={email}
        onChange={handleValueChange}
        onBlur={handleValidEmail}
        helperText={emailError && getEmailErrorMessage()}
        disabled={loading}
        autoFocus
        inputProps={{
          'aria-label': 'campo de email',
        }}
      />
      <Button
        fullWidth
        aria-label={
          !email || emailError
            ? 'Desabilitado, para avançar você precisa informar seu e-mail de acesso.'
            : 'Confirmar e-mail de acesso.'
        }
        type="submit"
        title="Continuar"
        variant="contained"
        color="primary"
        loading={loading}
        disabled={!email || emailError}
        size="large"
      >
        Confirmar
      </Button>
    </form>
  );
};

export default AuthFormsPasswordRecover;
