import React, { FunctionComponent, ComponentType, useState, useRef } from 'react';

import type { Nullable } from '@portal-types/helpers';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import DetailsDrawer from 'components/DetailsDrawer';
import Feature from 'components/UI/Feature';
import Typography from 'components/UI/Typography';
import { fullDateFormat, hoursAndMinutes } from 'helpers/date';
import { formatAmount, formatCardNumbers } from 'helpers/formatters/formatters';
import { Features } from 'models/featureToggle';
import { SaleItem } from 'models/sales';
import { transactionPendingAction } from 'services/sales';

import { salesInfoDictionary } from './constants';
import SalesGroupSalePending from './SalesGroupSalePendingActions';

const useStyles = makeStyles((theme) => ({
  detailHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  salesInfoItem: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),
    borderBottom: `1px solid ${theme.palette.carbon[10]}`,
  },
}));

type Props = {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  details: Omit<SaleItem, 'type' | 'status'> & { type: string; status: string };
  StatusIcon: Nullable<ComponentType>;
  TypeIcon: Nullable<ComponentType>;
};

const SalesGroupSaleDetails: FunctionComponent<Props> = ({ open, onClose, onOpen, details, StatusIcon, TypeIcon }) => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState<'confirm' | 'revert'>('confirm');

  const confirmSuccess = useRef(false);
  const revertSuccess = useRef(false);
  const error = useRef(false);

  const pendingActionsDictionary = {
    confirm: {
      set: (flag: boolean) => {
        confirmSuccess.current = flag;
      },
      label: 'Confirmar',
    },
    revert: {
      set: (flag: boolean) => {
        revertSuccess.current = flag;
      },
      label: 'Cancelar',
    },
  };

  const patchTransactionPendingAction = (action: 'confirm' | 'revert', transactionCode: string) => {
    error.current = false;

    transactionPendingAction(action, transactionCode)
      .then(({ data = {} }) => {
        if ('success' in data && data.success) {
          pendingActionsDictionary[action].set(true);
          return;
        }

        throw Error();
      })
      .catch(() => {
        error.current = true;
      })
      .finally(() => setIsModalOpen(false));
  };

  const {
    value,
    status,
    description,
    type,
    installments,
    date,
    brandLabel: brand,
    cardNumber,
    acquirerMessage,
    ref,
    saleCode,
    authorizer,
  } = details;

  const renderSaleInfoContent = () => {
    const salesInfo = {
      date: date && fullDateFormat(date),
      hour: date && hoursAndMinutes(date, true),
      description,
      type,
      installments: installments && (installments === 1 ? `${installments} vez` : `${installments} vezes`),
      brand,
      cardNumber: cardNumber && formatCardNumbers(cardNumber),
      ref,
      saleCode,
      authorizer,
    };

    return Object.entries(salesInfoDictionary).map(([keyName, v]) => {
      const saleInfoValue = salesInfo[keyName as keyof typeof salesInfo];
      return (
        saleInfoValue && (
          <Box key={keyName} className={classes.salesInfoItem}>
            <Typography variant="caption">{v}</Typography>
            <Typography variant="caption">{saleInfoValue}</Typography>
          </Box>
        )
      );
    });
  };

  return (
    <DetailsDrawer title="Total a receber" open={open} onClose={onClose} onOpen={onOpen}>
      <Box className={classes.detailHeader}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="subtitleWebBold">{`R$ ${formatAmount(value || 0)}`}</Typography>
          <Typography variant="caption">Valor da venda (sem os descontos)</Typography>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" my={4}>
        {StatusIcon && <StatusIcon data-testid="receivable-details-icon" />}
        {status && <Typography variant="caption">{status}</Typography>}
        {acquirerMessage && <Typography variant="caption">{acquirerMessage}</Typography>}
      </Box>
      <Box display="flex" flexDirection="column" width="100%">
        {renderSaleInfoContent()}
      </Box>

      {status === 'Pendente' && (
        <Feature name={Features.SALES_PENDING_ACTIONS}>
          <SalesGroupSalePending
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            action={currentAction}
            actionLabel={pendingActionsDictionary[currentAction].label}
            patchTransactionPendingAction={patchTransactionPendingAction}
            transactionCode={saleCode}
            setConfirmSuccess={pendingActionsDictionary.confirm.set}
            setRevertSuccess={pendingActionsDictionary.revert.set}
            setCurrentAction={setCurrentAction}
            setError={(flag: boolean) => {
              error.current = flag;
            }}
            confirmSuccess={confirmSuccess.current}
            revertSuccess={revertSuccess.current}
            error={error.current}
          />
        </Feature>
      )}
    </DetailsDrawer>
  );
};

export default SalesGroupSaleDetails;
