import React from 'react';

import { Box, makeStyles } from '@material-ui/core';

import Typography from 'components/UI/Typography';

const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const FaqHeader = (): JSX.Element => {
  const classes = useStyles();

  return (
    <Box className={classes.headerWrapper}>
      <Typography variant="subtitleWebBold">Dúvidas ao utilizar sua maquininha?</Typography>
    </Box>
  );
};

export default FaqHeader;
