import type { FunctionComponent } from 'react';
import React from 'react';

import { Box, List } from '@material-ui/core';
import { fade, makeStyles } from '@material-ui/core/styles';

import Feature from 'components/UI/Feature';
import Typography from 'components/UI/Typography';
import type { Routes } from 'models/routes';

import SideMenuNavigationGroupItem from './Item/SideMenuNavigationGroupItem';

type Props = {
  groupName?: string;
  routes: Routes;
  location: string;
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2, 0),
  },
  subheader: {
    margin: theme.spacing(0, 0, 1, 2),
    color: fade(theme.palette.secondary.contrastText, 0.7),
    textTransform: 'uppercase',
  },
}));

const SideMenuNavigationGroup: FunctionComponent<Props> = ({ routes, groupName, location }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography variant="caption" component="header" className={classes.subheader}>
        {groupName}
      </Typography>
      <List disablePadding>
        {routes.map((route) =>
          route.featureKey ? (
            <Feature name={route.featureKey} key={route.title}>
              <SideMenuNavigationGroupItem item={route} active={location === route.path} />
            </Feature>
          ) : (
            <SideMenuNavigationGroupItem key={route.title} item={route} active={location === route.path} />
          ),
        )}
      </List>
    </Box>
  );
};

export default SideMenuNavigationGroup;
