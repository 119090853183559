import React from 'react';
import type { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import BannerOffers from 'components/BannerCarousel';
import DialogWebBanking from 'components/DialogWebBanking';
import LastSales from 'components/LastSales';
import LastSalesSkeleton from 'components/LastSales/Skeleton';
import MySummariesHeader from 'components/MySummaries/Header/MySummariesHeader';
import PageSection from 'components/PageSection';
import SalesComparison from 'components/SalesComparison';
import SmallCard from 'components/SmallCard';
import EmptyList from 'components/UI/EmptyList';
import Typography from 'components/UI/Typography';
import { subMonths } from 'date-fns';
import { formatRangeDate } from 'helpers/formatters/formatters';
import { ReceivableSummary, ReceivableSummaryData } from 'models/receivables';
import { SalesSummary, SaleItem } from 'models/sales';
import { getReceivables } from 'services/receivables';
import { getSales, getLastSales } from 'services/sales';
import { AccountSelectors } from 'store/accounts';

import { DialogsSelectors } from '../../store/dialogs';
import { MySummariesCardText } from './constants';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(6, 0),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(4, 0),
    },
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(4),
    },
  },
  pageSection: {
    backgroundColor: theme.palette.backgroundColor.surface?.secondary,
    border: `1px solid ${theme.palette.carbon[10]}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    width: '100%',
  },
  cardsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(9, 1fr)',
    gridGap: theme.spacing(2),
  },
  bannersContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    '& > div:not(:last-child)': {
      margin: theme.spacing(0, 2, 2, 0),
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
      },
    },
  },
  chip: {
    width: 72,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 16,
    backgroundColor: theme.palette.textColor.link,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    textTransform: 'uppercase',
    marginBottom: theme.spacing(1),
  },
  iconAndText: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginRight: theme.spacing(1),
    },
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  titleSalesList: {
    marginBottom: theme.spacing(2),
  },
  lastSales: {
    border: `1px solid ${theme.palette.carbon[10]}`,
    borderRadius: theme.spacing(1),
  },
  gridCards: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    '& > div:first-child': {
      margin: theme.spacing(0, 2, 0, 0),
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& > div:first-child': {
        margin: theme.spacing(0, 0, 4, 0),
      },
    },
  },
  titleCard: {
    marginBottom: theme.spacing(2),
  },
  salesChart: {
    width: '100%',
  },
  emptyContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& :first-child': {
      width: '100%',
    },
  },
  link: {
    color: theme.palette.textColor.link,
    textDecoration: 'none',
    fontWeight: 600,
  },
}));

const MySummaries: FunctionComponent = () => {
  const classes = useStyles();

  const [receivablesSummary, setReceivablesSummary] = React.useState<ReceivableSummary | undefined>(undefined);
  const [receivablesSummaryUpdateAt, setReceivablesSummaryUpdateAt] = React.useState<string | undefined>(undefined);
  const [receivablesSummaryLoading, setReceivablesSummaryLoading] = React.useState(true);
  const [receivablesSummaryError, setReceivablesSummaryError] = React.useState(false);

  const [saleList, setSaleList] = React.useState<SaleItem[] | null>(null);
  const [salesListError, setSalesListError] = React.useState(false);
  const [saleListLoading, setSaleListLoading] = React.useState(true);
  const [salesSummary, setSalesSummary] = React.useState<SalesSummary | undefined>(undefined);
  const [salesSummaryUpdateAt, setSalesSummaryUpdateAt] = React.useState<string | undefined>(undefined);
  const [salesSummaryLoading, setSalesSummaryLoading] = React.useState(true);
  const [salesSummaryError, setSalesSummaryError] = React.useState(false);

  const dialogWasOpened = useSelector(DialogsSelectors.dialogWebBankingIsOpen);

  const [dialogWebBankingIsOpen, setDialogWebBankingIsOpen] = React.useState(!dialogWasOpened);

  const accountDocumentNumber = useSelector(AccountSelectors.accountDocumentNumber);
  const accountUserStatus = useSelector(AccountSelectors.accountUserStatus);
  const isSaleEmptyList = saleList?.length === 0;

  const [, inSales, toReceive, , nextWeek, nextMonth, lastWeek, lastMonth] = MySummariesCardText;

  const lastSalesContent = () => {
    if (isSaleEmptyList) {
      return (
        <Box className={classes.emptyContainer}>
          <EmptyList subtitle="Você não tem nenhum resultado para exibir." />
        </Box>
      );
    }

    return saleList?.map((sale) => {
      return <LastSales sale={sale} key={sale.id} />;
    });
  };

  const initialRequestMap = React.useRef({
    receivables: {
      setSummaryError: (value: boolean) => setReceivablesSummaryError(value),
      setSummaryLoading: (value: boolean) => setReceivablesSummaryLoading(value),
      setSummaryUpdateAt: (value: string) => setReceivablesSummaryUpdateAt(value),
      setSummary: (value: ReceivableSummaryData | SalesSummary) =>
        setReceivablesSummary((value as unknown) as ReceivableSummary),
      getSummary: (value: string): Promise<ReceivableSummaryData | SalesSummary> =>
        getReceivables(value) as Promise<ReceivableSummaryData>,
    },
    sales: {
      setSummaryError: (value: boolean) => setSalesSummaryError(value),
      setSummaryLoading: (value: boolean) => setSalesSummaryLoading(value),
      setSummaryUpdateAt: (value: string) => setSalesSummaryUpdateAt(value),
      setSummary: (value: ReceivableSummaryData | SalesSummary) => setSalesSummary(value as SalesSummary),
      getSummary: (value: string): Promise<ReceivableSummaryData | SalesSummary> =>
        getSales(value) as Promise<SalesSummary>,
    },
  });

  const initialSummaryRequest = React.useCallback((key: 'receivables' | 'sales', documentNumber: string) => {
    const { current } = initialRequestMap;
    const { setSummaryError, setSummaryLoading, setSummaryUpdateAt, setSummary, getSummary } = current[key];

    setSummaryError(false);
    setSummaryLoading(true);
    getSummary(documentNumber)
      .then((res) => {
        setSummaryLoading(true);
        if ('data' in res) {
          const { data } = res;
          setSummaryUpdateAt(new Date().toISOString());
          setSummary(data! as SalesSummary);
        } else {
          throw new Error();
        }
      })
      .catch(() => setSummaryError(true))
      .finally(() => setSummaryLoading(false));
  }, []);

  const lastSales = () => {
    const today = new Date();
    const startDate = subMonths(today, 1);

    getLastSales(startDate, today, 3).then(({ data }) => {
      if (data) {
        setSaleList(data as SaleItem[]);
        setSaleListLoading(false);
      } else {
        setSalesListError(true);
      }
    });
  };

  React.useEffect(() => {
    initialSummaryRequest('receivables', accountDocumentNumber as string);
    initialSummaryRequest('sales', accountDocumentNumber as string);
    lastSales();
  }, [accountDocumentNumber, initialSummaryRequest]);

  return (
    <>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <MySummariesHeader />
        </Grid>

        <Grid item xs={12} className={classes.pageSection}>
          <PageSection
            title="Recebíveis"
            updatedAt={receivablesSummaryUpdateAt}
            updatedAtLoading={receivablesSummaryLoading}
            hasError={receivablesSummaryError}
            linkElement={
              <Link to="/receivables" className={classes.link} aria-label="Acessar área de recebíveis.">
                Ver recebíveis
              </Link>
            }
            onUpdateCard={() => initialSummaryRequest('receivables', accountDocumentNumber as string)}
          >
            <Box className={classes.cardsContainer}>
              <SmallCard
                icon={toReceive.icon}
                prefix={toReceive.prefix}
                value={receivablesSummary?.today?.total}
                count={`${receivablesSummary?.today?.count} vendas` || ''}
                rangeDate={formatRangeDate(receivablesSummary?.today?.startDate || '')}
                text={toReceive.text}
                isLoading={receivablesSummaryLoading}
                hasError={receivablesSummaryError}
              />
              <SmallCard
                icon={nextWeek.icon}
                prefix={nextWeek.prefix}
                value={receivablesSummary?.nextWeek?.total}
                count={`${receivablesSummary?.nextWeek?.count} vendas` || ''}
                rangeDate={formatRangeDate(
                  receivablesSummary?.nextWeek?.startDate || '',
                  receivablesSummary?.nextWeek?.endDate,
                )}
                text={nextWeek.text}
                isLoading={receivablesSummaryLoading}
                hasError={receivablesSummaryError}
              />
              <SmallCard
                icon={nextMonth.icon}
                prefix={nextMonth.prefix}
                value={receivablesSummary?.nextMonth?.total}
                count={`${receivablesSummary?.nextMonth?.count} vendas` || ''}
                rangeDate={formatRangeDate(
                  receivablesSummary?.nextMonth?.startDate || '',
                  receivablesSummary?.nextMonth?.endDate,
                )}
                text={nextMonth.text}
                isLoading={receivablesSummaryLoading}
                hasError={receivablesSummaryError}
              />
            </Box>
          </PageSection>
        </Grid>

        {accountUserStatus !== 'Cancelado' && (
          <Grid item xs={12} className={classes.gridCards}>
            <Grid item xs={12} md={6} className={classes.salesChart}>
              <Box className={classes.pageSection}>
                <Typography variant="bodyBold" className={classes.titleCard}>
                  Comparativo de vendas ao mês
                </Typography>
                <SalesComparison />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} className={classes.salesChart}>
              <Box className={classes.pageSection}>
                <BannerOffers />
              </Box>
            </Grid>
          </Grid>
        )}

        <Grid item xs={12} className={classes.pageSection}>
          <PageSection
            title="Vendas"
            updatedAt={salesSummaryUpdateAt}
            updatedAtLoading={salesSummaryLoading}
            hasError={salesSummaryError}
            linkElement={
              <Link to="/sales" className={classes.link} aria-label="Acessar área de recebíveis.">
                Ver vendas
              </Link>
            }
            onUpdateCard={() => initialSummaryRequest('sales', accountDocumentNumber as string)}
          >
            <Box className={classes.cardsContainer}>
              <SmallCard
                prefix={toReceive.prefix}
                value={salesSummary?.today?.total}
                count={`${salesSummary?.today?.count} vendas` || ''}
                rangeDate={formatRangeDate(salesSummary?.today?.startDate || '')}
                text={toReceive.text}
                isLoading={salesSummaryLoading}
                hasError={salesSummaryError}
              />
              <SmallCard
                prefix={inSales.prefix}
                value={salesSummary?.lastWeek?.total}
                text={lastWeek.text}
                count={`${salesSummary?.lastWeek?.count} vendas` || ''}
                rangeDate={formatRangeDate(salesSummary?.lastWeek?.endDate || '', salesSummary?.lastWeek?.startDate)}
                isLoading={salesSummaryLoading}
                hasError={salesSummaryError}
              />
              <SmallCard
                prefix={inSales.prefix}
                value={salesSummary?.lastMonth?.total}
                text={lastMonth.text}
                count={`${salesSummary?.lastMonth?.count} vendas` || ''}
                isLoading={salesSummaryLoading}
                rangeDate={formatRangeDate(salesSummary?.lastMonth?.endDate || '', salesSummary?.lastMonth?.startDate)}
                hasError={salesSummaryError}
              />
            </Box>
          </PageSection>

          <Box mt={2}>
            <PageSection title="Últimas vendas" hasError={salesListError} onUpdateCard={lastSales}>
              <Box className={classes.lastSales}>{saleListLoading ? <LastSalesSkeleton /> : lastSalesContent()}</Box>
            </PageSection>
          </Box>
        </Grid>
      </Grid>

      <DialogWebBanking isOpen={dialogWebBankingIsOpen} setOpen={setDialogWebBankingIsOpen} />
    </>
  );
};

export default MySummaries;
