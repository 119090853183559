import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import AuthLayout from 'layouts/Auth';
import AccessAccount from 'pages/Auth/AccountAccess';
import CreatePassword from 'pages/Auth/CreatePassword';
import ForgotPassword from 'pages/Auth/ForgotPassword';
import AuthLogin from 'pages/Auth/Login';

const usePublicRoutes = () =>
  useRoutes([
    {
      path: '/auth',
      element: <AuthLayout />,
      children: [
        {
          path: '/',
          element: <Navigate to="/auth/login" replace />,
        },
        {
          path: '/login',
          element: <AuthLogin />,
        },

        {
          path: '/access-account',
          element: <AccessAccount />,
        },
        {
          path: '/forgot-password',
          element: <ForgotPassword />,
        },
        {
          path: '/create-password',
          element: <CreatePassword />,
        },
      ],
    },
    { path: '*', element: <Navigate to="/auth" replace /> },
  ]);

export default usePublicRoutes;
