import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Box, Card, CardMedia, useMediaQuery } from '@material-ui/core';
import type { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Button from 'components/UI/Button';
import Typography from 'components/UI/Typography';
import { WhitelabelSelectors } from 'store/whitelabel';

const useStyles = makeStyles((theme) => ({
  card: {
    height: 216,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundSize: '560px auto',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right center',

    [theme.breakpoints.down('sm')]: {
      backgroundSize: 'auto 216px',
    },

    [theme.breakpoints.down('xs')]: {
      height: 336,
      backgroundImage: 'none',
      flexDirection: 'column',
    },
  },
  content: {
    minWidth: 232,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  cardImageMobile: {
    width: 296,
  },
  centralButton: {
    padding: theme.spacing(1, 2),
  },
  img: {
    height: 'inherit',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

const HelpCenter = () => {
  const xsScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

  const whitelabelTitle = useSelector(WhitelabelSelectors.whitelabelTitle);
  const whitelabelAssets = useSelector(WhitelabelSelectors.whitelabelAssets)!;

  const classes = useStyles();

  return (
    <Box>
      <Card className={classes.card}>
        {xsScreen && (
          <CardMedia
            component="img"
            alt=""
            image={whitelabelAssets['help-mobile-image']}
            title={`Central de ajuda ${whitelabelTitle}`}
            className={classes.cardImageMobile}
          />
        )}
        <Box className={classes.content}>
          <Box mb={3}>
            <Typography variant="body">Ficou com alguma dúvida?</Typography>
            <Typography variant="bodyBold">Vamos te ajudar</Typography>
          </Box>

          <Button
            variant="contained"
            size="small"
            color="primary"
            type="button"
            aria-label="Acessar central de ajuda."
            component={Link}
            componentProps={{
              to: '/faq',
            }}
            className={classes.centralButton}
          >
            Central de Ajuda
          </Button>
        </Box>

        <Box height="100%">
          <img src={whitelabelAssets['help-desktop-image']} alt="" className={classes.img} />
        </Box>
      </Card>
    </Box>
  );
};

export default HelpCenter;
