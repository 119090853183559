import React, { useEffect, useState } from 'react';
import type { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, IconButton, useMediaQuery } from '@material-ui/core';
import type { Theme } from '@material-ui/core/styles';
import { fade, makeStyles } from '@material-ui/core/styles';

import { ReactComponent as ArrowDropdownIcon } from 'assets/images/arrow-drop-down.svg';
import Typography from 'components/UI/Typography';
import helpersFormatters from 'helpers/formatters';
import { useAppDispatch } from 'store';
import { AccountActions, AccountSelectors } from 'store/accounts';

import SideMenuAvatar from '../../SideMenu/Avatar';
import PopoverOptions from '../../SideMenu/PopoverOptions';

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    padding: theme.spacing(3),
    border: `1px solid ${theme.palette.carbon[10]}`,
    background: theme.palette.common.white,
    borderRadius: 8,
    width: '100%',
    maxWidth: 550,
    height: 'auto',
    maxHeight: '90vh',
  },
  typography: {
    color: fade(theme.palette.secondary.contrastText, 0.7),
  },
  optionsUserArea: {
    marginLeft: theme.spacing(1),
  },
  usernameLabel: {
    color: fade(theme.palette.secondary.contrastText, 0.9),
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  section: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.secondary.contrastText,
  },
  userName: {
    display: 'flex',
    flexDirection: 'column',
  },
  paper: {
    backgroundColor: theme.palette.secondary.light,
    padding: theme.spacing(2),
    boxSizing: 'border-box',
    color: fade(theme.palette.secondary.contrastText, 0.9),
    '& svg': {
      marginRight: theme.spacing(1),
    },
    '& > div': {
      cursor: 'pointer',
    },
  },
  userArea: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
}));

type Props = {
  userName: string;
  documentNumber: string;
};

const SideMenuUserArea: FunctionComponent<Props> = ({ userName, documentNumber }) => {
  const matchesLgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const accountUserDocuments = useSelector(AccountSelectors.accountUserDocuments) || [];

  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    setAnchorEl(null);
  }, [location]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const submitChangeAccount = (newDocumentNumber: string) => {
    const userDocument = accountUserDocuments.find(
      (user: { documentNumber: string }) => user.documentNumber === newDocumentNumber,
    );

    dispatch(AccountActions.changeUserStatus(userDocument?.status!));
    dispatch(AccountActions.changeDocumentNumber(newDocumentNumber));
    dispatch(AccountActions.changeUserPortal(userDocument?.portalType!));
    dispatch(AccountActions.changeUserName(userDocument?.corporateName!));
    navigate('/', { replace: true });
  };

  return (
    <Box className={classes.section}>
      <Box aria-label="Abrir menu de opções da conta." onClick={(e) => handleClick(e)} className={classes.userArea}>
        <SideMenuAvatar userName={userName} contrastColor />
        {matchesLgUp && (
          <Box className={classes.userName}>
            <Typography variant="body2" component="p" className={classes.usernameLabel}>
              {helpersFormatters.formatUserName(userName)}
            </Typography>
            <Typography variant="overline" className={classes.typography}>
              {helpersFormatters.formatUserDocument(documentNumber)}
            </Typography>
          </Box>
        )}
        <IconButton className={classes.optionsUserArea} size="small" color="inherit">
          <ArrowDropdownIcon color="inherit" fontSize="small" />
        </IconButton>
      </Box>
      <PopoverOptions
        anchorEl={anchorEl}
        handleClose={handleClose}
        submitChangeAccount={submitChangeAccount}
        accountUserDocuments={accountUserDocuments}
      />
    </Box>
  );
};

export default SideMenuUserArea;
