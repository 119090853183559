import React, { FocusEvent, FormEvent, FunctionComponent, useEffect, useState, useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Link } from 'react-router-dom';

import { Box, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { Alert } from '@material-ui/lab';

import Button from 'components/UI/Button';
import Typography from 'components/UI/Typography';
import helpersValidates from 'helpers/validates';

const useStyles = makeStyles((theme) => ({
  inputs: {
    '& > *': {
      marginBottom: theme.spacing(2),
    },
    '& > a': {
      width: 160,
    },
  },
  buttons: {
    display: 'grid',
    gap: theme.spacing(1),
  },
  alert: {
    borderRadius: theme.shape.borderRadius * 2,
    display: 'flex',
    alignItems: 'center',
  },
  visibilityButton: {
    color: theme.palette.carbon[60],
  },
  carbon90: {
    color: theme.palette.carbon[90],
    marginBottom: theme.spacing(6),
  },
  forgotPasswordLink: {
    color: theme.palette.carbon[100],
  },
  firstAccessWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(2),
    '& > a': {
      color: theme.palette.textColor.link,
      marginLeft: theme.spacing(0.5),
      textDecoration: 'none',
      '& > span': {
        textDecoration: 'none',
      },
    },
  },
}));

type Props = {
  submitLogin: (email: string, password: string, reCaptchaResponse: string) => void;
  isLoading: boolean;
  errorMessage: string;
  errorMessageLink: string;
  acquirerUrl: string;
};

const AuthFormsEmail: FunctionComponent<Props> = ({
  submitLogin,
  acquirerUrl,
  isLoading,
  errorMessage,
  errorMessageLink,
}) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [isButtonDisable, setIsButtonDisable] = useState(true);

  const classes = useStyles();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const reloadErrorLink = () => {
    return window.location.reload();
  };

  const validEmail = (e: FocusEvent<HTMLInputElement>) => {
    setEmailError(!helpersValidates.isValidEmail(e.target.value));
  };

  const getEmailErrorMessage = () => {
    if (!email) return 'Este campo é obrigatório.';
    return 'E-mail inválido';
  };

  const handleReCaptchaVerify = async () => {
    if (!executeRecaptcha) {
      return '';
    }

    const token = await executeRecaptcha();
    return token;
  };

  const onSubmitLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isValidEmail = !helpersValidates.isValidEmail(email);
    setEmailError(isValidEmail);

    const reCaptchaToken = await handleReCaptchaVerify();

    if (email.trim() !== '' && !isValidEmail) {
      submitLogin(email, password, reCaptchaToken);
    }
  };

  const handleDisableButton = useCallback(() => {
    if (password && email && !emailError && !isLoading) {
      return setIsButtonDisable(false);
    }
    return setIsButtonDisable(true);
  }, [email, password, emailError, isLoading]);

  useEffect(() => {
    handleDisableButton();
  }, [handleDisableButton]);

  return (
    <form onSubmit={onSubmitLogin} noValidate>
      <Typography
        aria-label="Acessar conta."
        className={classes.carbon90}
        variant="header"
        component="h2"
        align="center"
        paragraph
        gutterBottom
      >
        Acessar conta
      </Typography>

      <Box className={classes.inputs}>
        <TextField
          fullWidth
          error={emailError}
          type="email"
          label="E-mail"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onBlur={validEmail}
          helperText={emailError && getEmailErrorMessage()}
          autoFocus
          autoComplete="email"
          inputProps={{
            'aria-label': 'Campo de email.',
          }}
        />
        <TextField
          fullWidth
          name="password"
          type={showPassword ? 'text' : 'password'}
          value={password}
          label="Senha"
          variant="outlined"
          autoComplete="current-password"
          onChange={(e) => setPassword(e.target.value)}
          inputProps={{
            'aria-label': 'Campo de senha.',
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="alternância da visibilidade do campo de senha."
                  onClick={() => setShowPassword((prevShowPassword) => !prevShowPassword)}
                >
                  {showPassword ? (
                    <VisibilityOffIcon className={classes.visibilityButton} />
                  ) : (
                    <VisibilityIcon className={classes.visibilityButton} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Link to="/auth/forgot-password" className={classes.forgotPasswordLink}>
          <Typography variant="bodylink" display="block" paragraph gutterBottom align="left">
            Esqueci minha senha.
          </Typography>
        </Link>
        {errorMessage && (
          <Alert
            className={classes.alert}
            variant="filled"
            severity="error"
            icon={<ReportProblemIcon />}
            data-testid="box-error-alert"
            aria-label="caixa com alerta de erro"
          >
            <Typography variant="body2">{errorMessage}</Typography>
            {errorMessageLink && (
              <Typography variant="bodylink" component="a" onClick={reloadErrorLink}>
                {errorMessageLink}
              </Typography>
            )}
          </Alert>
        )}
      </Box>

      <Box className={classes.buttons}>
        <Button
          type="submit"
          title="Entrar"
          fullWidth
          variant="contained"
          color="primary"
          size="large"
          aria-label={
            isButtonDisable
              ? 'Desabilitado, para avançar você precisa informar seu e-mail e senha de acesso.'
              : 'Acesar conta.'
          }
          loading={isLoading}
          disabled={isButtonDisable}
        >
          Entrar
        </Button>
        <Button
          aria-label="Solicitar maquininha de cartões."
          component="a"
          componentProps={{
            href: acquirerUrl,
            target: '_blank',
            rel: 'noopener',
          }}
          type="button"
          title="Solicitar maquininha de cartões."
          fullWidth
          variant="outlined"
          size="large"
        >
          Solicitar maquininha
        </Button>
      </Box>
      <Box className={classes.firstAccessWrapper}>
        <Typography variant="body2">Seu primeiro acesso? </Typography>
        <Link to="/auth/create-password">
          <Typography variant="bodylink2">Criar senha.</Typography>
        </Link>
      </Box>
    </form>
  );
};

export default AuthFormsEmail;
