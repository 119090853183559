import { createReducer } from '@reduxjs/toolkit';

import { Loading } from 'helpers/enums';

import { clearError, login, logout, refresh } from './actions';
import { credentialsInitialState } from './constants';

export default createReducer(credentialsInitialState, (builder) =>
  builder
    .addCase(login.pending, (state) => {
      state.loading = Loading.PENDING;
      state.data = undefined;
      state.error = undefined;
      state.unavailableService = undefined;
    })
    .addCase(login.fulfilled, (state, action) => {
      state.loading = Loading.IDLE;
      state.data = action.payload;
      state.error = undefined;
    })
    .addCase(login.rejected, (state, action) => {
      state.loading = Loading.IDLE;
      state.data = undefined;
      if (action.payload?.networkError) {
        state.unavailableService = true;
      } else {
        state.error = {
          message: action.payload?.message || '',
          messageLink: action.payload?.messageLink || '',
        };
      }
    })
    .addCase(refresh.pending, (state) => {
      state.loading = Loading.PENDING;
    })
    .addCase(refresh.fulfilled, (state, action) => {
      state.loading = Loading.IDLE;
      state.data = action.payload;
    })
    .addCase(refresh.rejected, (state) => {
      state.loading = Loading.IDLE;
      state.data = undefined;
    })
    .addCase(logout, () => credentialsInitialState)
    .addCase(clearError, (state) => ({ ...state, error: undefined, unavailableService: undefined })),
);
