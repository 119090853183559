import { createMuiTheme } from '@material-ui/core/styles';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';

import { backgroundColors, brandVariants, supportColors, textColors, compositionColors } from './colors';

export const themeDefault = ({ primary, secondary }: PaletteOptions) =>
  createMuiTheme({
    props: {
      MuiButton: {
        disableElevation: true,
      },
      MuiCircularProgress: {
        color: 'secondary',
      },
    },
    palette: {
      primary,
      secondary,
      // Mui Customization Default Theme  ->
      // https://mui.com/pt/material-ui/customization/default-theme/
      text: {
        primary: textColors.light.title,
        secondary: textColors.light.subtitle,
        disabled: textColors.light.disabled,
      },
      background: {
        default: backgroundColors.light.surface.default,
        paper: backgroundColors.light.surface.default,
      },
      common: {
        black: backgroundColors.light.neutral?.black,
        white: backgroundColors.light.neutral?.white,
      },
      error: {
        light: backgroundColors.light.negative.primary,
        main: backgroundColors.light.negative.primary,
        dark: backgroundColors.dark.negative.primary,
      },
      warning: {
        light: backgroundColors.light.warning.primary,
        main: backgroundColors.light.warning.primary,
        dark: backgroundColors.dark.warning.primary,
      },
      info: {
        light: backgroundColors.light.info.primary,
        main: backgroundColors.light.info.primary,
        dark: backgroundColors.dark.info.primary,
      },
      success: {
        light: backgroundColors.light.positive.primary,
        main: backgroundColors.light.positive.primary,
        dark: backgroundColors.dark.positive.primary,
      },
      // <- Mui Customization Default Theme
      carbon: brandVariants.carbon,
      marble: brandVariants.marble,
      backgroundColor: backgroundColors.light,
      textColor: textColors.light,
      composition: compositionColors.light,
      support: supportColors.light,
    },
    typography: {
      fontFamily: 'C6 Sans Text, sans-serif',
    },
    shape: { borderRadius: 4 },
    shadows: [
      'none',
      '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
      '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
      '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
      '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
      '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
      '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
      '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
      '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
      '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
      '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
      '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
      '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
      '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
      '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
      '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
      '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
      '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
      '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
      '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
      '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
      '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
      '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
      '-10px 0px 40px rgba(0, 0, 0, 0.6)',
    ],
    overrides: {
      MuiPaper: {
        elevation8: {
          boxShadow:
            '0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2)',
        },
        root: {
          '&.MuiAlert-filledSuccess': {
            borderRadius: 8,
          },
          '&.MuiAlert-filledError': {
            backgroundColor: backgroundColors.light.negative,
            borderRadius: 8,
            '& .MuiAlert-message, .MuiAlert-icon': {
              color: brandVariants.marble[100],
            },
          },
        },
      },
      MuiFormLabel: {
        root: {
          '&$focused': {
            color: brandVariants.carbon[60],
            '&.MuiInputLabel-outlined': {
              color: brandVariants.carbon[90],
            },
            '&.Mui-error.MuiInputLabel-outlined': {
              color: supportColors.light.OutlineInput,
            },
          },
          '&.Mui-error': {
            color: supportColors.light.OutlineInput,
          },
        },
      },
      // @ts-ignore
      MuiToggleButtonGroup: {
        root: {},
        grouped: {
          margin: `4px`,
        },
        groupedHorizontal: {
          // limpar estilização do material-ui
          '&:not(:first-child)': false,
          '&:not(:last-child)': false,
        },
      },
      MuiContainer: {
        maxWidthMd: {
          paddingLeft: '32px',
          paddingRight: '32px',
        },
        maxWidthSm: {
          paddingLeft: '16px',
          paddingRight: '16px',
        },
      },
      MuiToggleButton: {
        root: {
          borderRadius: '4px',
          backgroundColor: brandVariants.marble[100],
          borderColor: brandVariants.carbon[60],
          height: 32,
          color: brandVariants.carbon[70],
          fontSize: '1rem',
          '&$selected': {
            backgroundColor: brandVariants.carbon[100],
            borderColor: brandVariants.carbon[100],
            color: brandVariants.marble[100],
            '&:hover': {
              backgroundColor: brandVariants.carbon[100],
            },
          },
        },
        label: {
          textTransform: 'none',
        },
      },
      MuiInput: {
        input: {
          fontWeight: 500,
        },
        underline: {
          '&:after': {
            borderBottomColor: brandVariants.carbon[100],
          },
        },
      },
      MuiOutlinedInput: {
        root: {
          '&$focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: brandVariants.carbon[90],
            },
            '&$error': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: supportColors.light.OutlineInput,
              },
              '&+.MuiFormHelperText-root': {
                color: supportColors.light.OutlineInput,
              },
            },
          },
          '&$error': {
            '&+.MuiFormHelperText-root': {
              color: supportColors.light.OutlineInput,
            },
          },
          '&$disabled': {
            background: backgroundColors.light.surface.marbleDark,
            '& .MuiOutlinedInput-notchedOutline': {
              border: `solid 1px ${brandVariants.carbon[60]}`,
            },
            '&+.MuiFormHelperText-root': {
              color: brandVariants.carbon[60],
            },
          },
        },
        input: {
          color: brandVariants.carbon[90],
          fontWeight: 600,
          fontSize: '1rem',
          lineHeight: '1.5rem',
        },
        notchedOutline: {
          border: `solid 1px ${brandVariants.carbon[60]}`,
          borderRadius: '8px',
          '&:hover': {
            border: `solid 1px ${brandVariants.carbon[90]}`,
          },
        },
      },
      MuiAvatar: {
        colorDefault: {
          backgroundColor: backgroundColors.light.surface.marbleDark,
        },
      },
      MuiDrawer: {
        paper: {
          width: 288,
          '@media (min-width: 600px)': {
            width: 446,
          },
        },
      },
      MuiPopover: {
        paper: {
          borderRadius: 8,
          '@media (min-width:600px)': {
            marginTop: 8,
          },
        },
      },
      MuiCard: {
        root: {
          padding: '24px',
          border: `1px solid ${brandVariants.carbon[10]}`,
          borderRadius: '8px',
          boxShadow: 'none',
        },
      },
      MuiTab: {
        wrapper: {
          fontSize: '1rem',
          fontWeight: 'bold',
          textTransform: 'none',
          color: brandVariants.carbon[90],
        },
      },
    },
  });
