import React, { FunctionComponent, useState } from 'react';

import { Box, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';

import { AnticipationData, AnticipationItemStatusDictionary } from 'models/anticipation';

import AnticipationDetails from '../AnticipationDetails';
import AnticipationListItemDesktop from './Desktop';
import AnticipationListItemMobile from './Mobile';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(4, 2),
    borderBottom: `1px solid ${theme.palette.backgroundColor.surface?.marbleDark}`,
    color: theme.palette.carbon['70'],
    '&:hover': {
      backgroundColor: theme.palette.backgroundColor.surface?.marbleDark,
    },
  },
}));

type AnticipationListItemProps = AnticipationData & { changePageTab: () => void };
const AnticipationListItem: FunctionComponent<AnticipationListItemProps> = ({
  id,
  acquirers,
  netAmount,
  grossAmount,
  taxAmount,
  status,
  startAnticipatedDate,
  endAnticipatedDate,
  createdAt,
  changePageTab,
}) => {
  const classes = useStyles();
  const [detailsOpen, setDetailsOpen] = useState(false);

  const smScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const statusDictionary: AnticipationItemStatusDictionary = {
    reviewing: {
      text: 'Em análise',
      variant: 'pending',
      icon: SearchOutlinedIcon,
    },
    canceled: {
      text: 'Cancelado',
      variant: 'error',
      icon: CancelOutlinedIcon,
    },
    refused: {
      text: 'Recusado',
      variant: 'error',
      icon: ErrorOutlineOutlinedIcon,
    },
    approved: {
      text: 'Aprovado',
      variant: 'success',
      icon: CheckCircleOutlinedIcon,
    },
  };
  return (
    <>
      <AnticipationDetails
        open={detailsOpen}
        onClose={() => setDetailsOpen(false)}
        onOpen={() => setDetailsOpen(true)}
        details={{
          id,
          acquirers,
          grossAmount,
          netAmount,
          taxAmount,
          startAnticipatedDate,
          endAnticipatedDate,
          status: statusDictionary[status],
          createdAt,
        }}
        changePageTab={changePageTab}
      />
      <Box className={classes.root} onClick={() => setDetailsOpen(true)}>
        {smScreen ? (
          <AnticipationListItemMobile
            createdAt={createdAt}
            netAmount={netAmount}
            status={{ icon: statusDictionary[status].icon, variant: statusDictionary[status].variant }}
          />
        ) : (
          <AnticipationListItemDesktop
            createdAt={createdAt}
            id={id}
            acquirers={acquirers}
            netAmount={netAmount}
            status={{
              text: statusDictionary[status].text,
              icon: statusDictionary[status].icon,
              variant: statusDictionary[status].variant,
            }}
          />
        )}
      </Box>
    </>
  );
};
export default AnticipationListItem;
