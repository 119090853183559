import type { ChangeEvent, FocusEvent, FormEvent } from 'react';
import React, { useState } from 'react';

import { makeStyles, TextField } from '@material-ui/core';

import Button from 'components/UI/Button';
import Typography from 'components/UI/Typography';
import helpersValidates from 'helpers/validates';

const useStyles = makeStyles((theme) => ({
  input: {
    margin: theme.spacing(6, 0),
  },
  carbon90: {
    color: theme.palette.carbon[90],
  },
  carbon70: {
    color: theme.palette.carbon[70],
  },
}));

type Props = {
  loading: boolean;
  email: string;
  setEmail: Function;
  submit: (email: string, resend: boolean) => void;
};

const CreatePasswordEmailFrom = ({ loading, email, setEmail, submit }: Props): JSX.Element => {
  const [emailError, setEmailError] = useState(false);
  const classes = useStyles();

  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    if (helpersValidates.isValidEmail(e.target.value)) {
      setEmailError(false);
    }
  };

  const handleValidEmail = (e: FocusEvent<HTMLInputElement>) => {
    setEmailError(!helpersValidates.isValidEmail(e.target.value));
  };

  const getEmailErrorMessage = () => {
    if (!email) {
      return 'Este campo é obrigatório.';
    }
    return 'E-mail inválido.';
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!helpersValidates.isValidEmail(email)) {
      setEmailError(true);
    }

    return submit(email, false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography className={classes.carbon90} variant="header" component="h2" align="center" paragraph gutterBottom>
        Primeiro acesso
      </Typography>

      <Typography align="center" variant="body2" className={classes.carbon70}>
        Informe seu e-mail de cadastro para criar a sua senha.
      </Typography>
      <TextField
        fullWidth
        className={classes.input}
        error={emailError}
        label="E-mail"
        variant="outlined"
        helperText={emailError && getEmailErrorMessage()}
        disabled={loading}
        onChange={handleValueChange}
        onBlur={handleValidEmail}
        autoFocus
        inputProps={{
          'aria-label': 'campo de email',
        }}
      />
      <Button
        fullWidth
        aria-label="criar senha"
        type="submit"
        variant="contained"
        loading={loading}
        color="primary"
        disabled={!email || emailError}
        size="large"
      >
        Criar senha
      </Button>
    </form>
  );
};

export default CreatePasswordEmailFrom;
