import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';

import AuthHeader from 'components/Auth/Header';
import AuthLoading from 'components/Auth/Loading';
import { createBrowserHistory } from 'history';
import { WhitelabelSelectors } from 'store/whitelabel';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100vh',
  },
  hero: {
    backgroundImage: ({ sideAuthBg }: { sideAuthBg: string }) => `url(${sideAuthBg})`,
    height: '100%',
    width: '100%',
    display: 'block',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    overflow: 'auto',
  },
}));

const AuthLayout = () => {
  const whitelabelAssets = useSelector(WhitelabelSelectors.whitelabelAssets)!;
  const sideAuthBg = whitelabelAssets['side-auth-image'];

  const classes = useStyles({ sideAuthBg });
  const history = createBrowserHistory();
  const hasBack = history.location.pathname !== '/auth/login';

  return (
    <Grid container className={classes.root}>
      <Grid item xs={false} md={6}>
        <div className={classes.hero} />
      </Grid>
      <Grid item xs={12} md={6} component="main" className={classes.content}>
        <AuthLoading />
        <Box height={120} width="100%" px={3}>
          {hasBack ? <AuthHeader onBack={() => history.back()} /> : <AuthHeader />}
        </Box>
        <Box display="flex" alignItems="center" height="100%">
          <Box maxWidth={344} maxHeight={584}>
            <Outlet />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default AuthLayout;
