import React from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import BrandIcon from 'components/UI/BrandIcon';
import EmptyList from 'components/UI/EmptyList';
import ServerError from 'components/UI/ServerError';
import Typography from 'components/UI/Typography';
import { fullDateFormat } from 'helpers/date';
import { capitalize, formatAmount } from 'helpers/formatters/formatters';
import { Brands } from 'models/brands';
import {
  ReceivablesData,
  ReceivablesGroup,
  ReceivablesQueryParamsOperation,
  ReceivablesQueryParamsStatus,
  ReceivablesQueryUrParamsOperation,
  ReceivablesStatus,
  ReceivablesUrToQueryParamsStatus,
  UrType,
} from 'models/receivables';
import { getReceivableService } from 'services/receivables';

import ReceivablesSkeleton from '../Skeleton/ReceivablesSkeleton';
import ReceivablesGroupItem from './ReceivablesGroupItem';

const useStyles = makeStyles((theme) => ({
  accordion: {
    '& > .Mui-expanded': {
      backgroundColor: theme.palette.backgroundColor.surface?.marbleDark,
    },
  },
  receivablesGroupHeader: {
    marginBottom: theme.spacing(2),
  },
  receivablesGroup: {
    backgroundColor: theme.palette.backgroundColor.surface?.secondary,
    borderRadius: theme.spacing(1),
  },
  root: {
    '& > .MuiAccordionSummary-content': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  accordionDetails: {
    backgroundColor: theme.palette.backgroundColor.surface?.marbleDark,
  },
  column: {
    display: 'flex',
    alignItems: 'center',
  },
  valueContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    height: 40,
  },
  valueStatusContainer: {
    width: 92,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  carbon70Color: {
    color: theme.palette.textColor.paragraph,
  },
  brandContainer: {
    minWidth: 30,
    margin: theme.spacing(0, 2, 0, 1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.palette.carbon[10]}`,
    borderRadius: '50%',
    width: theme.spacing(4),
    height: theme.spacing(4),
    background: theme.palette.backgroundColor.surface?.marbleDark,
    '& > svg': {
      maxWidth: 26,
      maxHeight: 26,
    },
  },
  detail: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardNumberContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(0.5),
  },
  cardNumber: {
    minWidth: 80,
    marginRight: theme.spacing(1),
  },
  statusWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: theme.spacing(2),
    '& > span': {
      marginLeft: theme.spacing(0.5),
    },
  },
  carbon: {
    fill: theme.palette.carbon[100],
  },
  success: {
    fontSize: '0.75rem',
    fill: theme.palette.composition.status?.active,
  },
  warning: {
    fontSize: '0.75rem',
    fill: theme.palette.composition.status?.attention,
  },
  itemOperation: {
    marginLeft: theme.spacing(1),
  },
}));

type ReceivablesGroupItemProps = {
  receivable: UrType;
};

const ReceivablesUr: React.FunctionComponent<ReceivablesGroupItemProps> = ({ receivable }) => {
  const classes = useStyles();
  const firstPage = 1;
  const { cardBrand, operation, status, amount, date } = receivable;

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [receivables, setReceivables] = React.useState<ReceivablesData | null>(null);
  const [receivablesError, setReceivablesError] = React.useState<boolean>(false);
  const [isReceivablesLoading, setIsReceivablesLoading] = React.useState<boolean>(false);
  const [currentPage, setCurrentPage] = React.useState<number>(firstPage);
  const [totalPages, setTotalPages] = React.useState<number>(0);

  const statusList = {
    [ReceivablesStatus.AGENDADO]: () => <FiberManualRecordIcon className={classes.warning} />,
    [ReceivablesStatus.PAGO]: () => <FiberManualRecordIcon className={classes.success} />,
    [ReceivablesStatus.ADIADA]: () => <FiberManualRecordIcon className={classes.warning} />,
    [ReceivablesStatus.CANCELADA]: () => <FiberManualRecordIcon color="error" />,
    [ReceivablesStatus.PENDENTE]: () => <FiberManualRecordIcon className={classes.warning} />,
    [ReceivablesStatus.LIQUIDADA]: () => <FiberManualRecordIcon color="error" />,
    [ReceivablesStatus.OUTROS]: () => <FiberManualRecordIcon className={classes.warning} />,
    [ReceivablesStatus.BLOQUEADA]: () => <FiberManualRecordIcon color="error" />,
  };

  const getReceivablesList = React.useCallback(
    (currPage, callback: (data: ReceivablesData) => void) => {
      setIsReceivablesLoading(true);
      setReceivablesError(false);

      getReceivableService({
        startDate: date,
        endDate: date,
        currPage,
        status: ReceivablesUrToQueryParamsStatus[status] as ReceivablesQueryParamsStatus,
        cardBrand: cardBrand as Brands,
        operation:
          (operation?.valueOf() as ReceivablesQueryParamsOperation) === ReceivablesQueryParamsOperation.CREDIT
            ? [
                ReceivablesQueryParamsOperation.CREDIT,
                ReceivablesQueryParamsOperation.INSTALLMENT_WITHOUT_INTEREST,
                ReceivablesQueryParamsOperation.INSTALLMENT_WITH_INTEREST,
              ]
            : ReceivablesQueryParamsOperation.DEBIT,
      })
        .then(({ data }) => {
          if (data) {
            callback({ ...data });
            setTotalPages(data.totalPages);
            setCurrentPage(data.currentPage);
          } else {
            throw new Error('Nenhum recebível encontrado');
          }
        })
        .catch(() => setReceivablesError(true))
        .finally(() => setIsReceivablesLoading(false));
    },
    [date, cardBrand, operation, status],
  );

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const receivablesContent = () => {
    if (isReceivablesLoading) {
      return <ReceivablesSkeleton />;
    }

    if (receivablesError) {
      return (
        <ServerError
          onTryAgain={() => {
            getReceivablesList(currentPage, (data) => setReceivables(data));
          }}
        />
      );
    }

    if (receivables && receivables?.receivablesList.length) {
      return (
        <>
          {receivables?.receivablesList[0]?.items.map((item) => (
            <ReceivablesGroupItem key={item.orderId} receivable={item} />
          ))}
        </>
      );
    }

    return (
      <Grid container direction="column" justify="center" alignItems="center" item md={12} xs={12} sm={12}>
        <EmptyList subtitle="Você não tem recebíveis para exibir com o filtro selecionado." />
      </Grid>
    );
  };

  const mergePaginatedPayload = (oldRecord: ReceivablesData, newRecord: ReceivablesData) => {
    const local = [...oldRecord.receivablesList, ...newRecord.receivablesList];

    const items = local.reduce((acc: ReceivablesGroup[], curr: ReceivablesGroup) => {
      if (acc.length && acc[acc.length - 1].date && curr.date) {
        const accDate = acc[acc.length - 1].date as string;

        if (fullDateFormat(accDate) === fullDateFormat(curr.date)) {
          acc[acc.length - 1].items.push(...curr.items);
          return acc;
        }
      }

      acc.push(curr);
      return acc;
    }, []);

    return {
      currentPage: newRecord.currentPage,
      totalPages: newRecord.totalPages,
      receivablesList: items,
    };
  };

  const nextPage = () => {
    getReceivablesList(currentPage + 1, (data: ReceivablesData) => {
      const mergedData = mergePaginatedPayload(receivables!, data);
      setReceivables((mergedData as unknown) as ReceivablesData);
    });
  };

  React.useEffect(() => {
    if (expanded) {
      getReceivablesList(firstPage, (data) => setReceivables(data));
    }
  }, [getReceivablesList, expanded]);

  return (
    <Accordion expanded={expanded === 'um'} onChange={handleChange('um')} className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        className={classes.root}
      >
        <Grid item sm={8} md={9} xs={8}>
          <Box className={classes.column}>
            <Box className={classes.brandContainer}>{cardBrand && <BrandIcon brand={cardBrand} />}</Box>
            <Box className={classes.detail}>
              <Grid container direction="column">
                <Typography variant="caption" className={classes.carbon70Color}>
                  {capitalize(ReceivablesQueryUrParamsOperation[operation] || 'Outros')}
                </Typography>
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid item sm={4} md={2} xs={4} container justify="flex-start">
          <Box className={classes.valueContainer}>
            <Typography variant="caption" className={classes.carbon70Color}>
              {`R$ ${formatAmount(amount || 0)}`}
            </Typography>
          </Box>
          <Hidden smUp>
            <Box className={classes.valueStatusContainer}>
              <div className={classes.statusWrapper}>
                {status && statusList[(status as keyof ReceivablesStatus) as ReceivablesStatus]()}
                <Typography variant="caption">{status}</Typography>
              </div>
            </Box>
          </Hidden>
        </Grid>
        <Hidden smDown>
          <Grid item sm={2} md={2} xs={2}>
            <Box className={classes.valueStatusContainer}>
              <div className={classes.statusWrapper}>
                {status && statusList[(status as keyof ReceivablesStatus) as ReceivablesStatus]()}
                <Typography variant="caption">{status}</Typography>
              </div>
            </Box>
          </Grid>
        </Hidden>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12} container className={classes.receivablesGroupHeader}>
            <Grid item xs={4}>
              <Typography variant="caption3" className={classes.itemOperation}>
                Operação
              </Typography>
            </Grid>
            <Hidden smDown>
              <Grid item xs={2}>
                <Typography variant="caption3">Valor bruto</Typography>
              </Grid>
            </Hidden>
            <Hidden smDown>
              <Grid item xs={2}>
                <Typography variant="caption3">Descontos</Typography>
              </Grid>
            </Hidden>
            <Hidden smDown>
              <Grid item xs={2}>
                <Typography variant="caption3">Valor líquido</Typography>
              </Grid>
            </Hidden>
            <Hidden smDown>
              <Grid item xs={2}>
                <Typography variant="caption3">Status</Typography>
              </Grid>
            </Hidden>
          </Grid>
          <Grid item xs={12} container className={classes.receivablesGroup}>
            {receivablesContent()}
          </Grid>
          {receivables?.receivablesList && (
            <Grid item xs={12}>
              {totalPages > currentPage && (
                <Box display="flex" justifyContent="center">
                  <Button
                    type="submit"
                    title="Mostrar mais"
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={isReceivablesLoading}
                    onClick={nextPage}
                  >
                    MOSTRAR MAIS
                  </Button>
                </Box>
              )}
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default ReceivablesUr;
