import type { FunctionComponent } from 'react';
import React, { useState } from 'react';

import AuthFormsPasswordRecover from 'components/Auth/Forms/ForgotPassword';
import AuthFormsForgotSuccess from 'components/Auth/Forms/ForgotPassword/Success';
import { authRequests } from 'services/auth';

const AuthForgotPassword: FunctionComponent = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [isResendEmail, setIsResendEmail] = useState(false);
  const [success, setSuccess] = useState(false);

  const sendEmail = async (emailValue: string, resend: boolean) => {
    setLoading(true);
    await authRequests.forgotPassword(emailValue);

    if (resend) {
      setIsResendEmail(true);
    } else {
      setIsResendEmail(false);
    }

    setLoading(false);
    setSuccess(true);
  };

  const handleRender = () => {
    if (success) {
      return (
        <AuthFormsForgotSuccess loading={loading} isResendEmail={isResendEmail} email={email} resendEmail={sendEmail} />
      );
    }

    return (
      <AuthFormsPasswordRecover email={email} setEmail={setEmail} submit={sendEmail} error={false} loading={loading} />
    );
  };

  return handleRender();
};

export default AuthForgotPassword;
