import type { ElementType, PropsWithChildren, ReactElement } from 'react';
import React from 'react';

import type { ButtonProps as MuiButtonProps } from '@material-ui/core';
import { Button as MuiButton, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      borderRadius: theme.shape.borderRadius * 6,
      lineHeight: '18px',
    },
    sizeSmall: {
      fontSize: '0.75rem',
    },
    containedPrimary: {
      background: `linear-gradient(105.23deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
      '&$disabled': {
        background: theme.palette.backgroundColor.neutral?.light,
      },
      '&:hover': {
        background: `linear-gradient(105.23deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 100%)`,
      },
      '&:focus': {
        background: `linear-gradient(105.23deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 100%)`,
      },
      '& .TouchRipple-rippleVisible': {
        opacity: 0.75,
      },
      '& .MuiTouchRipple-child': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    containedSizeSmall: {
      padding: '9px 32px',
    },
    containedSizeLarge: {
      padding: '15px 32px',
    },
    outlined: {
      color: theme.palette.carbon[60],
      borderColor: theme.palette.carbon[35],
      '&:hover': {
        borderColor: theme.palette.backgroundColor.neutral?.mediumDark,
        backgroundColor: theme.palette.backgroundColor.surface?.marbleDark,
      },
      '&:focus': {
        color: theme.palette.carbon[60],
        backgroundColor: theme.palette.backgroundColor.surface?.marbleDark,
      },
      '&:active': {
        backgroundColor: theme.palette.backgroundColor.neutral?.light,
      },
      '&$focusVisible': {
        backgroundColor: theme.palette.backgroundColor.neutral?.light,
      },
      '& .MuiTouchRipple-child': {
        backgroundColor: 'unset',
      },
      '& .MuiCircularProgress-colorSecondary': {
        color: theme.palette.carbon[60],
      },
    },
    outlinedSizeSmall: {
      padding: theme.spacing(1, 4),
    },
    outlinedSizeLarge: {
      padding: '14px 32px',
    },
    text: {
      color: theme.palette.carbon[100],
      fontWeight: 'bold',
      borderRadius: 'unset',
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: theme.palette.backgroundColor.surface?.marbleDark,
      },
      '&:focus': {
        backgroundColor: theme.palette.carbon[10],
      },
      '&:active': {
        backgroundColor: theme.palette.carbon[10],
        '& .MuiTouchRipple-child': {
          backgroundColor: theme.palette.carbon[10],
        },
      },
      '& .MuiTouchRipple-child': {
        backgroundColor: 'unset',
      },
    },
    textSizeSmall: {
      fontSize: '0.8125rem',
    },
    textSizeLarge: {
      padding: '14px',
    },
    disabled: {},
    focusVisible: {},
  }),
  { name: 'MuiButton' },
);

type Props<C extends ElementType> = Omit<MuiButtonProps, 'component'> & {
  loading?: boolean;
  error?: boolean;
  component?: C;
  componentProps?: React.ComponentProps<C>;
};

export function Button<C extends ElementType>({
  children,
  loading,
  error,
  variant,
  size,
  startIcon,
  endIcon,
  disabled,
  component,
  componentProps,
  ...props
}: PropsWithChildren<Props<C>>) {
  useStyles();

  const Loader = (): ReactElement => {
    let loaderSize = null;

    switch (size) {
      case 'small':
        loaderSize = 12;
        break;
      case 'large':
        loaderSize = 24;
        break;
      case 'medium':
        loaderSize = 18;
        break;

      default:
        loaderSize = 16;
        break;
    }

    return <CircularProgress size={loaderSize} />;
  };

  return (
    <MuiButton
      variant={variant}
      size={size}
      startIcon={startIcon}
      endIcon={endIcon}
      component={component as ElementType}
      disabled={disabled || loading}
      {...componentProps}
      {...props}
    >
      {loading ? <Loader /> : children}
    </MuiButton>
  );
}

export default Button;
