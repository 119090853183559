import type { FunctionComponent, ReactElement } from 'react';
import React, { useState } from 'react';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';

import DirfModal from 'components/Dirf';
import Button from 'components/UI/Button';
import Feature from 'components/UI/Feature';
import Typography from 'components/UI/Typography';
import { Features } from 'models/featureToggle';

const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  reportButton: {
    color: theme.palette.textColor.link,
    fontSize: '1rem',
    textTransform: 'none',
    borderRadius: '8px',
  },
}));

const MySummariesHeader: FunctionComponent = (): ReactElement => {
  const classes = useStyles();

  const [isDirfModalOpen, setIsDirfModalOpen] = useState(false);

  return (
    <Box className={classes.headerWrapper}>
      <Typography variant="subtitleWebBold">Resumos</Typography>
      <Feature name={Features.DIRF}>
        <Button
          variant="text"
          size="large"
          type="button"
          aria-label="Baixar DIRF."
          startIcon={<GetAppIcon />}
          onClick={() => setIsDirfModalOpen(true)}
          className={classes.reportButton}
        >
          Baixar DIRF
        </Button>
        <DirfModal isOpen={isDirfModalOpen} setIsOpen={setIsDirfModalOpen} />
      </Feature>
    </Box>
  );
};

export default MySummariesHeader;
