import React, { useState, useRef, useEffect } from 'react';
import { useIsVisible } from 'react-is-visible';

import { Grid, Card, makeStyles, CardActions, useMediaQuery, CardMedia, CardHeader } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import ReadMore from 'components/ReadMore';
import Button from 'components/UI/Button';
import Typography from 'components/UI/Typography';
import { fullDateFormat } from 'helpers/date';
import { markAsRead } from 'services/notification';

const useStyles = makeStyles((theme) => ({
  wrapperTitle: {
    margin: theme.spacing(2, 0, 1),
  },
  date: {
    color: theme.palette.carbon[70],
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    borderRadius: theme.shape.borderRadius,
  },
  buttonText: {
    color: theme.palette.textColor.link,
  },
  cardHeader: {
    padding: 0,
    '& > .MuiCardHeader-action': {
      marginTop: 0,
    },
  },
  cardActions: {
    padding: 0,
  },
  monthTitle: {
    padding: theme.spacing(2, 0),
  },
  notificationWrapper: {
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  circleNotificationIcon: {
    color: theme.palette.composition.status?.active,
    fontSize: '1rem',
  },
}));

type NotificationProps = {
  id: number;
  createdAt: string | Date;
  subject: string;
  description: string;
  imageUrl?: string;
  isRead: boolean;
  cta?: {
    url: string;
    text: string;
  };
};

const Notification = ({
  id,
  subject,
  description,
  createdAt,
  imageUrl,
  cta,
  isRead,
}: NotificationProps): JSX.Element => {
  const classes = useStyles();

  const divRef = useRef<HTMLDivElement>(null);
  const isVisible = useIsVisible(divRef, { once: true });
  const [notificationVisible, setNotificationVisible] = useState(isRead);

  const matchesSmDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const textLength = matchesSmDown ? 130 : 121;

  const [isReadMore, setIsReadMore] = useState<boolean>(true);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  useEffect(() => {
    if (!isRead && isVisible) {
      markAsRead(id).then(({ data }) => {
        if (data) {
          setNotificationVisible(true);
        }
      });
    }
  }, [isRead, id, isVisible]);

  return (
    <div id={id.toString()} ref={divRef} className={classes.notificationWrapper}>
      <Card variant="outlined">
        <CardHeader
          className={classes.cardHeader}
          action={
            notificationVisible ? (
              <DoneAllIcon className={classes.circleNotificationIcon} />
            ) : (
              <FiberManualRecordIcon className={classes.circleNotificationIcon} />
            )
          }
          title={<Typography variant="subtitle2">{subject}</Typography>}
          subheader={<Typography variant="caption">{fullDateFormat(createdAt)}</Typography>}
        />
        {imageUrl && <CardMedia className={classes.media} title={subject} image={imageUrl} />}
        <ReadMore text={description} isReadMore={isReadMore} />
        <CardActions className={classes.cardActions}>
          <Grid container justify={cta ? 'space-between' : 'flex-end'} item xs={12}>
            {cta && (
              <Button
                component="a"
                componentProps={{
                  href: cta?.url,
                  target: '_blank',
                  rel: 'noreferrer noopener',
                }}
                variant="contained"
                color="primary"
              >
                {cta?.text}
              </Button>
            )}
            {description.length > textLength && (
              <Button
                size="small"
                variant="text"
                onClick={toggleReadMore}
                endIcon={isReadMore ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                className={classes.buttonText}
              >
                {isReadMore ? 'Exibir mais' : 'Exibir menos'}
              </Button>
            )}
          </Grid>
        </CardActions>
      </Card>
    </div>
  );
};

export default Notification;
