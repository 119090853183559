import React from 'react';
import type { FunctionComponent } from 'react';

import type { Nullable } from '@portal-types/helpers';

import { Box, useMediaQuery } from '@material-ui/core';
import type { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import MailRoundedIcon from '@material-ui/icons/MailRounded';

import { ReactComponent as WhatsAppIcon } from 'assets/images/whatsapp.svg';
import Button from 'components/UI/Button';
import Typography from 'components/UI/Typography';

const useStyles = makeStyles((theme) => ({
  shareButtonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(2, 0),
    '& a:hover': {
      textDecoration: 'none',
    },
  },
  shareButton: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    textTransform: 'none',
    borderRadius: '8px',
    padding: theme.spacing(1),
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      '&.MuiLink-root': {
        minWidth: 40,
      },
      '& .MuiButton-startIcon': {
        margin: 0,
      },
    },
  },
}));

type Props = {
  link: Nullable<string>;
};

const PaymentLinkShareButtons: FunctionComponent<Props> = ({ link }) => {
  const classes = useStyles();
  const matchesXsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

  return (
    <>
      {link && (
        <Box className={classes.shareButtonsWrapper}>
          <Typography variant="caption">Compartilhar via</Typography>
          <Button
            variant="text"
            size="medium"
            color="secondary"
            type="button"
            startIcon={<WhatsAppIcon fontSize="small" />}
            className={classes.shareButton}
            component="a"
            aria-label="Compartilhar link criado por Whatsapp."
            href={`https://wa.me/?text=Sua%20compra%20est%C3%A1%20prestes%20a%20ser%20conclu%C3%ADda,%20aqui%20est%C3%A1%20seu%20link%20dispon%C3%ADvel%20para%20pagamento%20em%20${link}`}
            componentProps={{
              target: '_blank',
              rel: 'noreferrer noopener',
            }}
          >
            {matchesXsDown ? null : 'WhatsApp'}
          </Button>

          <Button
            variant="text"
            size="medium"
            color="secondary"
            type="button"
            startIcon={
              matchesXsDown ? <MailRoundedIcon fontSize="small" /> : <MailOutlineRoundedIcon fontSize="small" />
            }
            className={classes.shareButton}
            component="a"
            aria-label="Compartilhar link criado por e-mail."
            href={`mailto:?subject=Sua%20compra%20est%C3%A1%20prestes%20a%20ser%20conclu%C3%ADda&body=Sua%20compra%20est%C3%A1%20prestes%20a%20ser%20conclu%C3%ADda%C2%B8%20aqui%20est%C3%A1%20seu%20link%20dispon%C3%ADvel%20para%20pagamento%20em%20${link}`}
            componentProps={{
              target: '_blank',
              rel: 'noreferrer noopener',
            }}
          >
            {matchesXsDown ? null : 'E-mail'}
          </Button>
        </Box>
      )}
    </>
  );
};

export default PaymentLinkShareButtons;
