import React from 'react';
import type { FunctionComponent } from 'react';

import { Box, IconButton, SwipeableDrawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import Typography from 'components/UI/Typography';

const useStyles = makeStyles((theme) => ({
  drawer: {
    '& > .MuiPaper-root': {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        borderRadius: `${theme.shape.borderRadius * 4}px 0 0 0`,
        width: 360,
      },
    },
  },
  closeButtonBox: {
    padding: theme.spacing(2),
  },
  closeButton: {
    padding: theme.spacing(1),
  },
  closeIcon: {
    color: theme.palette.carbon[100],
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    marginBottom: theme.spacing(3),
    color: theme.palette.carbon[100],
  },
}));

type Props = {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  title?: string;
};

const DetailsDrawer: FunctionComponent<Props> = ({ open, onClose, onOpen, title, children }) => {
  const classes = useStyles();

  return (
    <SwipeableDrawer
      className={classes.drawer}
      anchor="right"
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      aria-label="drawer lateral"
      disableSwipeToOpen
    >
      <Box className={classes.closeButtonBox}>
        <IconButton className={classes.closeButton} onClick={onClose} aria-label="Fechar janela.">
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </Box>
      <Box className={classes.container}>
        <Box className={classes.content}>
          {title && (
            <Box mb={2}>
              <Typography variant="body">{title}</Typography>
            </Box>
          )}
          {children}
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

export default DetailsDrawer;
