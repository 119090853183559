export const filterStatus: Array<{ value: string; label: string }> = [
  { value: 'inSeparation', label: 'Em separação' },
  { value: 'withCarrier', label: 'Com a transportadora' },
  { value: 'onDeliveryRoute', label: 'Em rota de entrega' },
  { value: 'delivered', label: 'Entregue' },
  { value: 'deliveredFailed', label: 'Falha na entrega' },
];

export enum OrderType {
  COIL = 'coil',
  CHARGER = 'charger',
}

export const MachineOptions: Array<{ value: string; label: string }> = [
  { value: '720054 - Carregador N910720054 - Carregador N910', label: 'Smart' },
  { value: '720053 - Carregador D195 USB', label: 'SuperMini' },
  { value: '720052 - Carregador S920 Mini Base', label: 'Essencial' },
];

export const ChangeReasonOptions: Array<{ value: string; label: string }> = [
  { value: 'Cabo rompido', label: 'Cabo rompido' },
  { value: 'Carregador quebrado', label: 'Carregador quebrado' },
  { value: 'Mau contato', label: 'Mau contato' },
  { value: 'Não está carregando', label: 'Não está carregando' },
  { value: 'Perda do carregador', label: 'Perda do carregador' },
  { value: 'Outros', label: 'Outros' },
];
