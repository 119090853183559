import React, { FunctionComponent } from 'react';

import { Box } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import Typography from 'components/UI/Typography';
import { fullDateFormat } from 'helpers/date';
import { formatAmount } from 'helpers/formatters/formatters';
import { AnticipationData } from 'models/anticipation';

import AcquirerLabel from '../AcquirerLabel';
import AnticipationStatus from '../AnticipationStatus/AnticipationStatus';

type Props = Omit<
  AnticipationData,
  'status' | 'grossAmount' | 'taxAmount' | 'startAnticipatedDate' | 'endAnticipatedDate'
> & {
  status: {
    text?: string;
    icon: typeof SvgIcon;
    variant: 'success' | 'error' | 'pending';
  };
};

const AnticipationListItemDesktop: FunctionComponent<Props> = ({ createdAt, id, acquirers, netAmount, status }) => {
  return (
    <>
      <Box display="flex" width="100%">
        <Box width="30%">
          <Typography variant="caption">{fullDateFormat(createdAt)}</Typography>
        </Box>
        <Box width="30%">
          <Typography variant="caption">{`#${id}`}</Typography>
        </Box>
        <Box width="40%">
          <AcquirerLabel
            documentNumber={acquirers.map((acquirer) => acquirer.documentNumber)}
            name={acquirers.map((acquirer) => acquirer.name)}
          />
        </Box>
      </Box>
      <Box display="flex" width="100%">
        <Box width="40%" />
        <Box width="30%">
          <Typography variant="caption">{`R$ ${formatAmount(netAmount)}`}</Typography>
        </Box>
        <Box width="30%" display="flex" justifyContent="space-between" alignItems="center">
          <AnticipationStatus text={status.text} icon={status.icon} variant={status.variant} />
          <ArrowForwardIosIcon fontSize="small" />
        </Box>
      </Box>
    </>
  );
};

export default AnticipationListItemDesktop;
