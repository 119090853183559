export enum Features {
  ANTICIPATION = 'anticipation',
  PAYMENT_LINK = 'payment_link',
  MY_SUMMARIES = 'my_summaries',
  ACCOUNT = 'account',
  COMMERCIAL_CONDITIONS = 'commercial_conditions',
  SALES = 'sales',
  SALES_PENDING_ACTIONS = 'sales_pending_actions',
  HELP = 'help',
  RECEIVABLES = 'receivables',
  RECEIVABLES_ALERT_STATUS = 'receivables_alert_status',
  MACHINES = 'machines',
  TAX_SIMULATOR = 'tax_simulator',
  CONCILIATOR = 'conciliator',
  OFFERS = 'offers',
  EDI_REPORT = 'edi_report',
  PENDING_RECEIVABLES = 'pending_receivables',
  NOTIFICATIONS = 'notifications',
  DIRF = 'dirf',
  ORDER_FORM = 'order_form',
}

export type FeatureFlags = {
  anticipation: boolean;
  payment_link: boolean;
  account: boolean;
  help: boolean;
  commercial_conditions: boolean;
  my_summaries: boolean;
  receivables: boolean;
  sales: boolean;
  sales_pending_actions: boolean;
  receivables_alert_status: boolean;
  machines: boolean;
  tax_simulator: boolean;
  conciliator: boolean;
  offers: boolean;
  edi_report: boolean;
  pending_receivables: boolean;
  notifications: boolean;
  dirf: boolean;
  order_form: boolean;
};
