import type { FunctionComponent } from 'react';
import React, { useState } from 'react';

import { Box, useMediaQuery } from '@material-ui/core';
import type { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Error';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import Button from 'components/UI/Button';
import Typography from 'components/UI/Typography';
import { fullDateFormat } from 'helpers/date';
import { formatIntToAmount } from 'helpers/formatters/formatters';
import { PaymentLink, PaymentLinkStatus } from 'models/paymentLink';

import PaymentLinkItemShare from './Share';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 1),
    borderBottom: `1px solid ${theme.palette.carbon[10]}`,
    '&:hover button': {
      display: 'inline-flex',
    },
  },
  amount: {
    whiteSpace: 'nowrap',
  },
  statusContainer: {
    width: 60,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: theme.spacing(5),

    [theme.breakpoints.down('xs')]: {
      width: 24,
      marginLeft: theme.spacing(2),
      '& span': {
        display: 'none',
      },
    },
  },
  button: {
    padding: theme.spacing(1, 2),
    display: 'none',
    whiteSpace: 'nowrap',

    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
    },
  },
  carbon70: {
    color: theme.palette.carbon[70],
  },
}));

type Props = {
  item: PaymentLink;
  isLoading: boolean;
};

const PaymentLinkItem: FunctionComponent<Props> = ({ item, isLoading }) => {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);

  const { amount, status: statusItem, description, expirationDate, link, id } = item;
  const status = statusItem || ('' as PaymentLinkStatus);
  const openPayment = [PaymentLinkStatus.REFUSED, PaymentLinkStatus.PENDING].includes(status);
  const matchesSmDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const statuses: { [value in PaymentLinkStatus | '']: Record<string, JSX.Element | null> } = {
    approved: {
      statusContainer: (
        <>
          <Typography variant="caption">Pago</Typography>
          <DoneIcon fontSize="small" />
        </>
      ),
      typographyDetail: null,
    },
    pending: {
      statusContainer: (
        <>
          <Typography variant="caption">Pendente</Typography>
          <QueryBuilderIcon fontSize="small" />
        </>
      ),
      typographyDetail: (
        <Typography className={classes.carbon70} variant="caption">
          {`Expira em ${fullDateFormat(expirationDate || '')}`}
        </Typography>
      ),
    },
    expired: {
      statusContainer: (
        <>
          <Typography variant="caption">Expirado</Typography>
          <RemoveCircleIcon fontSize="small" />
        </>
      ),
      typographyDetail: (
        <Typography className={classes.carbon70} variant="caption">
          Tempo expirado. É preciso gerar outro link.
        </Typography>
      ),
    },
    refused: {
      statusContainer: (
        <>
          <Typography variant="caption">Recusado</Typography>
          <ErrorIcon fontSize="small" />
        </>
      ),
      typographyDetail: (
        <Typography className={classes.carbon70} variant="caption">
          {`Expira em ${fullDateFormat(expirationDate || '')}`}
        </Typography>
      ),
    },
    '': {
      statusContainer: <></>,
      typographyDetail: <></>,
    },
  };

  const shareLink = () => {
    if (matchesSmDown && openPayment) {
      return setOpenModal(true);
    }
    return false;
  };

  const renderButton = () =>
    (!isLoading || openModal) && (
      <Button
        title="Compartilhar link"
        variant="contained"
        color="primary"
        size="small"
        className={classes.button}
        onClick={() => setOpenModal(true)}
      >
        Compartilhar link
      </Button>
    );

  return (
    <Box className={classes.root}>
      <Box display="flex" justifyContent="space-between" alignItems="center" onClick={shareLink}>
        <Box>
          <Typography variant="body">{description || 'Sem descrição'}</Typography>
          {statuses[status]?.typographyDetail}
        </Box>
        <Box display="flex" alignItems="center" ml={2}>
          {!matchesSmDown && openPayment && <Box mr={4}>{renderButton()}</Box>}
          <Box>
            <Typography variant="body" className={classes.amount}>{`R$ ${formatIntToAmount(amount || 0)}`}</Typography>
          </Box>
          <Box className={classes.statusContainer}>{statuses[status]?.statusContainer}</Box>
        </Box>
      </Box>
      {openPayment && (
        <PaymentLinkItemShare
          data={{ amount, description, expirationDate, link, id }}
          open={openModal}
          onClose={() => setOpenModal(false)}
        />
      )}
    </Box>
  );
};

export default PaymentLinkItem;
