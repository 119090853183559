import { BusinessDataDictionary, AddressDictionary, ContactDictionary, BankDictionary } from 'models/account';

export const businessDataDictionary: BusinessDataDictionary = {
  type: 'Tipo legal',
  cpf: 'CPF',
  cnpj: 'CNPJ',
  corporateName: 'Nome',
  brandName: 'Nome fantasia',
};

export const addressDictionary: AddressDictionary = {
  country: 'País',
  state: 'Estado',
  city: 'Cidade',
  zipCode: 'CEP',
  neighborhood: 'Bairro',
  addressName: 'Rua',
  addressNumber: 'Número',
  complementaryAddress: 'Complemento',
};

export const contactDictionary: ContactDictionary = {
  name: 'Contato',
  email: 'E-mail',
  cellPhone: 'Telefone Celular',
  commercialPhone: 'Telefone Comercial',
};

export const bankDictionary: BankDictionary = {
  holder: 'Nome do Titular',
  accountNumber: 'Conta',
  branchNumber: 'Agência',
  bankName: 'Banco',
};
