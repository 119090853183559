import { addSeconds, formatISO } from 'date-fns';
import { client, HTTPError } from 'services/client';

import type { LoginActionResponseBody } from './types';

const parseAuthData = (parsedBody: LoginActionResponseBody | undefined) => {
  if (parsedBody) {
    return {
      accessToken: parsedBody?.access_token as string,
      refreshToken: parsedBody?.refresh_token as string,
      expirationDate: formatISO(addSeconds(new Date(), parsedBody?.expires_in as number)),
    };
  }

  return {
    accessToken: '',
    refreshToken: '',
    expirationDate: '',
  };
};

export const authRequests = {
  forgotPassword: async (email: string) => {
    try {
      await client.post(`user/v1/users/${email}/forgot-password`, {
        data: {
          email,
        },
        headers: {
          'whitelabel-source': window.location.host,
        },
        useWhitelabel: true,
      });
      return true;
    } catch (err) {
      return false;
    }
  },
  refreshCredentials: async (refreshToken: string) => {
    try {
      const response = await client.post<LoginActionResponseBody>('portal/v1/authorization', {
        data: {
          refresh_token: refreshToken,
          grant_type: 'refresh_token',
        },
        useWhitelabel: true,
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const authData = parseAuthData(response.parsedBody);

      return authData;
    } catch (e) {
      if (e instanceof HTTPError) {
        switch (e.response.status) {
          case 401:
            throw e.response.parsedBody.error;
          default:
            throw new Error('Erro na requisição');
        }
      } else {
        throw e;
      }
    }
  },
  login: async (email: string, password: string, reCaptchaResponse: string) => {
    const response = await client.post<LoginActionResponseBody>('portal/v1/authorization', {
      data: {
        email,
        password,
        grant_type: 'password',
        'g-recaptcha-response': reCaptchaResponse,
      },
      useWhitelabel: true,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const authData = parseAuthData(response.parsedBody);

    const accountData = {
      email,
      userDocuments: response.parsedBody?.userDocuments,
    };

    return { authData, accountData };
  },
};
