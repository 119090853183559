import React, { FunctionComponent } from 'react';

import { Box } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import Typography from 'components/UI/Typography';
import { fullDateFormat } from 'helpers/date';
import { formatAmount } from 'helpers/formatters/formatters';
import { AnticipationData } from 'models/anticipation';

import AnticipationStatus from '../AnticipationStatus/AnticipationStatus';

type Props = Omit<
  AnticipationData,
  'status' | 'grossAmount' | 'taxAmount' | 'id' | 'acquirers' | 'startAnticipatedDate' | 'endAnticipatedDate'
> & {
  status: {
    icon: typeof SvgIcon;
    variant: 'success' | 'error' | 'pending';
  };
};

const AnticipationListItemDesktop: FunctionComponent<Props> = ({ createdAt, netAmount, status }) => {
  return (
    <>
      <Box display="flex" width="100%">
        <Box width="40%">
          <Typography variant="caption">{fullDateFormat(createdAt)}</Typography>
        </Box>
        <Box width="40%">
          <Typography variant="caption">{`R$ ${formatAmount(netAmount)}`}</Typography>
        </Box>
        <Box width="20%" display="flex" justifyContent="space-between" alignItems="center">
          <AnticipationStatus icon={status.icon} variant={status.variant} />
          <ArrowForwardIosIcon fontSize="small" />
        </Box>
      </Box>
    </>
  );
};

export default AnticipationListItemDesktop;
