import React, { FunctionComponent } from 'react';

import { Box, Card, Grid, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CampaignFree from 'assets/images/campaign-free.png';
import PageContainer from 'components/UI/PageContainer';
import PageTitle from 'components/UI/PageTitle';
import Typography from 'components/UI/Typography';

const useStyles = makeStyles((theme) => ({
  card: {
    background: `linear-gradient(134.83deg, #4F4F4F 0%, ${theme.palette.carbon[100]} 100%)`,
    padding: theme.spacing(4),
    margin: 'auto',
    color: theme.palette.common.white,
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4),
      width: 400,
    },
  },
  subtitle: {
    '& > strong': {
      display: 'block',
    },
  },
  boxTable: {
    border: `1px solid ${theme.palette.composition.inverseStroke?.[32]}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    marginBottom: 0,
  },
  gridOffers: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
  },
  pos: {
    display: 'flex',
    flexDirection: 'column',
  },
  goal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  link: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
}));

const CardOffers: FunctionComponent = () => {
  const classes = useStyles();

  const link = 'https://cdn.c6bank.com.br/c6-site-docs/regulamento-campanha-adesao-zero-C6-pay.pdf';

  return (
    <Card className={classes.card}>
      <img alt="imagem de adesão zero" src={CampaignFree} />

      <Box mt={3}>
        <Typography variant="subtitle" paragraph align="center" className={classes.subtitle}>
          <strong>Adesão Zero C6 Pay</strong>
          Você pode ter isenção na taxa da maquininha!
        </Typography>
        <Typography variant="caption2" paragraph component="p" align="left">
          Basta atingir um faturamento mínimo mensal e receber as vendas pelo C6 Bank.
        </Typography>
      </Box>

      <Box className={classes.boxTable} p={1} mb={3} width={253} maxWidth={336}>
        <Grid item xs={12}>
          <Typography variant="caption3">Meta do faturamento mensal:</Typography>
        </Grid>
        <Box className={classes.gridOffers}>
          <Box className={classes.pos}>
            <Typography variant="caption2">C6 Pay SuperMini</Typography>
            <Typography variant="caption2">C6 Pay Essencial</Typography>
            <Typography variant="caption2">C6 Pay Smart</Typography>
          </Box>
          <Box className={classes.goal}>
            <Typography variant="caption2">R$ 3.500</Typography>
            <Typography variant="caption2">R$ 5.000</Typography>
            <Typography variant="caption2">R$ 5.000</Typography>
          </Box>
        </Box>
      </Box>
      <Box mt={3}>
        <Typography variant="caption" paragraph align="left" className={classes.subtitle}>
          <strong>Já pagou a taxa esse mês?</strong>
          Não tem problema, o valor será reembolsado na próxima agenda de recebíveis.
        </Typography>
      </Box>
      <Link href={link} className={classes.link} target="_blank" rel="noreferrer noopener" color="inherit">
        <Typography variant="bodylink2" align="left" color="inherit">
          Consulte o regulamento.
        </Typography>
      </Link>
    </Card>
  );
};

const Offers: FunctionComponent = () => {
  return (
    <PageContainer>
      <Grid item xs={12}>
        <PageTitle title="Ofertas" isLoading={false} />
      </Grid>

      <Grid item xs={12}>
        <CardOffers />
      </Grid>
    </PageContainer>
  );
};

export default Offers;
