import React from 'react';
import type { FunctionComponent } from 'react';

import { ReactComponent as CieloIcon } from 'assets/images/acquirer-cielo.svg';
import { ReactComponent as GetnetIcon } from 'assets/images/acquirer-getnet.svg';
import { ReactComponent as PaygoIcon } from 'assets/images/acquirer-paygo.svg';
import { ReactComponent as RedeIcon } from 'assets/images/acquirer-rede.svg';
import { ReactComponent as SafrapayIcon } from 'assets/images/acquirer-safrapay.svg';
import { ReactComponent as StoneIcon } from 'assets/images/acquirer-stone.svg';
import Typography from 'components/UI/Typography';

type AcquirerDictionary = {
  [key: string]: JSX.Element;
};

type Props = {
  documentNumber: string | string[];
  name: string | string[];
};

const AcquirerLabel: FunctionComponent<Props> = ({ documentNumber, name }) => {
  const acquirerDictionary: AcquirerDictionary = {
    '01027058000191': <CieloIcon />,
    '01425787000104': <RedeIcon />,
    '10440482000154': <GetnetIcon />,
    '58160789000128': <SafrapayIcon />,
    '16501555000157': <StoneIcon />,
    '06167186000154': <PaygoIcon />,
  };

  const acquirerDocumentNumber = typeof documentNumber === 'string' ? documentNumber : documentNumber[0];
  const acquirerName = typeof name === 'object' ? name.join(' | ') : name;

  const hasAcquirerInDictionary = acquirerDocumentNumber in acquirerDictionary;
  const hasManyNames = typeof name === 'object' && name.length > 1;

  return (
    <Typography variant="subtitleWebUppercaseSmall">
      {!hasAcquirerInDictionary || hasManyNames ? acquirerName : acquirerDictionary[acquirerDocumentNumber]}
    </Typography>
  );
};

export default AcquirerLabel;
