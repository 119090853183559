import { format, isToday, parseISO, isValid } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const parseDate = (date: string | Date): Date => {
  if (typeof date === 'object') {
    return new Date(date);
  }

  return parseISO(date);
};

export const hoursAndMinutes = (date: string | Date | undefined, secondaryFormat?: boolean) => {
  if (!date) {
    return secondaryFormat ? '00h00' : '00:00';
  }
  return format(parseDate(date), secondaryFormat ? "HH'h'mm" : 'HH:mm', { locale: ptBR });
};

export const longLocalizedDate = (date: string | Date | undefined) => {
  if (!date) {
    return '1 de Janeiro de 2021';
  }

  return isToday(parseDate(date))
    ? format(parseDate(date), "'Hoje'", { locale: ptBR })
    : format(parseDate(date), 'PPP', { locale: ptBR });
};

export const returnWeekday = (date: string | Date | undefined) => {
  if (!date) {
    return 'Domingo';
  }
  const weekday = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];
  const day = parseDate(date).getDay();

  return weekday[day];
};

export const datePickerFormat = (date: string | Date | undefined) => {
  const defaultValue = '01/01/2021';
  if (!date) return defaultValue;
  if (!isValid(date)) return defaultValue;
  return format(parseDate(date), 'dd/MM/yyyy', { locale: ptBR });
};

export const fullDateFormat = (date: string | Date | undefined) => {
  if (!date) {
    return '01/01/2021';
  }

  return format(parseDate(date), 'dd/MM/yyyy', { locale: ptBR });
};

export const formatRequestDate = (date: string | Date | undefined) => {
  if (!date) {
    return '2021-01-01';
  }

  return format(parseDate(date), 'yyyy-MM-dd');
};

export const longDateAndTime = (date: string | Date | undefined) => {
  if (!date) {
    return '01/01/21 00:00';
  }

  return format(parseDate(date), "dd'/'MM'/'Y' 'HH':'mm", { locale: ptBR });
};

export const returnMonth = (date: string): string => {
  const monthMap: { [key: string]: string } = {
    '01': 'Janeiro',
    '02': 'Fevereiro',
    '03': 'Março',
    '04': 'Abril',
    '05': 'Maio',
    '06': 'Junho',
    '07': 'Julho',
    '08': 'Agosto',
    '09': 'Setembro',
    '10': 'Outubro',
    '11': 'Novembro',
    '12': 'Dezembro',
  };
  const [month] = date.split('-');

  return monthMap[month];
};
