import React, { useState } from 'react';

import { authRequests } from 'services/auth';

import CreatePasswordEmailFrom from './CreatePasswordEmailFrom';
import CreatePasswordSuccess from './CreatePasswordSuccess';

const CreatePassword = (): JSX.Element => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [isResendEmail, setIsResendEmail] = useState(false);
  const [success, setSuccess] = useState(false);

  const sendEmail = async (emailValue: string, resend: boolean) => {
    setLoading(true);
    await authRequests.forgotPassword(emailValue);

    if (resend) {
      setIsResendEmail(true);
    } else {
      setIsResendEmail(false);
    }

    setLoading(false);
    setSuccess(true);
  };

  const handleRenderContent = () => {
    if (success) {
      return (
        <CreatePasswordSuccess isResendEmail={isResendEmail} email={email} resendEmail={sendEmail} loading={loading} />
      );
    }
    return <CreatePasswordEmailFrom email={email} setEmail={setEmail} loading={loading} submit={sendEmail} />;
  };

  return handleRenderContent();
};

export default CreatePassword;
