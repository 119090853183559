import React, { ReactElement } from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Grid, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Typography from 'components/UI/Typography';
import { ContentPage, FaqType } from 'models/faq';

const useStyle = makeStyles((theme) => ({
  link: {
    cursor: 'pointer',
  },
}));

type FaqAccordionProps = {
  faqList: FaqType;
  setAnswer: (element: ReactElement) => void;
  setTitleFaq: (title: string) => void;
  setContentPage: (contentPage: ContentPage) => void;
};

const FaqAccordion = ({ faqList, setAnswer, setTitleFaq, setContentPage }: FaqAccordionProps): JSX.Element => {
  const classes = useStyle();
  const formatDrawer = (title: string, answerElement: ReactElement, contentPage: ContentPage) => {
    setAnswer(answerElement);
    setTitleFaq(title);
    setContentPage(contentPage);
  };

  return (
    <>
      {faqList.map(({ doubt, doubtList }) => (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="faq1-content" id="faq1-header">
            <Typography variant="subtitleMobile">{doubt}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction="column">
              {doubtList.map(({ title, answer }) => (
                <Typography
                  className={classes.link}
                  variant="bodyBold"
                  gutterBottom
                  onClick={() => formatDrawer(title, answer, 'answer')}
                >
                  {title}
                </Typography>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default FaqAccordion;
