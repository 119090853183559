import { SvgIcon } from '@material-ui/core';

export enum AnticipationSteps {
  INIT = 'init',
  CONFIRMATION = 'confirmation',
  SUCCESS = 'success',
}

export type AnticipationResquestData = Array<{
  simulationId: number;
  grossAmount: number;
  taxAmount: number;
}>;

export type AnticipationResponseData = Array<{
  id: number;
  createdAt: string;
}>;

export type SimulationResponseData = {
  status: 'success' | 'unavailable';
  grossAmount: number;
  taxAmount: number;
  netAmount: number;
  acquirers: Array<SimulationAcquirer>;
};

export type SimulationAcquirer = {
  simulationId: number;
  acquirerName: string;
  acquirerDocumentNumber: string;
  grossAmount: number;
  netAmount: number;
  taxAmount: number;
};

export type AcquirersInputValues = {
  [key: string]: {
    initialValue: number;
    value: number;
    active: boolean;
    disabled: boolean;
    error: boolean;
  };
};

export type AnticipationData = {
  id: number;
  acquirers: Array<{
    documentNumber: string;
    name: string;
  }>;
  grossAmount: number;
  netAmount: number;
  taxAmount: number;
  status: AnticipationStatusEnum;
  startAnticipatedDate: string;
  endAnticipatedDate: string;
  createdAt: string;
};

export type AnticipationListData = Array<AnticipationData>;

export enum AnticipationStatusEnum {
  REVIEWING = 'reviewing',
  CANCELED = 'canceled',
  REFUSED = 'refused',
  APPROVED = 'approved',
}

export type AnticipationItemStatusDictionary = {
  [key in AnticipationStatusEnum]: AnticipationItemStatusDictionaryValues;
};

export type AnticipationItemStatusDictionaryValues = {
  text: string;
  variant: 'pending' | 'error' | 'success';
  icon: typeof SvgIcon;
};
