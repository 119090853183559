import React from 'react';

import { Box, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as PixIcon } from 'assets/images/pix-icon.svg';
import Typography from 'components/UI/Typography';

import { TaxPixType } from './constants';

const useStyles = makeStyles((theme) => ({
  pixIcon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    '& > svg': {
      flexShrink: 0,
      color: theme.palette.primary.main,
      fill: theme.palette.primary.main,
    },
  },
}));

type PixConditionsCardProps = {
  userPortal: 'full' | 'tef';
};

const PixConditionsCard = ({ userPortal }: PixConditionsCardProps) => {
  const classes = useStyles();

  return (
    <Card>
      <Box display="flex">
        <PixIcon className={classes.pixIcon} />
        <Box marginLeft={2}>
          <Typography variant="bodyBold">Transações via Pix</Typography>
          <Typography variant="paragraph">{`Tarifa fixa de ${TaxPixType[userPortal]} por pagamento confirmado`}</Typography>
          <Typography variant="caption">A tarifa por venda confirmada tem o valor máximo de R$ 9,00</Typography>
        </Box>
      </Box>
    </Card>
  );
};

export default PixConditionsCard;
