import { createAction } from '@reduxjs/toolkit';

import type { SaveAccountAction } from './types';

type SaveAccountActionData = SaveAccountAction['input']['data'];

export const saveAccount = createAction<SaveAccountAction['input']>('account/save-account');
export const changeDocumentNumber = createAction<SaveAccountActionData['documentNumber']>(
  'account/change-documentNumber',
);
export const changeUserStatus = createAction<SaveAccountActionData['status']>('account/change-userStatus');
export const changeUserName = createAction<SaveAccountActionData['name']>('account/change-userName');
export const changeUserPortal = createAction<SaveAccountActionData['userPortal']>('account/change-userPortal');
export const clearPersisted = createAction('account/clear-persisted');
