import React from 'react';
import type { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router';

import { Box } from '@material-ui/core';
import { fade, makeStyles } from '@material-ui/core/styles';

import DownloadCard from 'components/DownloadCard';
import ErrorBoundary from 'components/ErrorBoundary';
import Header from 'components/Header';
import OuibounceModal from 'components/OuibounceModal';
import SideMenu from 'components/SideMenu';
import { DownloadAreaSelectors } from 'store/download';
import { DownloadAreaData } from 'store/download/types';

const useStyles = makeStyles((theme) => {
  const colorSecondaryContrastText90 = fade(theme.palette.secondary.contrastText, 0.9);

  return {
    root: {
      backgroundColor: theme.palette.secondary.main,
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      overflowY: 'hidden',
    },
    header: {
      minHeight: 64,
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& > span': {
        color: colorSecondaryContrastText90,
      },
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      overflowY: 'auto',
      [theme.breakpoints.up('lg')]: {
        flexDirection: 'row',
      },
    },
    sidebar: {
      minHeight: 64,
      padding: theme.spacing(0, 2),
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.up('lg')]: {
        alignItems: 'flex-start',
        padding: theme.spacing(0),
        minWidth: 240,
      },
    },
    content: {
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(0, 2),
      flexGrow: 1,
      overflowY: 'auto',
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(0, 4),
      },
      [theme.breakpoints.up('lg')]: {
        borderTopLeftRadius: theme.spacing(2),
        padding: theme.spacing(0, 5),
      },
    },
    link: {
      color: colorSecondaryContrastText90,
      display: 'flex',
      alignItems: 'center',
    },
    exitIcon: {
      fill: colorSecondaryContrastText90,
    },
    exit: {
      color: colorSecondaryContrastText90,
      marginLeft: theme.spacing(1),
    },
    boxLogoIcon: {
      display: 'flex',
    },
    button: {
      padding: 0,
    },
  };
});

const MainLayout: FunctionComponent = () => {
  const classes = useStyles();
  const downloading = useSelector(DownloadAreaSelectors.downloading);
  const reports = useSelector(DownloadAreaSelectors.reportList) as DownloadAreaData['reportList'];

  return (
    <OuibounceModal
      downloading={downloading}
      title={`Baixando relatório (${Object.keys(reports).length})`}
      description="Atenção: Se você fechar a guia do navegador, o arquivo que está sendo baixado não será salvo."
    >
      <Box className={classes.root}>
        <Header />
        <Box className={classes.container}>
          <SideMenu />
          <Box className={classes.content} component="main">
            <ErrorBoundary>
              <Outlet />
              {downloading && <DownloadCard />}
            </ErrorBoundary>
          </Box>
        </Box>
      </Box>
    </OuibounceModal>
  );
};

export default MainLayout;
