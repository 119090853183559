export type ChargerType =
  | '720054 - Carregador N910'
  | '720053 - Carregador D195 USB'
  | '720052 - Carregador S920 Mini Base'
  | '';

export type Order = {
  coilQuantity: number;
  charger: ChargerType;
  reason: string;
};

export enum PAGES {
  TRACKING = 'tracking',
  COIL_FORM = 'coilForm',
  CHARGER_FORM = 'chargerForm',
  SUCCESS = 'success',
}
