import React, { FunctionComponent } from 'react';

import {
  Box,
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery,
  Theme,
  Hidden,
  Grid,
} from '@material-ui/core';
import BatteryIcon from '@material-ui/icons/BatteryCharging90Outlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import { ReactComponent as Machine } from 'assets/images/maquininha.svg';
import { ReactComponent as StickyNoteIcon } from 'assets/images/sticky_note_2.svg';
import clsx from 'clsx';
import Typography from 'components/UI/Typography';

import TrackingStepperDesktop from './Stepper/Desktop/TrackingStepperDesktop';
import TrackingStepperMobile from './Stepper/Mobile/TrackingStepperMobile';

const useStyles = makeStyles((theme) => ({
  accordionPreview: {
    display: 'flex',
  },
  cardRequest: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    width: '100%',
  },
  orderBox: {
    maxWidth: 410,
  },
  maquininhaIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 32,
    height: 32,
    borderRadius: '50%',
    border: `1px solid ${theme.palette.carbon[10]}`,
    margin: theme.spacing(0, 1, 0, 0),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0, 0, 1, 0),
    },
  },
  accordionContainer: {
    border: `1px solid ${theme.palette.carbon[10]}`,
  },
  accordionContainerExpanded: {
    backgroundColor: theme.palette.backgroundColor.surface?.marbleDark,
  },
  accordionDetails: {
    display: 'flex',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(1),
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  accordionDetailsMachineInfo: {
    display: 'flex',
    flexDirection: 'column',
    width: '34%',
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      width: '100%',
    },
  },
  accordionDetailsStep: {
    width: '66%',
    [theme.breakpoints.down('sm')]: {
      '& .MuiStepper-root': {
        padding: theme.spacing(5, 0),
      },
    },
  },
  accordionMachineName: {
    display: 'flex',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  accordionMachineInfo: {
    display: 'grid',
    gap: theme.spacing(1),
    gridTemplateRows: '1fr 1fr',
  },
  info: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  accordionBorder: {
    borderTop: `2px solid ${theme.palette.carbon[10]}`,
  },
  statusWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    '& > p': {
      [theme.breakpoints.down('sm')]: {
        textOverflow: 'ellipsis',
        maxWidth: 70,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
    },
  },
  warning: {
    fontSize: '0.75rem',
    fill: theme.palette.composition.status?.attention,
  },
  success: {
    fontSize: '0.75rem',
    fill: theme.palette.composition.status?.active,
  },
  error: {
    fontSize: '0.75rem',
    fill: theme.palette.composition.status?.negative,
  },
}));

export enum TrackingCardStatus {
  inSeparation = 'Em separação',
  withCarrier = 'Com a transportadora',
  onDeliveryRoute = 'Em rota de entrega',
  delivered = 'Entregue',
  deliveredFailed = 'Falha na entrega',
  underReview = 'Em análise',
}

export type TrackingCardProps = {
  status: string;
  date: string;
  deliveryForecast?: string;
  equipment?: string;
  quantity?: number;
  orderNumber: number;
  equipmentModel?: string;
  equipmentSerialNumber?: string;
};

const TrackingCard: FunctionComponent<TrackingCardProps> = ({
  status,
  date,
  deliveryForecast,
  equipment,
  quantity,
  orderNumber,
  equipmentModel,
  equipmentSerialNumber,
}) => {
  const classes = useStyles();
  const isSmScreen = useMediaQuery((muitheme: Theme) => muitheme.breakpoints.down('sm'));
  const [expanded, setExpanded] = React.useState<number | false>();

  const handleChange = (panel: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const statusList = {
    [TrackingCardStatus.inSeparation]: <FiberManualRecordIcon className={classes.warning} />,
    [TrackingCardStatus.delivered]: <FiberManualRecordIcon className={classes.success} />,
    [TrackingCardStatus.withCarrier]: <FiberManualRecordIcon className={classes.warning} />,
    [TrackingCardStatus.deliveredFailed]: <FiberManualRecordIcon className={classes.error} color="error" />,
    [TrackingCardStatus.onDeliveryRoute]: <FiberManualRecordIcon className={classes.warning} />,
    [TrackingCardStatus.underReview]: <FiberManualRecordIcon className={classes.warning} />,
  };

  const statusActive: { [key: string]: number } = {
    'Em separação': 0,
    'Em analise': 0,
    'Com a transportadora': 1,
    'Em rota de entrega': 2,
    Entregue: 4,
    'Falha na entrega': 3,
  };

  const equipmentIconMap = (): JSX.Element => {
    if (equipment && equipment === 'Carregador') {
      return <BatteryIcon />;
    }
    if (equipment && equipment === 'Bobina') {
      return <StickyNoteIcon />;
    }
    return <Machine />;
  };

  const equipmentLabelMap = (): string => {
    if (equipment === 'Carregador') {
      return 'Carregador';
    }
    if (equipment === 'Bobina') {
      return 'Bobina';
    }
    if (equipment) {
      return equipment;
    }

    return 'Não informado';
  };

  const equipmentInfo = (): JSX.Element => {
    if (equipment === 'Carregador') {
      return (
        <>
          <Box className={classes.info}>
            <Typography component="p" variant="caption3">
              Modelo:
            </Typography>
            <Typography component="p" variant="caption3">
              {equipmentModel}
            </Typography>
          </Box>
          <Box className={classes.info}>
            <Typography component="p" variant="caption3">
              Quantidade:
            </Typography>
            <Typography component="p" variant="caption3">
              1
            </Typography>
          </Box>
        </>
      );
    }
    if (equipment === 'Bobina') {
      return (
        <>
          <Box className={classes.info}>
            <Typography component="p" variant="caption3">
              Quantidade:
            </Typography>
            <Typography component="p" variant="caption3">
              {quantity || 'Não informado'}
            </Typography>
          </Box>
          <Box className={classes.info}>
            <Typography component="p" variant="caption3">
              Unidades:
            </Typography>
            <Typography component="p" variant="caption3">
              {quantity ? quantity * 6 : 'Não informado'}
            </Typography>
          </Box>
        </>
      );
    }
    return (
      <>
        <Box className={classes.info}>
          <Typography component="p" variant="caption3">
            Modelo
          </Typography>
          <Typography component="p" variant="caption2">
            {equipmentModel || 'Não informado'}
          </Typography>
        </Box>
        <Box className={classes.info}>
          <Typography component="p" variant="caption3">
            Nº de série
          </Typography>
          <Typography component="p" variant="caption2">
            {equipmentSerialNumber || 'Não informado'}
          </Typography>
        </Box>
      </>
    );
  };

  const [dateFormat] = date?.split(' ');
  const [deliveryForecastFormat] = deliveryForecast ? deliveryForecast.split(' ') : [''];

  return (
    <Accordion
      expanded={expanded === orderNumber}
      onChange={handleChange(orderNumber)}
      className={clsx(classes.accordionContainer, { [classes.accordionContainerExpanded]: expanded })}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container alignItems="center" justify="space-between" item md={12}>
          <Grid container alignItems="center" item md={6} xs={7} className={classes.orderBox}>
            <Box className={classes.maquininhaIconContainer}>{equipmentIconMap()}</Box>
            <Box>
              <Typography component="p" variant="caption">
                {equipmentLabelMap()}
              </Typography>
              {isSmScreen ? (
                <Typography component="p" variant="caption">{`#${orderNumber}`}</Typography>
              ) : (
                <Typography component="p" variant="caption">{`Pedido #${orderNumber}`}</Typography>
              )}
            </Box>
          </Grid>
          <Hidden smDown>
            <Grid item md={2}>
              <Typography component="p" variant="caption">
                {dateFormat || ''}
              </Typography>
            </Grid>
            <Grid item md={2}>
              <Typography component="p" variant="caption">
                {deliveryForecastFormat}
              </Typography>
            </Grid>
          </Hidden>
          <Grid item md={2} xs={5} className={classes.statusWrapper}>
            {status && statusList[(status as keyof TrackingCardStatus) as TrackingCardStatus]}
            <Typography component="p" variant="caption">
              {status}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionBorder}>
        <Box className={classes.accordionDetails}>
          <Box className={classes.accordionDetailsMachineInfo}>
            <Box className={classes.accordionMachineName}>
              <Typography variant="caption3">{equipment || 'Não informado'}</Typography>
            </Box>
            <Box className={classes.accordionMachineInfo}>{equipmentInfo()}</Box>
          </Box>
          <Box className={classes.accordionDetailsStep}>
            {isSmScreen ? (
              <TrackingStepperMobile activeStep={statusActive[status]} />
            ) : (
              <TrackingStepperDesktop activeStep={statusActive[status]} />
            )}
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default TrackingCard;
