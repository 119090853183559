/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import { Dialog, Box, IconButton, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import Typography from 'components/UI/Typography';
import { useAppDispatch } from 'store';
import { DialogsActions } from 'store/dialogs';

const useStyles = makeStyles((theme) => ({
  modalTitle: {
    color: theme.palette.textColor.title,
    marginBottom: theme.spacing(2),
  },
  modalWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
  },
  closeIcon: {
    width: '15px',
    height: '15px',
    '& :first-child': {
      width: '18px',
      height: '18px',
    },
  },
  modalContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  modalDescription: {
    margin: theme.spacing(2, 0),
    color: theme.palette.carbon[70],
    '& > ul': {
      listStylePosition: 'outside',
      margin: '1px solid red',
    },
  },
  buttonSection: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(4),
  },
  firstButton: {
    marginRight: theme.spacing(2),
  },
}));

type DialogMigratedUsersProps = {
  setOpen: (arg: boolean) => void;
  isOpen: boolean;
};

const DialogMigratedUsers = ({ setOpen, isOpen }: DialogMigratedUsersProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const handleCloseDialog = () => {
    setOpen(false);
    dispatch(DialogsActions.changeWebBankingModal(true));
  };

  const urlDownloadYourBank = 'https://www.c6bank.com.br/baixe-seu-banco';
  const urlDownloadWebBank = 'https://www.c6bank.com.br/web-banking/';

  return (
    <>
      <Dialog
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        maxWidth="xs"
        fullWidth
      >
        <Box className={classes.modalWrapper}>
          <Box className={classes.modalContainer}>
            <Typography variant="bodyBold" className={classes.modalTitle}>
              Informações sobre Link de Pagamento
            </Typography>
            <IconButton aria-label="close" className={classes.closeIcon} onClick={handleCloseDialog}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography variant="paragraph" paragraph>
            O Portal do Cliente teve sua migração para os canais do C6 Bank. Com isso, você terá acesso à função Link de
            Pagamento de forma ainda mais completa: somente no app C6 Bank e web banking.
          </Typography>
          <Typography variant="subtitleSmall">Acesse e confira:</Typography>
          <ul>
            <li>
              <Typography variant="paragraph">
                No app C6 Bank: acesse sua conta, toque em “Todos”, toque em “Cobrar” e escolha a opção “Link de
                Pagamento”. <br />
                Para baixar o app, acesse:{' '}
                <Link href={urlDownloadYourBank}>https://www.c6bank.com.br/baixe-seu-banco</Link>
              </Typography>
            </li>
            <li>
              <Typography variant="paragraph">
                No web banking (apenas para clientes C6 Empresas): acesse sua conta, clique em “Cobrar”, no menu
                lateral, e escolha a opção “Link de Pagamento”. <br />
                Para baixar o web banking, acesse:{' '}
                <Link href={urlDownloadWebBank}>https://www.c6bank.com.br/web-banking/</Link>
              </Typography>
            </li>
          </ul>

          <Typography variant="bodyBold" paragraph>
            Importante: não será mais possível gerar Link de Pagamento no Portal do Cliente.
          </Typography>

          <Typography variant="paragraph">Até mais,</Typography>

          <Typography variant="bodyBold">Time C6 Bank.</Typography>
        </Box>
      </Dialog>
    </>
  );
};

export default DialogMigratedUsers;
