import { formatUserDocument } from 'helpers/formatters/formatters';
import {
  AnticipationListData,
  AnticipationResponseData,
  AnticipationResquestData,
  SimulationResponseData,
} from 'models/anticipation';
import { client } from 'services/client';

type GetSimulationResponse = {
  data?: SimulationResponseData;
  errorMessage?: string;
};

export const getSimulation = async (params: string[]): Promise<GetSimulationResponse> => {
  const localStorageAuthJson = localStorage.getItem('persist:auth') as string;
  const localStorageAuth = JSON.parse(localStorageAuthJson);
  const localStorageAuthData = JSON.parse(localStorageAuth.data);

  const localStorageAccountJson = localStorage.getItem('persist:account') as string;
  const localStorageAccount = JSON.parse(localStorageAccountJson);
  const localStorageAccountData = JSON.parse(localStorageAccount.data);

  const acquirerParams = params.length ? `?acquirers=${params.join('&acquirers=')}` : '';
  try {
    const response = await client.get<GetSimulationResponse['data']>(
      `portal/v1/anticipations/simulation${acquirerParams}`,
      {
        token: localStorageAuthData.accessToken,
        useWhitelabel: true,
        headers: {
          'document-number': formatUserDocument(localStorageAccountData.documentNumber),
        },
      },
    );

    if (response.parsedBody) {
      return { data: response.parsedBody };
    }

    throw new Error('Ocorreu um erro ao buscar a simulação de antecipação');
  } catch (e) {
    return {
      errorMessage: e.message,
    };
  }
};

type PostAnticipationResponse = {
  data?: AnticipationResponseData;
  errorMessage?: string;
};

export const postAnticipation = async (anticipates: AnticipationResquestData): Promise<PostAnticipationResponse> => {
  const localStorageAuthJson = localStorage.getItem('persist:auth') as string;
  const localStorageAuth = JSON.parse(localStorageAuthJson);
  const localStorageAuthData = JSON.parse(localStorageAuth.data);

  try {
    const response = await client.post<PostAnticipationResponse['data']>('portal/v1/anticipations', {
      token: localStorageAuthData.accessToken,
      headers: {
        'content-type': 'application/json',
      },
      data: anticipates,
    });

    if (response.parsedBody) {
      return { data: response.parsedBody };
    }

    throw new Error('Ocorreu um erro ao enviar a simulação de antecipação');
  } catch (e) {
    return {
      errorMessage: e.message,
    };
  }
};

type GetAnticipationListResponse = {
  data?: AnticipationListData;
  errorMessage?: string;
};
export const getAnticipationList = async (): Promise<GetAnticipationListResponse> => {
  const localStorageAuthJson = localStorage.getItem('persist:auth') as string;
  const localStorageAuth = JSON.parse(localStorageAuthJson);
  const localStorageAuthData = JSON.parse(localStorageAuth.data);
  const localStorageAccountJson = localStorage.getItem('persist:account') as string;
  const localStorageAccount = JSON.parse(localStorageAccountJson);
  const localStorageAccountData = JSON.parse(localStorageAccount.data);

  try {
    const response = await client.get<GetAnticipationListResponse['data']>(`portal/v1/anticipations`, {
      token: localStorageAuthData.accessToken,
      useWhitelabel: true,
      headers: {
        'document-number': formatUserDocument(localStorageAccountData.documentNumber),
      },
    });
    if (response.parsedBody) {
      return { data: response.parsedBody };
    }

    throw new Error('Ocorreu um erro');
  } catch (e) {
    return {
      errorMessage: e.message,
    };
  }
};
