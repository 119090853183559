import * as React from 'react';
import { useNavigate } from 'react-router';

import { Box, makeStyles } from '@material-ui/core';
import CheckCircle from '@material-ui/icons/CheckCircle';
import InfoRounded from '@material-ui/icons/InfoRounded';
import { Alert } from '@material-ui/lab';

import { ReactComponent as EmailImage } from 'assets/images/email-success-send.svg';
import Button from 'components/UI/Button';
import Typography from 'components/UI/Typography';

type Props = {
  email: string;
  resendEmail: (email: string, resend: true) => void;
  isResendEmail: boolean;
  loading: boolean;
};

const useStyles = makeStyles((theme) => ({
  buttonsWrapper: {
    display: 'grid',
    gap: theme.spacing(2),
  },
  alert: {
    borderRadius: theme.shape.borderRadius * 2,
  },
  carbon70: {
    color: theme.palette.carbon[70],
  },
  alertVerify: {
    padding: theme.spacing(2),
    '& > .MuiAlert-message': {
      padding: 0,
    },
  },
}));

const CreatePasswordSuccess = ({ email, resendEmail, isResendEmail, loading }: Props): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <>
      <Box display="flex" justifyContent="center">
        <EmailImage alignmentBaseline="central" />
      </Box>
      <Box my={6}>
        <Typography variant="header" component="h2" align="center" paragraph gutterBottom>
          Criação de senha
        </Typography>
        <Typography align="center" variant="body2" component="p" gutterBottom paragraph>
          {`Enviaremos um e-mail para ${email} com as instruções para que você crie a senha de acesso.`}
        </Typography>
        <Alert severity="info" color="info" icon={<InfoRounded color="inherit" />} className={classes.alertVerify}>
          <Typography align="center" component="span" paragraph variant="caption2">
            Caso não receba, confira sua caixa de spam ou verifique se o e-mail informado é o cadastrado e tente
            novamente.
          </Typography>
        </Alert>
      </Box>

      <Box className={classes.buttonsWrapper}>
        <Button
          component="a"
          onClick={() => navigate('/auth/login')}
          size="large"
          fullWidth
          variant="contained"
          color="primary"
          title="Acessar Conta"
          aria-label="Acessar conta."
          disabled={loading}
        >
          Acessar conta
        </Button>
        {isResendEmail && !loading && (
          <Alert
            className={classes.alert}
            variant="filled"
            severity="success"
            icon={<CheckCircle />}
            aria-label="caixa com alerta de sucesso"
          >
            E-mail reenviado com sucesso!
          </Alert>
        )}
        {!isResendEmail && (
          <Button
            size="large"
            type="button"
            variant="outlined"
            fullWidth
            title="Reenviar E-mail"
            aria-label="Reenviar e-mail de recuperação de senha."
            loading={loading}
            onClick={() => resendEmail(email, true)}
          >
            Reenviar E-mail
          </Button>
        )}
      </Box>
    </>
  );
};

export default CreatePasswordSuccess;
