import { createReducer } from '@reduxjs/toolkit';

import { downloading, reportList } from './actions';
import { downloadAreaInitialState } from './constants';
import type { DownloadAreaState } from './types';

export default createReducer<DownloadAreaState>(downloadAreaInitialState, (builder) =>
  builder
    .addCase(downloading, (state, action) => {
      state.data = { ...state.data, downloading: action.payload } as DownloadAreaState['data'];
    })
    .addCase(reportList, (state, action) => {
      state.data = { ...state.data, reportList: action.payload } as DownloadAreaState['data'];
    }),
);
