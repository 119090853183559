import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { Box, Card, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Button from 'components/UI/Button';
import PageContainer from 'components/UI/PageContainer';
import PageTitle from 'components/UI/PageTitle';
import ServerError from 'components/UI/ServerError';
import Typography from 'components/UI/Typography';
import {
  formatAmount,
  formatDoubleToPerCentValue,
  formatNumberOfDays,
  formatNumbersOfDayAndInstallments,
} from 'helpers/formatters/formatters';
import {
  OperationTypeKeys,
  PaymentMethodOptions,
  PaymentMethodKeys,
  TaxesParsed,
  TaxesPaymentMethodValue,
} from 'models/taxSimulator';
import { getTaxes } from 'services/taxes';

import Form from './Form';
import ResultCard from './ResultCard';
import Skeleton from './Skeleton';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(3),
    margin: 'auto',
    color: theme.palette.carbon[70],
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4),
      width: 656,
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  contents: {
    maxWidth: 280,
    width: '100%',
  },
  form: {
    '& > div': {
      marginBottom: theme.spacing(3),
    },
  },
  sectionButton: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column-reverse',
    flexWrap: 'wrap',
    '& > button:not(:last-child)': {
      marginTop: theme.spacing(2),
    },

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      flexWrap: 'nowrap',
      '& > button:not(:last-child)': {
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(0),
      },
    },
  },
}));

const TaxSimulator = () => {
  const classes = useStyles();
  const operationTypeOptions = { credit: 'Crédito', debit: 'Débito' };
  const installmentsOptions = [
    { value: '1', label: 'À vista' },
    { value: '2', label: '2 parcelas' },
    { value: '3', label: '3 parcelas' },
    { value: '4', label: '4 parcelas' },
    { value: '5', label: '5 parcelas' },
    { value: '6', label: '6 parcelas' },
    { value: '7', label: '7 parcelas' },
    { value: '8', label: '8 parcelas' },
    { value: '9', label: '9 parcelas' },
    { value: '10', label: '10 parcelas' },
    { value: '11', label: '11 parcelas' },
    { value: '12', label: '12 parcelas' },
  ];
  const initialDictionaryResultValues = {
    paymentMethod: '-',
    receiveAmount: `R$ ${formatAmount(0)}`,
    receiveDate: formatNumberOfDays(0, false),
    installments: '',
    paymentLinkFee: `R$ ${formatAmount(0)}`,
    tax: formatDoubleToPerCentValue(0),
  };

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodKeys | ''>('');
  const [paymentMethodOptions, setPaymentMethodOptions] = useState<{ [key in PaymentMethodKeys]?: string }>({});

  const [operationType, setOperationType] = useState<OperationTypeKeys | ''>('');

  const [installment, setInstallment] = useState('1');

  const [amount, setAmount] = useState(0);
  const [errorAmount, setErrorAmount] = useState('');

  const [taxes, setTaxes] = useState<Partial<TaxesParsed> | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [dictionaryResultValues, setDictionaryResultValues] = useState(initialDictionaryResultValues);

  const dictionaryResultLabels = {
    paymentMethod: 'Meio de pagamento',
    receiveAmount: 'Total a receber',
    receiveDate: 'Receba em',
    installments: 'Parcela',
    paymentLinkFee: 'Valor por link pago',
    tax: 'Taxa',
  };

  const isResetButtonDisabled = !amount && !paymentMethod && !operationType;
  const ariasLabelResetButton = {
    disabled:
      'Desabilitado, para recomeçar uma simulação, você precisa preencher ao menos um campo no simulador de taxas.',
    enabled: 'Recomeçar simulação de taxas.',
  };

  const isSimulateButtonDisabled = !amount || !paymentMethod || !operationType;
  const ariasLabelSimulateButton = {
    disabled: 'Desabilitado, para avançar você precisa informar os dados para simular as taxas de venda.',
    enabled: 'Simular taxas de venda.',
  };

  const initRequestGetTaxes = useCallback(() => {
    setHasError(false);
    setIsLoading(true);

    getTaxes()
      .then(({ data }) => {
        if (data) {
          const newTaxes = {
            machine: {
              data: data['PADRÃO'],
              label: 'Maquininha',
            },
            paymentLink: {
              data: data['LINK PGTO'],
              label: 'Link de pagamento',
            },
          };

          const newTaxesKey = Object.keys(newTaxes) as Array<keyof typeof newTaxes>;
          const taxesParsed = newTaxesKey.reduce((acc: Partial<TaxesParsed>, curr) => {
            const { label } = newTaxes[curr];
            const taxData = newTaxes[curr].data;
            const installments = taxData.filter((tax) => tax.operation.match('(PARC.|PARCELADO) SEM'));
            const [credit] = taxData.filter((tax) => tax.operation.includes('CRÉDITO'));
            const [debit] = taxData.filter((tax) => tax.operation.includes('DÉBITO'));

            const partialObject = {
              label,
              installments,
              credit,
              debit,
            };

            return { ...acc, [curr]: { ...partialObject } };
          }, {});

          const newPaymentMethodOptions = newTaxesKey.reduce(
            (acc: Partial<PaymentMethodOptions>, curr) => ({
              ...acc,
              [curr]: taxesParsed[curr]?.label,
            }),
            {},
          );

          setTaxes(taxesParsed);
          setPaymentMethodOptions(newPaymentMethodOptions);
        } else {
          throw Error();
        }
      })
      .catch(() => setHasError(true))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    initRequestGetTaxes();

    return () => {
      setHasError(false);
      setIsLoading(false);
    };
  }, [initRequestGetTaxes]);

  const validEmptyField = (value: string | number, setError: React.Dispatch<React.SetStateAction<string>>) => {
    if (!value) {
      return setError('Este campo é obrigatório.');
    }

    return setError('');
  };

  const changeAmountValue = (e: ChangeEvent<HTMLInputElement>) => {
    const inputtedValue = e.target.value;
    const value = parseInt(inputtedValue.replace(/\D/g, ''), 10);
    if (String(value).length < 9) {
      setAmount(value);
    }
  };

  const getValueFromSelectAndSetValue = <T,>(
    event: ChangeEvent<{ value: unknown }>,
    setValueFunction: (value: React.SetStateAction<'' | T>) => void,
  ) => {
    const value = event.target.value as T;
    setValueFunction(value);
  };

  const getTaxValuesByCondition = (
    operationTypeScoped: OperationTypeKeys,
    installmentScoped: string,
    paymentMethodValues: TaxesPaymentMethodValue,
  ) => {
    if (operationTypeScoped === 'credit' && parseInt(installmentScoped, 10) > 1) {
      const { tax, receiving } = paymentMethodValues?.installments?.find((inst) =>
        inst.operation.includes(installmentScoped),
      )!;
      return { tax, receiving: formatNumbersOfDayAndInstallments(receiving) };
    }

    const { tax, receiving } = paymentMethodValues?.[operationTypeScoped];
    const isWeekDay = operationTypeScoped === 'debit';
    return { tax, receiving: formatNumberOfDays(receiving, isWeekDay) };
  };

  if (paymentMethod === 'paymentLink') {
    dictionaryResultValues.paymentLinkFee = `R$ ${formatAmount(0.35)}`;
  }

  if (paymentMethod === 'machine') {
    dictionaryResultValues.paymentLinkFee = `R$ ${formatAmount(0)}`;
  }

  const calculateAmountLessTax = () => {
    if (!paymentMethod || !operationType) {
      return;
    }

    const paymentMethodValues = taxes?.[paymentMethod]!;
    const { tax, receiving } = getTaxValuesByCondition(operationType, installment, paymentMethodValues);

    const amountFromPorcent = amount * (tax / 100);
    const value = amount - amountFromPorcent;
    const installmentsInt = parseInt(installment, 10);
    const installmentText =
      installmentsInt > 1 ? `${installmentsInt}x de ${formatAmount(value / 100 / installmentsInt)}` : '';

    setDictionaryResultValues({
      paymentMethod: paymentMethodValues?.label as string,
      receiveAmount: `R$ ${formatAmount(value / 100)}`,
      receiveDate: receiving,
      installments: installmentText,
      paymentLinkFee: dictionaryResultValues.paymentLinkFee,
      tax: formatDoubleToPerCentValue(tax / 100),
    });
  };

  const resetForm = () => {
    setPaymentMethod('');
    setOperationType('');
    setInstallment('1');
    setAmount(0);
    setDictionaryResultValues(initialDictionaryResultValues);
  };

  if (hasError) {
    return (
      <ServerError
        onTryAgain={() => initRequestGetTaxes()}
        title="Ocorreu um erro"
        message="Algo deu errado e não tivemos uma resposta do servidor."
      />
    );
  }

  return (
    <PageContainer>
      <Grid item xs={12}>
        <PageTitle title="Simulador de vendas" isLoading={isLoading} />
      </Grid>

      {isLoading ? (
        <Skeleton />
      ) : (
        <Grid item xs={12}>
          <Card className={classes.card}>
            <Typography variant="subtitle2" component="h2" color="textPrimary">
              Qual valor de venda você deseja simular?
            </Typography>
            <Typography variant="caption">
              Saiba o quanto você irá receber em cada venda realizada pelo seu estabelecimento.
            </Typography>

            <Box className={classes.container}>
              <Box className={`${classes.form} ${classes.contents}`}>
                <Form
                  amount={amount}
                  changeAmountValue={changeAmountValue}
                  errorAmount={errorAmount}
                  setErrorAmount={setErrorAmount}
                  paymentMethod={paymentMethod}
                  paymentMethodOptions={paymentMethodOptions}
                  setPaymentMethod={setPaymentMethod}
                  operationType={operationType}
                  operationTypeOptions={operationTypeOptions}
                  setOperationType={setOperationType}
                  installment={installment}
                  installmentOptions={installmentsOptions}
                  setInstallment={setInstallment}
                  getValueFromSelectAndSetValue={getValueFromSelectAndSetValue}
                  validEmptyField={validEmptyField}
                />
              </Box>
              <Box className={classes.contents}>
                <ResultCard labels={dictionaryResultLabels} values={dictionaryResultValues} />
              </Box>
            </Box>

            <Box className={classes.sectionButton}>
              <Button
                type="button"
                title="Recomeçar"
                fullWidth
                variant="outlined"
                size="large"
                aria-label={isResetButtonDisabled ? ariasLabelResetButton.disabled : ariasLabelResetButton.enabled}
                disabled={isResetButtonDisabled}
                onClick={() => resetForm()}
              >
                Recomeçar
              </Button>
              <Button
                type="button"
                title="Simular Venda"
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                aria-label={
                  isSimulateButtonDisabled ? ariasLabelSimulateButton.disabled : ariasLabelSimulateButton.enabled
                }
                disabled={isSimulateButtonDisabled}
                onClick={() => calculateAmountLessTax()}
              >
                Simular Taxas
              </Button>
            </Box>
          </Card>
        </Grid>
      )}
    </PageContainer>
  );
};

export default TaxSimulator;
