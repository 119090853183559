export function formatCpf(userDocument: string) {
  const formatted = userDocument.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  return formatted.length !== 14 ? '000.000.000-00' : formatted;
}

export function formatCnpj(userDocument: string) {
  const formatted = userDocument.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
  return formatted.length !== 18 ? '00.000.000/0000-00' : formatted;
}

export function formatZipCode(zipCode: string) {
  const formatted = zipCode.replace(/^([\d]{2})([\d]{3})([\d]{3})|^[\d]{2}.[\d]{3}-[\d]{3}/, '$1$2-$3');
  return formatted.length !== 9 ? '00000-000' : formatted;
}

export function formatDoubleValue(value: number): string {
  return `${value.toFixed(2).toString().replace('.', ',')}`;
}

export function formatDoubleToPerCentValue(value: number): string {
  return `${formatDoubleValue(value * 100)}%`;
}

export function formatNumberOfDays(days: number, isWeekDays: boolean): string {
  if (isWeekDays) {
    return days > 1 ? `${days} dias úteis` : `${days} dia útil`;
  }

  return days > 1 ? `${days} dias` : `${days} dia`;
}

export function formatNumbersOfDayAndInstallments(days: number): string {
  return formatNumberOfDays(days, false);
}

export function formatUserDocument(userDocument: string) {
  const stripedUserDocument = userDocument.split(',')[0].trim().replace(/\D/g, '');
  return stripedUserDocument.length <= 11 ? formatCpf(stripedUserDocument) : formatCnpj(stripedUserDocument);
}

export function userInitials(userName: string) {
  const fullName = userName
    .replace(/[^A-Za-z\s\u00c0-\u00ff]/g, '')
    .split(' ')
    .filter(Boolean);
  const firstInitials = (fullName[0] && fullName[0][0]) || '';
  const lastInitials = fullName.length > 1 ? fullName[fullName.length - 1][0].trim() : '';
  const initials = firstInitials + lastInitials;
  return initials ? initials.toUpperCase() : '';
}

export function formatUserFirstName(userName: string) {
  const fullName = userName
    .trim()
    .replace(/[^A-Za-z\s\u00c0-\u00ff]/g, '')
    .split(' ');
  const firstName = fullName[0] ?? '';
  return firstName;
}

export function formatAmount(n: number | undefined) {
  if (!n || typeof n !== 'number') {
    return '0,00';
  }

  return n.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export const formatIntToAmount = (value: number) => {
  return formatAmount(value / 100);
};

export function capitalize(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

export const formatCardNumbers = (cardNumber: string) => `•••• •••• •••• ${cardNumber.trim().slice(-4)}`;
export const formatCardNumbersSmall = (cardNumber: string) => `•••• ${cardNumber.trim().slice(-4)}`;

export function formatUserName(userName: string) {
  const fullName = userName
    .toLowerCase()
    .trim()
    .replace(/[^A-Za-z\s\u00c0-\u00ff]/g, '')
    .split(' ');
  const firstName = capitalize(fullName[0]) ?? '';
  const lastName = fullName.length > 1 ? capitalize(fullName[fullName.length - 1]) : '';
  const formattedUserName = fullName.length === 1 ? firstName : `${firstName} ${lastName}`;
  return formattedUserName;
}

export function removeFormatUserDocumentNumber(userDocumentNumber: string): string {
  return userDocumentNumber.replace(/[^\d]/g, '');
}

export const formatRangeDate = (startDate: string, endDate?: string) => {
  const [, monthStartDate, dayStartDate] = startDate.split('-');
  if (endDate) {
    const [, monthEndDate, dayEndDate] = endDate?.split('-');
    return `${dayStartDate}/${monthStartDate} até ${dayEndDate}/${monthEndDate}`;
  }
  return `${dayStartDate}/${monthStartDate}`;
};
