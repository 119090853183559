import type { ChangeEvent, FormEvent, FunctionComponent } from 'react';
import React, { useState } from 'react';

import {
  Box,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Button from 'components/UI/Button';
import Typography from 'components/UI/Typography';
import helpersFormatters from 'helpers/formatters';

import { installmentsOptions } from './constants';

const useStyles = makeStyles((theme) => ({
  form: {
    margin: theme.spacing(1, 1, 0),
  },
  sectionTextFields: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& > div': {
      marginBottom: theme.spacing(2),
    },
  },
  sectionPaymentMethods: {
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      marginTop: theme.spacing(2),
    },
    '& > label': {
      width: 'fit-content',
    },
  },
  sectionPixMessage: {
    display: 'flex',
    margin: theme.spacing(3, 0),
    '& > svg': {
      flexShrink: 0,
      marginRight: theme.spacing(1),
    },
  },
  sectionButtons: {
    display: 'flex',
    '& > *:not(last-child)': {
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(3, 5),
    },
  },
  headerModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

type Props = {
  createLink: (arg: {
    amount: number;
    description: string;
    debit: boolean;
    credit: {
      installments: number;
    } | null;
  }) => void;
  closeModal: () => void;
};

const PaymentLinkCreate: FunctionComponent<Props> = ({ createLink, closeModal }) => {
  const classes = useStyles();
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState(0);
  const [errorDescription, setErrorDescription] = useState<string | null>(null);
  const [errorAmount, setErrorAmount] = useState<string | null>(null);

  const [debit, setDebit] = useState(false);
  const [credit, setCredit] = useState(false);
  const [installments, setInstallments] = useState(1);

  const changeDescriptionValue = (e: ChangeEvent<HTMLInputElement>) => {
    const inputedValue = e.target.value;
    setDescription(inputedValue);
  };

  const changeAmountValue = (e: ChangeEvent<HTMLInputElement>) => {
    const inputedValue = e.target.value;
    const value = parseInt(inputedValue.replace(/\D/g, ''), 10);
    if (String(value).length < 9) {
      setAmount(value);
    }
  };

  const changeSwitchValue = (
    e: ChangeEvent<HTMLInputElement>,
    setValue: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    setValue(Boolean(e.target.checked));
  };

  const getInstallmentFromSelect = (event: ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as number;
    setInstallments(value);
  };

  const validEmptyField = (value: string | number, setError: React.Dispatch<React.SetStateAction<string | null>>) => {
    if (!value) {
      return setError('Este campo é obrigatório.');
    }
    if (value < 500) {
      return setError('O valor mínimo é de R$5,00');
    }
    return setError(null);
  };

  const validateDescriptionValue = (value: string, setError: React.Dispatch<React.SetStateAction<string | null>>) => {
    if (!value) {
      return setError('Este campo é obrigatório.');
    }

    if (value.length < 5 || value.length > 50) {
      return setError('Campo Nome do produto ou descrição com a quantidade de caracteres não permitida.');
    }

    return setError(null);
  };

  const verifyRequiredValues = () => {
    if (amount && description && (debit || credit) && !errorDescription) {
      return true;
    }

    return false;
  };

  const callCreateLink = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const paymentLink = {
      description,
      amount,
      debit,
      credit: credit ? { installments } : null,
    };

    createLink(paymentLink);
  };

  return (
    <>
      <Box className={classes.headerModal}>
        <Typography variant="header2" component="h1">
          Criar link de pagamento
        </Typography>
        <IconButton onClick={() => closeModal()} color="secondary" aria-label="Fechar janela.">
          <CloseIcon />
        </IconButton>
      </Box>
      <form onSubmit={callCreateLink} noValidate className={classes.form}>
        <FormControl className={classes.sectionTextFields}>
          <TextField
            error={!!errorDescription}
            type="text"
            id="description-link"
            label="Nome do produto ou descrição"
            variant="standard"
            value={description}
            onChange={changeDescriptionValue}
            onBlur={() => validateDescriptionValue(description, setErrorDescription)}
            helperText={errorDescription || 'Min 5 e máx 50 caracteres'}
            required
          />
          <TextField
            error={!!errorAmount}
            type="text"
            id="amount-link"
            label="Valor do pagamento"
            variant="standard"
            value={helpersFormatters.formatIntToAmount(amount)}
            onChange={changeAmountValue}
            onBlur={() => validEmptyField(amount, setErrorAmount)}
            helperText={errorAmount || 'Cada link criado só poderá ser pago uma única vez.'}
            required
            InputProps={{
              startAdornment: <InputAdornment position="start">R$</InputAdornment>,
            }}
          />
        </FormControl>
        <Box className={classes.sectionPaymentMethods}>
          <Typography variant="subtitle2" component="h2" gutterBottom>
            Como você gostaria de receber?
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={debit}
                onChange={(e) => changeSwitchValue(e, setDebit)}
                color="primary"
                name="debitSwitch"
                aria-label="Botão para assinalar Débito"
              />
            }
            label={<Typography variant="body">Débito</Typography>}
          />
          <FormControlLabel
            control={
              <Switch
                checked={credit}
                onChange={(e) => changeSwitchValue(e, setCredit)}
                color="primary"
                name="rememberSwitch"
                aria-label="Botão para assinalar Crédito"
              />
            }
            label={<Typography variant="body">Crédito</Typography>}
          />
          <FormControl disabled={!credit}>
            <InputLabel id="label-select-installments">Configure o limite de parcelas</InputLabel>
            <Select
              labelId="label-select-installments"
              value={installments}
              onChange={getInstallmentFromSelect}
              IconComponent={ExpandMoreIcon}
              variant="standard"
            >
              {installmentsOptions.map((item, key) => (
                <MenuItem value={item.value} key={`${key + 1}`} selected={item.value === installments}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box className={classes.sectionButtons}>
          <Button
            type="submit"
            title="Voltar"
            fullWidth
            variant="outlined"
            size="small"
            aria-label="Fechar janela."
            onClick={closeModal}
          >
            Voltar
          </Button>
          <Button
            type="submit"
            title="Criar Link"
            fullWidth
            variant="contained"
            color="primary"
            size="small"
            aria-label={
              !verifyRequiredValues()
                ? 'Desabilitado, para avançar você precisa informar os dados para a criação do link de pagamento.'
                : 'Criar link de pagamento.'
            }
            disabled={!verifyRequiredValues()}
          >
            Criar Link
          </Button>
        </Box>
      </form>
    </>
  );
};

export default PaymentLinkCreate;
