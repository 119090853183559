import React, { ReactElement } from 'react';
import type { FunctionComponent } from 'react';

import type { Nullable } from '@portal-types/helpers';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import type SvgIcon from '@material-ui/core/SvgIcon';

import DetailsDrawer from 'components/DetailsDrawer';
import Typography from 'components/UI/Typography';
import { fullDateFormat, hoursAndMinutes } from 'helpers/date';
import { formatAmount, formatCardNumbers, formatCnpj } from 'helpers/formatters/formatters';
import { ReceivableItem, OperationLabel, ReceivablesStatus } from 'models/receivables';

import { salesInfoDictionary } from './constants';
import ReceivablesGroupPendingActions from './ReceivablesGroupPendingActions';

const useStyles = makeStyles((theme) => ({
  saleInfoTitle: {
    textAlign: 'left',
    width: '100%',
    marginBottom: theme.spacing(1),
    color: theme.palette.carbon[70],
  },
  salesInfoItem: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),
    borderBottom: `1px solid ${theme.palette.carbon[10]}`,
  },
}));

type ManagerTypeTransactionProps = {
  amount: number;
  date: ReceivableItem['receivableDate'];
  discount: number;
  receivableStatus: string;
  installments: number;
  StatusComponent: ReactElement;
};

const ManageReceivableBasedOnAmount: FunctionComponent<ManagerTypeTransactionProps> = ({
  amount,
  date,
  discount,
  StatusComponent,
  installments,
  receivableStatus,
}) => {
  const classes = useStyles();

  const renderDate = (title: string) =>
    date && <Typography variant="bodyBold">{`${title} ${fullDateFormat(date)}`}</Typography>;

  const renderAboutText = (title: string) => (
    <Typography variant="caption" className={classes.saleInfoTitle}>
      {title}
    </Typography>
  );

  if (amount > 0) {
    return (
      <>
        {receivableStatus === 'pago' && renderDate('Recebido em')}
        {StatusComponent}
        <Box display="flex" alignItems="center" mb={3}>
          <Box display="flex" flexDirection="column" alignItems="center" mx={3}>
            <Typography variant="body">{`Valor da ${installments > 1 ? 'parcela' : 'venda'}`}</Typography>
            <Typography variant="body">{`R$ ${formatAmount(amount || 0)}`}</Typography>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center" mx={3}>
            <Typography variant="body">Descontos</Typography>
            <Typography variant="body">{`R$ ${formatAmount(discount || 0)}`}</Typography>
          </Box>
        </Box>
      </>
    );
  }

  return (
    <>
      {renderDate('Data de cobrança')}
      {StatusComponent}
      {renderAboutText('Sobre a cobrança')}
    </>
  );
};

type Details = Omit<ReceivableItem, 'operation'> & { operation: OperationLabel | string };
type ReceivablesGroupItemDetailsProps = {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  details: Details;
  statusIcon: Nullable<typeof SvgIcon>;
};

const ReceivablesGroupItemDetails: FunctionComponent<ReceivablesGroupItemDetailsProps> = ({
  open,
  onClose,
  onOpen,
  details,
  statusIcon: StatusIcon,
}) => {
  const classes = useStyles();
  const {
    amount,
    totalAmount,
    receivableDate,
    status,
    discount,
    operation,
    saleDate,
    cardBrand,
    cardNumber,
    nsu,
    orderId,
    installment,
    installments,
    financialInstitutionName,
    financialInstitution,
  } = details;

  const renderSaleInfoContent = () => {
    const date = amount && amount > 0 ? saleDate : receivableDate;
    const salesInfo = {
      formattedInstallments: installments && `${installment} de ${installments}`,
      date: date && fullDateFormat(date),
      hour: nsu && date && hoursAndMinutes(date, true),
      operation,
      cardBrand,
      cardNumber: cardNumber && formatCardNumbers(cardNumber),
      nsu,
      orderId,
      financialInstitutionName,
      financialInstitution: financialInstitution && formatCnpj(financialInstitution || ''),
    };

    return Object.entries(salesInfoDictionary).map(([keyName, value]) => {
      const saleInfoValue = salesInfo[keyName as keyof typeof salesInfo];
      return (
        saleInfoValue && (
          <Box key={keyName} className={classes.salesInfoItem}>
            <Typography variant="caption">{value}</Typography>
            <Typography variant="caption">{saleInfoValue}</Typography>
          </Box>
        )
      );
    });
  };

  const displayStatus = status === ReceivablesStatus.BLOQUEADA ? ReceivablesStatus.PENDENTE : status;

  return (
    <DetailsDrawer title="Total a receber" open={open} onClose={onClose} onOpen={onOpen}>
      <Typography variant="subtitleWebBold">{`R$ ${formatAmount(totalAmount || 0)}`}</Typography>
      <ManageReceivableBasedOnAmount
        amount={amount || 0}
        discount={discount || 0}
        date={receivableDate}
        receivableStatus={status || ''}
        installments={installments || 0}
        StatusComponent={
          <Box display="flex" flexDirection="column" alignItems="center" my={4}>
            {StatusIcon && <StatusIcon fontSize="small" data-testid="receivable-details-icon" />}
            {displayStatus && <Typography variant="caption">{displayStatus}</Typography>}
          </Box>
        }
      />

      <Box display="flex" flexDirection="column" width="100%">
        {renderSaleInfoContent()}
      </Box>

      {status === ReceivablesStatus.BLOQUEADA && <ReceivablesGroupPendingActions />}
    </DetailsDrawer>
  );
};

export default ReceivablesGroupItemDetails;
