import type { FunctionComponent } from 'react';
import React, { useState } from 'react';

import { Box, Grid, Hidden, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import BorderAllRoundedIcon from '@material-ui/icons/BorderAllRounded';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckIcon from '@material-ui/icons/Check';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';

import BrandIcon from 'components/UI/BrandIcon';
import Typography from 'components/UI/Typography';
import { capitalize, formatAmount, formatCardNumbersSmall } from 'helpers/formatters/formatters';
import { ReceivableItem, OperationLabel, ReceivablesOperation, ReceivablesStatus } from 'models/receivables';

import ReceivablesGroupItemDetails from './Details';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2, 0),
    width: '100%',
    borderBottom: `1px solid ${theme.palette.divider}`,
    cursor: 'pointer',
  },
  column: {
    display: 'flex',
    alignItems: 'center',
  },
  valueContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    marginRight: theme.spacing(2),
    height: 40,
  },
  installments: {
    color: theme.palette.carbon[60],
  },
  sendDocumentsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(4),
    },
  },
  sendDocuments: {
    fontSize: theme.typography.fontSize,
    fontWeight: 'bold',
    color: theme.palette.textColor.link,
    cursor: 'pointer',
    textAlign: 'center',
  },
  statusValue: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  statusContainer: {
    width: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: theme.spacing(1),
  },
  carbon70Color: {
    color: theme.palette.carbon[70],
  },
  brandContainer: {
    minWidth: 30,
    margin: theme.spacing(0, 2, 0, 1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.palette.carbon[10]}`,
    borderRadius: '50%',
    width: theme.spacing(4),
    height: theme.spacing(4),
    background: theme.palette.backgroundColor.surface?.marbleDark,
    '& > svg': {
      maxWidth: 26,
      maxHeight: 26,
    },
  },
  detail: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardNumberContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(0.5),
  },
  cardNumber: {
    minWidth: 80,
    marginRight: theme.spacing(1),
  },
  netValue: {
    '& > div:first-child': {
      textAlign: 'left',
    },
    '& > div:last-child': {
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
  },
  carbon: {
    fill: theme.palette.carbon[100],
  },
  statusWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  iconButton: {
    padding: 0,
    margin: 0,
  },
  success: {
    fontSize: '0.75rem',
    fill: theme.palette.composition.status?.active,
  },
  warning: {
    fontSize: '0.75rem',
    fill: theme.palette.composition.status?.attention,
  },
}));

type ReceivablesGroupItemProps = {
  receivable: ReceivableItem;
};

const ReceivablesGroupItem: FunctionComponent<ReceivablesGroupItemProps> = ({ receivable }) => {
  const classes = useStyles();
  const {
    installments: quantityInstallments,
    cardBrand,
    cardNumber,
    operation,
    status,
    totalAmount,
    discount,
    amount,
  } = receivable;

  const [detailsOpen, setDetailsOpen] = useState(false);

  const statuses = {
    [ReceivablesStatus.AGENDADO]: {
      element: <FiberManualRecordIcon className={classes.warning} />,
      icon: CalendarTodayIcon,
    },
    [ReceivablesStatus.PAGO]: {
      element: <FiberManualRecordIcon className={classes.success} />,
      icon: DoneAllIcon,
    },
    [ReceivablesStatus.ADIADA]: {
      element: <FiberManualRecordIcon className={classes.warning} />,
      icon: SubdirectoryArrowRightIcon,
    },
    [ReceivablesStatus.CANCELADA]: {
      element: <FiberManualRecordIcon color="error" />,
      icon: CancelOutlinedIcon,
    },
    [ReceivablesStatus.PENDENTE]: {
      element: <FiberManualRecordIcon className={classes.warning} />,
      icon: QueryBuilderIcon,
    },
    [ReceivablesStatus.LIQUIDADA]: {
      element: <FiberManualRecordIcon color="error" />,
      icon: CheckIcon,
    },
    [ReceivablesStatus.OUTROS]: {
      element: <FiberManualRecordIcon className={classes.warning} />,
      icon: BorderAllRoundedIcon,
    },
    [ReceivablesStatus.BLOQUEADA]: {
      element: <FiberManualRecordIcon color="error" />,
      icon: LockOutlinedIcon,
    },
  };

  const operationLabel = (): string => {
    if (!operation) {
      return '';
    }

    if (quantityInstallments && quantityInstallments > 1) {
      return OperationLabel.CREDITO_PARCELADO;
    }

    switch (operation) {
      case ReceivablesOperation.DEBITO:
        return OperationLabel.DEBITO;
      case ReceivablesOperation.CREDITO:
        return OperationLabel.CREDITO_A_VISTA;
      default:
        return capitalize(operation);
    }
  };

  const displayStatus = status === ReceivablesStatus.BLOQUEADA ? ReceivablesStatus.PENDENTE : status;

  return (
    <>
      <ReceivablesGroupItemDetails
        open={detailsOpen}
        onClose={() => setDetailsOpen(false)}
        onOpen={() => setDetailsOpen(true)}
        details={{ ...receivable, operation: operationLabel() }}
        statusIcon={displayStatus && statuses[displayStatus].icon}
      />
      <Box className={classes.root} onClick={() => setDetailsOpen(true)}>
        <Grid container item xs={12} justify="space-between">
          <Grid item md={4} sm={6} xs={6}>
            <Box className={classes.column}>
              <Box className={classes.brandContainer}>{cardBrand && <BrandIcon brand={cardBrand} />}</Box>
              <Box className={classes.detail}>
                <Typography variant="caption" className={classes.carbon70Color}>
                  {operationLabel()}
                </Typography>
                {cardNumber && (
                  <Typography variant="caption" className={classes.cardNumber}>
                    {formatCardNumbersSmall(cardNumber)}
                  </Typography>
                )}
              </Box>
            </Box>
          </Grid>
          <Hidden smDown>
            <Grid item md={2} container alignItems="center">
              <Typography variant="caption">{`R$ ${formatAmount(amount || 0)}`}</Typography>
            </Grid>
          </Hidden>

          <Hidden smDown>
            <Grid item md={2} container alignItems="center">
              <Typography variant="caption">{`R$ ${formatAmount(discount || 0)}`}</Typography>
            </Grid>
          </Hidden>

          <Grid item md={2} xs={4} container alignItems="center" className={classes.netValue}>
            <Grid item xs={12}>
              <Typography variant="caption">{`R$ ${formatAmount(totalAmount || 0)}`}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.statusContainer}>
                {displayStatus && statuses[displayStatus].element}
                <Typography variant="caption">{displayStatus}</Typography>
              </Box>
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid item md={2} xs={2} container alignItems="center" className={classes.statusValue}>
              <Grid item md={6} xs={6}>
                <Box className={classes.statusContainer}>
                  {displayStatus && statuses[displayStatus].element}
                  <Typography variant="caption">{displayStatus}</Typography>
                </Box>
              </Grid>
              <Grid item md={6} xs={6} className={classes.statusWrapper}>
                <IconButton
                  aria-label="detalhes"
                  size="small"
                  className={classes.iconButton}
                  onClick={() => setDetailsOpen(true)}
                >
                  <AttachFileIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </Box>
    </>
  );
};

export default ReceivablesGroupItem;
