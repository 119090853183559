import { formatUserDocument } from 'helpers/formatters/formatters';
import { DirfResponse } from 'models/dirf';
import { client } from 'services/client';

export const dirfReportService = async (year: string): Promise<DirfResponse> => {
  try {
    const localStorageAuthJson = localStorage.getItem('persist:auth') as string;
    const localStorageAuth = JSON.parse(localStorageAuthJson);
    const localStorageAuthData = JSON.parse(localStorageAuth.data);

    const localStorageAccountJson = localStorage.getItem('persist:account') as string;
    const localStorageAccount = JSON.parse(localStorageAccountJson);
    const localStorageAccountData = JSON.parse(localStorageAccount.data);

    const response = await client.get<BlobPart>(`portal/v1/accounts/dirf?year=${year}`, {
      token: localStorageAuthData.accessToken,
      useWhitelabel: true,
      headers: {
        'document-number': formatUserDocument(localStorageAccountData.documentNumber),
        accept: 'application/pdf',
      },
    });

    if (response?.parsedBlob) {
      return {
        data: response?.parsedBlob,
      };
    }

    throw new Error('Ocorreu um erro ao baixar o relatório');
  } catch (error) {
    return { errorMessage: error.message };
  }
};
