/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import { Dialog, Box, IconButton, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import Typography from 'components/UI/Typography';
import { useAppDispatch } from 'store';
import { DialogsActions } from 'store/dialogs';

const useStyles = makeStyles((theme) => ({
  modalTitle: {
    color: theme.palette.textColor.title,
    marginBottom: theme.spacing(2),
  },
  modalWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    '& > ul': {
      listStylePosition: 'outside',
      margin: '1px solid red',
    },
    '& > ol': {
      listStylePosition: 'outside',
      margin: '1px solid red',
    },
  },
  closeIcon: {
    width: '15px',
    height: '15px',
    '& :first-child': {
      width: '18px',
      height: '18px',
    },
  },
  modalContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  modalDescription: {
    margin: theme.spacing(2, 0),
  },
  buttonSection: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(4),
  },
  firstButton: {
    marginRight: theme.spacing(2),
  },
}));

const urlDownloadYourBank = 'https://www.c6bank.com.br/baixe-seu-banco';
const urlWebBanking = 'https://www.c6bank.com.br/web-banking/';

type DialogWebBankingProps = {
  setOpen: (arg: boolean) => void;
  isOpen: boolean;
};

const DialogWebBanking = ({ setOpen, isOpen }: DialogWebBankingProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const handleCloseDialog = () => {
    setOpen(false);
    dispatch(DialogsActions.changeWebBankingModal(true));
  };

  return (
    <>
      <Dialog
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        maxWidth="xs"
        fullWidth
      >
        <Box className={classes.modalWrapper}>
          <Box className={classes.modalContainer}>
            <Typography variant="bodyBold" className={classes.modalTitle}>
              C6 Pay: Aviso importante
            </Typography>
            <IconButton aria-label="close" className={classes.closeIcon} onClick={handleCloseDialog}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Typography variant="body" className={classes.modalDescription}>
            <b>Cliente C6 Pay, temos um aviso importante para você: o Portal C6 Pay será desativado em breve</b> e todas
            as funcionalidades que você já conhece deverão ser acessadas pelo app C6 Bank.
          </Typography>
          <Typography variant="paragraph" paragraph>
            Para acessar, é só baixar o app e abrir a sua conta C6 em poucos minutos de forma 100% digital e gratuita
            para clientes PJ.
          </Typography>
          <Typography variant="paragraph" paragraph>
            Para clientes MEI, é cobrada uma taxa de manutenção de R$8,00/mês, com possibilidade de isenção - consulte
            mais informações.{' '}
          </Typography>
          <Typography variant="paragraph">Assim, você tem acesso à melhor experiência C6 Pay:</Typography>

          <ul>
            <li>
              <Typography variant="paragraph">
                Faça sua gestão de vendas e recebíveis de forma ágil e simplificada;
              </Typography>
            </li>
            <li>
              <Typography variant="paragraph">Baixe relatórios personalizados de vendas e recebíveis;</Typography>
            </li>
            <li>
              <Typography variant="paragraph">
                Acompanhe o status de todos os seus pedidos, até a etapa de entrega;
              </Typography>
            </li>
            <li>
              <Typography variant="paragraph">Consulte os manuais das maquininhas;</Typography>
            </li>
            <li>
              <Typography variant="paragraph">E muito mais!</Typography>
            </li>
          </ul>

          <Typography variant="paragraph" paragraph>
            Abra a sua conta: <Link href={urlDownloadYourBank}>https://www.c6bank.com.br/baixe-seu-banco</Link>
          </Typography>

          <Typography variant="paragraph" paragraph>
            Se você já é cliente C6, é só acessar o app e conferir:
          </Typography>

          <ol>
            <li>
              <Typography variant="paragraph" paragraph>
                Acesse a sua conta;
              </Typography>
            </li>
            <li>
              <Typography variant="paragraph" paragraph>
                Na tela inicial, toque em "Todos"
              </Typography>
            </li>
            <li>
              <Typography variant="paragraph" paragraph>
                Selecione "Maquininha C6 Pay" e pronto!
              </Typography>
            </li>
          </ol>

          <Typography variant="paragraph" paragraph>
            Clientes C6 Empresas também têm acesso ao web banking do C6 Bank:
          </Typography>

          <ol>
            <li>
              <Typography variant="paragraph" paragraph>
                Baixe o aplicativo no site <Link href={urlWebBanking}>https://www.c6bank.com.br/web-banking/</Link>;
              </Typography>
            </li>
            <li>
              <Typography variant="paragraph" paragraph>
                Acesse a sua conta;
              </Typography>
            </li>
            <li>
              <Typography variant="paragraph" paragraph>
                Toque em "C6 Pay";
              </Typography>
            </li>
            <li>
              <Typography variant="paragraph" paragraph>
                Escolha "Maquininha C6 Pay" e pronto!
              </Typography>
            </li>
          </ol>

          <Typography variant="paragraph" paragraph>
            Até mais,
          </Typography>
          <Typography variant="bodyBold" paragraph>
            Time C6 Bank.
          </Typography>

          <Typography variant="paragraph" paragraph>
            Experimente um banco completo e tenha tudo em um só app. Abra sua conta no C6 e conheça o banco da sua vida.
            Acesse e baixe o app agora!
          </Typography>
        </Box>
      </Dialog>
    </>
  );
};

export default DialogWebBanking;
