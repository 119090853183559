import type { FunctionComponent } from 'react';
import React from 'react';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2, 1),
    borderBottom: `1px solid ${theme.palette.carbon[10]}`,
  },
}));

const PaymentLinkSkeleton: FunctionComponent = () => {
  const classes = useStyles();

  const skeletonKeys = [1, 2, 3, 4, 5, 6];
  return (
    <Box width="100%">
      {skeletonKeys.map((key) => {
        return (
          <Box className={classes.row} key={key}>
            <Box display="flex">
              <Box display="flex" flexDirection="column" justifyContent="space-between">
                <Skeleton width={120} height={24} animation="wave" />
                <Skeleton width={120} height={16} animation="wave" />
              </Box>
            </Box>
            <Box display="flex" alignItems="center">
              <Skeleton width={50} height={24} animation="wave" />
              <Box ml={5}>
                <Skeleton width={32} height={40} animation="wave" />
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default PaymentLinkSkeleton;
