import React, { Dispatch, SetStateAction, useState } from 'react';

import { makeStyles, Modal, Box, Grid } from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { withStyles } from '@material-ui/styles';

import { ReactComponent as EdiIcon } from 'assets/images/edi.svg';
import Button from 'components/UI/Button';
import Typography from 'components/UI/Typography';
import { datePickerFormat } from 'helpers/date';
import { getSalesEdi } from 'services/sales';

const useStyles = makeStyles((theme) => ({
  modalContent: {
    padding: theme.spacing(3),
    border: `1px solid ${theme.palette.carbon[10]}`,
    boxSizing: 'border-box',
    background: theme.palette.common.white,
    borderRadius: 8,
    width: '100%',
    outline: 'none',
    overflowY: 'scroll',
    height: '100vh',
    [theme.breakpoints.up('sm')]: {
      overflowY: 'hidden',
      width: 550,
      height: 'auto',
    },
    display: 'grid',
    gap: theme.spacing(3),
  },
  ediIcon: {
    marginBottom: theme.spacing(3),
  },
  modalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rectangle: {
    border: `1px solid ${theme.palette.carbon[10]}`,
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  datePicker: {
    opacity: 0,
    width: 0,
    height: 0,
  },
  buttonsWrapper: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
    '& > :last-child': {
      margin: theme.spacing(1, 0, 0, 0),
    },
    [theme.breakpoints.up('xs')]: {
      flexDirection: 'row-reverse',
      '& > :last-child': {
        margin: theme.spacing(0, 1, 0, 0),
      },
    },
  },
  actionButton: {
    padding: theme.spacing(2),
    justifyContent: 'center',
    alignItems: 'center',
  },
  explanation: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0, 2),
  },
}));

const ShowDateButton = withStyles((theme) => ({
  root: {
    color: theme.palette.carbon[100],
    borderColor: theme.palette.carbon[100],
    borderRadius: theme.spacing(0.5),
    '&:hover': {
      borderColor: theme.palette.carbon[70],
      color: theme.palette.carbon[70],
    },
  },
}))(Button);

type ModalEdiProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const ModalEdi = ({ open, setOpen }: ModalEdiProps) => {
  const classes = useStyles();
  const today = new Date().toISOString();
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const [labelButton, setLabelButton] = useState('Exibir data');
  const [buttonIsDisable, setButtonIsDisable] = useState(true);

  const [selectedDate, setSelectedDate] = useState<Date | string>(today);

  const [reportLoading, setReportLoading] = useState(false);
  const [reportError, setReportError] = useState(false);

  const shouldDisableWeekend = (date: Date) => {
    return date.getDay() === 0 || date.getDay() === 6;
  };

  const downloadEDIReport = () => {
    setReportLoading(true);
    getSalesEdi(selectedDate as string)
      .then((response) => {
        if (response.data) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.id = 'download_button_report';
          link.setAttribute('download', `edi.xlsx`);
          link.click();
          link.remove();
        } else {
          throw new Error(response.errorMessage);
        }
      })
      .catch(() => setReportError(true))
      .finally(() => setReportLoading(false));
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)} className={classes.modalContainer}>
      <Box className={classes.modalContent}>
        <Grid container alignItems="center" direction="column">
          <EdiIcon className={classes.ediIcon} />
          <Typography variant="bodyBold">Baixar extrato EDI</Typography>
          <Typography variant="caption" className={classes.explanation} align="center">
            Baixe o extrato de um dia específico dos últimos 30 dias úteis com as transações que foram processadas e
            liquidadas na sua conta.
          </Typography>
        </Grid>
        <Box className={classes.rectangle}>
          <Box>
            <Typography variant="paragraph">Selecione uma data para o extrato</Typography>
            <Typography variant="caption">Os últimos 30 dias úteis estão disponíveis</Typography>
          </Box>
          <KeyboardDatePicker
            format="dd/MM/yyyy"
            open={datePickerOpen}
            value={selectedDate}
            onOpen={() => setDatePickerOpen(true)}
            onClose={() => setDatePickerOpen(false)}
            variant="inline"
            disableToolbar
            disableFuture
            disabled
            autoOk
            shouldDisableDate={(date) => shouldDisableWeekend(date as Date)}
            className={classes.datePicker}
            onChange={(date) => {
              setSelectedDate(date as Date);
              setLabelButton(datePickerFormat(date as Date));
              setButtonIsDisable(false);
            }}
          />
          <ShowDateButton
            onClick={() => setDatePickerOpen(true)}
            color="default"
            variant="outlined"
            startIcon={<CalendarTodayIcon />}
          >
            {labelButton}
          </ShowDateButton>
        </Box>
        <Box className={classes.buttonsWrapper}>
          <Button
            variant="contained"
            title="aplicar filtros"
            className={classes.actionButton}
            color="primary"
            fullWidth
            onClick={downloadEDIReport}
            disabled={buttonIsDisable}
            loading={reportLoading}
          >
            Baixar
          </Button>
          <Button
            fullWidth
            onClick={() => setOpen(false)}
            variant="outlined"
            className={classes.actionButton}
            color="default"
            type="reset"
          >
            Cancelar
          </Button>
        </Box>
        {reportError && (
          <Typography variant="caption" align="center">
            Houve um erro ao buscar o relatório. Verifique se tem venda nesta data ou tente novamente mais tarde.
          </Typography>
        )}
      </Box>
    </Modal>
  );
};

export default ModalEdi;
