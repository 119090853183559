import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import PageSection from 'components/PageSection';
import SalesFilter from 'components/Sales/Filter';
import SalesGroup from 'components/Sales/Group';
import SalesGroupSkeleton from 'components/Sales/Group/Skeleton';
import SalesHeader from 'components/Sales/Header';
import SmallCard from 'components/SmallCard';
import Button from 'components/UI/Button';
import EmptyList from 'components/UI/EmptyList';
import ScrollToTopButton from 'components/UI/ScrollToTopButton/ScrollToTopButton';
import ServerError from 'components/UI/ServerError';
import { fullDateFormat } from 'helpers/date';
import { formatRangeDate } from 'helpers/formatters/formatters';
import type { SaleGroup, SaleGroupedRecord, Filter, SalesSummary } from 'models/sales';
import { getSales, getSalesService } from 'services/sales';
import { AccountSelectors } from 'store/accounts';

import { periodDictionary } from './constants';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(6, 0),

    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(4, 0),
    },

    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(4),
    },
  },
  cardsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(9, 1fr)',
    gridGap: theme.spacing(2),
  },
  emptyStateContainer: {
    margin: theme.spacing(6, 'auto'),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(3, 'auto'),
    },
  },
  pageSection: {
    backgroundColor: theme.palette.backgroundColor.surface?.secondary,
    border: `1px solid ${theme.palette.carbon[10]}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    width: '100%',
  },
  iconLink: {
    color: theme.palette.info.dark,
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    fontWeight: theme.typography.fontWeightBold,
    '& > svg': {
      marginRight: theme.spacing(0.5),
    },
  },
  link: {
    color: theme.palette.info.dark,
    textDecoration: 'none',
  },
}));

const Sales = () => {
  const today = new Date();
  const classes = useStyles();

  const [sales, setSales] = useState<SaleGroup | null>(null);
  const [salesError, setSalesError] = useState<boolean>(false);
  const [salesIsLoading, setSalesIsLoading] = useState(true);

  const [salesSummaries, setSalesSummaries] = useState<SalesSummary | null>(null);
  const [salesSummariesLoading, setSalesSummariesLoading] = useState(false);
  const [salesSummariesError, setSalesSummariesError] = useState(false);
  const [salesSummariesUpdatedAt, setSalesSummariesUpdatedAt] = useState<string>();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [filter, setFilter] = useState<Filter>({
    endDate: today,
    startDate: today,
    method: 'all',
    status: 'all',
    date: 'today',
  });

  const accountDocumentNumber = useSelector(AccountSelectors.accountDocumentNumber);

  const { today: todayText, lastWeek, lastMonth } = periodDictionary;

  const mergePaginatedPayload = (oldRecord: SaleGroup, newRecord: SaleGroup) => {
    const local = [...oldRecord.groupedRecords, ...newRecord.groupedRecords];

    const items = local.reduce((acc: SaleGroupedRecord[], curr: SaleGroupedRecord) => {
      if (acc.length && acc[acc.length - 1].date && curr.date) {
        const accDate = acc[acc.length - 1].date as string;

        if (fullDateFormat(accDate) === fullDateFormat(curr.date)) {
          acc[acc.length - 1].items.push(...curr.items);
          acc[acc.length - 1].total = acc[acc.length - 1].total + curr.total;
          return acc;
        }
      }

      acc.push(curr);
      return acc;
    }, []);

    return {
      currentPage: newRecord.currentPage,
      totalPages: newRecord.totalPages,
      groupedRecords: items,
    };
  };

  const saleService = useCallback(
    (currPage: number, callback: (data: SaleGroup) => void) => {
      setSalesIsLoading(true);
      setSalesError(false);

      const newCurrentPage = currPage + 1;

      getSalesService({
        documentNumber: accountDocumentNumber as string,
        startDate: filter.startDate,
        endDate: filter.endDate,
        status: filter.status,
        paymentType: filter.method,
        page: newCurrentPage,
      })
        .then((response) => {
          const { data, errorMessage } = response;

          if (data) {
            setTotalPages(data.totalPages);
            setCurrentPage(newCurrentPage);
            callback(data);
          } else {
            throw new Error(errorMessage);
          }
        })
        .catch((error) => {
          setSalesError(true);
          return { errorMessage: error };
        })
        .finally(() => setSalesIsLoading(false));
    },
    [filter, accountDocumentNumber],
  );

  const handleSalesSummary = (accountDocument: string): void => {
    setSalesSummariesLoading(true);
    setSalesSummariesError(false);
    setSalesSummariesUpdatedAt(new Date().toISOString());

    getSales(accountDocument as string)
      .then((response) => {
        if (response.data) {
          setSalesSummaries(response.data);
        } else {
          throw new Error(response.errorMessage);
        }
      })
      .catch(() => setSalesSummariesError(true))
      .finally(() => setSalesSummariesLoading(false));
  };

  const nextPage = () => {
    saleService(currentPage, (data: SaleGroup) => {
      const mergedData = mergePaginatedPayload(sales!, data);
      setSales(mergedData);
    });
  };

  useEffect(() => {
    saleService(0, (data) => {
      setSales(data);
    });
  }, [saleService]);

  useEffect(() => {
    handleSalesSummary(accountDocumentNumber as string);
  }, [accountDocumentNumber]);

  const SalesList = () => {
    if (salesIsLoading) {
      return <SalesGroupSkeleton />;
    }

    if (salesError) {
      return (
        <ServerError
          onTryAgain={() => {
            saleService(1, (data) => {
              setSales(data);
            });
          }}
        />
      );
    }

    if (sales && sales.groupedRecords.length) {
      return (
        <>
          {sales.groupedRecords.map((group) => (
            <SalesGroup date={group.date} total={group.total} key={group.date} list={group.items} />
          ))}
          {totalPages > currentPage && (
            <Box display="flex" flexDirection="column" alignItems="center">
              <Button
                type="submit"
                title="Mostrar mais"
                variant="contained"
                color="primary"
                size="small"
                disabled={salesIsLoading || totalPages <= currentPage}
                onClick={() => nextPage()}
              >
                MOSTRAR MAIS
              </Button>
            </Box>
          )}
          <ScrollToTopButton />
        </>
      );
    }

    return <EmptyList subtitle="Você não tem vendas para exibir com o filtro selecionado." />;
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <SalesHeader
          page={currentPage}
          titleProps={{
            title: 'Vendas',
          }}
        />
      </Grid>
      <Grid item xs={12} className={classes.pageSection}>
        <PageSection
          title="Resumo de vendas"
          updatedAt={salesSummariesUpdatedAt}
          updatedAtLoading={salesSummariesLoading}
          hasError={salesSummariesError}
          onUpdateCard={() => handleSalesSummary(accountDocumentNumber as string)}
        >
          <Box className={classes.cardsContainer}>
            <SmallCard
              prefix="R$"
              value={salesSummaries?.today?.total}
              count={`${salesSummaries?.today?.count} vendas` || ''}
              rangeDate={formatRangeDate(salesSummaries?.today?.startDate || '')}
              text={todayText}
              isLoading={salesSummariesLoading}
              hasError={salesSummariesError}
            />
            <SmallCard
              prefix="R$"
              value={salesSummaries?.lastWeek?.total}
              text={lastWeek}
              count={`${salesSummaries?.lastWeek?.count} vendas` || ''}
              rangeDate={formatRangeDate(salesSummaries?.lastWeek?.endDate || '', salesSummaries?.lastWeek?.startDate)}
              isLoading={salesSummariesLoading}
              hasError={salesSummariesError}
            />
            <SmallCard
              prefix="R$"
              value={salesSummaries?.lastMonth?.total}
              text={lastMonth}
              count={`${salesSummaries?.lastMonth?.count} vendas` || ''}
              isLoading={salesSummariesLoading}
              rangeDate={formatRangeDate(
                salesSummaries?.lastMonth?.endDate || '',
                salesSummaries?.lastMonth?.startDate,
              )}
              hasError={salesSummariesError}
            />
          </Box>
        </PageSection>
      </Grid>
      <Grid item xs={12} className={classes.pageSection}>
        <PageSection
          title="Vendas"
          updatedAt={salesSummariesUpdatedAt}
          updatedAtLoading={salesSummariesLoading}
          hasError={salesSummariesError}
          onUpdateCard={() => handleSalesSummary(accountDocumentNumber as string)}
        >
          <SalesFilter setFilter={setFilter} filter={filter} />
          <Grid item xs={12}>
            <SalesList />
          </Grid>
        </PageSection>
      </Grid>
    </Grid>
  );
};

export default Sales;
