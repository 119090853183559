import React, { useState } from 'react';
import type { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import { Box, Modal, Popover } from '@material-ui/core';
import { fade, makeStyles } from '@material-ui/core/styles';

import { ReactComponent as AccountBoxIcon } from 'assets/images/account-box.svg';
import { ReactComponent as ExitIcon } from 'assets/images/exit.svg';
import { ReactComponent as SwitchAccountIcon } from 'assets/images/switch-account.svg';
import AuthAccessAccount from 'components/Auth/Forms/AccountAccess';
import Button from 'components/UI/Button';
import Typography from 'components/UI/Typography';
import { useAppDispatch } from 'store';
import { AccountUserDocument } from 'store/accounts/types';
import { CredentialsActions } from 'store/auth/credentials';

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    padding: theme.spacing(3),
    border: `1px solid ${theme.palette.carbon[10]}`,
    background: theme.palette.common.white,
    borderRadius: 8,
    width: '100%',
    maxWidth: 550,
    height: 'auto',
    maxHeight: '90vh',
  },
  typography: {
    color: fade(theme.palette.secondary.contrastText, 0.7),
  },
  usernameLabel: {
    color: fade(theme.palette.secondary.contrastText, 0.9),
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  button: {
    textTransform: 'none',
    color: 'inherit',
    padding: 0,
    marginTop: theme.spacing(2),
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:focus-visible': {
      outline: `1px solid ${fade(theme.palette.secondary.contrastText, 0.7)}`,
      outlineOffset: '2px',
    },
  },
  section: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1, 0, 2),
    justifyContent: 'space-between',
    color: theme.palette.secondary.contrastText,
  },
  userName: {
    display: 'flex',
    flexDirection: 'column',
  },
  linkAccount: {
    textDecoration: 'none',
    color: 'inherit',
  },
  exitIcon: {
    fill: fade(theme.palette.secondary.contrastText, 0.9),
  },
  paper: {
    backgroundColor: theme.palette.secondary.light,
    padding: theme.spacing(2),
    boxSizing: 'border-box',
    color: fade(theme.palette.secondary.contrastText, 0.9),
    '& svg': {
      marginRight: theme.spacing(1),
    },
    '& > div': {
      cursor: 'pointer',
    },
  },
}));

type Props = {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  submitChangeAccount: (documentNumber: string) => void;
  accountUserDocuments: AccountUserDocument[];
};

const PopoverOptions: FunctionComponent<Props> = ({
  anchorEl,
  handleClose,
  submitChangeAccount,
  accountUserDocuments,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useAppDispatch();

  const isAccountUserEqualOne = accountUserDocuments.length === 1;

  const classes = useStyles();

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{
          paper: classes.paper,
        }}
      >
        <Link to="/account" className={classes.linkAccount}>
          <Box display="flex" alignItems="center">
            <AccountBoxIcon />
            <Typography variant="body2">Ver minha conta</Typography>
          </Box>
        </Link>

        {!isAccountUserEqualOne && (
          <Button className={classes.button} variant="text" onClick={() => setIsModalOpen(true)}>
            <SwitchAccountIcon />
            <Typography variant="body2">Ver outra conta</Typography>
          </Button>
        )}

        <Link to="/auth/login" className={classes.linkAccount} onClick={() => dispatch(CredentialsActions.logout())}>
          <Box display="flex" alignItems="center" mt={2}>
            <ExitIcon className={classes.exitIcon} />
            <Typography variant="body2">Sair</Typography>
          </Box>
        </Link>
      </Popover>

      <Modal
        className={classes.modalContainer}
        open={isModalOpen}
        aria-labelledby="Crie um link de pagamento."
        aria-describedby="Crie links de pagamento e compartilhe com seus clientes."
      >
        <Box height="100%" className={classes.modalContent}>
          <AuthAccessAccount
            submit={(value) => {
              setIsModalOpen(false);
              submitChangeAccount(value);
            }}
            onCancel={() => setIsModalOpen(false)}
            error={false}
            loading={false}
            userDocuments={accountUserDocuments?.map((ac) => ({
              documentNumber: ac.documentNumber,
              corporateName: ac.corporateName || 'Nome indisponível',
            }))}
            title="Ver outra conta"
          />
        </Box>
      </Modal>
    </>
  );
};

export default PopoverOptions;
