import type { FunctionComponent } from 'react';
import React from 'react';

import { Box, Card, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

type Props = {
  type: 'machine' | 'link';
};

const useStyles = makeStyles((theme) => ({
  cardCell: {
    gridColumnEnd: 'span 3',

    [theme.breakpoints.down('md')]: {
      gridColumnEnd: 'span 6',
    },
    [theme.breakpoints.down('xs')]: {
      gridColumnEnd: 'span 12',
    },
  },
  machineCard: {
    height: '156px',
  },
  linkCard: {
    height: '193px',
  },
  title: {
    whiteSpace: 'pre-wrap',
  },
  infoText: {
    color: theme.palette.carbon[90],
  },
  footerContainer: {
    borderTop: `1px solid ${theme.palette.carbon[10]}`,
  },
  footerText: {
    color: theme.palette.carbon[90],
    display: 'flex',
    alignItems: 'center',
  },
}));

const CommercialConditionsCardSkeleton: FunctionComponent<Props> = ({ type }) => {
  const classes = useStyles();

  const isMachineType = type === 'machine';

  return (
    <Card className={isMachineType ? classes.machineCard : classes.linkCard} data-testid="commercial-card-skeleton">
      <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
        <Box display="flex" justifyContent="space-between">
          <Skeleton width={16} animation="wave" />
          <Box pl={2} width="100%">
            <Skeleton animation="wave" />
          </Box>
        </Box>
        <Box display="flex">
          <Box display="flex" justifyContent="space-between" flexDirection="column" mr={4}>
            <Skeleton width={46} height="100%" animation="wave" />
            <Skeleton width={46} height="100%" animation="wave" />
          </Box>
          <Box display="flex" justifyContent="space-between" flexDirection="column">
            <Skeleton width={46} height="100%" animation="wave" />
            <Skeleton width={46} height="100%" animation="wave" />
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default CommercialConditionsCardSkeleton;
