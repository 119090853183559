import { Nullable } from '@portal-types/helpers';

export enum BusinessLegalType {
  BUSINESS = 'business',
  PERSONAL = 'personal',
}

export type BusinessData = {
  documentNumber: Nullable<string>;
  corporateName: Nullable<string>;
  brandName: Nullable<string>;
  akreditasiId: Nullable<string>;
  type: Nullable<BusinessLegalType>;
};

export enum ParsedBusinessLegalType {
  BUSINESS = 'Pessoa Juridica',
  PERSONAL = 'Pessoa Fisica',
}

export type ParsedBusinessData = Pick<BusinessData, 'corporateName' | 'brandName'> & {
  cpf: Nullable<string>;
  cnpj: Nullable<string>;
  type: Nullable<ParsedBusinessLegalType>;
};

export type BusinessDataDictionary = Record<keyof ParsedBusinessData, string>;

export type Address = {
  addressName: Nullable<string>;
  addressNumber: Nullable<string>;
  city: Nullable<string>;
  country: Nullable<string>;
  complementaryAddress: Nullable<string>;
  neighborhood: Nullable<string>;
  state: Nullable<string>;
  zipCode: Nullable<string>;
};

export type AddressDictionary = Record<keyof Address, string>;

export type BankAccount = {
  accountNumber: Nullable<string>;
  branchNumber: Nullable<string>;
  holder: Nullable<string>;
  bankName: Nullable<string>;
  accountType: Nullable<string>;
};

export type BankDictionary = Record<keyof Omit<BankAccount, 'accountType'>, string>;

export enum ContactPhoneType {
  CELLPHONE = 'CELLPHONE',
  COMMERCIAL = 'COMMERCIAL',
}

export type Contacts = {
  email: Nullable<string>;
  name: Nullable<string>;
  phones: {
    type: ContactPhoneType;
    number: string;
  }[];
};

export type ParsedContact = Omit<Contacts, 'phones'> & {
  cellPhone: Nullable<string>;
  commercialPhone: Nullable<string>;
};

export type ContactDictionary = Record<keyof ParsedContact, string>;

export type AccountDataResponse = BusinessData & {
  addresses: Address[];
  bankAccounts: BankAccount[];
  contacts: Nullable<Contacts>;
};

export enum AccountGroupTitle {
  BUSINESS_DATA = 'Dados do Negócio',
  ADDRESS = 'Endereço',
  CONTACT = 'Contato',
  BANK = 'Banco',
}
