import type { FunctionComponent } from 'react';
import React from 'react';

import CreditCardIcon from '@material-ui/icons/CreditCard';

import { ReactComponent as Abrapetite } from 'assets/images/brands/abrapetite.svg';
import { ReactComponent as Alelo } from 'assets/images/brands/alelo.svg';
import { ReactComponent as Amex } from 'assets/images/brands/amex.svg';
import { ReactComponent as Banescard } from 'assets/images/brands/banescard.svg';
import { ReactComponent as Banesecard } from 'assets/images/brands/banese-card.svg';
import { ReactComponent as Ben } from 'assets/images/brands/ben.svg';
import { ReactComponent as Cabal } from 'assets/images/brands/cabal.svg';
import { ReactComponent as Cetelem } from 'assets/images/brands/cetelem.svg';
import { ReactComponent as Credsystem } from 'assets/images/brands/credsystem.svg';
import { ReactComponent as Credz } from 'assets/images/brands/credz.svg';
import { ReactComponent as Diners } from 'assets/images/brands/diners-club.svg';
import { ReactComponent as Elo } from 'assets/images/brands/elo.svg';
import { ReactComponent as GoodCard } from 'assets/images/brands/good-card.svg';
import { ReactComponent as GreenCard } from 'assets/images/brands/green-card.svg';
import { ReactComponent as Hiper } from 'assets/images/brands/hiper.svg';
import { ReactComponent as Hipercard } from 'assets/images/brands/hipercard.svg';
import { ReactComponent as JCB } from 'assets/images/brands/jcb.svg';
import { ReactComponent as Maestro } from 'assets/images/brands/maestro.svg';
import { ReactComponent as Mais } from 'assets/images/brands/mais.svg';
import { ReactComponent as Mastercard } from 'assets/images/brands/mastercard.svg';
import { ReactComponent as Nutricash } from 'assets/images/brands/nutricash.svg';
import { ReactComponent as Sicredi } from 'assets/images/brands/sicredi.svg';
import { ReactComponent as Sodexo } from 'assets/images/brands/sodexo.svg';
import { ReactComponent as Sorocred } from 'assets/images/brands/sorocred.svg';
import { ReactComponent as Ticket } from 'assets/images/brands/ticket.svg';
import { ReactComponent as Vegas } from 'assets/images/brands/vegas-cartoes-beneficios.svg';
import { ReactComponent as VeroCard } from 'assets/images/brands/vero-card.svg';
import { ReactComponent as VisaElectron } from 'assets/images/brands/visa-electron.svg';
import { ReactComponent as Visa } from 'assets/images/brands/visa.svg';
import { ReactComponent as Vr } from 'assets/images/brands/vr-beneficios.svg';
import { BrandDictionary, Brands } from 'models/brands';

export const brandDictionary: BrandDictionary = {
  [Brands.AMEX]: <Amex />,
  [Brands.BANESCARD]: <Banescard />,
  [Brands.BANESECARD]: <Banesecard />,
  [Brands.CABAL]: <Cabal />,
  [Brands.CETELEM]: <Cetelem />,
  [Brands.CREDSYSTEM]: <Credsystem />,
  [Brands.CREDZ]: <Credz />,
  [Brands.DINERS]: <Diners />,
  [Brands.ELO]: <Elo />,
  [Brands.HIPER]: <Hiper />,
  [Brands.HIPERCARD]: <Hipercard />,
  [Brands.JCB]: <JCB />,
  [Brands.MAESTRO]: <Maestro />,
  [Brands.MAIS]: <Mais />,
  [Brands.MASTERCARD]: <Mastercard />,
  [Brands.MASTER]: <Mastercard />,
  [Brands.SICREDI]: <Sicredi />,
  [Brands.SOROCRED]: <Sorocred />,
  [Brands.VISA]: <Visa />,
  [Brands.VISA_ELECTRON]: <VisaElectron />,

  [Brands.ABRAPETITE]: <Abrapetite />,
  [Brands.BEN]: <Ben />,
  [Brands.ALELO]: <Alelo />,
  [Brands.GOOD_CARD]: <GoodCard />,
  [Brands.GREEN_CARD]: <GreenCard />,
  [Brands.NUTRICASH]: <Nutricash />,
  [Brands.SODEXO]: <Sodexo />,
  [Brands.TICKET]: <Ticket />,
  [Brands.VR]: <Vr />,
  [Brands.VEROCARD]: <VeroCard />,
  [Brands.VEGAS_CARD]: <Vegas />,
  [Brands.CNAB]: <CreditCardIcon />,
  // TODO: [Brands.BANRICARD]: 'banricard',
  // TODO: [Brands.BIQ_BENEFICIOS]: 'biq-beneficios',
  // TODO: [Brands.BNBCLUBE]: 'bnbclube',
  // TODO: [Brands.CONVENIOS_CARD]: 'convenios-card',
  // TODO: [Brands.COOPERCRED]: 'coopercred',
  // TODO: [Brands.EUCARD]: 'eucard',
  // TODO: [Brands.FLEX_CAR_VISA_VAL]: 'flex-car',
  // TODO: [Brands.MAXXVAN]: 'maxxvan',
  // TODO: [Brands.PLANVALE]: 'planvale',
  // TODO: [Brands.SINDPLUS]: 'sindplus',
  // TODO: [Brands.UP_BRASIL]: 'up-brasil',
  // TODO: [Brands.VALECARD]: 'valecard',
  // TODO: [Brands.VALESHOP]: 'valeshop',
};

type Props = {
  brand: Brands;
};

const BrandIcon: FunctionComponent<Props> = ({ brand }) => {
  return <>{brandDictionary[brand]}</>;
};

export default BrandIcon;
