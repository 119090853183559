import React from 'react';

import { Box, Link, makeStyles } from '@material-ui/core';

import Typography from 'components/UI/Typography';

type ManualItemProps = {
  manualLink: string;
  machineImageLink: string;
  machineName: string;
  machineModel: string;
};

const useStyles = makeStyles((theme) => ({
  manualItem: {
    display: 'flex',
    alignContent: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    width: '100%',
    maxWidth: 184,
    minHeight: 276,
    backgroundColor: theme.palette.backgroundColor.surface?.tertiary,
    '& img': {
      maxWidth: 120,
      height: 100,
    },
    '& p, a': {
      textAlign: 'center',
    },
  },
  imageCopy: {
    color: theme.palette.carbon[60],
  },
}));

const ManualItem = ({ manualLink, machineImageLink, machineModel, machineName }: ManualItemProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Box component="li" className={classes.manualItem}>
      <Typography variant="caption" className={classes.imageCopy}>
        *Imagem meramente ilustrativa
      </Typography>
      <img src={machineImageLink} alt={`imagem ${machineModel}`} />
      <Typography variant="bodyBold">{machineName}</Typography>
      <Typography variant="body">{machineModel}</Typography>
      <Link href={manualLink} download target="_blank" rel="noopener noreferrer">
        <Typography variant="bodylink2">Baixar manual</Typography>
      </Link>
    </Box>
  );
};

export default ManualItem;
