import React from 'react';
import type { FunctionComponent } from 'react';

import { SvgIcon, Avatar, Box, Card, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import Typography from 'components/UI/Typography';
import { formatAmount } from 'helpers/formatters/formatters';

import SmallCardSkeleton from './SmallCardSkeleton';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    display: 'flex',
    width: '100%',
    minHeight: theme.spacing(12),
    backgroundColor: theme.palette.backgroundColor.surface?.tertiary,
  },
  title: {
    textTransform: 'capitalize',
  },
  value: {
    marginLeft: theme.spacing(1),
  },
  infoIcon: {
    marginRight: theme.spacing(1),
  },
  cardCell: {
    gridColumnEnd: 'span 3',

    [theme.breakpoints.down('sm')]: {
      gridColumnEnd: 'span 12',
      marginBottom: theme.spacing(2),
    },
  },
  avatar: {
    backgroundColor: theme.palette.backgroundColor.surface?.marbleDark,
  },
}));

type Props = {
  icon?: typeof SvgIcon;
  prefix?: string;
  suffix?: string;
  value?: number;
  count?: string;
  text: string;
  rangeDate?: string;
  isLoading: boolean;
  hasError: boolean;
};

const MySummariesCard: FunctionComponent<Props> = ({
  icon: Icon,
  prefix,
  value,
  count,
  text,
  rangeDate,
  isLoading,
  hasError,
}) => {
  const classes = useStyles();
  const isValidValue = value ?? 0;

  if (isLoading) {
    return (
      <Box className={classes.cardCell}>
        <SmallCardSkeleton />
      </Box>
    );
  }

  if (hasError) {
    return (
      <Box className={classes.cardCell}>
        <Card className={classes.card}>
          <Grid container direction="column" alignItems="center">
            <Grid container direction="row" alignItems="center" justify="center">
              <InfoOutlinedIcon className={classes.infoIcon} fontSize="small" />
              <Typography variant="bodyBold">Visualização indisponível</Typography>
            </Grid>
          </Grid>
        </Card>
      </Box>
    );
  }

  return (
    <Box className={classes.cardCell}>
      <Card className={classes.card}>
        {Icon && (
          <Box mr={2}>
            <Avatar className={classes.avatar}>
              <Icon color="primary" />
            </Avatar>
          </Box>
        )}
        <Grid item xs={12} container direction="column" justify="space-between">
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body" className={classes.title}>
              {text}
            </Typography>
            {rangeDate && <Typography variant="body">{rangeDate}</Typography>}
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Typography variant="caption">{prefix}</Typography>
              <Typography variant="bodyBold" className={classes.value}>
                {formatAmount(isValidValue)}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography variant="caption">{count}</Typography>
            </Box>
          </Box>
        </Grid>
      </Card>
    </Box>
  );
};

export default MySummariesCard;
