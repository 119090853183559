import type { FunctionComponent, ReactElement } from 'react';
import React from 'react';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ReceivablesFilterDate from 'components/Receivables/Filter/Date';
import SelectDropdown from 'components/SelectDropdown';
import {
  ReceivablesFilter as ReceivablesFilterType,
  ReceivablesQueryParamsBrands,
  ReceivablesQueryParamsOperation,
  ReceivablesQueryParamsStatus,
} from 'models/receivables';

const useStyles = makeStyles((theme) => ({
  selectWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(3),
      justifyContent: 'flex-start',
    },
  },
  columnStyle: {
    flexDirection: 'column',
    '& .MuiToggleButtonGroup-root': {
      flexWrap: 'wrap',
    },
    '& [class*="selectWrapper"]': {
      width: '100%',
      marginTop: theme.spacing(2),
    },
  },
}));

type Props = {
  setFilter: (filters: ReceivablesFilterType) => void;
  filter: ReceivablesFilterType;
  isColumn?: boolean;
};

const statusOptions: Array<{ value: ReceivablesQueryParamsStatus; label: string }> = [
  { value: ReceivablesQueryParamsStatus.SCHEDULED, label: 'Agendado' },
  { value: ReceivablesQueryParamsStatus.BLOCKED, label: 'Bloqueado' },
  { value: ReceivablesQueryParamsStatus.PAYED, label: 'Pago' },
  { value: ReceivablesQueryParamsStatus.PENDING, label: 'Pendente' },
];

const operationOptions: Array<{ value: ReceivablesQueryParamsOperation; label: string }> = [
  { value: ReceivablesQueryParamsOperation.CREDIT, label: 'Crédito à vista' },
  { value: ReceivablesQueryParamsOperation.DEBIT, label: 'Débito' },
  { value: ReceivablesQueryParamsOperation.INSTALLMENT_WITHOUT_INTEREST, label: 'Parcelado estabelecimento' },
  { value: ReceivablesQueryParamsOperation.INSTALLMENT_WITH_INTEREST, label: 'Parcelado emissor' },
];

const brandOptions: Array<{ value: ReceivablesQueryParamsBrands; label: string }> = [
  { value: ReceivablesQueryParamsBrands.HIPERCARD, label: 'Hipercard' },
  { value: ReceivablesQueryParamsBrands.MASTER, label: 'Mastercard' },
  { value: ReceivablesQueryParamsBrands.AMEX, label: 'Amex' },
  { value: ReceivablesQueryParamsBrands.ELO, label: 'Elo' },
  { value: ReceivablesQueryParamsBrands.VISA, label: 'Visa' },
  { value: ReceivablesQueryParamsBrands.DINERS, label: 'Diners' },
  { value: ReceivablesQueryParamsBrands.TICKET, label: 'Ticket' },
  { value: ReceivablesQueryParamsBrands.CETELEM, label: 'Cetelem' },
];

const ReceivablesFilter: FunctionComponent<Props> = ({ filter, setFilter, isColumn }): ReactElement => {
  const classes = useStyles();
  const [openPopperStatus, setOpenPopperStatus] = React.useState(false);
  const [openPopperOperation, setOpenPopperOperation] = React.useState(false);
  const [openPopperBrand, setOpenPopperBrand] = React.useState(false);
  const [anchorElStatus, setAnchorElStatus] = React.useState<null | HTMLElement>(null);
  const [anchorElOperation, setAnchorElOperation] = React.useState<null | HTMLElement>(null);
  const [anchorElBrand, setAnchorElBrand] = React.useState<null | HTMLElement>(null);

  const handleClickStatus = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElStatus(event.currentTarget);
    setOpenPopperStatus((previousOpen) => !previousOpen);
    setOpenPopperOperation(false);
    setOpenPopperBrand(false);
  };

  const handleClickOperation = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElOperation(event.currentTarget);
    setOpenPopperOperation((previousOpen) => !previousOpen);
    setOpenPopperStatus(false);
    setOpenPopperBrand(false);
  };

  const handleClickBrand = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElBrand(event.currentTarget);
    setOpenPopperBrand((previousOpen) => !previousOpen);
    setOpenPopperStatus(false);
    setOpenPopperOperation(false);
  };

  const changeFilter = (filterName: string) => {
    return (field: string[]) =>
      setFilter({
        ...filter,
        [filterName]: field,
      });
  };

  const canBeOpenOperation = openPopperOperation && Boolean(anchorElOperation);
  const canBeOpenStatus = openPopperStatus && Boolean(anchorElStatus);
  const canBeOpenBrand = openPopperBrand && Boolean(anchorElBrand);

  return (
    <Box className={`${classes.container} ${isColumn && classes.columnStyle}`}>
      <ReceivablesFilterDate filter={filter} setFilter={setFilter} />
      <Box className={classes.selectWrapper}>
        <SelectDropdown
          setAnchorEl={setAnchorElBrand}
          anchorEl={anchorElBrand}
          onChange={changeFilter('brand')}
          idPopper="brand"
          options={brandOptions}
          canBeOpen={canBeOpenBrand}
          buttonLabel="Bandeiras"
          onButtonClick={handleClickBrand}
          openPopper={openPopperBrand}
          setOpenPopper={setOpenPopperBrand}
        />
        <SelectDropdown
          setAnchorEl={setAnchorElOperation}
          anchorEl={anchorElOperation}
          onChange={changeFilter('operation')}
          idPopper="operation"
          options={operationOptions}
          canBeOpen={canBeOpenOperation}
          buttonLabel="Operações"
          onButtonClick={handleClickOperation}
          openPopper={openPopperOperation}
          setOpenPopper={setOpenPopperOperation}
        />
        <SelectDropdown
          setAnchorEl={setAnchorElStatus}
          anchorEl={anchorElStatus}
          onChange={changeFilter('status')}
          idPopper="status"
          options={statusOptions}
          canBeOpen={canBeOpenStatus}
          buttonLabel="Status"
          onButtonClick={handleClickStatus}
          openPopper={openPopperStatus}
          setOpenPopper={setOpenPopperStatus}
        />
      </Box>
    </Box>
  );
};

export default ReceivablesFilter;
