import React from 'react';
import { Link } from 'react-router-dom';

import { Box, FormControl, FormControlLabel, Grid, makeStyles, Radio, RadioGroup } from '@material-ui/core';
import InfoRounded from '@material-ui/icons/InfoRounded';
import { Alert } from '@material-ui/lab';

import PageSection from 'components/PageSection';
import Button from 'components/UI/Button';
import Typography from 'components/UI/Typography';
import { Order, PAGES } from 'models/machine';
import { createServiceOrder } from 'services/serviceOrders';

import { OrderType } from '../constants';

const useStyles = makeStyles((theme) => ({
  formControlLabel: {
    background: theme.palette.backgroundColor.surface?.marbleDark,
    border: `1px solid ${theme.palette.carbon[10]}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  checkAddressLink: {
    color: theme.palette.carbon[100],
  },
  card: {
    border: `1px solid ${theme.palette.carbon[10]}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(4),
    background: theme.palette.backgroundColor.surface?.secondary,
  },
  formControl: {
    margin: theme.spacing(4, 0),
    width: '100%',
  },
  buttonWrapper: {
    marginTop: theme.spacing(4),
    '& > button:first-child': {
      marginBottom: theme.spacing(1),
    },
  },
}));

type PaperRollsRequestProps = {
  setPage: React.Dispatch<React.SetStateAction<PAGES>>;
  setCode: React.Dispatch<React.SetStateAction<number>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setOrder: React.Dispatch<React.SetStateAction<Order>>;
  setOrderType: React.Dispatch<React.SetStateAction<OrderType | undefined>>;
  loading: boolean;
  order: Order;
  handleBack: () => void;
};

const PaperRollsRequest = ({
  setPage,
  setCode,
  setOrder,
  setOrderType,
  setLoading,
  loading,
  order,
  handleBack,
}: PaperRollsRequestProps): JSX.Element => {
  const classes = useStyles();
  const [quantity, setQuantity] = React.useState<string | null>(null);
  const [, setSuccess] = React.useState<boolean>(false);

  const handleChangeQuantity = (event: React.ChangeEvent<{ value: string }>): void => {
    const { value } = event.target;
    setQuantity(value as string);
    setOrder({ ...order, coilQuantity: parseInt(value, 10) as number });
  };

  const handleSubmit = (): void => {
    setLoading(true);
    try {
      if (quantity) {
        createServiceOrder({ coilQuantity: parseInt(quantity, 10) })
          .then(({ data }) => {
            if (data?.code) {
              setPage(PAGES.SUCCESS);
              setCode(data.code);
              setOrderType(OrderType.COIL);
            }
          })
          .catch(() => {
            setSuccess(false);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } catch (error) {
      setSuccess(false);
    }
  };

  return (
    <Grid container spacing={2} justify="center">
      <Box width={504} className={classes.card}>
        <PageSection title="Pedido de bobinas">
          <Typography variant="body">
            Preencha as informações do formulário para solicitar bobinas para a sua maquininha.
          </Typography>

          <FormControl component="fieldset" className={classes.formControl}>
            <Typography variant="bodyBold" paragraph gutterBottom>
              Quantidade de bobinas?
            </Typography>
            <RadioGroup aria-label="gender" name="quantityPaperRolls" value={quantity} onChange={handleChangeQuantity}>
              <div className={classes.formControlLabel}>
                <FormControlLabel
                  value={1}
                  control={<Radio checked={quantity === '1'} />}
                  label={
                    <>
                      <Typography variant="caption3">1 pacote</Typography>
                      <Typography variant="caption">{` (6 unidades de bobinas)`}</Typography>
                    </>
                  }
                />
              </div>
              <div className={classes.formControlLabel}>
                <FormControlLabel
                  value={2}
                  control={<Radio checked={quantity === '2'} />}
                  label={
                    <>
                      <Typography variant="caption3">2 pacote</Typography>
                      <Typography variant="caption">{` (12 unidades de bobinas)`}</Typography>
                    </>
                  }
                />
              </div>
              <div className={classes.formControlLabel}>
                <FormControlLabel
                  value={3}
                  control={<Radio checked={quantity === '3'} />}
                  label={
                    <>
                      <Typography variant="caption3">3 pacote</Typography>
                      <Typography variant="caption">{` (18 unidades de bobinas)`}</Typography>
                    </>
                  }
                />
              </div>
            </RadioGroup>
          </FormControl>
          <Alert severity="info" icon={<InfoRounded color="inherit" />}>
            <Typography variant="body2" gutterBottom>
              {`Seu pedido será entregue no endereço do estabelecimento cadastrado em nosso sistema. `}
              <Link to="/account" className={classes.checkAddressLink}>
                <Typography variant="bodylink">Confira seu endereço de entrega</Typography>
              </Link>
            </Typography>
            <Typography variant="body2">
              Prazo de entrega: 5 dias para regiões Sul, Sudeste e Centro Oeste e 7 dias para regiões Norte e Nordeste.
            </Typography>
          </Alert>
          <Grid container justify="center" className={classes.buttonWrapper}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              disabled={quantity === null || loading}
              onClick={handleSubmit}
            >
              solicitar bobinas
            </Button>
            <Button fullWidth variant="outlined" size="large" onClick={handleBack}>
              Voltar
            </Button>
          </Grid>
        </PageSection>
      </Box>
    </Grid>
  );
};

export default PaperRollsRequest;
