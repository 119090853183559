import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Grid, Box, makeStyles } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import PageSection from 'components/PageSection';
import ReceivablesFilter from 'components/Receivables/Filter';
import PendingReceivablesGroup from 'components/Receivables/Group/PendingReceivablesGroup';
import ReceivablesGroup from 'components/Receivables/Group/ReceivablesGroup';
import ReceivablesGroupSkeleton from 'components/Receivables/Group/Skeleton';
import ReceivablesHeader from 'components/Receivables/Header';
import SmallCard from 'components/SmallCard';
import Button from 'components/UI/Button';
import EmptyList from 'components/UI/EmptyList/EmptyList';
import Feature from 'components/UI/Feature';
import PageAlertNotification from 'components/UI/PageAlertNotification';
import ScrollToTopButton from 'components/UI/ScrollToTopButton/ScrollToTopButton';
import ServerError from 'components/UI/ServerError';
import { formatRangeDate } from 'helpers/formatters/formatters';
import { Features } from 'models/featureToggle';
import {
  ReceivableSummary,
  ReceivablesFilter as ReceivablesFilterType,
  ReceivablesQueryParamsDate,
  ReceivablesQueryParamsStatus,
  ReceivablesQueryParamsOperation,
  PendingReceivablesData,
  ReceivablesQueryParamsBrands,
  ReceivablesUrData,
} from 'models/receivables';
import {
  getPendingReceivables as getPendingReceivablesService,
  getReceivables,
  getReceivablesUrService,
} from 'services/receivables';
import { AccountSelectors } from 'store/accounts';

import { periodDictionary } from './constants';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(6, 0),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(4, 0),
    },
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(4),
    },
  },
  cardsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(9, 1fr)',
    gridGap: theme.spacing(2),
  },
  emptyStateContainer: {
    margin: theme.spacing(6, 'auto'),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(3, 'auto'),
    },
  },
  pageSection: {
    backgroundColor: theme.palette.backgroundColor.surface?.secondary,
    border: `1px solid ${theme.palette.carbon[10]}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    width: '100%',
  },
  iconLink: {
    color: theme.palette.textColor.link,
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    fontWeight: theme.typography.fontWeightBold,
    '& > svg': {
      marginRight: theme.spacing(0.5),
    },
  },
  link: {
    color: theme.palette.textColor.link,
    textDecoration: 'none',
  },
}));

const Receivables: React.FunctionComponent = () => {
  const firstPage = 1;
  const today = new Date();
  const classes = useStyles();

  const [summary, setSummary] = React.useState<ReceivableSummary | null>(null);
  const [summaryError, setSummaryError] = React.useState(false);
  const [isSummaryLoading, setIsSummaryLoading] = React.useState(true);
  const [summaryUpdatedAt, setSummaryUpdatedAt] = React.useState<string | undefined>(undefined);

  const [pendingReceivables, setPendingReceivables] = React.useState<PendingReceivablesData | null>(null);
  const [isPendingReceivablesLoading, setIsPendingReceivablesLoading] = React.useState(true);
  const [pendingReceivablesError, setPendingReceivablesError] = React.useState(false);

  const [receivables, setReceivables] = React.useState<ReceivablesUrData['items']>({});
  const [currentPage, setCurrentPage] = React.useState(firstPage);
  const [totalItems, setTotalItems] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);

  const urDates = React.useRef<string[] | null>(null);
  const [isReceivablesLoading, setIsReceivablesLoading] = React.useState(true);
  const [receivablesUpdatedAt, setReceivablesUpdatedAt] = React.useState<string | undefined>(undefined);
  const [receivablesError, setReceivablesError] = React.useState(false);

  const accountDocumentNumber = useSelector(AccountSelectors.accountDocumentNumber);

  const isReceivablesListEmpty = totalItems === 0;

  const [filter, setFilter] = React.useState<ReceivablesFilterType>({
    startDate: today,
    endDate: today,
    checkedDate: ReceivablesQueryParamsDate.TODAY,
    operation: ReceivablesQueryParamsOperation.ALL,
    status: ReceivablesQueryParamsStatus.ALL,
    brand: ReceivablesQueryParamsBrands.ALL,
  });

  const receivableService = React.useCallback(
    (currPage: number, callback: (data: ReceivablesUrData) => void) => {
      setIsReceivablesLoading(true);
      setReceivablesError(false);

      const { startDate, endDate, operation, status, brand } = filter;
      getReceivablesUrService({
        startDate,
        endDate,
        currentPage: currPage,
        perPage: 10,
        cardBrand: brand,
        status,
        operation,
      })
        .then(({ data }) => {
          if (data) {
            setReceivablesUpdatedAt(new Date().toISOString());
            setCurrentPage(data.currentPage);
            setTotalPages(data.totalPages);
            setTotalItems(data.totalItems);
            callback({ ...data });
            urDates.current = Object.keys(data.items);
          } else {
            throw new Error();
          }
        })
        .catch(() => setReceivablesError(true))
        .finally(() => setIsReceivablesLoading(false));
    },
    [filter],
  );

  const getPendingReceivables = React.useCallback(
    (currPage: number, callback: (data: PendingReceivablesData) => void) => {
      setIsPendingReceivablesLoading(true);
      setPendingReceivablesError(false);

      getPendingReceivablesService(currPage, 10)
        .then((response) => {
          const { data, errorMessage } = response;

          if (data) {
            callback(data);
          } else {
            throw new Error(errorMessage);
          }
        })
        .catch((error) => {
          setPendingReceivablesError(true);
          return { errorMessage: error };
        })
        .finally(() => setIsPendingReceivablesLoading(false));
    },
    [],
  );

  const getReceivablesSummaryRequest = React.useCallback((documentNumber: string) => {
    setIsSummaryLoading(true);
    setSummaryError(false);

    getReceivables(documentNumber)
      .then(({ data }) => {
        if (data) {
          setSummaryUpdatedAt(new Date().toISOString());
          setSummary(data);
        } else {
          throw new Error();
        }
      })
      .catch(() => setSummaryError(true))
      .finally(() => setIsSummaryLoading(false));
  }, []);

  React.useLayoutEffect(() => {
    receivableService(firstPage, (data) => {
      setReceivables(data.items);
    });
  }, [receivableService]);

  React.useLayoutEffect(() => {
    getPendingReceivables(firstPage, (data) => {
      setPendingReceivables(data);
    });
  }, [getPendingReceivables]);

  React.useLayoutEffect(() => {
    getReceivablesSummaryRequest(accountDocumentNumber as string);
  }, [getReceivablesSummaryRequest, accountDocumentNumber]);

  const mergeReceivablesGroupItems = (oldRecord: any, newRecord: any) => {
    if (!oldRecord) return newRecord;
    Object.keys(newRecord).forEach((key) => {
      if (oldRecord[key]) {
        oldRecord[key].urs = [...oldRecord[key].urs, ...newRecord[key].urs];
      } else {
        oldRecord[key] = newRecord[key];
      }
    });
    return oldRecord;
  };
  const nextPage = () => {
    receivableService(currentPage + 1, (data) => {
      const mergedData = mergeReceivablesGroupItems(receivables, data.items);
      setReceivables(mergedData);
    });
  };

  const renderReceivableList = () => {
    if (isReceivablesLoading) {
      return <ReceivablesGroupSkeleton />;
    }

    if (receivablesError) {
      return (
        <ServerError
          onTryAgain={() => {
            receivableService(firstPage, (data) => {
              setReceivables(data.items);
            });
          }}
        />
      );
    }

    if (receivables && Object.keys(receivables).length > 0) {
      return Object.keys(receivables).map((receivablesDate) => {
        const dateInfo = receivables[receivablesDate];
        return (
          <ReceivablesGroup
            key={receivablesDate}
            date={receivablesDate}
            list={dateInfo.urs}
            total={dateInfo.totalAmount}
            totalReceivables={dateInfo.totalReceivables}
          />
        );
      });
    }

    return <EmptyList subtitle="Você não tem recebíveis para exibir com o filtro selecionado." />;
  };

  const renderPendingReceivableList = () => {
    if (
      !isPendingReceivablesLoading &&
      !pendingReceivablesError &&
      pendingReceivables &&
      pendingReceivables.transactions.length
    ) {
      return <PendingReceivablesGroup total={pendingReceivables.total} list={pendingReceivables.transactions} />;
    }

    return null;
  };

  const systemAlertText =
    'Os pagamentos agendados para hoje 03/08, das vendas realizadas na modalidade crédito, serão pagos até amanhã - 04/08. Pedimos desculpas pelo transtorno e estamos trabalhando todos os dias para melhorar.';

  return (
    <Grid container className={classes.container}>
      <Feature name={Features.RECEIVABLES_ALERT_STATUS}>
        <PageAlertNotification systemAlertText={systemAlertText} />
      </Feature>
      <Grid item xs={12}>
        <ReceivablesHeader filter={filter} />
      </Grid>
      <Grid item xs={12} className={classes.pageSection}>
        <PageSection
          title="Resumo dos recebíveis"
          updatedAt={summaryUpdatedAt}
          updatedAtLoading={isSummaryLoading}
          hasError={summaryError}
          onUpdateCard={() => getReceivablesSummaryRequest(accountDocumentNumber as string)}
        >
          <Box className={classes.cardsContainer}>
            <SmallCard
              prefix="R$"
              value={summary?.today?.total}
              count={`${summary?.today?.count} vendas` || ''}
              rangeDate={formatRangeDate(summary?.today?.startDate || '')}
              text={periodDictionary.today}
              isLoading={isSummaryLoading}
              hasError={summaryError}
            />
            <SmallCard
              prefix="R$"
              value={summary?.nextWeek?.total}
              count={`${summary?.nextWeek?.count} vendas` || ''}
              rangeDate={formatRangeDate(summary?.nextWeek?.startDate || '', summary?.nextWeek?.endDate)}
              text={periodDictionary.nextWeek}
              isLoading={isSummaryLoading}
              hasError={summaryError}
            />
            <SmallCard
              prefix="R$"
              value={summary?.nextMonth?.total}
              count={`${summary?.nextMonth?.count} vendas` || ''}
              rangeDate={formatRangeDate(summary?.nextMonth?.startDate || '', summary?.nextMonth?.endDate)}
              text={periodDictionary.nextMonth}
              isLoading={isSummaryLoading}
              hasError={summaryError}
            />
          </Box>
        </PageSection>
      </Grid>

      <Grid item xs={12} className={classes.pageSection}>
        <PageSection
          title="Recebíveis"
          updatedAt={receivablesUpdatedAt}
          updatedAtLoading={isReceivablesLoading}
          hasError={receivablesError}
          onUpdateCard={() => {
            receivableService(currentPage, (data) => {
              const mergedData = mergeReceivablesGroupItems(receivables, data.items);
              setReceivables(mergedData);
            });
          }}
          linkElement={
            <div className={classes.iconLink}>
              <HelpOutlineIcon fontSize="small" />
              <Link to="/faq" className={classes.link} aria-label="entenda seus recebíveis.">
                Entenda seus recebíveis
              </Link>
            </div>
          }
        >
          <Feature name={Features.PENDING_RECEIVABLES}>{renderPendingReceivableList()}</Feature>

          <ReceivablesFilter setFilter={setFilter} filter={filter} />

          {renderReceivableList()}

          {!isReceivablesListEmpty && (
            <Grid item xs={12}>
              {totalPages > currentPage && (
                <Box display="flex" justifyContent="center">
                  <Button
                    type="submit"
                    title="Mostrar mais"
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={isReceivablesLoading}
                    onClick={nextPage}
                  >
                    MOSTRAR MAIS
                  </Button>
                  <ScrollToTopButton />
                </Box>
              )}
            </Grid>
          )}
        </PageSection>
      </Grid>
    </Grid>
  );
};

export default Receivables;
