import type { ThunkApi } from '@portal-types/store';

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { WhitelabelConfig } from 'models/whitelabelConfig';
import { getWhitelabelConfigs } from 'services/whitelabel';

export const clearWhitelabel = createAction('whitelabel/clear-whitelabel');

export const getWhitelabel = createAsyncThunk<WhitelabelConfig, void, ThunkApi>(
  'whitelabel/get-whitelabel',
  async (_, thunkApi) => {
    try {
      const whitelabelConfigs = await getWhitelabelConfigs();

      if (whitelabelConfigs.data) {
        return whitelabelConfigs.data;
      }

      throw new Error();
    } catch (error) {
      return thunkApi.rejectWithValue({ message: error.message });
    }
  },
);
