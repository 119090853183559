import type { FunctionComponent } from 'react';
import React from 'react';

import { Box, useMediaQuery } from '@material-ui/core';
import type { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import SideMenuNavigation from './Navigation/SideMenuNavigation';

const useStyles = makeStyles((theme) => ({
  sidebar: {
    minHeight: 64,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      alignItems: 'flex-start',
      padding: theme.spacing(0),
      minWidth: 240,
    },
  },
}));

const SideMenu: FunctionComponent = () => {
  const matchesLgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const classes = useStyles();

  return (
    <>
      {matchesLgUp && (
        <Box className={classes.sidebar}>
          <SideMenuNavigation />
        </Box>
      )}
    </>
  );
};

export default SideMenu;
