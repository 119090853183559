import React, { ChangeEvent, FunctionComponent, useState } from 'react';

import { Box, Card, InputAdornment, TextField, Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import { Alert } from '@material-ui/lab';

import Button from 'components/UI/Button';
import Typography from 'components/UI/Typography';
import { formatAmount, formatIntToAmount } from 'helpers/formatters/formatters';
import type { AcquirersInputValues, SimulationAcquirer, SimulationResponseData } from 'models/anticipation';

import AcquirerLabel from '../AcquirerLabel';
import AnticipationRules from '../AnticipationRules';
import AnticipationTypographyHighlight from '../TypographyHighlight';

const useStyles = makeStyles((theme) => ({
  carbon70: {
    color: theme.palette.carbon[70],
  },
  carbon35: {
    color: theme.palette.carbon[35],
  },
  neutralDark: {
    color: theme.palette.textColor.dark,
  },
  showRulesDecoration: {
    textDecorationLine: 'underline',
    color: theme.palette.textColor.link,
    padding: 0,
    '&:hover': {
      textDecorationLine: 'underline',
      background: 'transparent',
    },
  },
  button: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(4, 8, 1),
    },
  },
  amountSection: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& > div:first-child': {
        marginBottom: theme.spacing(3),
      },
    },
  },
  acquirerCards: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      alignItems: 'start',
    },
  },
  textFieldAmount: {
    width: 120,
    '& .MuiInput-input': {
      textAlign: 'end',
    },
    [theme.breakpoints.down('xs')]: {
      width: 160,
    },
  },
}));

type AcquirerFunctions = {
  changeAcquirerAmount: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    acquirerDocumentNumber: string,
  ) => void;
  switchAcquirer: (e: ChangeEvent<HTMLInputElement>, acquirerDocumentNumber: string) => void;
};

type CardAcquirerProps = AcquirerFunctions & {
  acquirer: SimulationAcquirer;
  active: boolean;
  disabled: boolean;
  error: boolean;
  value: number;
};

const CardAcquirer: FunctionComponent<CardAcquirerProps> = ({
  acquirer,
  active,
  disabled,
  error,
  value,
  switchAcquirer,
  changeAcquirerAmount,
}) => {
  const classes = useStyles();
  const { acquirerDocumentNumber, grossAmount } = acquirer;

  return (
    <Card className={classes.acquirerCards}>
      <Switch
        id={`switch-acquirer-${acquirerDocumentNumber}`}
        checked={active}
        onChange={(e) => switchAcquirer(e, acquirerDocumentNumber)}
        disabled={disabled}
        color="primary"
        inputProps={{
          'aria-label': 'Botão de ligar ou desligar o recebivel à antecipar',
        }}
      />
      <Box className={classes.amountSection}>
        <Box>
          <AcquirerLabel documentNumber={acquirer.acquirerDocumentNumber} name={acquirer.acquirerName} />
          <Typography variant="caption" component="p" className={active ? classes.neutralDark : classes.carbon35}>
            {`Disponível: R$ ${formatAmount(grossAmount)}`}
          </Typography>
        </Box>

        <TextField
          className={classes.textFieldAmount}
          error={active ? error : false}
          helperText={error ? 'Valor mínimo R$ 100,00' : null}
          label="Editar valor"
          id={`input-acquirer-amount-${acquirerDocumentNumber}`}
          value={formatIntToAmount(value)}
          onChange={(e) => changeAcquirerAmount(e, acquirerDocumentNumber)}
          disabled={!active}
          size="small"
          InputProps={{
            margin: 'none',
            startAdornment: (
              <InputAdornment position="start">
                <Typography variant="caption" className={active ? classes.neutralDark : classes.carbon35}>
                  R$
                </Typography>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Card>
  );
};

type Props = AcquirerFunctions & {
  acquirers: SimulationResponseData['acquirers'];
  amountWithDiscount: number;
  acquirerInputAmount: AcquirersInputValues;
  onSimulate: () => void;
};

const AnticipationInit: FunctionComponent<Props> = ({
  acquirers,
  acquirerInputAmount,
  changeAcquirerAmount,
  switchAcquirer,
  onSimulate,
}) => {
  const classes = useStyles();
  const [rulesOpen, setRulesOpen] = useState(false);
  const totalReceiving = Object.values(acquirerInputAmount)
    .filter((acquirer) => acquirer.active)
    .reduce((acc, curr) => acc + curr.value, 0);

  const errorAcquirerAmount = Object.values(acquirerInputAmount).filter((acquirer) => acquirer.error);

  return (
    <>
      <AnticipationRules open={rulesOpen} onClose={() => setRulesOpen(false)} onOpen={() => setRulesOpen(true)} />
      <Card>
        <Typography variant="bodyBold" component="h2" gutterBottom>
          Qual valor você deseja antecipar?
        </Typography>
        <Typography variant="caption" className={classes.carbon70} component="p">
          Aqui você encontra os recebíveis de todas as maquininhas registradas em seu CNPJ.
        </Typography>
        <Typography variant="caption" component="p" className={classes.carbon70}>
          O valor mínimo para antecipação é de R$ 100,00 por adquirente.
        </Typography>

        <Box my={4}>
          {acquirers.map((acquirer) => {
            const { active, disabled, error, value } = acquirerInputAmount[acquirer.acquirerDocumentNumber];
            return (
              <CardAcquirer
                acquirer={acquirer}
                active={active}
                disabled={disabled}
                error={error}
                value={value}
                switchAcquirer={switchAcquirer}
                changeAcquirerAmount={changeAcquirerAmount}
                key={acquirer.acquirerDocumentNumber}
              />
            );
          })}
        </Box>

        <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
          <Box>
            <Typography variant="caption" component="p" className={classes.carbon70}>
              Total a receber
            </Typography>
          </Box>

          <AnticipationTypographyHighlight value={formatIntToAmount(totalReceiving)} />
        </Box>

        <Alert severity="info" color="info" icon={<InfoRoundedIcon color="inherit" />}>
          {'Atenção: taxas desta operação incidirão sobre o valor selecionado. '}

          <Button
            variant="text"
            size="small"
            className={classes.showRulesDecoration}
            onClick={() => setRulesOpen(true)}
            aria-label="Abrir janela de dúvidas frequentes."
          >
            Confira as dúvidas frequentes
          </Button>
        </Alert>

        <Box className={classes.button}>
          <Button
            fullWidth
            title="Simular antecipação"
            variant="contained"
            color="primary"
            size="large"
            aria-label="Simular antecipação de recebíveis."
            onClick={onSimulate}
            disabled={totalReceiving < 10000 || !!errorAcquirerAmount.length}
          >
            Simular antecipação
          </Button>
        </Box>
      </Card>
    </>
  );
};

export default AnticipationInit;
