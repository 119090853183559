import { formatRequestDate } from 'helpers/date';
import { formatUserDocument } from 'helpers/formatters/formatters';
import {
  SaleGroup,
  SalesSummaryResponse,
  SalesParams,
  SalesSummaryV2Response,
  SalesSummary,
  SaleItem,
  SalesGraphResponse,
  SalesGraph,
} from 'models/sales';
import { client } from 'services/client';

type GetAllSalesParsed = { data?: SaleGroup; errorMessage?: string };

export const getSalesService = async ({
  documentNumber,
  startDate,
  endDate,
  status,
  paymentType,
  page,
  'page-size': pageSize,
}: SalesParams): Promise<GetAllSalesParsed> => {
  try {
    const statusQuery = () => {
      if (status === 'all') return '';
      return `&status=${status}`;
    };

    const paymentTypeQuery = () => {
      if (paymentType === 'all') return '';
      return `&payment-type=${paymentType}`;
    };

    const localStorageAuthJson = localStorage.getItem('persist:auth') as string;
    const localStorageAuth = JSON.parse(localStorageAuthJson);
    const localStorageAuthData = JSON.parse(localStorageAuth.data);

    const response = await client.get<SaleGroup>(
      `portal/v1/sales?start-date=${formatRequestDate(startDate)}&end-date=${formatRequestDate(
        endDate,
      )}${statusQuery()}${paymentTypeQuery()}&page=${page}&page-size=${pageSize || 30}`,
      {
        token: localStorageAuthData.accessToken,
        useWhitelabel: true,
        headers: {
          'document-number': formatUserDocument(documentNumber),
        },
      },
    );

    if (response.parsedBody) {
      return { data: response.parsedBody };
    }

    throw new Error('Ocorreu um erro ao buscar as vendas');
  } catch (e) {
    return {
      errorMessage: 'Ocorreu um erro ao buscar as vendas',
    };
  }
};

export type SalesReportResponse = {
  data?: BlobPart;
  errorMessage?: string;
};

export const getSalesReportService = async ({
  startDate,
  endDate,
  status,
  paymentType,
  page,
}: SalesParams): Promise<SalesReportResponse> => {
  try {
    const statusQuery = () => {
      if (status === 'all') return '';
      return `&status=${status}`;
    };

    const paymentTypeQuery = () => {
      if (paymentType === 'all') return '';
      return `&payment-type=${paymentType}`;
    };

    const localStorageAuthJson = localStorage.getItem('persist:auth') as string;
    const localStorageAuth = JSON.parse(localStorageAuthJson);
    const localStorageAuthData = JSON.parse(localStorageAuth.data);

    const localStorageAccountJson = localStorage.getItem('persist:account') as string;
    const localStorageAccount = JSON.parse(localStorageAccountJson);
    const localStorageAccountData = JSON.parse(localStorageAccount.data);

    const response = await client.get<SalesReportResponse>(
      `portal/v1/sales?start-date=${formatRequestDate(startDate)}&end-date=${formatRequestDate(
        endDate,
      )}${statusQuery()}${paymentTypeQuery()}&page=${page}&page-size=30`,
      {
        token: localStorageAuthData.accessToken,
        useWhitelabel: true,
        headers: {
          'document-number': formatUserDocument(localStorageAccountData.documentNumber),
          accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
      },
    );
    if (response.parsedBlob) {
      return { data: response.parsedBlob };
    }

    throw new Error('Ocorreu um erro ao buscar as vendas');
  } catch (e) {
    return {
      errorMessage: 'Ocorreu um erro ao buscar as vendas',
    };
  }
};

export type GetSalesSummaryParsed = {
  data?: SalesSummaryResponse;
  errorMessage?: string;
};

export const getSalesSummary = async (documentNumber: string): Promise<GetSalesSummaryParsed> => {
  try {
    const localStorageAuthJson = localStorage.getItem('persist:auth') as string;
    const localStorageAuth = JSON.parse(localStorageAuthJson);
    const localStorageAuthData = JSON.parse(localStorageAuth.data);

    const response = await client.get<SalesSummaryResponse>(`portal/v1/sales/summary`, {
      token: localStorageAuthData.accessToken,
      useWhitelabel: true,
      headers: {
        'document-number': formatUserDocument(documentNumber),
      },
    });

    if (response.parsedBody) {
      return { data: response.parsedBody };
    }

    throw new Error('Ocorreu um erro ao buscar o resumo de vendas');
  } catch (e) {
    return {
      errorMessage: 'Ocorreu um erro ao buscar o resumo de vendas',
    };
  }
};

export type LastSalesResponse = {
  data?: SaleItem[];
  errorMessage?: string;
};

export const getLastSales = async (startDate: Date, endDate: Date, count: number): Promise<LastSalesResponse> => {
  const localStorageAuthJson = localStorage.getItem('persist:auth') as string;
  const localStorageAuth = JSON.parse(localStorageAuthJson);
  const localStorageAuthData = JSON.parse(localStorageAuth.data);

  const localStorageAccountJson = localStorage.getItem('persist:account') as string;
  const localStorageAccount = JSON.parse(localStorageAccountJson);
  const localStorageAccountData = JSON.parse(localStorageAccount.data);

  try {
    const { parsedBody } = await client.get<SaleItem[]>(
      `portal/v1/sales/last?start-date=${formatRequestDate(startDate)}&end-date=${formatRequestDate(
        endDate,
      )}&count=${count}`,
      {
        token: localStorageAuthData.accessToken,
        useWhitelabel: true,
        headers: {
          'document-number': formatUserDocument(localStorageAccountData.documentNumber),
        },
      },
    );

    if (parsedBody) {
      return {
        data: parsedBody,
      };
    }

    throw new Error('Ocorreu um erro ao buscar as ultimas vendas');
  } catch (error) {
    return {
      errorMessage: 'Ocorreu um erro ao buscar as ultimas vendas',
    };
  }
};

export const getSales = async (documentNumber: string): Promise<SalesSummaryV2Response> => {
  const localStorageAuthJson = localStorage.getItem('persist:auth') as string;
  const localStorageAuth = JSON.parse(localStorageAuthJson);
  const localStorageAuthData = JSON.parse(localStorageAuth.data);

  try {
    const { parsedBody } = await client.get<SalesSummary>(`portal/v2/sales/summary`, {
      token: localStorageAuthData.accessToken,
      useWhitelabel: true,
      headers: {
        'document-number': formatUserDocument(documentNumber),
      },
    });

    if (parsedBody) {
      return {
        data: parsedBody,
      };
    }

    throw new Error('Ocorreu um erro ao buscar os Valores Parciais');
  } catch (error) {
    return { errorMessage: error.message };
  }
};

export type EDIReportResponse = {
  data?: BlobPart;
  errorMessage?: string;
};

export const getSalesEdi = async (date: string): Promise<EDIReportResponse> => {
  try {
    const localStorageAuthJson = localStorage.getItem('persist:auth') as string;
    const localStorageAuth = JSON.parse(localStorageAuthJson);
    const localStorageAuthData = JSON.parse(localStorageAuth.data);

    const localStorageAccountJson = localStorage.getItem('persist:account') as string;
    const localStorageAccount = JSON.parse(localStorageAccountJson);
    const localStorageAccountData = JSON.parse(localStorageAccount.data);

    const response = await client.get<EDIReportResponse>(`portal/v1/sales/edi?date=${formatRequestDate(date)}`, {
      token: localStorageAuthData.accessToken,
      useWhitelabel: true,
      headers: {
        'document-number': formatUserDocument(localStorageAccountData.documentNumber),
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    });

    if (response.parsedBlob) {
      return { data: response.parsedBlob };
    }

    throw new Error('Ocorreu um erro ao trazer o relatório do EDI');
  } catch (e) {
    return {
      errorMessage: 'Ocorreu um erro ao trazer o relatório do EDI',
    };
  }
};

export type TransactionPendingActionResponse = {
  data?: {
    success: boolean;
  };
  errorMessage?: string;
};

export const transactionPendingAction = async (
  action: string,
  transactionCode: string,
): Promise<TransactionPendingActionResponse> => {
  const actionLabel = action === 'confirm' ? 'confirmar' : 'reverter';

  try {
    const localStorageAuthJson = localStorage.getItem('persist:auth') as string;
    const localStorageAuth = JSON.parse(localStorageAuthJson);
    const localStorageAuthData = JSON.parse(localStorageAuth.data);

    const localStorageAccountJson = localStorage.getItem('persist:account') as string;
    const localStorageAccount = JSON.parse(localStorageAccountJson);
    const localStorageAccountData = JSON.parse(localStorageAccount.data);

    const response = await client.patch<{ success: boolean }>(`portal/v1/sales/transaction`, {
      token: localStorageAuthData.accessToken,
      headers: {
        'document-number': formatUserDocument(localStorageAccountData.documentNumber),
        'content-type': 'application/json',
      },
      data: {
        saleCode: transactionCode,
        action,
      },
    });

    if (response.parsedBody) {
      return { data: response.parsedBody };
    }

    throw new Error(`Ocorreu um erro ao tentar ${actionLabel} a transação`);
  } catch (e) {
    return {
      errorMessage: `Ocorreu um erro ao tentar ${actionLabel} a transação`,
    };
  }
};

export const salesGraph = async (startDate: string, endDate: string): Promise<SalesGraphResponse> => {
  try {
    const localStorageAuthJson = localStorage.getItem('persist:auth') as string;
    const localStorageAuth = JSON.parse(localStorageAuthJson);
    const localStorageAuthData = JSON.parse(localStorageAuth.data);

    const localStorageAccountJson = localStorage.getItem('persist:account') as string;
    const localStorageAccount = JSON.parse(localStorageAccountJson);
    const localStorageAccountData = JSON.parse(localStorageAccount.data);

    const { parsedBody } = await client.get<SalesGraph>(
      `portal/v1/sales/graph?startDate=${startDate}&endDate=${endDate}`,
      {
        token: localStorageAuthData.accessToken,
        useWhitelabel: true,
        headers: {
          'document-number': formatUserDocument(localStorageAccountData.documentNumber),
          'content-type': 'application/json',
        },
      },
    );

    if (parsedBody) {
      return { data: parsedBody };
    }

    throw new Error('Occorreu um erro ao buscar os dados de comparativo de vendas.');
  } catch (error) {
    return {
      errorMessage: error.message,
    };
  }
};
