import type { CSSProperties, ElementType } from 'react';

import type { TypographyProps } from '@portal-types/ui/typography';

export const variantDictionary: Record<
  TypographyProps['variant'],
  { style: CSSProperties; component: ElementType<any> }
> = {
  // Newest Typography
  // -> Desktop <-
  titleHeroSemibold: {
    style: {
      fontFamily: 'C6 Sans Display',
      fontWeight: 400,
      fontSize: '4.5rem',
    },
    component: 'h1',
  },
  titleheroLight: {
    style: {
      fontFamily: 'C6 Sans Display',
      fontWeight: 300,
      fontSize: '4.5rem',
    },
    component: 'h1',
  },
  titlexxLargeSemibold: {
    style: {
      fontFamily: 'C6 Sans Display',
      fontSize: '56px',
      fontWeight: 600,
      lineHeight: '70px',
    },
    component: 'h1',
  },
  titlexxLargeLight: {
    style: {
      fontFamily: 'C6 Sans Display',
      fontSize: '56px',
      fontWeight: 300,
      lineHeight: '70px',
    },
    component: 'h1',
  },
  titlexLargeSemibold: {
    style: {
      fontFamily: 'C6 Sans Display',
      fontSize: '48px',
      fontWeight: 600,
      lineHeight: '60px',
    },
    component: 'h1',
  },
  titlexLargeLight: {
    style: {
      fontFamily: 'C6 Sans Display',
      fontSize: '48px',
      fontWeight: 300,
      lineHeight: '60px',
    },
    component: 'h1',
  },
  titleLargeSemibold: {
    style: {
      fontFamily: 'C6 Sans Display',
      fontSize: '40px',
      fontWeight: 600,
      lineHeight: '50px',
    },
    component: 'h1',
  },
  titleLargeLight: {
    style: {
      fontFamily: 'C6 Sans Display',
      fontSize: '40px',
      fontWeight: 300,
      lineHeight: '50px',
    },
    component: 'h1',
  },
  titleMediumSemibold: {
    style: {
      fontFamily: 'C6 Sans Display',
      fontSize: '32px',
      fontWeight: 600,
      lineHeight: '40px',
    },
    component: 'h1',
  },
  titleMediumLight: {
    style: {
      fontFamily: 'C6 Sans Display',
      fontSize: '32px',
      fontWeight: 300,
      lineHeight: '40px',
    },
    component: 'h1',
  },
  titleSmallSemibold: {
    style: {
      fontFamily: 'C6 Sans Display',
      fontSize: '24px',
      fontWeight: 600,
      lineHeight: '30px',
    },
    component: 'h1',
  },
  titleSmallLight: {
    style: {
      fontFamily: 'C6 Sans Display',
      fontSize: '24px',
      fontWeight: 300,
      lineHeight: '30px',
    },
    component: 'h1',
  },
  titlexSmall: {
    style: {
      fontFamily: 'C6 Sans Display',
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '22px',
    },
    component: 'h1',
  },
  subtitleLarge: {
    style: {
      fontFamily: 'C6 Sans Display',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '20px',
    },
    component: 'h2',
  },
  subtitleSmall: {
    style: {
      fontFamily: 'C6 Sans Display',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '18px',
    },
    component: 'h2',
  },
  overlineLarge: {
    style: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '18px',
    },
    component: 'span',
  },
  overlineMedium: {
    style: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '15px',
    },
    component: 'span',
  },
  overlineSmall: {
    style: {
      fontSize: '10px',
      fontWeight: 400,
      lineHeight: '12px',
    },
    component: 'span',
  },
  paragraphxLargeRegularShort: {
    style: {
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: '23px',
    },
    component: 'p',
  },
  paragraphxLargeRegularLong: {
    style: {
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: '27px',
    },
    component: 'p',
  },
  paragraphxLargeBoldShort: {
    style: {
      fontSize: '18px',
      fontWeight: 700,
      lineHeight: '23px',
    },
    component: 'p',
  },
  paragraphxLargeBoldLong: {
    style: {
      fontSize: '18px',
      fontWeight: 700,
      lineHeight: '27px',
    },
    component: 'p',
  },
  paragraphLargeRegularShort: {
    style: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '20px',
    },
    component: 'p',
  },
  paragraphLargeRegularLong: {
    style: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
    },
    component: 'p',
  },
  paragraphLargeBoldShort: {
    style: {
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '20px',
    },
    component: 'p',
  },
  paragraphLargeBoldLong: {
    style: {
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '24px',
    },
    component: 'p',
  },
  paragraphMediumRegularShort: {
    style: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
    },
    component: 'p',
  },
  paragraphMediumBoldShort: {
    style: {
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '20px',
    },
    component: 'p',
  },
  paragraphSmallRegular: {
    style: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '15px',
    },
    component: 'p',
  },
  paragraphSmallBold: {
    style: {
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: '15px',
    },
    component: 'p',
  },
  paragraphxSmallRegular: {
    style: {
      fontSize: '10px',
      fontWeight: 400,
      lineHeight: '12px',
    },
    component: 'p',
  },
  paragraphxSmallBold: {
    style: {
      fontSize: '10px',
      fontWeight: 700,
      lineHeight: '12px',
    },
    component: 'p',
  },
  // -> Components <-
  textButtonRegular: {
    style: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '18px',
      letterSpacing: '0.5px',
    },
    component: 'span',
  },
  textButtonSmall: {
    style: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '16px',
      letterSpacing: '0.5px',
    },
    component: 'span',
  },
  textButtonBold: {
    style: {
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '20px',
      letterSpacing: '0.5px',
    },
    component: 'span',
  },

  inputLabel: {
    style: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
    },
    component: 'span',
  },
  inputBordlessNumber: {
    style: {
      fontSize: '28px',
      fontWeight: 400,
      lineHeight: '32px',
    },
    component: 'span',
  },
  inputBordlessText: {
    style: {
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: '32px',
    },
    component: 'span',
  },
  titleAppBarAndroid: {
    style: {
      fontFamily: 'C6 Sans Display',
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '24px',
    },
    component: 'span',
  },
  titleAppBarIos: {
    style: {
      fontFamily: 'C6 Sans Display',
      fontSize: '17px',
      fontWeight: 600,
      lineHeight: '24px',
    },
    component: 'span',
  },
  overline2: {
    style: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '16px',
    },
    component: 'span',
  },
  valueText: {
    style: {
      fontSize: '32px',
      fontWeight: 400,
      lineHeight: '40px',
    },
    component: 'span',
  },
  valueStrikeThrough: {
    style: {
      fontSize: '32px',
      fontWeight: 400,
      lineHeight: '40px',
    },
    component: 'span',
  },
  userNameText: {
    style: {
      fontFamily: 'C6 Sans Display',
      fontSize: '24px',
      fontWeight: 600,
      lineHeight: '28px',
    },
    component: 'span',
  },
  overlineBig: {
    style: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
    },
    component: 'span',
  },
  textLinkBold: {
    style: {
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '15px',
    },
    component: 'a',
  },
  textLinkSmall: {
    style: {
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: '16px',
    },
    component: 'a',
  },
  chatBubbleText: {
    style: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
    },
    component: 'span',
  },
  shortcutListItem: {
    style: {
      fontFamily: 'C6 Sans Display',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '16px',
    },
    component: 'span',
  },

  // Old Typography
  subtitleWebBold: {
    style: {
      fontSize: '1.5rem',
      lineHeight: '2rem',
      fontWeight: 600,
    },
    component: 'h2',
  },
  subtitleWebUppercaseSmall: {
    style: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
      textTransform: 'uppercase',
      fontWeight: 600,
    },
    component: 'h2',
  },
  subtitleMobile2: {
    style: {
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
      fontWeight: 600,
    },
    component: 'h2',
  },
  subtitleMobile: {
    style: {
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
      fontWeight: 400,
    },
    component: 'h2',
  },
  body: {
    style: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontWeight: 400,
    },
    component: 'p',
  },
  bodyBold: {
    style: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontWeight: 700,
    },
    component: 'p',
  },
  paragraph: {
    style: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontWeight: 500,
    },
    component: 'p',
  },
  caption: {
    style: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
    },
    component: 'span',
  },
  textLink: {
    style: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
      fontWeight: 400,
      textDecorationLine: 'underline',
    },
    component: 'span',
  },

  // Really Old Typography

  header: {
    style: {
      fontSize: '1.5rem',
      lineHeight: '2rem',
      fontWeight: 400,
    },
    component: 'h3',
  },
  header2: {
    style: {
      fontSize: '1.5rem',
      lineHeight: '2rem',
      fontWeight: 600,
    },
    component: 'h3',
  },

  subtitle: {
    style: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontWeight: 500,
    },
    component: 'h5',
  },
  subtitle2: {
    style: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontWeight: 600,
    },
    component: 'h5',
  },
  body2: {
    style: {
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: '1.5rem',
    },
    component: 'p',
  },
  bodylink: {
    style: {
      fontWeight: 600,
      fontSize: '0.875rem',
      lineHeight: '1.5rem',
      textDecorationLine: 'underline',
      cursor: 'pointer',
    },
    component: 'span',
  },
  bodylink2: {
    style: {
      fontWeight: 600,
      fontSize: '0.75rem',
      lineHeight: '1rem',
      textDecorationLine: 'underline',
      cursor: 'pointer',
    },
    component: 'span',
  },
  button: {
    style: {
      lineHeight: '1.125rem',
      letterSpacing: '0.046875em',
    },
    component: 'span',
  },
  caption2: {
    style: {
      fontWeight: 500,
      fontSize: '0.75rem',
      lineHeight: '1rem',
    },
    component: 'span',
  },
  caption3: {
    style: {
      fontWeight: 600,
      fontSize: '0.75rem',
      lineHeight: '1rem',
    },
    component: 'span',
  },
  overline: {
    style: {
      fontWeight: 600,
      fontSize: '0.625rem',
      lineHeight: '1rem',
    },
    component: 'span',
  },
};
