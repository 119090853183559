import type { FunctionComponent } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';

import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AccessTimeRoundedIcon from '@material-ui/icons/AccessTimeRounded';
import PhoneInTalkRoundedIcon from '@material-ui/icons/PhoneInTalkRounded';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import HelpCard from 'components/Help/Card';
import HelpCenter from 'components/Help/Center';
import PageTitle from 'components/UI/PageTitle';
import { WhitelabelSelectors } from 'store/whitelabel';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(6, 0),

    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(4, 0),
    },

    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(4),
    },
  },
  cardsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(9, 1fr)',
    gridGap: theme.spacing(2),
  },
}));

const Help: FunctionComponent = () => {
  const classes = useStyles();
  const whitelabelPages = useSelector(WhitelabelSelectors.whitelabelPages)!;
  const whitelabelLinks = useSelector(WhitelabelSelectors.whitelabelLinks)!;

  const Container = () => (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <PageTitle title="Ajuda" />
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.cardsContainer}>
          <HelpCard
            icon={AccessTimeRoundedIcon}
            title={whitelabelPages.help['opening-hours'].title}
            content={whitelabelPages.help['opening-hours'].content}
          />
          <HelpCard
            icon={PhoneInTalkRoundedIcon}
            title={whitelabelPages.help['first-contact'].title}
            content={whitelabelPages.help['first-contact'].content}
            phone={whitelabelLinks['help-first-contact']}
          />
          {whitelabelLinks['help-second-contact'].includes('api.whatsapp.com/send?phone=') ? (
            <HelpCard
              icon={WhatsAppIcon}
              title={whitelabelPages.help['second-contact'].title}
              content={whitelabelPages.help['second-contact'].content}
              link={whitelabelLinks['help-second-contact']}
            />
          ) : (
            <HelpCard
              icon={PhoneInTalkRoundedIcon}
              title={whitelabelPages.help['second-contact'].title}
              content={whitelabelPages.help['second-contact'].content}
              phone={whitelabelLinks['help-second-contact']}
            />
          )}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <HelpCenter />
      </Grid>
    </Grid>
  );

  return <Container />;
};

export default Help;
