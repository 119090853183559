import type { FunctionComponent, ReactElement } from 'react';
import React, { useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { Typography, Modal } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import { ToggleButtonGroup, ToggleButton, Alert } from '@material-ui/lab';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import Button from 'components/UI/Button';
import { isValid, startOfDay, add } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { datePickerFormat } from 'helpers/date';
import { ReceivablesQueryParamsDate, ReceivablesFilter } from 'models/receivables';

const useStyles = makeStyles((theme) => ({
  toggleButtonContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItens: 'flex-start',
    },
  },
  modalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    padding: theme.spacing(3),
    border: `1px solid ${theme.palette.carbon[10]}`,
    background: theme.palette.common.white,
    borderRadius: 8,
    width: '90%',
    maxWidth: 550,
    overflowY: 'auto',
    outline: 'none',
    height: 'auto',
    maxHeight: '90vh',
  },
  modalHeader: {
    paddingBottom: theme.spacing(3),
  },
  modalBody: {
    display: 'grid',
    gap: theme.spacing(1),
    justifyContent: 'center',
    gridTemplateColumns: '1fr 1fr',
  },
  modalErrorMessage: {
    color: theme.palette.error.main,
    paddingTop: theme.spacing(1),
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'center',
    padding: `${theme.spacing(4)}px 0 ${theme.spacing(2)}px`,
    '& > *:first-child': {
      margin: theme.spacing(0, 1, 0, 0),
    },
    [theme.breakpoints.down('xs')]: {
      '& > *:first-child': {
        margin: theme.spacing(0, 0, 1),
      },
      flexDirection: 'column',
    },
  },
  datePicker: {
    [theme.breakpoints.down('sm')]: {
      '& + .MuiInputAdornment-root': {
        width: theme.spacing(5),
      },
    },
  },
  actionButton: {
    padding: theme.spacing(1, 2),
    justifyContent: 'center',
    alignItems: 'center',
  },
  alertWrapper: {
    margin: theme.spacing(2, 0),
  },
  toogleButton: {
    borderRadius: 120,
  },
  calendarIcon: {
    width: 14,
    marginRight: theme.spacing(0.5),
  },
}));

type Props = {
  setFilter: (filters: ReceivablesFilter) => void;
  filter: ReceivablesFilter;
};

const ReceivablesFilterDate: FunctionComponent<Props> = ({ setFilter, filter }): ReactElement => {
  const classes = useStyles();
  const [customDate, setCustomDate] = useState(false);
  const [checkedDate, setCheckedDate] = useState<ReceivablesQueryParamsDate>(filter.checkedDate);
  const [startDatePeriod, setStartDatePeriod] = useState<Date>(filter.startDate);
  const [endDatePeriod, setEndDatePeriod] = useState<Date>(filter.endDate);
  const [datePeriodApplyButtonDisabled, setDatePeriodApplyButtonDisabled] = useState(false);
  const [isValidDate, setIsValidDate] = useState(true);

  const dateButtons: Array<{ value: ReceivablesQueryParamsDate; label: string | any }> = [
    { value: ReceivablesQueryParamsDate.TODAY, label: 'Hoje' },
    { value: ReceivablesQueryParamsDate.NEXT_WEEK, label: '7 dias' },
    { value: ReceivablesQueryParamsDate.NEXT_MONTH, label: '30 dias' },
    {
      value: ReceivablesQueryParamsDate.DATE_PERIOD,
      label:
        checkedDate === ReceivablesQueryParamsDate.DATE_PERIOD ? (
          <Box display="flex" alignItems="center">
            <CalendarTodayIcon className={classes.calendarIcon} />
            {`${datePickerFormat(startDatePeriod)}- ${datePickerFormat(endDatePeriod)}`}
          </Box>
        ) : (
          <Box display="flex" alignItems="center">
            <CalendarTodayIcon className={classes.calendarIcon} />
            Período
          </Box>
        ),
    },
  ];

  const errorMessages = {
    minDate: 'A data final não pode ser menor que a data de inicio',
    maxDate: 'Você não pode selecionar recebíveis no futuro',
    format: 'Formato de data inválido',
  };

  type DateRange = () => {
    [key in Exclude<ReceivablesQueryParamsDate, ReceivablesQueryParamsDate.DATE_PERIOD>]: () => {
      endDate: Date;
      startDate: Date;
    };
  };

  const dateRange: DateRange = () => {
    const endDate = new Date();
    const startDate = new Date(endDate);
    const nextDay = endDate.getDate() + 1;
    const nextSevenDays = endDate.getDate() + 7;
    return {
      [ReceivablesQueryParamsDate.TODAY]: () => {
        return { startDate, endDate };
      },
      [ReceivablesQueryParamsDate.NEXT_DAY]: () => {
        startDate.setDate(nextDay);
        endDate.setDate(nextDay);
        return { startDate, endDate };
      },
      [ReceivablesQueryParamsDate.NEXT_WEEK]: () => {
        startDate.setDate(nextDay);
        endDate.setDate(nextSevenDays);
        return { startDate, endDate };
      },
      [ReceivablesQueryParamsDate.NEXT_MONTH]: () => {
        startDate.setDate(nextSevenDays + 1);
        endDate.setDate(nextSevenDays + 31);
        return { startDate, endDate };
      },
    };
  };

  const applyDateFilter = (clickedDate: ReceivablesQueryParamsDate) => {
    if (clickedDate === null && checkedDate !== ReceivablesQueryParamsDate.DATE_PERIOD) {
      return;
    }
    if (
      clickedDate === ReceivablesQueryParamsDate.DATE_PERIOD ||
      (checkedDate === ReceivablesQueryParamsDate.DATE_PERIOD && clickedDate === null)
    ) {
      setCustomDate(true);
      return;
    }

    setCheckedDate(clickedDate);
    const { endDate, startDate } = dateRange()[clickedDate]();
    setFilter({
      ...filter,
      checkedDate: clickedDate,
      endDate,
      startDate,
    });
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <ToggleButtonGroup
        value={checkedDate}
        exclusive
        onChange={(_, currLabel) => applyDateFilter(currLabel)}
        className={classes.toggleButtonContainer}
      >
        {dateButtons.map(({ label, value }) => (
          <ToggleButton className={classes.toogleButton} key={value} value={value} selected={checkedDate === value}>
            <Typography variant="body2">{label}</Typography>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <Modal
        className={classes.modalContainer}
        open={customDate}
        aria-labelledby="Selecione as datas"
        aria-describedby="Selecione um período de datas para seus recebíveis."
      >
        <section className={classes.modalContent}>
          <header className={classes.modalHeader}>
            <Typography variant="h6">Selecione um período</Typography>
          </header>
          <div className={classes.modalBody}>
            <KeyboardDatePicker
              value={startDatePeriod}
              label="Data de início"
              variant="inline"
              format="dd/MM/yyyy"
              minDate={new Date('2021-06-07T00:00')}
              minDateMessage="Você não pode consultar períodos inferiores à 07/06/2021"
              autoOk
              inputProps={{ className: classes.datePicker }}
              onChange={(date) => {
                setStartDatePeriod(date as Date);
                if ((date as Date) > endDatePeriod) {
                  setDatePeriodApplyButtonDisabled(true);
                  setIsValidDate(false);
                } else if (!isValid(date)) {
                  setIsValidDate(false);
                } else {
                  setIsValidDate(true);
                  setDatePeriodApplyButtonDisabled(false);
                }
              }}
              KeyboardButtonProps={{
                'aria-label': 'Selecionar data de início dos recebíveis',
              }}
              invalidDateMessage={errorMessages.format}
            />
            <KeyboardDatePicker
              value={endDatePeriod}
              label="Data de fim"
              variant="inline"
              format="dd/MM/yyyy"
              autoOk
              maxDate={add(startDatePeriod, { days: 31 })}
              maxDateMessage="O período deve ter um intervalo máximo de 31 dias"
              inputProps={{ className: classes.datePicker }}
              onChange={(date) => {
                setEndDatePeriod(date as Date);
                if (startOfDay(startDatePeriod) > (date as Date)) {
                  setDatePeriodApplyButtonDisabled(true);
                  setIsValidDate(false);
                } else if (!isValid(date)) {
                  setIsValidDate(false);
                } else {
                  setIsValidDate(true);
                  setDatePeriodApplyButtonDisabled(false);
                }
              }}
              KeyboardButtonProps={{
                'aria-label': 'Selecionar data de fim dos recebíveis',
              }}
              invalidDateMessage={errorMessages.format}
            />
          </div>
          <div className={classes.modalErrorMessage}>
            {datePeriodApplyButtonDisabled ? 'A data de fim não pode ser anterior a data de início' : null}
          </div>
          <div className={classes.alertWrapper}>
            <Alert severity="info" color="info" icon={<InfoRoundedIcon color="inherit" />}>
              Para consultar períodos anteriores à 07/06/2021, entre em contato através da nossa Central de ajuda.
            </Alert>
          </div>
          <footer className={classes.modalFooter}>
            <Button
              onClick={() => {
                setStartDatePeriod(filter.startDate);
                setEndDatePeriod(filter.endDate);
                setDatePeriodApplyButtonDisabled(false);
                setCustomDate(false);
              }}
              variant="outlined"
              className={classes.actionButton}
              fullWidth
              color="default"
              type="reset"
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              title="aplicar filtros"
              className={classes.actionButton}
              fullWidth
              color="primary"
              disabled={datePeriodApplyButtonDisabled || !isValidDate}
              onClick={() => {
                setFilter({
                  endDate: endDatePeriod,
                  startDate: startDatePeriod,
                  brand: filter.brand,
                  operation: filter.operation,
                  status: filter.status,
                  checkedDate: ReceivablesQueryParamsDate.DATE_PERIOD,
                });
                setCheckedDate(ReceivablesQueryParamsDate.DATE_PERIOD);
                setCustomDate(false);
              }}
            >
              Aplicar filtros
            </Button>
          </footer>
        </section>
      </Modal>
    </MuiPickersUtilsProvider>
  );
};

export default ReceivablesFilterDate;
